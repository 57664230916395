// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v5.28.3
// source: infra/unifiedfleet/api/v1/models/chromeos/lab/siminfo.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "unifiedfleet.api.v1.models.chromeos.lab";

/** Next Tag: 22 */
export enum NetworkProvider {
  NETWORK_OTHER = 0,
  NETWORK_UNSUPPORTED = 5,
  NETWORK_TEST = 1,
  NETWORK_ATT = 2,
  NETWORK_TMOBILE = 3,
  NETWORK_VERIZON = 4,
  NETWORK_SPRINT = 6,
  NETWORK_DOCOMO = 7,
  NETWORK_SOFTBANK = 8,
  NETWORK_KDDI = 9,
  NETWORK_RAKUTEN = 10,
  NETWORK_VODAFONE = 11,
  NETWORK_EE = 12,
  NETWORK_AMARISOFT = 13,
  NETWORK_ROGER = 14,
  NETWORK_BELL = 15,
  NETWORK_TELUS = 16,
  NETWORK_FI = 17,
  NETWORK_CBRS = 18,
  NETWORK_LINEMO = 19,
  NETWORK_POVO = 20,
  NETWORK_HANSHIN = 21,
}

export function networkProviderFromJSON(object: any): NetworkProvider {
  switch (object) {
    case 0:
    case "NETWORK_OTHER":
      return NetworkProvider.NETWORK_OTHER;
    case 5:
    case "NETWORK_UNSUPPORTED":
      return NetworkProvider.NETWORK_UNSUPPORTED;
    case 1:
    case "NETWORK_TEST":
      return NetworkProvider.NETWORK_TEST;
    case 2:
    case "NETWORK_ATT":
      return NetworkProvider.NETWORK_ATT;
    case 3:
    case "NETWORK_TMOBILE":
      return NetworkProvider.NETWORK_TMOBILE;
    case 4:
    case "NETWORK_VERIZON":
      return NetworkProvider.NETWORK_VERIZON;
    case 6:
    case "NETWORK_SPRINT":
      return NetworkProvider.NETWORK_SPRINT;
    case 7:
    case "NETWORK_DOCOMO":
      return NetworkProvider.NETWORK_DOCOMO;
    case 8:
    case "NETWORK_SOFTBANK":
      return NetworkProvider.NETWORK_SOFTBANK;
    case 9:
    case "NETWORK_KDDI":
      return NetworkProvider.NETWORK_KDDI;
    case 10:
    case "NETWORK_RAKUTEN":
      return NetworkProvider.NETWORK_RAKUTEN;
    case 11:
    case "NETWORK_VODAFONE":
      return NetworkProvider.NETWORK_VODAFONE;
    case 12:
    case "NETWORK_EE":
      return NetworkProvider.NETWORK_EE;
    case 13:
    case "NETWORK_AMARISOFT":
      return NetworkProvider.NETWORK_AMARISOFT;
    case 14:
    case "NETWORK_ROGER":
      return NetworkProvider.NETWORK_ROGER;
    case 15:
    case "NETWORK_BELL":
      return NetworkProvider.NETWORK_BELL;
    case 16:
    case "NETWORK_TELUS":
      return NetworkProvider.NETWORK_TELUS;
    case 17:
    case "NETWORK_FI":
      return NetworkProvider.NETWORK_FI;
    case 18:
    case "NETWORK_CBRS":
      return NetworkProvider.NETWORK_CBRS;
    case 19:
    case "NETWORK_LINEMO":
      return NetworkProvider.NETWORK_LINEMO;
    case 20:
    case "NETWORK_POVO":
      return NetworkProvider.NETWORK_POVO;
    case 21:
    case "NETWORK_HANSHIN":
      return NetworkProvider.NETWORK_HANSHIN;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum NetworkProvider");
  }
}

export function networkProviderToJSON(object: NetworkProvider): string {
  switch (object) {
    case NetworkProvider.NETWORK_OTHER:
      return "NETWORK_OTHER";
    case NetworkProvider.NETWORK_UNSUPPORTED:
      return "NETWORK_UNSUPPORTED";
    case NetworkProvider.NETWORK_TEST:
      return "NETWORK_TEST";
    case NetworkProvider.NETWORK_ATT:
      return "NETWORK_ATT";
    case NetworkProvider.NETWORK_TMOBILE:
      return "NETWORK_TMOBILE";
    case NetworkProvider.NETWORK_VERIZON:
      return "NETWORK_VERIZON";
    case NetworkProvider.NETWORK_SPRINT:
      return "NETWORK_SPRINT";
    case NetworkProvider.NETWORK_DOCOMO:
      return "NETWORK_DOCOMO";
    case NetworkProvider.NETWORK_SOFTBANK:
      return "NETWORK_SOFTBANK";
    case NetworkProvider.NETWORK_KDDI:
      return "NETWORK_KDDI";
    case NetworkProvider.NETWORK_RAKUTEN:
      return "NETWORK_RAKUTEN";
    case NetworkProvider.NETWORK_VODAFONE:
      return "NETWORK_VODAFONE";
    case NetworkProvider.NETWORK_EE:
      return "NETWORK_EE";
    case NetworkProvider.NETWORK_AMARISOFT:
      return "NETWORK_AMARISOFT";
    case NetworkProvider.NETWORK_ROGER:
      return "NETWORK_ROGER";
    case NetworkProvider.NETWORK_BELL:
      return "NETWORK_BELL";
    case NetworkProvider.NETWORK_TELUS:
      return "NETWORK_TELUS";
    case NetworkProvider.NETWORK_FI:
      return "NETWORK_FI";
    case NetworkProvider.NETWORK_CBRS:
      return "NETWORK_CBRS";
    case NetworkProvider.NETWORK_LINEMO:
      return "NETWORK_LINEMO";
    case NetworkProvider.NETWORK_POVO:
      return "NETWORK_POVO";
    case NetworkProvider.NETWORK_HANSHIN:
      return "NETWORK_HANSHIN";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum NetworkProvider");
  }
}

/** Next Tag: 3 */
export enum SIMType {
  SIM_UNKNOWN = 0,
  SIM_PHYSICAL = 1,
  SIM_DIGITAL = 2,
}

export function sIMTypeFromJSON(object: any): SIMType {
  switch (object) {
    case 0:
    case "SIM_UNKNOWN":
      return SIMType.SIM_UNKNOWN;
    case 1:
    case "SIM_PHYSICAL":
      return SIMType.SIM_PHYSICAL;
    case 2:
    case "SIM_DIGITAL":
      return SIMType.SIM_DIGITAL;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SIMType");
  }
}

export function sIMTypeToJSON(object: SIMType): string {
  switch (object) {
    case SIMType.SIM_UNKNOWN:
      return "SIM_UNKNOWN";
    case SIMType.SIM_PHYSICAL:
      return "SIM_PHYSICAL";
    case SIMType.SIM_DIGITAL:
      return "SIM_DIGITAL";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SIMType");
  }
}

/** Next Tag: 6 */
export interface SIMInfo {
  readonly slotId: number;
  readonly type: SIMType;
  readonly eid: string;
  readonly testEsim: boolean;
  readonly profileInfo: readonly SIMProfileInfo[];
}

/** Next Tag: 7 */
export interface SIMProfileInfo {
  readonly iccid: string;
  readonly simPin: string;
  readonly simPuk: string;
  readonly carrierName: NetworkProvider;
  readonly ownNumber: string;
  /** The SIM state as reported by the cellular modem. */
  readonly state: SIMProfileInfo_State;
  /**
   * Features supported by the profile.
   * These features are used to determine what tests can be run against which SIMs
   * in the lab, see go/cros-cellular-features for more information.
   * File bugs against buganizer component: 979102.
   */
  readonly features: readonly SIMProfileInfo_Feature[];
}

/** Possible states of the SIM profile. */
export enum SIMProfileInfo_State {
  /** UNSPECIFIED - State not set. */
  UNSPECIFIED = 0,
  /** BROKEN - The device is unusable. */
  BROKEN = 1,
  /** LOCKED - The device needs to be unlocked. */
  LOCKED = 2,
  /** NO_NETWORK - No data connection available and not in a failed state. */
  NO_NETWORK = 3,
  /** WORKING - The device is registered with a network provider, and data connections and messaging may be available for use. */
  WORKING = 4,
  /** WRONG_CONFIG - The device has an invalid configuration in UFS. */
  WRONG_CONFIG = 5,
}

export function sIMProfileInfo_StateFromJSON(object: any): SIMProfileInfo_State {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return SIMProfileInfo_State.UNSPECIFIED;
    case 1:
    case "BROKEN":
      return SIMProfileInfo_State.BROKEN;
    case 2:
    case "LOCKED":
      return SIMProfileInfo_State.LOCKED;
    case 3:
    case "NO_NETWORK":
      return SIMProfileInfo_State.NO_NETWORK;
    case 4:
    case "WORKING":
      return SIMProfileInfo_State.WORKING;
    case 5:
    case "WRONG_CONFIG":
      return SIMProfileInfo_State.WRONG_CONFIG;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SIMProfileInfo_State");
  }
}

export function sIMProfileInfo_StateToJSON(object: SIMProfileInfo_State): string {
  switch (object) {
    case SIMProfileInfo_State.UNSPECIFIED:
      return "UNSPECIFIED";
    case SIMProfileInfo_State.BROKEN:
      return "BROKEN";
    case SIMProfileInfo_State.LOCKED:
      return "LOCKED";
    case SIMProfileInfo_State.NO_NETWORK:
      return "NO_NETWORK";
    case SIMProfileInfo_State.WORKING:
      return "WORKING";
    case SIMProfileInfo_State.WRONG_CONFIG:
      return "WRONG_CONFIG";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SIMProfileInfo_State");
  }
}

/** Possible features that the SIM supports. */
export enum SIMProfileInfo_Feature {
  /** FEATURE_UNSPECIFIED - Unset feature. */
  FEATURE_UNSPECIFIED = 0,
  /** FEATURE_LIVE_NETWORK - The SIM supports a generic live network. */
  FEATURE_LIVE_NETWORK = 1,
  /** FEATURE_SMS - The SIM supports SMS messaging. */
  FEATURE_SMS = 2,
}

export function sIMProfileInfo_FeatureFromJSON(object: any): SIMProfileInfo_Feature {
  switch (object) {
    case 0:
    case "FEATURE_UNSPECIFIED":
      return SIMProfileInfo_Feature.FEATURE_UNSPECIFIED;
    case 1:
    case "FEATURE_LIVE_NETWORK":
      return SIMProfileInfo_Feature.FEATURE_LIVE_NETWORK;
    case 2:
    case "FEATURE_SMS":
      return SIMProfileInfo_Feature.FEATURE_SMS;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SIMProfileInfo_Feature");
  }
}

export function sIMProfileInfo_FeatureToJSON(object: SIMProfileInfo_Feature): string {
  switch (object) {
    case SIMProfileInfo_Feature.FEATURE_UNSPECIFIED:
      return "FEATURE_UNSPECIFIED";
    case SIMProfileInfo_Feature.FEATURE_LIVE_NETWORK:
      return "FEATURE_LIVE_NETWORK";
    case SIMProfileInfo_Feature.FEATURE_SMS:
      return "FEATURE_SMS";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SIMProfileInfo_Feature");
  }
}

function createBaseSIMInfo(): SIMInfo {
  return { slotId: 0, type: 0, eid: "", testEsim: false, profileInfo: [] };
}

export const SIMInfo: MessageFns<SIMInfo> = {
  encode(message: SIMInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.slotId !== 0) {
      writer.uint32(8).int32(message.slotId);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.eid !== "") {
      writer.uint32(26).string(message.eid);
    }
    if (message.testEsim !== false) {
      writer.uint32(32).bool(message.testEsim);
    }
    for (const v of message.profileInfo) {
      SIMProfileInfo.encode(v!, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SIMInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSIMInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.slotId = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.eid = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.testEsim = reader.bool();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.profileInfo.push(SIMProfileInfo.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SIMInfo {
    return {
      slotId: isSet(object.slotId) ? globalThis.Number(object.slotId) : 0,
      type: isSet(object.type) ? sIMTypeFromJSON(object.type) : 0,
      eid: isSet(object.eid) ? globalThis.String(object.eid) : "",
      testEsim: isSet(object.testEsim) ? globalThis.Boolean(object.testEsim) : false,
      profileInfo: globalThis.Array.isArray(object?.profileInfo)
        ? object.profileInfo.map((e: any) => SIMProfileInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SIMInfo): unknown {
    const obj: any = {};
    if (message.slotId !== 0) {
      obj.slotId = Math.round(message.slotId);
    }
    if (message.type !== 0) {
      obj.type = sIMTypeToJSON(message.type);
    }
    if (message.eid !== "") {
      obj.eid = message.eid;
    }
    if (message.testEsim !== false) {
      obj.testEsim = message.testEsim;
    }
    if (message.profileInfo?.length) {
      obj.profileInfo = message.profileInfo.map((e) => SIMProfileInfo.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<SIMInfo>): SIMInfo {
    return SIMInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SIMInfo>): SIMInfo {
    const message = createBaseSIMInfo() as any;
    message.slotId = object.slotId ?? 0;
    message.type = object.type ?? 0;
    message.eid = object.eid ?? "";
    message.testEsim = object.testEsim ?? false;
    message.profileInfo = object.profileInfo?.map((e) => SIMProfileInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSIMProfileInfo(): SIMProfileInfo {
  return { iccid: "", simPin: "", simPuk: "", carrierName: 0, ownNumber: "", state: 0, features: [] };
}

export const SIMProfileInfo: MessageFns<SIMProfileInfo> = {
  encode(message: SIMProfileInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.iccid !== "") {
      writer.uint32(10).string(message.iccid);
    }
    if (message.simPin !== "") {
      writer.uint32(18).string(message.simPin);
    }
    if (message.simPuk !== "") {
      writer.uint32(26).string(message.simPuk);
    }
    if (message.carrierName !== 0) {
      writer.uint32(32).int32(message.carrierName);
    }
    if (message.ownNumber !== "") {
      writer.uint32(42).string(message.ownNumber);
    }
    if (message.state !== 0) {
      writer.uint32(48).int32(message.state);
    }
    writer.uint32(58).fork();
    for (const v of message.features) {
      writer.int32(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SIMProfileInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSIMProfileInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.iccid = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.simPin = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.simPuk = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.carrierName = reader.int32() as any;
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.ownNumber = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        }
        case 7: {
          if (tag === 56) {
            message.features.push(reader.int32() as any);

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.features.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SIMProfileInfo {
    return {
      iccid: isSet(object.iccid) ? globalThis.String(object.iccid) : "",
      simPin: isSet(object.simPin) ? globalThis.String(object.simPin) : "",
      simPuk: isSet(object.simPuk) ? globalThis.String(object.simPuk) : "",
      carrierName: isSet(object.carrierName) ? networkProviderFromJSON(object.carrierName) : 0,
      ownNumber: isSet(object.ownNumber) ? globalThis.String(object.ownNumber) : "",
      state: isSet(object.state) ? sIMProfileInfo_StateFromJSON(object.state) : 0,
      features: globalThis.Array.isArray(object?.features)
        ? object.features.map((e: any) => sIMProfileInfo_FeatureFromJSON(e))
        : [],
    };
  },

  toJSON(message: SIMProfileInfo): unknown {
    const obj: any = {};
    if (message.iccid !== "") {
      obj.iccid = message.iccid;
    }
    if (message.simPin !== "") {
      obj.simPin = message.simPin;
    }
    if (message.simPuk !== "") {
      obj.simPuk = message.simPuk;
    }
    if (message.carrierName !== 0) {
      obj.carrierName = networkProviderToJSON(message.carrierName);
    }
    if (message.ownNumber !== "") {
      obj.ownNumber = message.ownNumber;
    }
    if (message.state !== 0) {
      obj.state = sIMProfileInfo_StateToJSON(message.state);
    }
    if (message.features?.length) {
      obj.features = message.features.map((e) => sIMProfileInfo_FeatureToJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<SIMProfileInfo>): SIMProfileInfo {
    return SIMProfileInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SIMProfileInfo>): SIMProfileInfo {
    const message = createBaseSIMProfileInfo() as any;
    message.iccid = object.iccid ?? "";
    message.simPin = object.simPin ?? "";
    message.simPuk = object.simPuk ?? "";
    message.carrierName = object.carrierName ?? 0;
    message.ownNumber = object.ownNumber ?? "";
    message.state = object.state ?? 0;
    message.features = object.features?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
