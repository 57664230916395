// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v5.28.3
// source: infra/unifiedfleet/api/v1/models/chromeos/lab/modeminfo.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "unifiedfleet.api.v1.models.chromeos.lab";

/** Next Tag: 9 */
export enum ModemType {
  MODEM_TYPE_UNSPECIFIED = 0,
  /** MODEM_TYPE_UNSUPPORTED - Unsupported modem */
  MODEM_TYPE_UNSUPPORTED = 8,
  /** MODEM_TYPE_QUALCOMM_SC7180 - Qualcomm modem */
  MODEM_TYPE_QUALCOMM_SC7180 = 1,
  /** MODEM_TYPE_FIBOCOMM_L850GL - Fibocomm modem */
  MODEM_TYPE_FIBOCOMM_L850GL = 2,
  /** MODEM_TYPE_NL668 - NL668 */
  MODEM_TYPE_NL668 = 3,
  /** MODEM_TYPE_FM350 - FM350 */
  MODEM_TYPE_FM350 = 4,
  /** MODEM_TYPE_FM101 - FM101 */
  MODEM_TYPE_FM101 = 5,
  /** MODEM_TYPE_QUALCOMM_SC7280 - SC7280 */
  MODEM_TYPE_QUALCOMM_SC7280 = 6,
  /** MODEM_TYPE_EM060 - EM060 */
  MODEM_TYPE_EM060 = 7,
  /** MODEM_TYPE_RW101 - RW101 */
  MODEM_TYPE_RW101 = 9,
  /** MODEM_TYPE_RW135 - RW135 */
  MODEM_TYPE_RW135 = 10,
  /** MODEM_TYPE_LCUK54 - LCUK54 */
  MODEM_TYPE_LCUK54 = 11,
}

export function modemTypeFromJSON(object: any): ModemType {
  switch (object) {
    case 0:
    case "MODEM_TYPE_UNSPECIFIED":
      return ModemType.MODEM_TYPE_UNSPECIFIED;
    case 8:
    case "MODEM_TYPE_UNSUPPORTED":
      return ModemType.MODEM_TYPE_UNSUPPORTED;
    case 1:
    case "MODEM_TYPE_QUALCOMM_SC7180":
      return ModemType.MODEM_TYPE_QUALCOMM_SC7180;
    case 2:
    case "MODEM_TYPE_FIBOCOMM_L850GL":
      return ModemType.MODEM_TYPE_FIBOCOMM_L850GL;
    case 3:
    case "MODEM_TYPE_NL668":
      return ModemType.MODEM_TYPE_NL668;
    case 4:
    case "MODEM_TYPE_FM350":
      return ModemType.MODEM_TYPE_FM350;
    case 5:
    case "MODEM_TYPE_FM101":
      return ModemType.MODEM_TYPE_FM101;
    case 6:
    case "MODEM_TYPE_QUALCOMM_SC7280":
      return ModemType.MODEM_TYPE_QUALCOMM_SC7280;
    case 7:
    case "MODEM_TYPE_EM060":
      return ModemType.MODEM_TYPE_EM060;
    case 9:
    case "MODEM_TYPE_RW101":
      return ModemType.MODEM_TYPE_RW101;
    case 10:
    case "MODEM_TYPE_RW135":
      return ModemType.MODEM_TYPE_RW135;
    case 11:
    case "MODEM_TYPE_LCUK54":
      return ModemType.MODEM_TYPE_LCUK54;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ModemType");
  }
}

export function modemTypeToJSON(object: ModemType): string {
  switch (object) {
    case ModemType.MODEM_TYPE_UNSPECIFIED:
      return "MODEM_TYPE_UNSPECIFIED";
    case ModemType.MODEM_TYPE_UNSUPPORTED:
      return "MODEM_TYPE_UNSUPPORTED";
    case ModemType.MODEM_TYPE_QUALCOMM_SC7180:
      return "MODEM_TYPE_QUALCOMM_SC7180";
    case ModemType.MODEM_TYPE_FIBOCOMM_L850GL:
      return "MODEM_TYPE_FIBOCOMM_L850GL";
    case ModemType.MODEM_TYPE_NL668:
      return "MODEM_TYPE_NL668";
    case ModemType.MODEM_TYPE_FM350:
      return "MODEM_TYPE_FM350";
    case ModemType.MODEM_TYPE_FM101:
      return "MODEM_TYPE_FM101";
    case ModemType.MODEM_TYPE_QUALCOMM_SC7280:
      return "MODEM_TYPE_QUALCOMM_SC7280";
    case ModemType.MODEM_TYPE_EM060:
      return "MODEM_TYPE_EM060";
    case ModemType.MODEM_TYPE_RW101:
      return "MODEM_TYPE_RW101";
    case ModemType.MODEM_TYPE_RW135:
      return "MODEM_TYPE_RW135";
    case ModemType.MODEM_TYPE_LCUK54:
      return "MODEM_TYPE_LCUK54";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ModemType");
  }
}

/** Next Tag: 6 */
export interface ModemInfo {
  readonly type: ModemType;
  /** Equipment Identifier */
  readonly imei: string;
  /** Comma separated band numbers */
  readonly supportedBands: string;
  /** Number of SIM's present */
  readonly simCount: number;
  /** The device model cellular sub-variant. */
  readonly modelVariant: string;
}

function createBaseModemInfo(): ModemInfo {
  return { type: 0, imei: "", supportedBands: "", simCount: 0, modelVariant: "" };
}

export const ModemInfo: MessageFns<ModemInfo> = {
  encode(message: ModemInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.imei !== "") {
      writer.uint32(18).string(message.imei);
    }
    if (message.supportedBands !== "") {
      writer.uint32(26).string(message.supportedBands);
    }
    if (message.simCount !== 0) {
      writer.uint32(32).int32(message.simCount);
    }
    if (message.modelVariant !== "") {
      writer.uint32(42).string(message.modelVariant);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ModemInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.imei = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.supportedBands = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.simCount = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.modelVariant = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModemInfo {
    return {
      type: isSet(object.type) ? modemTypeFromJSON(object.type) : 0,
      imei: isSet(object.imei) ? globalThis.String(object.imei) : "",
      supportedBands: isSet(object.supportedBands) ? globalThis.String(object.supportedBands) : "",
      simCount: isSet(object.simCount) ? globalThis.Number(object.simCount) : 0,
      modelVariant: isSet(object.modelVariant) ? globalThis.String(object.modelVariant) : "",
    };
  },

  toJSON(message: ModemInfo): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = modemTypeToJSON(message.type);
    }
    if (message.imei !== "") {
      obj.imei = message.imei;
    }
    if (message.supportedBands !== "") {
      obj.supportedBands = message.supportedBands;
    }
    if (message.simCount !== 0) {
      obj.simCount = Math.round(message.simCount);
    }
    if (message.modelVariant !== "") {
      obj.modelVariant = message.modelVariant;
    }
    return obj;
  },

  create(base?: DeepPartial<ModemInfo>): ModemInfo {
    return ModemInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemInfo>): ModemInfo {
    const message = createBaseModemInfo() as any;
    message.type = object.type ?? 0;
    message.imei = object.imei ?? "";
    message.supportedBands = object.supportedBands ?? "";
    message.simCount = object.simCount ?? 0;
    message.modelVariant = object.modelVariant ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
