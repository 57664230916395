// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v5.28.3
// source: infra/unifiedfleet/api/v1/models/rack_lse.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../../../../../google/protobuf/timestamp.pb";

export const protobufPackage = "unifiedfleet.api.v1.models";

/**
 * RackLSE is the Rack Lab Setup Environment.
 * It refers to the entity in the lab which has Rack(s) associated with it.
 * It also has other components associated with it like switches, kvms, rpms.
 */
export interface RackLSE {
  /**
   * A unique random generated string
   * The format will be rackLSEs/XXX
   */
  readonly name: string;
  /**
   * The prototype that this rack LSE should follow. System will use this
   * prototype to detect if the LSE is completed or valid.
   */
  readonly rackLsePrototype: string;
  readonly chromeBrowserRackLse?: ChromeBrowserRackLSE | undefined;
  readonly chromeosRackLse?:
    | ChromeOSRackLSE
    | undefined;
  /** The racks that this LSE is linked to. No rack is linked if it's NULL. */
  readonly racks: readonly string[];
  /** Record the last update timestamp of this RackLSE (In UTC timezone) */
  readonly updateTime: string | undefined;
}

/**
 * ChromeBrowserRackLSE refers to the entity which has
 * kvms and rpms connected to it in Chrome Browser lab
 */
export interface ChromeBrowserRackLSE {
  /**
   * KVMs in the rack, they're the attached kvms' names, which are the same as their hostnames
   *
   * @deprecated
   */
  readonly kvms: readonly string[];
  /**
   * Switches in the rack.
   *
   * @deprecated
   */
  readonly switches: readonly string[];
}

/**
 * ChromeOSRackLSE refers to the entity which has
 * switches and rpms connected to it in Chrome OS lab
 */
export interface ChromeOSRackLSE {
  /** RPMs in the rack */
  readonly rpms: readonly string[];
  /** KVMs in the rack */
  readonly kvms: readonly string[];
  readonly switches: readonly string[];
}

function createBaseRackLSE(): RackLSE {
  return {
    name: "",
    rackLsePrototype: "",
    chromeBrowserRackLse: undefined,
    chromeosRackLse: undefined,
    racks: [],
    updateTime: undefined,
  };
}

export const RackLSE: MessageFns<RackLSE> = {
  encode(message: RackLSE, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.rackLsePrototype !== "") {
      writer.uint32(18).string(message.rackLsePrototype);
    }
    if (message.chromeBrowserRackLse !== undefined) {
      ChromeBrowserRackLSE.encode(message.chromeBrowserRackLse, writer.uint32(26).fork()).join();
    }
    if (message.chromeosRackLse !== undefined) {
      ChromeOSRackLSE.encode(message.chromeosRackLse, writer.uint32(34).fork()).join();
    }
    for (const v of message.racks) {
      writer.uint32(42).string(v!);
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RackLSE {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRackLSE() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.rackLsePrototype = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.chromeBrowserRackLse = ChromeBrowserRackLSE.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.chromeosRackLse = ChromeOSRackLSE.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.racks.push(reader.string());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RackLSE {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      rackLsePrototype: isSet(object.rackLsePrototype) ? globalThis.String(object.rackLsePrototype) : "",
      chromeBrowserRackLse: isSet(object.chromeBrowserRackLse)
        ? ChromeBrowserRackLSE.fromJSON(object.chromeBrowserRackLse)
        : undefined,
      chromeosRackLse: isSet(object.chromeosRackLse) ? ChromeOSRackLSE.fromJSON(object.chromeosRackLse) : undefined,
      racks: globalThis.Array.isArray(object?.racks) ? object.racks.map((e: any) => globalThis.String(e)) : [],
      updateTime: isSet(object.updateTime) ? globalThis.String(object.updateTime) : undefined,
    };
  },

  toJSON(message: RackLSE): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.rackLsePrototype !== "") {
      obj.rackLsePrototype = message.rackLsePrototype;
    }
    if (message.chromeBrowserRackLse !== undefined) {
      obj.chromeBrowserRackLse = ChromeBrowserRackLSE.toJSON(message.chromeBrowserRackLse);
    }
    if (message.chromeosRackLse !== undefined) {
      obj.chromeosRackLse = ChromeOSRackLSE.toJSON(message.chromeosRackLse);
    }
    if (message.racks?.length) {
      obj.racks = message.racks;
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime;
    }
    return obj;
  },

  create(base?: DeepPartial<RackLSE>): RackLSE {
    return RackLSE.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RackLSE>): RackLSE {
    const message = createBaseRackLSE() as any;
    message.name = object.name ?? "";
    message.rackLsePrototype = object.rackLsePrototype ?? "";
    message.chromeBrowserRackLse = (object.chromeBrowserRackLse !== undefined && object.chromeBrowserRackLse !== null)
      ? ChromeBrowserRackLSE.fromPartial(object.chromeBrowserRackLse)
      : undefined;
    message.chromeosRackLse = (object.chromeosRackLse !== undefined && object.chromeosRackLse !== null)
      ? ChromeOSRackLSE.fromPartial(object.chromeosRackLse)
      : undefined;
    message.racks = object.racks?.map((e) => e) || [];
    message.updateTime = object.updateTime ?? undefined;
    return message;
  },
};

function createBaseChromeBrowserRackLSE(): ChromeBrowserRackLSE {
  return { kvms: [], switches: [] };
}

export const ChromeBrowserRackLSE: MessageFns<ChromeBrowserRackLSE> = {
  encode(message: ChromeBrowserRackLSE, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.kvms) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.switches) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeBrowserRackLSE {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeBrowserRackLSE() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.kvms.push(reader.string());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.switches.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeBrowserRackLSE {
    return {
      kvms: globalThis.Array.isArray(object?.kvms) ? object.kvms.map((e: any) => globalThis.String(e)) : [],
      switches: globalThis.Array.isArray(object?.switches) ? object.switches.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ChromeBrowserRackLSE): unknown {
    const obj: any = {};
    if (message.kvms?.length) {
      obj.kvms = message.kvms;
    }
    if (message.switches?.length) {
      obj.switches = message.switches;
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeBrowserRackLSE>): ChromeBrowserRackLSE {
    return ChromeBrowserRackLSE.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeBrowserRackLSE>): ChromeBrowserRackLSE {
    const message = createBaseChromeBrowserRackLSE() as any;
    message.kvms = object.kvms?.map((e) => e) || [];
    message.switches = object.switches?.map((e) => e) || [];
    return message;
  },
};

function createBaseChromeOSRackLSE(): ChromeOSRackLSE {
  return { rpms: [], kvms: [], switches: [] };
}

export const ChromeOSRackLSE: MessageFns<ChromeOSRackLSE> = {
  encode(message: ChromeOSRackLSE, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.rpms) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.kvms) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.switches) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOSRackLSE {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOSRackLSE() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rpms.push(reader.string());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.kvms.push(reader.string());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.switches.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOSRackLSE {
    return {
      rpms: globalThis.Array.isArray(object?.rpms) ? object.rpms.map((e: any) => globalThis.String(e)) : [],
      kvms: globalThis.Array.isArray(object?.kvms) ? object.kvms.map((e: any) => globalThis.String(e)) : [],
      switches: globalThis.Array.isArray(object?.switches) ? object.switches.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: ChromeOSRackLSE): unknown {
    const obj: any = {};
    if (message.rpms?.length) {
      obj.rpms = message.rpms;
    }
    if (message.kvms?.length) {
      obj.kvms = message.kvms;
    }
    if (message.switches?.length) {
      obj.switches = message.switches;
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOSRackLSE>): ChromeOSRackLSE {
    return ChromeOSRackLSE.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOSRackLSE>): ChromeOSRackLSE {
    const message = createBaseChromeOSRackLSE() as any;
    message.rpms = object.rpms?.map((e) => e) || [];
    message.kvms = object.kvms?.map((e) => e) || [];
    message.switches = object.switches?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
