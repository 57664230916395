// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v5.28.3
// source: infra/unifiedfleet/api/v1/models/machine.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../../../../../google/protobuf/timestamp.pb";
import { ChassisXBrandType, chassisXBrandTypeFromJSON, chassisXBrandTypeToJSON } from "./chassis_x.pb";
import { Location } from "./location.pb";
import { Nic } from "./network.pb";
import { OwnershipData } from "./ownership.pb";
import { Drac, KVMInterface, RPMInterface } from "./peripherals.pb";
import { State, stateFromJSON, stateToJSON } from "./state.pb";

export const protobufPackage = "unifiedfleet.api.v1.models";

/**
 * ChromeOSDeviceType refers to the different types of
 * device/machine in the Chrome OS lab
 */
export enum ChromeOSDeviceType {
  CHROME_OS_DEVICE_TYPE_UNSPECIFIED = 0,
  DEVICE_CHROMEBOOK = 1,
  DEVICE_LABSTATION = 2,
  DEVICE_SERVO = 3,
}

export function chromeOSDeviceTypeFromJSON(object: any): ChromeOSDeviceType {
  switch (object) {
    case 0:
    case "CHROME_OS_DEVICE_TYPE_UNSPECIFIED":
      return ChromeOSDeviceType.CHROME_OS_DEVICE_TYPE_UNSPECIFIED;
    case 1:
    case "DEVICE_CHROMEBOOK":
      return ChromeOSDeviceType.DEVICE_CHROMEBOOK;
    case 2:
    case "DEVICE_LABSTATION":
      return ChromeOSDeviceType.DEVICE_LABSTATION;
    case 3:
    case "DEVICE_SERVO":
      return ChromeOSDeviceType.DEVICE_SERVO;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ChromeOSDeviceType");
  }
}

export function chromeOSDeviceTypeToJSON(object: ChromeOSDeviceType): string {
  switch (object) {
    case ChromeOSDeviceType.CHROME_OS_DEVICE_TYPE_UNSPECIFIED:
      return "CHROME_OS_DEVICE_TYPE_UNSPECIFIED";
    case ChromeOSDeviceType.DEVICE_CHROMEBOOK:
      return "DEVICE_CHROMEBOOK";
    case ChromeOSDeviceType.DEVICE_LABSTATION:
      return "DEVICE_LABSTATION";
    case ChromeOSDeviceType.DEVICE_SERVO:
      return "DEVICE_SERVO";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ChromeOSDeviceType");
  }
}

/**
 * AttachedDeviceType refers to the different types of device/machine in the
 * lab environment.
 *
 * Next Tag: 7
 */
export enum AttachedDeviceType {
  ATTACHED_DEVICE_TYPE_UNSPECIFIED = 0,
  ATTACHED_DEVICE_TYPE_APPLE_PHONE = 1,
  ATTACHED_DEVICE_TYPE_ANDROID_PHONE = 2,
  ATTACHED_DEVICE_TYPE_APPLE_TABLET = 3,
  ATTACHED_DEVICE_TYPE_ANDROID_TABLET = 4,
  ATTACHED_DEVICE_TYPE_DEVBOARD = 5,
  ATTACHED_DEVICE_TYPE_JETSTREAM = 6,
}

export function attachedDeviceTypeFromJSON(object: any): AttachedDeviceType {
  switch (object) {
    case 0:
    case "ATTACHED_DEVICE_TYPE_UNSPECIFIED":
      return AttachedDeviceType.ATTACHED_DEVICE_TYPE_UNSPECIFIED;
    case 1:
    case "ATTACHED_DEVICE_TYPE_APPLE_PHONE":
      return AttachedDeviceType.ATTACHED_DEVICE_TYPE_APPLE_PHONE;
    case 2:
    case "ATTACHED_DEVICE_TYPE_ANDROID_PHONE":
      return AttachedDeviceType.ATTACHED_DEVICE_TYPE_ANDROID_PHONE;
    case 3:
    case "ATTACHED_DEVICE_TYPE_APPLE_TABLET":
      return AttachedDeviceType.ATTACHED_DEVICE_TYPE_APPLE_TABLET;
    case 4:
    case "ATTACHED_DEVICE_TYPE_ANDROID_TABLET":
      return AttachedDeviceType.ATTACHED_DEVICE_TYPE_ANDROID_TABLET;
    case 5:
    case "ATTACHED_DEVICE_TYPE_DEVBOARD":
      return AttachedDeviceType.ATTACHED_DEVICE_TYPE_DEVBOARD;
    case 6:
    case "ATTACHED_DEVICE_TYPE_JETSTREAM":
      return AttachedDeviceType.ATTACHED_DEVICE_TYPE_JETSTREAM;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AttachedDeviceType");
  }
}

export function attachedDeviceTypeToJSON(object: AttachedDeviceType): string {
  switch (object) {
    case AttachedDeviceType.ATTACHED_DEVICE_TYPE_UNSPECIFIED:
      return "ATTACHED_DEVICE_TYPE_UNSPECIFIED";
    case AttachedDeviceType.ATTACHED_DEVICE_TYPE_APPLE_PHONE:
      return "ATTACHED_DEVICE_TYPE_APPLE_PHONE";
    case AttachedDeviceType.ATTACHED_DEVICE_TYPE_ANDROID_PHONE:
      return "ATTACHED_DEVICE_TYPE_ANDROID_PHONE";
    case AttachedDeviceType.ATTACHED_DEVICE_TYPE_APPLE_TABLET:
      return "ATTACHED_DEVICE_TYPE_APPLE_TABLET";
    case AttachedDeviceType.ATTACHED_DEVICE_TYPE_ANDROID_TABLET:
      return "ATTACHED_DEVICE_TYPE_ANDROID_TABLET";
    case AttachedDeviceType.ATTACHED_DEVICE_TYPE_DEVBOARD:
      return "ATTACHED_DEVICE_TYPE_DEVBOARD";
    case AttachedDeviceType.ATTACHED_DEVICE_TYPE_JETSTREAM:
      return "ATTACHED_DEVICE_TYPE_JETSTREAM";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum AttachedDeviceType");
  }
}

/**
 * Machine refers to the devices or bare metal servers
 * which serves the tests directly.
 *
 * Next Tag: 16
 */
export interface Machine {
  /**
   * A unique name for the machine.
   *   * AssetTag for ChromeOS machine (device)
   *   * Serial number for Chrome machine for now (server) (Ideally it should be assetTag once it’s ready)
   * The format will be machines/asset:XXX or machines/sn:XXX
   */
  readonly name: string;
  /**
   * serial_number of the machine.
   * for chromeOS machine, user is not allowed to update this field.
   * SSW will update this field.
   */
  readonly serialNumber: string;
  /** Location details of where the machine is placed */
  readonly location:
    | Location
    | undefined;
  /** Chrome Browser Machine */
  readonly chromeBrowserMachine?:
    | ChromeBrowserMachine
    | undefined;
  /**
   * Chrome OS Machine. The registration system will subscribe to the HaRT system,
   * and automatically get it using Machine name once a machine gets recorded.
   */
  readonly chromeosMachine?:
    | ChromeOSMachine
    | undefined;
  /** Attached Device. Device data for any USB-attached devices. */
  readonly attachedDevice?:
    | AttachedDevice
    | undefined;
  /** Development board, e.g., Andreiboard */
  readonly devboard?:
    | Devboard
    | undefined;
  /** Record the last update timestamp of this machine (In UTC timezone) */
  readonly updateTime:
    | string
    | undefined;
  /** Record the ACL info of the machine */
  readonly realm: string;
  /** tags user can attach for easy querying/searching */
  readonly tags: readonly string[];
  /** State of the machine */
  readonly resourceState: State;
  /** Asset tag of the machine */
  readonly assetTag: string;
  /** Ownership information of the host like ResourceGroup, Security level etc. */
  readonly ownership: OwnershipData | undefined;
}

/** ChromeBrowserMachine refers to bare metal servers in Chrome Browser lab */
export interface ChromeBrowserMachine {
  /** Descriptive name of Chrome Machine */
  readonly displayName: string;
  /**
   * Should match one of the predefined Chrome Platforms.
   * Refers to ChromePlatform name
   */
  readonly chromePlatform: string;
  /** Interface of its connected devices. */
  readonly kvmInterface: KVMInterface | undefined;
  readonly rpmInterface:
    | RPMInterface
    | undefined;
  /** Usually it's a buganizer bug. */
  readonly deploymentTicket: string;
  /** Can be covered by deployment_ticket */
  readonly description: string;
  readonly nicObjects: readonly Nic[];
  readonly dracObject: Drac | undefined;
}

/**
 * ChromeOSMachine refers duts and servers in Chrome OS lab
 * NEXT TAG: 19
 */
export interface ChromeOSMachine {
  /**
   * All infos come from the HaRT system.
   * used by referencing device config.
   */
  readonly referenceBoard: string;
  /** used by ChromeOS build system. */
  readonly buildTarget: string;
  readonly model: string;
  /** Chromebook, labstation, servo, … */
  readonly deviceType: ChromeOSDeviceType;
  /** Used internally, Different boards/models could share the same name. */
  readonly googleCodeName: string;
  /** HaRT doesn't guarantee its existence. */
  readonly macAddress: string;
  /** User not allowed to update this field. SSW will update this field. */
  readonly sku: string;
  /** the DLM SKU ID read from VPD on the device if set */
  readonly dlmSkuId: string;
  /** Phase of the device [PVT, EVT, etc,.] if available. */
  readonly phase: string;
  /** Cost center associated with the asset. */
  readonly costCenter: string;
  /**
   * The manufacturing ID for a given DUT
   * User not allowed to update this field. SSW will update this field.
   */
  readonly hwid: string;
  /** GPN stands for Google Part Number, unique identifier for the device */
  readonly gpn: string;
  /** Capability to support Chromebook X features */
  readonly hwXComplianceVersion: number;
  /** Capability to support Chromebook X features */
  readonly isCbx: boolean;
  /** Chromebook X feature type */
  readonly cbxFeatureType: ChassisXBrandType;
  /** Whether the SKU contains both CBX and non-CBX devices */
  readonly isMixedX: boolean;
  /** If the DUT has bluetooth */
  readonly hasWifiBt: boolean;
  /** The wifichip string of the DUT */
  readonly wifiBluetooth: string;
}

/**
 * AttachedDevice refers to attached devices in the labs.
 *
 * Next Tag: 5
 */
export interface AttachedDevice {
  /** Manufacturer of the device. E.g. Apple, Samsung. */
  readonly manufacturer: string;
  /** Device type of the device. E.g. iPad, Android phone, Android tablet. */
  readonly deviceType: AttachedDeviceType;
  /** Reference board of the device. E.g. goldfish, bravo, GT-I9000 */
  readonly buildTarget: string;
  /** Model of the device aka product in some cases. E.g. Nexus One, GT-I9000 */
  readonly model: string;
}

/**
 * Devboard refers to development boards for component firmware testing.
 *
 * Next Tag: 2
 */
export interface Devboard {
  readonly andreiboard?: Andreiboard | undefined;
  readonly icetower?: Icetower | undefined;
  readonly dragonclaw?: Dragonclaw | undefined;
}

/**
 * Andreiboard refers to andreiboard type development boards.
 *
 * Next Tag: 2
 */
export interface Andreiboard {
  /**
   * Serial for the UltraDebug interface.
   * Can be empty if the devboard does not have UltraDebug.
   */
  readonly ultradebugSerial: string;
}

/**
 * Icetower refers to icetower type development boards.
 *
 * Next Tag: 2
 */
export interface Icetower {
  /** Fingerprint module ID. */
  readonly fingerprintId: string;
}

/**
 * Dragonclaw refers to dragonclaw type development boards.
 *
 * Next Tag: 2
 */
export interface Dragonclaw {
  /** Fingerprint module ID. */
  readonly fingerprintId: string;
}

function createBaseMachine(): Machine {
  return {
    name: "",
    serialNumber: "",
    location: undefined,
    chromeBrowserMachine: undefined,
    chromeosMachine: undefined,
    attachedDevice: undefined,
    devboard: undefined,
    updateTime: undefined,
    realm: "",
    tags: [],
    resourceState: 0,
    assetTag: "",
    ownership: undefined,
  };
}

export const Machine: MessageFns<Machine> = {
  encode(message: Machine, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.serialNumber !== "") {
      writer.uint32(18).string(message.serialNumber);
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(26).fork()).join();
    }
    if (message.chromeBrowserMachine !== undefined) {
      ChromeBrowserMachine.encode(message.chromeBrowserMachine, writer.uint32(34).fork()).join();
    }
    if (message.chromeosMachine !== undefined) {
      ChromeOSMachine.encode(message.chromeosMachine, writer.uint32(42).fork()).join();
    }
    if (message.attachedDevice !== undefined) {
      AttachedDevice.encode(message.attachedDevice, writer.uint32(90).fork()).join();
    }
    if (message.devboard !== undefined) {
      Devboard.encode(message.devboard, writer.uint32(106).fork()).join();
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(50).fork()).join();
    }
    if (message.realm !== "") {
      writer.uint32(58).string(message.realm);
    }
    for (const v of message.tags) {
      writer.uint32(66).string(v!);
    }
    if (message.resourceState !== 0) {
      writer.uint32(80).int32(message.resourceState);
    }
    if (message.assetTag !== "") {
      writer.uint32(98).string(message.assetTag);
    }
    if (message.ownership !== undefined) {
      OwnershipData.encode(message.ownership, writer.uint32(114).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Machine {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMachine() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.chromeBrowserMachine = ChromeBrowserMachine.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.chromeosMachine = ChromeOSMachine.decode(reader, reader.uint32());
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.attachedDevice = AttachedDevice.decode(reader, reader.uint32());
          continue;
        }
        case 13: {
          if (tag !== 106) {
            break;
          }

          message.devboard = Devboard.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.realm = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.resourceState = reader.int32() as any;
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.assetTag = reader.string();
          continue;
        }
        case 14: {
          if (tag !== 114) {
            break;
          }

          message.ownership = OwnershipData.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Machine {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      location: isSet(object.location) ? Location.fromJSON(object.location) : undefined,
      chromeBrowserMachine: isSet(object.chromeBrowserMachine)
        ? ChromeBrowserMachine.fromJSON(object.chromeBrowserMachine)
        : undefined,
      chromeosMachine: isSet(object.chromeosMachine) ? ChromeOSMachine.fromJSON(object.chromeosMachine) : undefined,
      attachedDevice: isSet(object.attachedDevice) ? AttachedDevice.fromJSON(object.attachedDevice) : undefined,
      devboard: isSet(object.devboard) ? Devboard.fromJSON(object.devboard) : undefined,
      updateTime: isSet(object.updateTime) ? globalThis.String(object.updateTime) : undefined,
      realm: isSet(object.realm) ? globalThis.String(object.realm) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      resourceState: isSet(object.resourceState) ? stateFromJSON(object.resourceState) : 0,
      assetTag: isSet(object.assetTag) ? globalThis.String(object.assetTag) : "",
      ownership: isSet(object.ownership) ? OwnershipData.fromJSON(object.ownership) : undefined,
    };
  },

  toJSON(message: Machine): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.location !== undefined) {
      obj.location = Location.toJSON(message.location);
    }
    if (message.chromeBrowserMachine !== undefined) {
      obj.chromeBrowserMachine = ChromeBrowserMachine.toJSON(message.chromeBrowserMachine);
    }
    if (message.chromeosMachine !== undefined) {
      obj.chromeosMachine = ChromeOSMachine.toJSON(message.chromeosMachine);
    }
    if (message.attachedDevice !== undefined) {
      obj.attachedDevice = AttachedDevice.toJSON(message.attachedDevice);
    }
    if (message.devboard !== undefined) {
      obj.devboard = Devboard.toJSON(message.devboard);
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime;
    }
    if (message.realm !== "") {
      obj.realm = message.realm;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.resourceState !== 0) {
      obj.resourceState = stateToJSON(message.resourceState);
    }
    if (message.assetTag !== "") {
      obj.assetTag = message.assetTag;
    }
    if (message.ownership !== undefined) {
      obj.ownership = OwnershipData.toJSON(message.ownership);
    }
    return obj;
  },

  create(base?: DeepPartial<Machine>): Machine {
    return Machine.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Machine>): Machine {
    const message = createBaseMachine() as any;
    message.name = object.name ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.chromeBrowserMachine = (object.chromeBrowserMachine !== undefined && object.chromeBrowserMachine !== null)
      ? ChromeBrowserMachine.fromPartial(object.chromeBrowserMachine)
      : undefined;
    message.chromeosMachine = (object.chromeosMachine !== undefined && object.chromeosMachine !== null)
      ? ChromeOSMachine.fromPartial(object.chromeosMachine)
      : undefined;
    message.attachedDevice = (object.attachedDevice !== undefined && object.attachedDevice !== null)
      ? AttachedDevice.fromPartial(object.attachedDevice)
      : undefined;
    message.devboard = (object.devboard !== undefined && object.devboard !== null)
      ? Devboard.fromPartial(object.devboard)
      : undefined;
    message.updateTime = object.updateTime ?? undefined;
    message.realm = object.realm ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    message.resourceState = object.resourceState ?? 0;
    message.assetTag = object.assetTag ?? "";
    message.ownership = (object.ownership !== undefined && object.ownership !== null)
      ? OwnershipData.fromPartial(object.ownership)
      : undefined;
    return message;
  },
};

function createBaseChromeBrowserMachine(): ChromeBrowserMachine {
  return {
    displayName: "",
    chromePlatform: "",
    kvmInterface: undefined,
    rpmInterface: undefined,
    deploymentTicket: "",
    description: "",
    nicObjects: [],
    dracObject: undefined,
  };
}

export const ChromeBrowserMachine: MessageFns<ChromeBrowserMachine> = {
  encode(message: ChromeBrowserMachine, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.displayName !== "") {
      writer.uint32(10).string(message.displayName);
    }
    if (message.chromePlatform !== "") {
      writer.uint32(18).string(message.chromePlatform);
    }
    if (message.kvmInterface !== undefined) {
      KVMInterface.encode(message.kvmInterface, writer.uint32(34).fork()).join();
    }
    if (message.rpmInterface !== undefined) {
      RPMInterface.encode(message.rpmInterface, writer.uint32(42).fork()).join();
    }
    if (message.deploymentTicket !== "") {
      writer.uint32(66).string(message.deploymentTicket);
    }
    if (message.description !== "") {
      writer.uint32(74).string(message.description);
    }
    for (const v of message.nicObjects) {
      Nic.encode(v!, writer.uint32(90).fork()).join();
    }
    if (message.dracObject !== undefined) {
      Drac.encode(message.dracObject, writer.uint32(98).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeBrowserMachine {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeBrowserMachine() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.displayName = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.chromePlatform = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.kvmInterface = KVMInterface.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.rpmInterface = RPMInterface.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.deploymentTicket = reader.string();
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.description = reader.string();
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.nicObjects.push(Nic.decode(reader, reader.uint32()));
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.dracObject = Drac.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeBrowserMachine {
    return {
      displayName: isSet(object.displayName) ? globalThis.String(object.displayName) : "",
      chromePlatform: isSet(object.chromePlatform) ? globalThis.String(object.chromePlatform) : "",
      kvmInterface: isSet(object.kvmInterface) ? KVMInterface.fromJSON(object.kvmInterface) : undefined,
      rpmInterface: isSet(object.rpmInterface) ? RPMInterface.fromJSON(object.rpmInterface) : undefined,
      deploymentTicket: isSet(object.deploymentTicket) ? globalThis.String(object.deploymentTicket) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      nicObjects: globalThis.Array.isArray(object?.nicObjects)
        ? object.nicObjects.map((e: any) => Nic.fromJSON(e))
        : [],
      dracObject: isSet(object.dracObject) ? Drac.fromJSON(object.dracObject) : undefined,
    };
  },

  toJSON(message: ChromeBrowserMachine): unknown {
    const obj: any = {};
    if (message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    if (message.chromePlatform !== "") {
      obj.chromePlatform = message.chromePlatform;
    }
    if (message.kvmInterface !== undefined) {
      obj.kvmInterface = KVMInterface.toJSON(message.kvmInterface);
    }
    if (message.rpmInterface !== undefined) {
      obj.rpmInterface = RPMInterface.toJSON(message.rpmInterface);
    }
    if (message.deploymentTicket !== "") {
      obj.deploymentTicket = message.deploymentTicket;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.nicObjects?.length) {
      obj.nicObjects = message.nicObjects.map((e) => Nic.toJSON(e));
    }
    if (message.dracObject !== undefined) {
      obj.dracObject = Drac.toJSON(message.dracObject);
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeBrowserMachine>): ChromeBrowserMachine {
    return ChromeBrowserMachine.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeBrowserMachine>): ChromeBrowserMachine {
    const message = createBaseChromeBrowserMachine() as any;
    message.displayName = object.displayName ?? "";
    message.chromePlatform = object.chromePlatform ?? "";
    message.kvmInterface = (object.kvmInterface !== undefined && object.kvmInterface !== null)
      ? KVMInterface.fromPartial(object.kvmInterface)
      : undefined;
    message.rpmInterface = (object.rpmInterface !== undefined && object.rpmInterface !== null)
      ? RPMInterface.fromPartial(object.rpmInterface)
      : undefined;
    message.deploymentTicket = object.deploymentTicket ?? "";
    message.description = object.description ?? "";
    message.nicObjects = object.nicObjects?.map((e) => Nic.fromPartial(e)) || [];
    message.dracObject = (object.dracObject !== undefined && object.dracObject !== null)
      ? Drac.fromPartial(object.dracObject)
      : undefined;
    return message;
  },
};

function createBaseChromeOSMachine(): ChromeOSMachine {
  return {
    referenceBoard: "",
    buildTarget: "",
    model: "",
    deviceType: 0,
    googleCodeName: "",
    macAddress: "",
    sku: "",
    dlmSkuId: "",
    phase: "",
    costCenter: "",
    hwid: "",
    gpn: "",
    hwXComplianceVersion: 0,
    isCbx: false,
    cbxFeatureType: 0,
    isMixedX: false,
    hasWifiBt: false,
    wifiBluetooth: "",
  };
}

export const ChromeOSMachine: MessageFns<ChromeOSMachine> = {
  encode(message: ChromeOSMachine, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.referenceBoard !== "") {
      writer.uint32(10).string(message.referenceBoard);
    }
    if (message.buildTarget !== "") {
      writer.uint32(18).string(message.buildTarget);
    }
    if (message.model !== "") {
      writer.uint32(26).string(message.model);
    }
    if (message.deviceType !== 0) {
      writer.uint32(32).int32(message.deviceType);
    }
    if (message.googleCodeName !== "") {
      writer.uint32(42).string(message.googleCodeName);
    }
    if (message.macAddress !== "") {
      writer.uint32(50).string(message.macAddress);
    }
    if (message.sku !== "") {
      writer.uint32(58).string(message.sku);
    }
    if (message.dlmSkuId !== "") {
      writer.uint32(146).string(message.dlmSkuId);
    }
    if (message.phase !== "") {
      writer.uint32(66).string(message.phase);
    }
    if (message.costCenter !== "") {
      writer.uint32(74).string(message.costCenter);
    }
    if (message.hwid !== "") {
      writer.uint32(82).string(message.hwid);
    }
    if (message.gpn !== "") {
      writer.uint32(90).string(message.gpn);
    }
    if (message.hwXComplianceVersion !== 0) {
      writer.uint32(97).double(message.hwXComplianceVersion);
    }
    if (message.isCbx !== false) {
      writer.uint32(104).bool(message.isCbx);
    }
    if (message.cbxFeatureType !== 0) {
      writer.uint32(112).int32(message.cbxFeatureType);
    }
    if (message.isMixedX !== false) {
      writer.uint32(120).bool(message.isMixedX);
    }
    if (message.hasWifiBt !== false) {
      writer.uint32(128).bool(message.hasWifiBt);
    }
    if (message.wifiBluetooth !== "") {
      writer.uint32(138).string(message.wifiBluetooth);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOSMachine {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOSMachine() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.referenceBoard = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.buildTarget = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.model = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.deviceType = reader.int32() as any;
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.googleCodeName = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.macAddress = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.sku = reader.string();
          continue;
        }
        case 18: {
          if (tag !== 146) {
            break;
          }

          message.dlmSkuId = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.phase = reader.string();
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.costCenter = reader.string();
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.hwid = reader.string();
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.gpn = reader.string();
          continue;
        }
        case 12: {
          if (tag !== 97) {
            break;
          }

          message.hwXComplianceVersion = reader.double();
          continue;
        }
        case 13: {
          if (tag !== 104) {
            break;
          }

          message.isCbx = reader.bool();
          continue;
        }
        case 14: {
          if (tag !== 112) {
            break;
          }

          message.cbxFeatureType = reader.int32() as any;
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.isMixedX = reader.bool();
          continue;
        }
        case 16: {
          if (tag !== 128) {
            break;
          }

          message.hasWifiBt = reader.bool();
          continue;
        }
        case 17: {
          if (tag !== 138) {
            break;
          }

          message.wifiBluetooth = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOSMachine {
    return {
      referenceBoard: isSet(object.referenceBoard) ? globalThis.String(object.referenceBoard) : "",
      buildTarget: isSet(object.buildTarget) ? globalThis.String(object.buildTarget) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      deviceType: isSet(object.deviceType) ? chromeOSDeviceTypeFromJSON(object.deviceType) : 0,
      googleCodeName: isSet(object.googleCodeName) ? globalThis.String(object.googleCodeName) : "",
      macAddress: isSet(object.macAddress) ? globalThis.String(object.macAddress) : "",
      sku: isSet(object.sku) ? globalThis.String(object.sku) : "",
      dlmSkuId: isSet(object.dlmSkuId) ? globalThis.String(object.dlmSkuId) : "",
      phase: isSet(object.phase) ? globalThis.String(object.phase) : "",
      costCenter: isSet(object.costCenter) ? globalThis.String(object.costCenter) : "",
      hwid: isSet(object.hwid) ? globalThis.String(object.hwid) : "",
      gpn: isSet(object.gpn) ? globalThis.String(object.gpn) : "",
      hwXComplianceVersion: isSet(object.hwXComplianceVersion) ? globalThis.Number(object.hwXComplianceVersion) : 0,
      isCbx: isSet(object.isCbx) ? globalThis.Boolean(object.isCbx) : false,
      cbxFeatureType: isSet(object.cbxFeatureType) ? chassisXBrandTypeFromJSON(object.cbxFeatureType) : 0,
      isMixedX: isSet(object.isMixedX) ? globalThis.Boolean(object.isMixedX) : false,
      hasWifiBt: isSet(object.hasWifiBt) ? globalThis.Boolean(object.hasWifiBt) : false,
      wifiBluetooth: isSet(object.wifiBluetooth) ? globalThis.String(object.wifiBluetooth) : "",
    };
  },

  toJSON(message: ChromeOSMachine): unknown {
    const obj: any = {};
    if (message.referenceBoard !== "") {
      obj.referenceBoard = message.referenceBoard;
    }
    if (message.buildTarget !== "") {
      obj.buildTarget = message.buildTarget;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.deviceType !== 0) {
      obj.deviceType = chromeOSDeviceTypeToJSON(message.deviceType);
    }
    if (message.googleCodeName !== "") {
      obj.googleCodeName = message.googleCodeName;
    }
    if (message.macAddress !== "") {
      obj.macAddress = message.macAddress;
    }
    if (message.sku !== "") {
      obj.sku = message.sku;
    }
    if (message.dlmSkuId !== "") {
      obj.dlmSkuId = message.dlmSkuId;
    }
    if (message.phase !== "") {
      obj.phase = message.phase;
    }
    if (message.costCenter !== "") {
      obj.costCenter = message.costCenter;
    }
    if (message.hwid !== "") {
      obj.hwid = message.hwid;
    }
    if (message.gpn !== "") {
      obj.gpn = message.gpn;
    }
    if (message.hwXComplianceVersion !== 0) {
      obj.hwXComplianceVersion = message.hwXComplianceVersion;
    }
    if (message.isCbx !== false) {
      obj.isCbx = message.isCbx;
    }
    if (message.cbxFeatureType !== 0) {
      obj.cbxFeatureType = chassisXBrandTypeToJSON(message.cbxFeatureType);
    }
    if (message.isMixedX !== false) {
      obj.isMixedX = message.isMixedX;
    }
    if (message.hasWifiBt !== false) {
      obj.hasWifiBt = message.hasWifiBt;
    }
    if (message.wifiBluetooth !== "") {
      obj.wifiBluetooth = message.wifiBluetooth;
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOSMachine>): ChromeOSMachine {
    return ChromeOSMachine.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOSMachine>): ChromeOSMachine {
    const message = createBaseChromeOSMachine() as any;
    message.referenceBoard = object.referenceBoard ?? "";
    message.buildTarget = object.buildTarget ?? "";
    message.model = object.model ?? "";
    message.deviceType = object.deviceType ?? 0;
    message.googleCodeName = object.googleCodeName ?? "";
    message.macAddress = object.macAddress ?? "";
    message.sku = object.sku ?? "";
    message.dlmSkuId = object.dlmSkuId ?? "";
    message.phase = object.phase ?? "";
    message.costCenter = object.costCenter ?? "";
    message.hwid = object.hwid ?? "";
    message.gpn = object.gpn ?? "";
    message.hwXComplianceVersion = object.hwXComplianceVersion ?? 0;
    message.isCbx = object.isCbx ?? false;
    message.cbxFeatureType = object.cbxFeatureType ?? 0;
    message.isMixedX = object.isMixedX ?? false;
    message.hasWifiBt = object.hasWifiBt ?? false;
    message.wifiBluetooth = object.wifiBluetooth ?? "";
    return message;
  },
};

function createBaseAttachedDevice(): AttachedDevice {
  return { manufacturer: "", deviceType: 0, buildTarget: "", model: "" };
}

export const AttachedDevice: MessageFns<AttachedDevice> = {
  encode(message: AttachedDevice, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.manufacturer !== "") {
      writer.uint32(10).string(message.manufacturer);
    }
    if (message.deviceType !== 0) {
      writer.uint32(16).int32(message.deviceType);
    }
    if (message.buildTarget !== "") {
      writer.uint32(26).string(message.buildTarget);
    }
    if (message.model !== "") {
      writer.uint32(34).string(message.model);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AttachedDevice {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttachedDevice() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.deviceType = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.buildTarget = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.model = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttachedDevice {
    return {
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      deviceType: isSet(object.deviceType) ? attachedDeviceTypeFromJSON(object.deviceType) : 0,
      buildTarget: isSet(object.buildTarget) ? globalThis.String(object.buildTarget) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
    };
  },

  toJSON(message: AttachedDevice): unknown {
    const obj: any = {};
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.deviceType !== 0) {
      obj.deviceType = attachedDeviceTypeToJSON(message.deviceType);
    }
    if (message.buildTarget !== "") {
      obj.buildTarget = message.buildTarget;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    return obj;
  },

  create(base?: DeepPartial<AttachedDevice>): AttachedDevice {
    return AttachedDevice.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AttachedDevice>): AttachedDevice {
    const message = createBaseAttachedDevice() as any;
    message.manufacturer = object.manufacturer ?? "";
    message.deviceType = object.deviceType ?? 0;
    message.buildTarget = object.buildTarget ?? "";
    message.model = object.model ?? "";
    return message;
  },
};

function createBaseDevboard(): Devboard {
  return { andreiboard: undefined, icetower: undefined, dragonclaw: undefined };
}

export const Devboard: MessageFns<Devboard> = {
  encode(message: Devboard, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.andreiboard !== undefined) {
      Andreiboard.encode(message.andreiboard, writer.uint32(10).fork()).join();
    }
    if (message.icetower !== undefined) {
      Icetower.encode(message.icetower, writer.uint32(18).fork()).join();
    }
    if (message.dragonclaw !== undefined) {
      Dragonclaw.encode(message.dragonclaw, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Devboard {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDevboard() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.andreiboard = Andreiboard.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.icetower = Icetower.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.dragonclaw = Dragonclaw.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Devboard {
    return {
      andreiboard: isSet(object.andreiboard) ? Andreiboard.fromJSON(object.andreiboard) : undefined,
      icetower: isSet(object.icetower) ? Icetower.fromJSON(object.icetower) : undefined,
      dragonclaw: isSet(object.dragonclaw) ? Dragonclaw.fromJSON(object.dragonclaw) : undefined,
    };
  },

  toJSON(message: Devboard): unknown {
    const obj: any = {};
    if (message.andreiboard !== undefined) {
      obj.andreiboard = Andreiboard.toJSON(message.andreiboard);
    }
    if (message.icetower !== undefined) {
      obj.icetower = Icetower.toJSON(message.icetower);
    }
    if (message.dragonclaw !== undefined) {
      obj.dragonclaw = Dragonclaw.toJSON(message.dragonclaw);
    }
    return obj;
  },

  create(base?: DeepPartial<Devboard>): Devboard {
    return Devboard.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Devboard>): Devboard {
    const message = createBaseDevboard() as any;
    message.andreiboard = (object.andreiboard !== undefined && object.andreiboard !== null)
      ? Andreiboard.fromPartial(object.andreiboard)
      : undefined;
    message.icetower = (object.icetower !== undefined && object.icetower !== null)
      ? Icetower.fromPartial(object.icetower)
      : undefined;
    message.dragonclaw = (object.dragonclaw !== undefined && object.dragonclaw !== null)
      ? Dragonclaw.fromPartial(object.dragonclaw)
      : undefined;
    return message;
  },
};

function createBaseAndreiboard(): Andreiboard {
  return { ultradebugSerial: "" };
}

export const Andreiboard: MessageFns<Andreiboard> = {
  encode(message: Andreiboard, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.ultradebugSerial !== "") {
      writer.uint32(10).string(message.ultradebugSerial);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Andreiboard {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAndreiboard() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.ultradebugSerial = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Andreiboard {
    return { ultradebugSerial: isSet(object.ultradebugSerial) ? globalThis.String(object.ultradebugSerial) : "" };
  },

  toJSON(message: Andreiboard): unknown {
    const obj: any = {};
    if (message.ultradebugSerial !== "") {
      obj.ultradebugSerial = message.ultradebugSerial;
    }
    return obj;
  },

  create(base?: DeepPartial<Andreiboard>): Andreiboard {
    return Andreiboard.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Andreiboard>): Andreiboard {
    const message = createBaseAndreiboard() as any;
    message.ultradebugSerial = object.ultradebugSerial ?? "";
    return message;
  },
};

function createBaseIcetower(): Icetower {
  return { fingerprintId: "" };
}

export const Icetower: MessageFns<Icetower> = {
  encode(message: Icetower, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.fingerprintId !== "") {
      writer.uint32(10).string(message.fingerprintId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Icetower {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIcetower() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.fingerprintId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Icetower {
    return { fingerprintId: isSet(object.fingerprintId) ? globalThis.String(object.fingerprintId) : "" };
  },

  toJSON(message: Icetower): unknown {
    const obj: any = {};
    if (message.fingerprintId !== "") {
      obj.fingerprintId = message.fingerprintId;
    }
    return obj;
  },

  create(base?: DeepPartial<Icetower>): Icetower {
    return Icetower.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Icetower>): Icetower {
    const message = createBaseIcetower() as any;
    message.fingerprintId = object.fingerprintId ?? "";
    return message;
  },
};

function createBaseDragonclaw(): Dragonclaw {
  return { fingerprintId: "" };
}

export const Dragonclaw: MessageFns<Dragonclaw> = {
  encode(message: Dragonclaw, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.fingerprintId !== "") {
      writer.uint32(10).string(message.fingerprintId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Dragonclaw {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDragonclaw() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.fingerprintId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Dragonclaw {
    return { fingerprintId: isSet(object.fingerprintId) ? globalThis.String(object.fingerprintId) : "" };
  },

  toJSON(message: Dragonclaw): unknown {
    const obj: any = {};
    if (message.fingerprintId !== "") {
      obj.fingerprintId = message.fingerprintId;
    }
    return obj;
  },

  create(base?: DeepPartial<Dragonclaw>): Dragonclaw {
    return Dragonclaw.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Dragonclaw>): Dragonclaw {
    const message = createBaseDragonclaw() as any;
    message.fingerprintId = object.fingerprintId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
