// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v5.28.3
// source: infra/unifiedfleet/api/v1/models/location.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "unifiedfleet.api.v1.models";

/**
 * Lab refers to the different Labs under chrome org
 * More labs to be added later if needed
 * Next tag: 12
 */
export enum Lab {
  LAB_UNSPECIFIED = 0,
  LAB_CHROME_ATLANTA = 1,
  LAB_CHROMEOS_SANTIAM = 2,
  LAB_CHROMEOS_DESTINY = 3,
  LAB_CHROMEOS_PROMETHEUS = 4,
  LAB_CHROMEOS_ATLANTIS = 5,
  LAB_CHROMEOS_LINDAVISTA = 6,
  LAB_DATACENTER_ATL97 = 7,
  LAB_DATACENTER_IAD97 = 8,
  LAB_DATACENTER_MTV96 = 9,
  LAB_DATACENTER_MTV97 = 10,
  LAB_DATACENTER_FUCHSIA = 11,
}

export function labFromJSON(object: any): Lab {
  switch (object) {
    case 0:
    case "LAB_UNSPECIFIED":
      return Lab.LAB_UNSPECIFIED;
    case 1:
    case "LAB_CHROME_ATLANTA":
      return Lab.LAB_CHROME_ATLANTA;
    case 2:
    case "LAB_CHROMEOS_SANTIAM":
      return Lab.LAB_CHROMEOS_SANTIAM;
    case 3:
    case "LAB_CHROMEOS_DESTINY":
      return Lab.LAB_CHROMEOS_DESTINY;
    case 4:
    case "LAB_CHROMEOS_PROMETHEUS":
      return Lab.LAB_CHROMEOS_PROMETHEUS;
    case 5:
    case "LAB_CHROMEOS_ATLANTIS":
      return Lab.LAB_CHROMEOS_ATLANTIS;
    case 6:
    case "LAB_CHROMEOS_LINDAVISTA":
      return Lab.LAB_CHROMEOS_LINDAVISTA;
    case 7:
    case "LAB_DATACENTER_ATL97":
      return Lab.LAB_DATACENTER_ATL97;
    case 8:
    case "LAB_DATACENTER_IAD97":
      return Lab.LAB_DATACENTER_IAD97;
    case 9:
    case "LAB_DATACENTER_MTV96":
      return Lab.LAB_DATACENTER_MTV96;
    case 10:
    case "LAB_DATACENTER_MTV97":
      return Lab.LAB_DATACENTER_MTV97;
    case 11:
    case "LAB_DATACENTER_FUCHSIA":
      return Lab.LAB_DATACENTER_FUCHSIA;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Lab");
  }
}

export function labToJSON(object: Lab): string {
  switch (object) {
    case Lab.LAB_UNSPECIFIED:
      return "LAB_UNSPECIFIED";
    case Lab.LAB_CHROME_ATLANTA:
      return "LAB_CHROME_ATLANTA";
    case Lab.LAB_CHROMEOS_SANTIAM:
      return "LAB_CHROMEOS_SANTIAM";
    case Lab.LAB_CHROMEOS_DESTINY:
      return "LAB_CHROMEOS_DESTINY";
    case Lab.LAB_CHROMEOS_PROMETHEUS:
      return "LAB_CHROMEOS_PROMETHEUS";
    case Lab.LAB_CHROMEOS_ATLANTIS:
      return "LAB_CHROMEOS_ATLANTIS";
    case Lab.LAB_CHROMEOS_LINDAVISTA:
      return "LAB_CHROMEOS_LINDAVISTA";
    case Lab.LAB_DATACENTER_ATL97:
      return "LAB_DATACENTER_ATL97";
    case Lab.LAB_DATACENTER_IAD97:
      return "LAB_DATACENTER_IAD97";
    case Lab.LAB_DATACENTER_MTV96:
      return "LAB_DATACENTER_MTV96";
    case Lab.LAB_DATACENTER_MTV97:
      return "LAB_DATACENTER_MTV97";
    case Lab.LAB_DATACENTER_FUCHSIA:
      return "LAB_DATACENTER_FUCHSIA";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Lab");
  }
}

/**
 * Zone refers to the different network zones under chrome org
 * Next tag: 98
 */
export enum Zone {
  ZONE_UNSPECIFIED = 0,
  /** ZONE_ATLANTA - "atl" Building ? Room ? */
  ZONE_ATLANTA = 1,
  /** ZONE_CHROMEOS1 - "chromeos1" // US-MTV-1600 // Santiam */
  ZONE_CHROMEOS1 = 2,
  /**
   * ZONE_CHROMEOS2 - DEPRECATED "chromeos2" // US-MTV-2081 // Atlantis
   *
   * @deprecated
   */
  ZONE_CHROMEOS2 = 3,
  /** ZONE_CHROMEOS3 - "chromeos3" // US-MTV-946 // Lindavista */
  ZONE_CHROMEOS3 = 4,
  /**
   * ZONE_CHROMEOS4 - DEPRECATED "chromeos4" // US-MTV-2081 // Destiny
   *
   * @deprecated
   */
  ZONE_CHROMEOS4 = 5,
  /** ZONE_CHROMEOS5 - "chromeos5" // US-MTV-946 // Lindavista */
  ZONE_CHROMEOS5 = 6,
  /** ZONE_CHROMEOS6 - "chromeos6" // US-MTV-2081 // Prometheus */
  ZONE_CHROMEOS6 = 7,
  /** ZONE_CHROMEOS7 - "chromeos7" // US-MTV-946 // Lindavista */
  ZONE_CHROMEOS7 = 8,
  /** ZONE_CHROMEOS15 - "chromeos15" // US-MTV-946 // Lindavista */
  ZONE_CHROMEOS15 = 10,
  /** ZONE_ATL97 - "atl97" //  US-ATL-MET1 // Room ? */
  ZONE_ATL97 = 11,
  /** ZONE_IAD97 - "iad97" // Building ? Room ? */
  ZONE_IAD97 = 12,
  /** ZONE_MTV96 - "mtv96" // US-MTV-41 // 1-1M0 */
  ZONE_MTV96 = 13,
  /** ZONE_MTV97 - "mtv97" // US-MTV-1950 // 1-144 */
  ZONE_MTV97 = 14,
  /** ZONE_FUCHSIA - "lab01" // Building ? Room ? */
  ZONE_FUCHSIA = 15,
  /** ZONE_CROS_GOOGLER_DESK - "desk" // For cros assets given to googlers */
  ZONE_CROS_GOOGLER_DESK = 16,
  /** ZONE_MTV1950_TESTING - "mtv1950" // temporary zone for testing cros assets in gTransit */
  ZONE_MTV1950_TESTING = 17,
  /** ZONE_SATLAB - "satlab" // zone for satlab duts */
  ZONE_SATLAB = 18,
  /** ZONE_BROWSER_GOOGLER_DESK - "desk" // for browser assets given to googlers */
  ZONE_BROWSER_GOOGLER_DESK = 19,
  /** ZONE_JETSTREAM_B946 - US-MTV-946-1-1E1 */
  ZONE_JETSTREAM_B946 = 20,
  /** ZONE_SFO36_NETWORK - "sfo36_network" // US-PAO-EM25 // Room 115 */
  ZONE_SFO36_NETWORK = 22,
  /** ZONE_SFO36_BROWSER - "sfo36_browser" // US-PAO-EM25 // Room 105 */
  ZONE_SFO36_BROWSER = 23,
  /** ZONE_SFO36_OS - "sfo36_os" // US-PAO-EM25 // Room 114, 116, 117 */
  ZONE_SFO36_OS = 24,
  /** ZONE_SFO36_OS_CHROMIUM - "sfo36_os_chromium" // US-PAO-EM25 // CRE199-204 */
  ZONE_SFO36_OS_CHROMIUM = 21,
  /** ZONE_SFP_SFPTEST - "sfp_sfptest" // Zone for SfP assets for partner `sfptest` */
  ZONE_SFP_SFPTEST = 25,
  /** ZONE_IAD65_OS - "iad65_os" // Zone for datacenter site IAD65 */
  ZONE_IAD65_OS = 26,
  /** ZONE_SFP_CROSTEST - "sfp_crostest" // Zone for SfP assets for partner `crostest` */
  ZONE_SFP_CROSTEST = 27,
  /** ZONE_SFP_2 - "sfp_2" // Zone for SfP assets for partner // Testhaus ID:2 */
  ZONE_SFP_2 = 28,
  /** ZONE_SFP_6 - "sfp_6" // Zone for SfP assets for partner // Testhaus ID:6 */
  ZONE_SFP_6 = 29,
  /** ZONE_SFP_4 - "sfp_4" // Zone for SfP assets for partner // Testhaus ID:4; */
  ZONE_SFP_4 = 30,
  /** ZONE_SFP_9 - "sfp_9" // Zone for SfP assets for partner // Testhaus ID:9; */
  ZONE_SFP_9 = 31,
  /** ZONE_SFP_10 - "sfp_10" // Zone for SfP assets for partner // Testhaus ID:10; */
  ZONE_SFP_10 = 32,
  /** ZONE_SFP_13 - "sfp_13" // Zone for SfP assets for partner // Testhaus ID:13; */
  ZONE_SFP_13 = 33,
  /** ZONE_SFP_14 - "sfp_14" // Zone for SfP assets for partner // Testhaus ID:14; */
  ZONE_SFP_14 = 34,
  /** ZONE_SFP_15 - "sfp_15" // Zone for SfP assets for partner // Testhaus ID:15; */
  ZONE_SFP_15 = 35,
  /** ZONE_SFP_16 - "sfp_16" // Zone for SfP assets for partner // Testhaus ID:16; */
  ZONE_SFP_16 = 36,
  /** ZONE_SFP_17 - "sfp_17" // Zone for SfP assets for partner // Testhaus ID:17; */
  ZONE_SFP_17 = 37,
  /** ZONE_SFP_20 - "sfp_20" // Zone for SfP assets for partner // Testhaus ID:20; */
  ZONE_SFP_20 = 38,
  /** ZONE_SFP_21 - "sfp_21" // Zone for SfP assets for partner // Testhaus ID:21; */
  ZONE_SFP_21 = 39,
  /** ZONE_SFP_22 - "sfp_22" // Zone for SfP assets for partner // Testhaus ID:22; */
  ZONE_SFP_22 = 40,
  /** ZONE_SFP_23 - "sfp_23" // Zone for SfP assets for partner // Testhaus ID:23; */
  ZONE_SFP_23 = 41,
  /** ZONE_SFP_24 - "sfp_24" // Zone for SfP assets for partner // Testhaus ID:24; */
  ZONE_SFP_24 = 42,
  /** ZONE_SFP_26 - ZONE_SFP_25 - intentionally skipped, botched partner setup */
  ZONE_SFP_26 = 43,
  /** ZONE_SFP_27 - "sfp_27" // Zone for SfP assets for partner // Testhaus ID:27; */
  ZONE_SFP_27 = 44,
  /** ZONE_SFP_28 - "sfp_28" // Zone for SfP assets for partner // Testhaus ID:28; */
  ZONE_SFP_28 = 45,
  /** ZONE_SFP_29 - "sfp_29" // Zone for SfP assets for partner // Testhaus ID:29; */
  ZONE_SFP_29 = 46,
  /** ZONE_SFP_30 - "sfp_30" // Zone for SfP assets for partner // Testhaus ID:30; */
  ZONE_SFP_30 = 47,
  /** ZONE_SFP_31 - "sfp_31" // Zone for SfP assets for partner // Testhaus ID:31; */
  ZONE_SFP_31 = 48,
  /** ZONE_SFP_32 - "sfp_32" // Zone for SfP assets for partner // Testhaus ID:32; */
  ZONE_SFP_32 = 49,
  /** ZONE_SFP_33 - "sfp_33" // Zone for SfP assets for partner // Testhaus ID:33; */
  ZONE_SFP_33 = 50,
  /** ZONE_SFP_34 - "sfp_34" // Zone for SfP assets for partner // Testhaus ID:34; */
  ZONE_SFP_34 = 51,
  /** ZONE_SFP_35 - "sfp_35" // Zone for SfP assets for partner // Testhaus ID:35; */
  ZONE_SFP_35 = 52,
  /** ZONE_SFP_36 - "sfp_36" // Zone for SfP assets for partner // Testhaus ID:36; */
  ZONE_SFP_36 = 53,
  /** ZONE_SFP_37 - "sfp_37" // Zone for SfP assets for partner // Testhaus ID:37; */
  ZONE_SFP_37 = 54,
  /** ZONE_SFP_38 - "sfp_38" // Zone for SfP assets for partner // Testhaus ID:38; */
  ZONE_SFP_38 = 55,
  /** ZONE_SFP_39 - "sfp_39" // Zone for SfP assets for partner // Testhaus ID:39; */
  ZONE_SFP_39 = 56,
  /** ZONE_SFP_40 - "sfp_40" // Zone for SfP assets for partner // Testhaus ID:40; */
  ZONE_SFP_40 = 57,
  /** ZONE_SFP_41 - "sfp_41" // Zone for SfP assets for partner // Testhaus ID:41; */
  ZONE_SFP_41 = 58,
  /** ZONE_SFP_42 - "sfp_42" // Zone for SfP assets for partner // Testhaus ID:42; */
  ZONE_SFP_42 = 59,
  /** ZONE_SFP_43 - "sfp_43" // Zone for SfP assets for partner // Testhaus ID:43; */
  ZONE_SFP_43 = 60,
  /** ZONE_SFP_44 - "sfp_44" // Zone for SfP assets for partner // Testhaus ID:44; */
  ZONE_SFP_44 = 61,
  /** ZONE_SFP_45 - "sfp_45" // Zone for SfP assets for partner // Testhaus ID:45; */
  ZONE_SFP_45 = 62,
  /** ZONE_SFP_46 - "sfp_46" // Zone for SfP assets for partner // Testhaus ID:46; */
  ZONE_SFP_46 = 63,
  /** ZONE_SFP_47 - "sfp_47" // Zone for SfP assets for partner // Testhaus ID:47; */
  ZONE_SFP_47 = 64,
  /** ZONE_SFP_48 - "sfp_48" // Zone for SfP assets for partner // Testhaus ID:48; */
  ZONE_SFP_48 = 65,
  /** ZONE_SFP_49 - "sfp_49" // Zone for SfP assets for partner // Testhaus ID:49; */
  ZONE_SFP_49 = 66,
  /** ZONE_SFP_50 - "sfp_50" // Zone for SfP assets for partner // Testhaus ID:50; */
  ZONE_SFP_50 = 67,
  /** ZONE_SFP_51 - "sfp_51" // Zone for SfP assets for partner // Testhaus ID:51; */
  ZONE_SFP_51 = 68,
  /** ZONE_SFP_52 - "sfp_52" // Zone for SfP assets for partner // Testhaus ID:52; */
  ZONE_SFP_52 = 69,
  /** ZONE_SFP_53 - "sfp_53" // Zone for SfP assets for partner // Testhaus ID:53; */
  ZONE_SFP_53 = 70,
  /** ZONE_SFP_54 - "sfp_54" // Zone for SfP assets for partner // Testhaus ID:54; */
  ZONE_SFP_54 = 71,
  /** ZONE_SFP_55 - "sfp_55" // Zone for SfP assets for partner // Testhaus ID:55; */
  ZONE_SFP_55 = 72,
  /** ZONE_SFP_56 - "sfp_56" // Zone for SfP assets for partner // Testhaus ID:56; */
  ZONE_SFP_56 = 73,
  /** ZONE_SFP_57 - "sfp_57" // Zone for SfP assets for partner // Testhaus ID:57; */
  ZONE_SFP_57 = 74,
  /** ZONE_SFP_58 - "sfp_58" // Zone for SfP assets for partner // Testhaus ID:58; */
  ZONE_SFP_58 = 75,
  /** ZONE_SFP_59 - "sfp_59" // Zone for SfP assets for partner // Testhaus ID:59; */
  ZONE_SFP_59 = 76,
  /** ZONE_SFP_60 - "sfp_60" // Zone for SfP assets for partner // Testhaus ID:60; */
  ZONE_SFP_60 = 77,
  /** ZONE_SFP_62 - ZONE_SFP_61 - intentionally skipped, botched partner setup */
  ZONE_SFP_62 = 78,
  /** ZONE_SFP_63 - "sfp_63" // Zone for SfP assets for partner // Testhaus ID:63; */
  ZONE_SFP_63 = 79,
  /** ZONE_SFP_64 - "sfp_64" // Zone for SfP assets for partner // Testhaus ID:64; */
  ZONE_SFP_64 = 80,
  /** ZONE_SFP_65 - "sfp_65" // Zone for SfP assets for partner // Testhaus ID:65; */
  ZONE_SFP_65 = 81,
  /** ZONE_SFP_66 - "sfp_66" // Zone for SfP assets for partner // Testhaus ID:66; */
  ZONE_SFP_66 = 82,
  /** ZONE_SFP_67 - "sfp_67" // Zone for SfP assets for partner // Testhaus ID:67; */
  ZONE_SFP_67 = 83,
  /** ZONE_SFP_68 - "sfp_68" // Zone for SfP assets for partner // Testhaus ID:68; */
  ZONE_SFP_68 = 84,
  /** ZONE_SFP_69 - "sfp_69" // Zone for SfP assets for partner // Testhaus ID:69; */
  ZONE_SFP_69 = 85,
  /** ZONE_SFP_70 - "sfp_70" // Zone for SfP assets for partner // Testhaus ID:70; */
  ZONE_SFP_70 = 86,
  /** ZONE_SFP_71 - "sfp_71" // Zone for SfP assets for partner // Testhaus ID:71; */
  ZONE_SFP_71 = 87,
  /** ZONE_SFP_72 - "sfp_72" // Zone for SfP assets for partner // Testhaus ID:72; */
  ZONE_SFP_72 = 88,
  /** ZONE_SFP_73 - "sfp_73" // Zone for SfP assets for partner // Testhaus ID:73; */
  ZONE_SFP_73 = 89,
  /** ZONE_SFP_75 - ZONE_SFP_74 - intentionally skipped, botched partner setup */
  ZONE_SFP_75 = 90,
  /** ZONE_SFP_76 - "sfp_76" // Zone for SfP assets for partner // Testhaus ID:76; */
  ZONE_SFP_76 = 91,
  /** ZONE_SFP_77 - "sfp_77" // Zone for SfP assets for partner // Testhaus ID:77; */
  ZONE_SFP_77 = 92,
  /** ZONE_SFP_78 - "sfp_78" // Zone for SfP assets for partner // Testhaus ID:78; */
  ZONE_SFP_78 = 93,
  /** ZONE_SFP_79 - "sfp_79" // Zone for SfP assets for partner // Testhaus ID:79; */
  ZONE_SFP_79 = 94,
  /** ZONE_SFP_80 - "sfp_80" // Zone for SfP assets for partner // Testhaus ID:80; */
  ZONE_SFP_80 = 95,
  /** ZONE_SFP_81 - "sfp_81" // Zone for SfP assets for partner // Testhaus ID:81; */
  ZONE_SFP_81 = 96,
  /** ZONE_SFP_82 - "sfp_82" // Zone for SfP assets for partner // Testhaus ID:82; */
  ZONE_SFP_82 = 97,
}

export function zoneFromJSON(object: any): Zone {
  switch (object) {
    case 0:
    case "ZONE_UNSPECIFIED":
      return Zone.ZONE_UNSPECIFIED;
    case 1:
    case "ZONE_ATLANTA":
      return Zone.ZONE_ATLANTA;
    case 2:
    case "ZONE_CHROMEOS1":
      return Zone.ZONE_CHROMEOS1;
    case 3:
    case "ZONE_CHROMEOS2":
      return Zone.ZONE_CHROMEOS2;
    case 4:
    case "ZONE_CHROMEOS3":
      return Zone.ZONE_CHROMEOS3;
    case 5:
    case "ZONE_CHROMEOS4":
      return Zone.ZONE_CHROMEOS4;
    case 6:
    case "ZONE_CHROMEOS5":
      return Zone.ZONE_CHROMEOS5;
    case 7:
    case "ZONE_CHROMEOS6":
      return Zone.ZONE_CHROMEOS6;
    case 8:
    case "ZONE_CHROMEOS7":
      return Zone.ZONE_CHROMEOS7;
    case 10:
    case "ZONE_CHROMEOS15":
      return Zone.ZONE_CHROMEOS15;
    case 11:
    case "ZONE_ATL97":
      return Zone.ZONE_ATL97;
    case 12:
    case "ZONE_IAD97":
      return Zone.ZONE_IAD97;
    case 13:
    case "ZONE_MTV96":
      return Zone.ZONE_MTV96;
    case 14:
    case "ZONE_MTV97":
      return Zone.ZONE_MTV97;
    case 15:
    case "ZONE_FUCHSIA":
      return Zone.ZONE_FUCHSIA;
    case 16:
    case "ZONE_CROS_GOOGLER_DESK":
      return Zone.ZONE_CROS_GOOGLER_DESK;
    case 17:
    case "ZONE_MTV1950_TESTING":
      return Zone.ZONE_MTV1950_TESTING;
    case 18:
    case "ZONE_SATLAB":
      return Zone.ZONE_SATLAB;
    case 19:
    case "ZONE_BROWSER_GOOGLER_DESK":
      return Zone.ZONE_BROWSER_GOOGLER_DESK;
    case 20:
    case "ZONE_JETSTREAM_B946":
      return Zone.ZONE_JETSTREAM_B946;
    case 22:
    case "ZONE_SFO36_NETWORK":
      return Zone.ZONE_SFO36_NETWORK;
    case 23:
    case "ZONE_SFO36_BROWSER":
      return Zone.ZONE_SFO36_BROWSER;
    case 24:
    case "ZONE_SFO36_OS":
      return Zone.ZONE_SFO36_OS;
    case 21:
    case "ZONE_SFO36_OS_CHROMIUM":
      return Zone.ZONE_SFO36_OS_CHROMIUM;
    case 25:
    case "ZONE_SFP_SFPTEST":
      return Zone.ZONE_SFP_SFPTEST;
    case 26:
    case "ZONE_IAD65_OS":
      return Zone.ZONE_IAD65_OS;
    case 27:
    case "ZONE_SFP_CROSTEST":
      return Zone.ZONE_SFP_CROSTEST;
    case 28:
    case "ZONE_SFP_2":
      return Zone.ZONE_SFP_2;
    case 29:
    case "ZONE_SFP_6":
      return Zone.ZONE_SFP_6;
    case 30:
    case "ZONE_SFP_4":
      return Zone.ZONE_SFP_4;
    case 31:
    case "ZONE_SFP_9":
      return Zone.ZONE_SFP_9;
    case 32:
    case "ZONE_SFP_10":
      return Zone.ZONE_SFP_10;
    case 33:
    case "ZONE_SFP_13":
      return Zone.ZONE_SFP_13;
    case 34:
    case "ZONE_SFP_14":
      return Zone.ZONE_SFP_14;
    case 35:
    case "ZONE_SFP_15":
      return Zone.ZONE_SFP_15;
    case 36:
    case "ZONE_SFP_16":
      return Zone.ZONE_SFP_16;
    case 37:
    case "ZONE_SFP_17":
      return Zone.ZONE_SFP_17;
    case 38:
    case "ZONE_SFP_20":
      return Zone.ZONE_SFP_20;
    case 39:
    case "ZONE_SFP_21":
      return Zone.ZONE_SFP_21;
    case 40:
    case "ZONE_SFP_22":
      return Zone.ZONE_SFP_22;
    case 41:
    case "ZONE_SFP_23":
      return Zone.ZONE_SFP_23;
    case 42:
    case "ZONE_SFP_24":
      return Zone.ZONE_SFP_24;
    case 43:
    case "ZONE_SFP_26":
      return Zone.ZONE_SFP_26;
    case 44:
    case "ZONE_SFP_27":
      return Zone.ZONE_SFP_27;
    case 45:
    case "ZONE_SFP_28":
      return Zone.ZONE_SFP_28;
    case 46:
    case "ZONE_SFP_29":
      return Zone.ZONE_SFP_29;
    case 47:
    case "ZONE_SFP_30":
      return Zone.ZONE_SFP_30;
    case 48:
    case "ZONE_SFP_31":
      return Zone.ZONE_SFP_31;
    case 49:
    case "ZONE_SFP_32":
      return Zone.ZONE_SFP_32;
    case 50:
    case "ZONE_SFP_33":
      return Zone.ZONE_SFP_33;
    case 51:
    case "ZONE_SFP_34":
      return Zone.ZONE_SFP_34;
    case 52:
    case "ZONE_SFP_35":
      return Zone.ZONE_SFP_35;
    case 53:
    case "ZONE_SFP_36":
      return Zone.ZONE_SFP_36;
    case 54:
    case "ZONE_SFP_37":
      return Zone.ZONE_SFP_37;
    case 55:
    case "ZONE_SFP_38":
      return Zone.ZONE_SFP_38;
    case 56:
    case "ZONE_SFP_39":
      return Zone.ZONE_SFP_39;
    case 57:
    case "ZONE_SFP_40":
      return Zone.ZONE_SFP_40;
    case 58:
    case "ZONE_SFP_41":
      return Zone.ZONE_SFP_41;
    case 59:
    case "ZONE_SFP_42":
      return Zone.ZONE_SFP_42;
    case 60:
    case "ZONE_SFP_43":
      return Zone.ZONE_SFP_43;
    case 61:
    case "ZONE_SFP_44":
      return Zone.ZONE_SFP_44;
    case 62:
    case "ZONE_SFP_45":
      return Zone.ZONE_SFP_45;
    case 63:
    case "ZONE_SFP_46":
      return Zone.ZONE_SFP_46;
    case 64:
    case "ZONE_SFP_47":
      return Zone.ZONE_SFP_47;
    case 65:
    case "ZONE_SFP_48":
      return Zone.ZONE_SFP_48;
    case 66:
    case "ZONE_SFP_49":
      return Zone.ZONE_SFP_49;
    case 67:
    case "ZONE_SFP_50":
      return Zone.ZONE_SFP_50;
    case 68:
    case "ZONE_SFP_51":
      return Zone.ZONE_SFP_51;
    case 69:
    case "ZONE_SFP_52":
      return Zone.ZONE_SFP_52;
    case 70:
    case "ZONE_SFP_53":
      return Zone.ZONE_SFP_53;
    case 71:
    case "ZONE_SFP_54":
      return Zone.ZONE_SFP_54;
    case 72:
    case "ZONE_SFP_55":
      return Zone.ZONE_SFP_55;
    case 73:
    case "ZONE_SFP_56":
      return Zone.ZONE_SFP_56;
    case 74:
    case "ZONE_SFP_57":
      return Zone.ZONE_SFP_57;
    case 75:
    case "ZONE_SFP_58":
      return Zone.ZONE_SFP_58;
    case 76:
    case "ZONE_SFP_59":
      return Zone.ZONE_SFP_59;
    case 77:
    case "ZONE_SFP_60":
      return Zone.ZONE_SFP_60;
    case 78:
    case "ZONE_SFP_62":
      return Zone.ZONE_SFP_62;
    case 79:
    case "ZONE_SFP_63":
      return Zone.ZONE_SFP_63;
    case 80:
    case "ZONE_SFP_64":
      return Zone.ZONE_SFP_64;
    case 81:
    case "ZONE_SFP_65":
      return Zone.ZONE_SFP_65;
    case 82:
    case "ZONE_SFP_66":
      return Zone.ZONE_SFP_66;
    case 83:
    case "ZONE_SFP_67":
      return Zone.ZONE_SFP_67;
    case 84:
    case "ZONE_SFP_68":
      return Zone.ZONE_SFP_68;
    case 85:
    case "ZONE_SFP_69":
      return Zone.ZONE_SFP_69;
    case 86:
    case "ZONE_SFP_70":
      return Zone.ZONE_SFP_70;
    case 87:
    case "ZONE_SFP_71":
      return Zone.ZONE_SFP_71;
    case 88:
    case "ZONE_SFP_72":
      return Zone.ZONE_SFP_72;
    case 89:
    case "ZONE_SFP_73":
      return Zone.ZONE_SFP_73;
    case 90:
    case "ZONE_SFP_75":
      return Zone.ZONE_SFP_75;
    case 91:
    case "ZONE_SFP_76":
      return Zone.ZONE_SFP_76;
    case 92:
    case "ZONE_SFP_77":
      return Zone.ZONE_SFP_77;
    case 93:
    case "ZONE_SFP_78":
      return Zone.ZONE_SFP_78;
    case 94:
    case "ZONE_SFP_79":
      return Zone.ZONE_SFP_79;
    case 95:
    case "ZONE_SFP_80":
      return Zone.ZONE_SFP_80;
    case 96:
    case "ZONE_SFP_81":
      return Zone.ZONE_SFP_81;
    case 97:
    case "ZONE_SFP_82":
      return Zone.ZONE_SFP_82;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Zone");
  }
}

export function zoneToJSON(object: Zone): string {
  switch (object) {
    case Zone.ZONE_UNSPECIFIED:
      return "ZONE_UNSPECIFIED";
    case Zone.ZONE_ATLANTA:
      return "ZONE_ATLANTA";
    case Zone.ZONE_CHROMEOS1:
      return "ZONE_CHROMEOS1";
    case Zone.ZONE_CHROMEOS2:
      return "ZONE_CHROMEOS2";
    case Zone.ZONE_CHROMEOS3:
      return "ZONE_CHROMEOS3";
    case Zone.ZONE_CHROMEOS4:
      return "ZONE_CHROMEOS4";
    case Zone.ZONE_CHROMEOS5:
      return "ZONE_CHROMEOS5";
    case Zone.ZONE_CHROMEOS6:
      return "ZONE_CHROMEOS6";
    case Zone.ZONE_CHROMEOS7:
      return "ZONE_CHROMEOS7";
    case Zone.ZONE_CHROMEOS15:
      return "ZONE_CHROMEOS15";
    case Zone.ZONE_ATL97:
      return "ZONE_ATL97";
    case Zone.ZONE_IAD97:
      return "ZONE_IAD97";
    case Zone.ZONE_MTV96:
      return "ZONE_MTV96";
    case Zone.ZONE_MTV97:
      return "ZONE_MTV97";
    case Zone.ZONE_FUCHSIA:
      return "ZONE_FUCHSIA";
    case Zone.ZONE_CROS_GOOGLER_DESK:
      return "ZONE_CROS_GOOGLER_DESK";
    case Zone.ZONE_MTV1950_TESTING:
      return "ZONE_MTV1950_TESTING";
    case Zone.ZONE_SATLAB:
      return "ZONE_SATLAB";
    case Zone.ZONE_BROWSER_GOOGLER_DESK:
      return "ZONE_BROWSER_GOOGLER_DESK";
    case Zone.ZONE_JETSTREAM_B946:
      return "ZONE_JETSTREAM_B946";
    case Zone.ZONE_SFO36_NETWORK:
      return "ZONE_SFO36_NETWORK";
    case Zone.ZONE_SFO36_BROWSER:
      return "ZONE_SFO36_BROWSER";
    case Zone.ZONE_SFO36_OS:
      return "ZONE_SFO36_OS";
    case Zone.ZONE_SFO36_OS_CHROMIUM:
      return "ZONE_SFO36_OS_CHROMIUM";
    case Zone.ZONE_SFP_SFPTEST:
      return "ZONE_SFP_SFPTEST";
    case Zone.ZONE_IAD65_OS:
      return "ZONE_IAD65_OS";
    case Zone.ZONE_SFP_CROSTEST:
      return "ZONE_SFP_CROSTEST";
    case Zone.ZONE_SFP_2:
      return "ZONE_SFP_2";
    case Zone.ZONE_SFP_6:
      return "ZONE_SFP_6";
    case Zone.ZONE_SFP_4:
      return "ZONE_SFP_4";
    case Zone.ZONE_SFP_9:
      return "ZONE_SFP_9";
    case Zone.ZONE_SFP_10:
      return "ZONE_SFP_10";
    case Zone.ZONE_SFP_13:
      return "ZONE_SFP_13";
    case Zone.ZONE_SFP_14:
      return "ZONE_SFP_14";
    case Zone.ZONE_SFP_15:
      return "ZONE_SFP_15";
    case Zone.ZONE_SFP_16:
      return "ZONE_SFP_16";
    case Zone.ZONE_SFP_17:
      return "ZONE_SFP_17";
    case Zone.ZONE_SFP_20:
      return "ZONE_SFP_20";
    case Zone.ZONE_SFP_21:
      return "ZONE_SFP_21";
    case Zone.ZONE_SFP_22:
      return "ZONE_SFP_22";
    case Zone.ZONE_SFP_23:
      return "ZONE_SFP_23";
    case Zone.ZONE_SFP_24:
      return "ZONE_SFP_24";
    case Zone.ZONE_SFP_26:
      return "ZONE_SFP_26";
    case Zone.ZONE_SFP_27:
      return "ZONE_SFP_27";
    case Zone.ZONE_SFP_28:
      return "ZONE_SFP_28";
    case Zone.ZONE_SFP_29:
      return "ZONE_SFP_29";
    case Zone.ZONE_SFP_30:
      return "ZONE_SFP_30";
    case Zone.ZONE_SFP_31:
      return "ZONE_SFP_31";
    case Zone.ZONE_SFP_32:
      return "ZONE_SFP_32";
    case Zone.ZONE_SFP_33:
      return "ZONE_SFP_33";
    case Zone.ZONE_SFP_34:
      return "ZONE_SFP_34";
    case Zone.ZONE_SFP_35:
      return "ZONE_SFP_35";
    case Zone.ZONE_SFP_36:
      return "ZONE_SFP_36";
    case Zone.ZONE_SFP_37:
      return "ZONE_SFP_37";
    case Zone.ZONE_SFP_38:
      return "ZONE_SFP_38";
    case Zone.ZONE_SFP_39:
      return "ZONE_SFP_39";
    case Zone.ZONE_SFP_40:
      return "ZONE_SFP_40";
    case Zone.ZONE_SFP_41:
      return "ZONE_SFP_41";
    case Zone.ZONE_SFP_42:
      return "ZONE_SFP_42";
    case Zone.ZONE_SFP_43:
      return "ZONE_SFP_43";
    case Zone.ZONE_SFP_44:
      return "ZONE_SFP_44";
    case Zone.ZONE_SFP_45:
      return "ZONE_SFP_45";
    case Zone.ZONE_SFP_46:
      return "ZONE_SFP_46";
    case Zone.ZONE_SFP_47:
      return "ZONE_SFP_47";
    case Zone.ZONE_SFP_48:
      return "ZONE_SFP_48";
    case Zone.ZONE_SFP_49:
      return "ZONE_SFP_49";
    case Zone.ZONE_SFP_50:
      return "ZONE_SFP_50";
    case Zone.ZONE_SFP_51:
      return "ZONE_SFP_51";
    case Zone.ZONE_SFP_52:
      return "ZONE_SFP_52";
    case Zone.ZONE_SFP_53:
      return "ZONE_SFP_53";
    case Zone.ZONE_SFP_54:
      return "ZONE_SFP_54";
    case Zone.ZONE_SFP_55:
      return "ZONE_SFP_55";
    case Zone.ZONE_SFP_56:
      return "ZONE_SFP_56";
    case Zone.ZONE_SFP_57:
      return "ZONE_SFP_57";
    case Zone.ZONE_SFP_58:
      return "ZONE_SFP_58";
    case Zone.ZONE_SFP_59:
      return "ZONE_SFP_59";
    case Zone.ZONE_SFP_60:
      return "ZONE_SFP_60";
    case Zone.ZONE_SFP_62:
      return "ZONE_SFP_62";
    case Zone.ZONE_SFP_63:
      return "ZONE_SFP_63";
    case Zone.ZONE_SFP_64:
      return "ZONE_SFP_64";
    case Zone.ZONE_SFP_65:
      return "ZONE_SFP_65";
    case Zone.ZONE_SFP_66:
      return "ZONE_SFP_66";
    case Zone.ZONE_SFP_67:
      return "ZONE_SFP_67";
    case Zone.ZONE_SFP_68:
      return "ZONE_SFP_68";
    case Zone.ZONE_SFP_69:
      return "ZONE_SFP_69";
    case Zone.ZONE_SFP_70:
      return "ZONE_SFP_70";
    case Zone.ZONE_SFP_71:
      return "ZONE_SFP_71";
    case Zone.ZONE_SFP_72:
      return "ZONE_SFP_72";
    case Zone.ZONE_SFP_73:
      return "ZONE_SFP_73";
    case Zone.ZONE_SFP_75:
      return "ZONE_SFP_75";
    case Zone.ZONE_SFP_76:
      return "ZONE_SFP_76";
    case Zone.ZONE_SFP_77:
      return "ZONE_SFP_77";
    case Zone.ZONE_SFP_78:
      return "ZONE_SFP_78";
    case Zone.ZONE_SFP_79:
      return "ZONE_SFP_79";
    case Zone.ZONE_SFP_80:
      return "ZONE_SFP_80";
    case Zone.ZONE_SFP_81:
      return "ZONE_SFP_81";
    case Zone.ZONE_SFP_82:
      return "ZONE_SFP_82";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Zone");
  }
}

/**
 * Location of the asset(Rack/Machine) in the lab
 * For Browser machine, lab and rack are the only field to fill in.
 * The fine-grained location is mainly for OS machine as we care about rack,
 * row, shelf.
 */
export interface Location {
  /**
   * Each lab has many aisles.
   * This field refers to the aisle number/name in the lab.
   */
  readonly aisle: string;
  /**
   * Each aisle has many rows.
   * This field refers to the row number/name in the aisle.
   */
  readonly row: string;
  /**
   * Each row has many racks.
   * This field refers to the rack number/name in the row.
   */
  readonly rack: string;
  /** The position of the rack in the row. */
  readonly rackNumber: string;
  /**
   * Each rack has many shelves.
   * This field refers to the shelf number/name in the rack.
   */
  readonly shelf: string;
  /**
   * Each shelf has many positions where assets can be placed.
   * This field refers to the position number/name in the shelf
   */
  readonly position: string;
  /**
   * A string descriptor representing location. This can be to
   * store barcode values for location or user defined names.
   */
  readonly barcodeName: string;
  /** Different zones in the chrome org. Required. */
  readonly zone: Zone;
  /**
   * CabinetAssetID of the rack/cabinet the asset is mounted in.
   * This is used as a unique identifier in Nlyte for cabinets.
   */
  readonly rackId: number;
  /** LocationGroupID of the current lab in Nlyte. */
  readonly labId: number;
  /**
   * Format: “Room_Name (City,Building,Floor)”. Lab is interchangeable with
   * Room.
   */
  readonly fullLocationName: string;
}

function createBaseLocation(): Location {
  return {
    aisle: "",
    row: "",
    rack: "",
    rackNumber: "",
    shelf: "",
    position: "",
    barcodeName: "",
    zone: 0,
    rackId: 0,
    labId: 0,
    fullLocationName: "",
  };
}

export const Location: MessageFns<Location> = {
  encode(message: Location, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.aisle !== "") {
      writer.uint32(18).string(message.aisle);
    }
    if (message.row !== "") {
      writer.uint32(26).string(message.row);
    }
    if (message.rack !== "") {
      writer.uint32(34).string(message.rack);
    }
    if (message.rackNumber !== "") {
      writer.uint32(42).string(message.rackNumber);
    }
    if (message.shelf !== "") {
      writer.uint32(50).string(message.shelf);
    }
    if (message.position !== "") {
      writer.uint32(58).string(message.position);
    }
    if (message.barcodeName !== "") {
      writer.uint32(66).string(message.barcodeName);
    }
    if (message.zone !== 0) {
      writer.uint32(72).int32(message.zone);
    }
    if (message.rackId !== 0) {
      writer.uint32(80).int32(message.rackId);
    }
    if (message.labId !== 0) {
      writer.uint32(88).int32(message.labId);
    }
    if (message.fullLocationName !== "") {
      writer.uint32(98).string(message.fullLocationName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Location {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocation() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.aisle = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.row = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.rack = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.rackNumber = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.shelf = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.position = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.barcodeName = reader.string();
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.zone = reader.int32() as any;
          continue;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.rackId = reader.int32();
          continue;
        }
        case 11: {
          if (tag !== 88) {
            break;
          }

          message.labId = reader.int32();
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.fullLocationName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Location {
    return {
      aisle: isSet(object.aisle) ? globalThis.String(object.aisle) : "",
      row: isSet(object.row) ? globalThis.String(object.row) : "",
      rack: isSet(object.rack) ? globalThis.String(object.rack) : "",
      rackNumber: isSet(object.rackNumber) ? globalThis.String(object.rackNumber) : "",
      shelf: isSet(object.shelf) ? globalThis.String(object.shelf) : "",
      position: isSet(object.position) ? globalThis.String(object.position) : "",
      barcodeName: isSet(object.barcodeName) ? globalThis.String(object.barcodeName) : "",
      zone: isSet(object.zone) ? zoneFromJSON(object.zone) : 0,
      rackId: isSet(object.rackId) ? globalThis.Number(object.rackId) : 0,
      labId: isSet(object.labId) ? globalThis.Number(object.labId) : 0,
      fullLocationName: isSet(object.fullLocationName) ? globalThis.String(object.fullLocationName) : "",
    };
  },

  toJSON(message: Location): unknown {
    const obj: any = {};
    if (message.aisle !== "") {
      obj.aisle = message.aisle;
    }
    if (message.row !== "") {
      obj.row = message.row;
    }
    if (message.rack !== "") {
      obj.rack = message.rack;
    }
    if (message.rackNumber !== "") {
      obj.rackNumber = message.rackNumber;
    }
    if (message.shelf !== "") {
      obj.shelf = message.shelf;
    }
    if (message.position !== "") {
      obj.position = message.position;
    }
    if (message.barcodeName !== "") {
      obj.barcodeName = message.barcodeName;
    }
    if (message.zone !== 0) {
      obj.zone = zoneToJSON(message.zone);
    }
    if (message.rackId !== 0) {
      obj.rackId = Math.round(message.rackId);
    }
    if (message.labId !== 0) {
      obj.labId = Math.round(message.labId);
    }
    if (message.fullLocationName !== "") {
      obj.fullLocationName = message.fullLocationName;
    }
    return obj;
  },

  create(base?: DeepPartial<Location>): Location {
    return Location.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Location>): Location {
    const message = createBaseLocation() as any;
    message.aisle = object.aisle ?? "";
    message.row = object.row ?? "";
    message.rack = object.rack ?? "";
    message.rackNumber = object.rackNumber ?? "";
    message.shelf = object.shelf ?? "";
    message.position = object.position ?? "";
    message.barcodeName = object.barcodeName ?? "";
    message.zone = object.zone ?? 0;
    message.rackId = object.rackId ?? 0;
    message.labId = object.labId ?? 0;
    message.fullLocationName = object.fullLocationName ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
