// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v5.28.3
// source: infra/unifiedfleet/api/v1/models/chromeos/device/config_id.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { BrandId } from "./brand_id.pb";
import { ModelId } from "./model_id.pb";
import { PlatformId } from "./platform_id.pb";
import { VariantId } from "./variant_id.pb";

export const protobufPackage = "unifiedfleet.api.v1.models.chromeos.device";

/**
 * These are the globally unique identifiers that determine what set of
 * configuration data is used for a given device.
 */
export interface ConfigId {
  /** Required. */
  readonly platformId:
    | PlatformId
    | undefined;
  /** Required. */
  readonly modelId:
    | ModelId
    | undefined;
  /** Required. */
  readonly variantId:
    | VariantId
    | undefined;
  /** Required. */
  readonly brandId: BrandId | undefined;
}

function createBaseConfigId(): ConfigId {
  return { platformId: undefined, modelId: undefined, variantId: undefined, brandId: undefined };
}

export const ConfigId: MessageFns<ConfigId> = {
  encode(message: ConfigId, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.platformId !== undefined) {
      PlatformId.encode(message.platformId, writer.uint32(10).fork()).join();
    }
    if (message.modelId !== undefined) {
      ModelId.encode(message.modelId, writer.uint32(18).fork()).join();
    }
    if (message.variantId !== undefined) {
      VariantId.encode(message.variantId, writer.uint32(26).fork()).join();
    }
    if (message.brandId !== undefined) {
      BrandId.encode(message.brandId, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConfigId {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigId() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.platformId = PlatformId.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.modelId = ModelId.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.variantId = VariantId.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.brandId = BrandId.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigId {
    return {
      platformId: isSet(object.platformId) ? PlatformId.fromJSON(object.platformId) : undefined,
      modelId: isSet(object.modelId) ? ModelId.fromJSON(object.modelId) : undefined,
      variantId: isSet(object.variantId) ? VariantId.fromJSON(object.variantId) : undefined,
      brandId: isSet(object.brandId) ? BrandId.fromJSON(object.brandId) : undefined,
    };
  },

  toJSON(message: ConfigId): unknown {
    const obj: any = {};
    if (message.platformId !== undefined) {
      obj.platformId = PlatformId.toJSON(message.platformId);
    }
    if (message.modelId !== undefined) {
      obj.modelId = ModelId.toJSON(message.modelId);
    }
    if (message.variantId !== undefined) {
      obj.variantId = VariantId.toJSON(message.variantId);
    }
    if (message.brandId !== undefined) {
      obj.brandId = BrandId.toJSON(message.brandId);
    }
    return obj;
  },

  create(base?: DeepPartial<ConfigId>): ConfigId {
    return ConfigId.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ConfigId>): ConfigId {
    const message = createBaseConfigId() as any;
    message.platformId = (object.platformId !== undefined && object.platformId !== null)
      ? PlatformId.fromPartial(object.platformId)
      : undefined;
    message.modelId = (object.modelId !== undefined && object.modelId !== null)
      ? ModelId.fromPartial(object.modelId)
      : undefined;
    message.variantId = (object.variantId !== undefined && object.variantId !== null)
      ? VariantId.fromPartial(object.variantId)
      : undefined;
    message.brandId = (object.brandId !== undefined && object.brandId !== null)
      ? BrandId.fromPartial(object.brandId)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
