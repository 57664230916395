// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v5.28.3
// source: infra/unifiedfleet/api/v1/models/caching_service.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../../../../../google/protobuf/timestamp.pb";
import { Zone, zoneFromJSON, zoneToJSON } from "./location.pb";
import { State, stateFromJSON, stateToJSON } from "./state.pb";

export const protobufPackage = "unifiedfleet.api.v1.models";

/**
 * CachingService refers to caching service information in the chromeos lab
 *
 * A lab can have multiple caching services running. Each caching service has a primary node
 * and a secondary node and serve a particular subnet.
 * UFS stores the information of all caching services available in the labs.
 */
export interface CachingService {
  /**
   * caching service name
   * format will be 'cachingservices/{ipv4}'
   */
  readonly name: string;
  /** port info of the caching service */
  readonly port: number;
  /**
   * particular subnet which the caching service serves/supports
   * Deprecated. Use 'serving_subnets' instead.
   *
   * @deprecated
   */
  readonly servingSubnet: string;
  /** ipv4 address of the primary node of the caching service */
  readonly primaryNode: string;
  /** ipv4 address of the secondary node of the caching service */
  readonly secondaryNode: string;
  /** state of the caching service */
  readonly state: State;
  /** description of the caching service */
  readonly description: string;
  /** record the last update timestamp of this caching service (In UTC timezone) */
  readonly updateTime:
    | string
    | undefined;
  /**
   * Particular subnets which the caching service serves/supports. One caching
   * service can support multiple subnets in the case of P2P network.
   */
  readonly servingSubnets: readonly string[];
  /**
   * The UFS zones the caching service serves. One caching service can support
   * multiple zones, e.g. [CHROMEOS2, CHROMEOS4, CHROMEOS6]
   */
  readonly zones: readonly Zone[];
}

function createBaseCachingService(): CachingService {
  return {
    name: "",
    port: 0,
    servingSubnet: "",
    primaryNode: "",
    secondaryNode: "",
    state: 0,
    description: "",
    updateTime: undefined,
    servingSubnets: [],
    zones: [],
  };
}

export const CachingService: MessageFns<CachingService> = {
  encode(message: CachingService, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.port !== 0) {
      writer.uint32(16).int32(message.port);
    }
    if (message.servingSubnet !== "") {
      writer.uint32(26).string(message.servingSubnet);
    }
    if (message.primaryNode !== "") {
      writer.uint32(34).string(message.primaryNode);
    }
    if (message.secondaryNode !== "") {
      writer.uint32(42).string(message.secondaryNode);
    }
    if (message.state !== 0) {
      writer.uint32(48).int32(message.state);
    }
    if (message.description !== "") {
      writer.uint32(58).string(message.description);
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(66).fork()).join();
    }
    for (const v of message.servingSubnets) {
      writer.uint32(74).string(v!);
    }
    writer.uint32(82).fork();
    for (const v of message.zones) {
      writer.int32(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CachingService {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCachingService() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.port = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.servingSubnet = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.primaryNode = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.secondaryNode = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.description = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.servingSubnets.push(reader.string());
          continue;
        }
        case 10: {
          if (tag === 80) {
            message.zones.push(reader.int32() as any);

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.zones.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CachingService {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      port: isSet(object.port) ? globalThis.Number(object.port) : 0,
      servingSubnet: isSet(object.servingSubnet) ? globalThis.String(object.servingSubnet) : "",
      primaryNode: isSet(object.primaryNode) ? globalThis.String(object.primaryNode) : "",
      secondaryNode: isSet(object.secondaryNode) ? globalThis.String(object.secondaryNode) : "",
      state: isSet(object.state) ? stateFromJSON(object.state) : 0,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      updateTime: isSet(object.updateTime) ? globalThis.String(object.updateTime) : undefined,
      servingSubnets: globalThis.Array.isArray(object?.servingSubnets)
        ? object.servingSubnets.map((e: any) => globalThis.String(e))
        : [],
      zones: globalThis.Array.isArray(object?.zones) ? object.zones.map((e: any) => zoneFromJSON(e)) : [],
    };
  },

  toJSON(message: CachingService): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.port !== 0) {
      obj.port = Math.round(message.port);
    }
    if (message.servingSubnet !== "") {
      obj.servingSubnet = message.servingSubnet;
    }
    if (message.primaryNode !== "") {
      obj.primaryNode = message.primaryNode;
    }
    if (message.secondaryNode !== "") {
      obj.secondaryNode = message.secondaryNode;
    }
    if (message.state !== 0) {
      obj.state = stateToJSON(message.state);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime;
    }
    if (message.servingSubnets?.length) {
      obj.servingSubnets = message.servingSubnets;
    }
    if (message.zones?.length) {
      obj.zones = message.zones.map((e) => zoneToJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<CachingService>): CachingService {
    return CachingService.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CachingService>): CachingService {
    const message = createBaseCachingService() as any;
    message.name = object.name ?? "";
    message.port = object.port ?? 0;
    message.servingSubnet = object.servingSubnet ?? "";
    message.primaryNode = object.primaryNode ?? "";
    message.secondaryNode = object.secondaryNode ?? "";
    message.state = object.state ?? 0;
    message.description = object.description ?? "";
    message.updateTime = object.updateTime ?? undefined;
    message.servingSubnets = object.servingSubnets?.map((e) => e) || [];
    message.zones = object.zones?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
