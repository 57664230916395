// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v5.28.3
// source: infra/unifiedfleet/api/v1/rpc/fleet.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Cbi } from "../../../../../go.chromium.org/chromiumos/config/proto/chromiumos/test/lab/api/cbi.pb";
import { UsbDrive } from "../../../../../go.chromium.org/chromiumos/config/proto/chromiumos/test/lab/api/usb_drive.pb";
import {
  WifiRouterDeviceType,
  wifiRouterDeviceTypeFromJSON,
  wifiRouterDeviceTypeToJSON,
  WifiRouterFeature,
  wifiRouterFeatureFromJSON,
  wifiRouterFeatureToJSON,
} from "../../../../../go.chromium.org/chromiumos/config/proto/chromiumos/test/lab/api/wifi_router.pb";
import { Empty } from "../../../../../google/protobuf/empty.pb";
import { FieldMask } from "../../../../../google/protobuf/field_mask.pb";
import { Status } from "../../../../../google/rpc/status.pb";
import { asset } from "../models/asset.pb";
import { CachingService } from "../models/caching_service.pb";
import { ChromePlatform } from "../models/chrome_platform.pb";
import {
  Chameleon_AudioBoxJackPlugger,
  chameleon_AudioBoxJackPluggerFromJSON,
  chameleon_AudioBoxJackPluggerToJSON,
} from "../models/chromeos/lab/chameleon.pb";
import {
  DutState,
  PeripheralState,
  peripheralStateFromJSON,
  peripheralStateToJSON,
} from "../models/chromeos/lab/dut_state.pb";
import { ModemType, modemTypeFromJSON, modemTypeToJSON } from "../models/chromeos/lab/modeminfo.pb";
import { ServoTopology } from "../models/chromeos/lab/servo.pb";
import { SIMInfo } from "../models/chromeos/lab/siminfo.pb";
import { MachineLSEPrototype, RackLSEPrototype } from "../models/lse_prototype.pb";
import { Machine } from "../models/machine.pb";
import { ChromeOSDeviceData, DutMeta, LabMeta, MachineLSE, OSVersion, VM } from "../models/machine_lse.pb";
import { MachineLSEDeployment } from "../models/machine_lse_deployment.pb";
import { DHCPConfig, IP, Nic, Vlan } from "../models/network.pb";
import { OwnershipData } from "../models/ownership.pb";
import { Drac, KVM, RPM, Switch } from "../models/peripherals.pb";
import { Rack } from "../models/rack.pb";
import { RackLSE } from "../models/rack_lse.pb";
import { SchedulingUnit } from "../models/scheduling_unit.pb";
import { DefaultWifi } from "../models/secret.pb";
import { State, stateFromJSON, StateRecord, stateToJSON } from "../models/state.pb";

export const protobufPackage = "unifiedfleet.api.v1.rpc";

export interface GetDUTsForLabstationRequest {
  /** hostname is the hostname of a labstation */
  readonly hostname: readonly string[];
}

export interface GetDUTsForLabstationResponse {
  readonly items: readonly GetDUTsForLabstationResponse_LabstationMapping[];
}

export interface GetDUTsForLabstationResponse_LabstationMapping {
  /** hostname is the hostname of the labstation. */
  readonly hostname: string;
  /**
   * dutName is the name of the dut or scheduling unit.
   * For the moment 2023-06-XX, only non-multidut units are supported.
   */
  readonly dutName: readonly string[];
}

export interface UpdateMachineLSEDeploymentRequest {
  /** The MachineLSEDeployment to update. */
  readonly machineLseDeployment:
    | MachineLSEDeployment
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface BatchUpdateMachineLSEDeploymentRequest {
  /** The parent resource shared by all deployment records being updated. */
  readonly parent: string;
  /** A maximum of 1000 requests can be handled in one call. */
  readonly requests: readonly UpdateMachineLSEDeploymentRequest[];
}

export interface BatchUpdateMachineLSEDeploymentResponse {
  /** deployment records updated. */
  readonly machineLseDeployments: readonly MachineLSEDeployment[];
}

export interface GetMachineLSEDeploymentRequest {
  /** The host identifier (e.g. serial number) to retrieve the deployment record. */
  readonly name: string;
}

export interface BatchGetMachineLSEDeploymentsRequest {
  /** The parent resource shared by all machine lse deployment records being retrieved. */
  readonly parent: string;
  /**
   * The names (e.g. serial number) of the machine lse deployment records to retrieve.
   * Format: machineLSEDeployments/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetMachineLSEDeploymentsResponse {
  /** The Machine lses deployment records to retrieve. */
  readonly machineLseDeployments: readonly MachineLSEDeployment[];
}

export interface ListMachineLSEDeploymentsRequest {
  /**
   * The maximum number of deployment records to return.
   * The service may return fewer than this value.
   * If not specified, 100 deployment records will be returned by default.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListMachineLSEDeployments` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListMachineLSEDeployments` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition. */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListMachineLSEDeploymentsResponse {
  /** The deployment records from datastore. */
  readonly machineLseDeployments: readonly MachineLSEDeployment[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface CreateVMRequest {
  /** The vm to create. */
  readonly vm: VM | undefined;
  readonly networkOption: NetworkOption | undefined;
}

export interface UpdateVMRequest {
  /** The VM to update. */
  readonly vm:
    | VM
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask:
    | readonly string[]
    | undefined;
  /** The network option to set the VM */
  readonly networkOption: NetworkOption | undefined;
}

export interface GetVMRequest {
  /** The name of the VM to retrieve. */
  readonly name: string;
}

export interface DeleteVMRequest {
  /** The name of the VM to delete. */
  readonly name: string;
}

export interface ListVMsRequest {
  /**
   * The maximum number of vlans to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 vms will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListVMs` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListVlans` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListVMsResponse {
  /** The vms from datastore. */
  readonly vms: readonly VM[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface GetDHCPConfigRequest {
  /** The hostname to retrieve the dhcp config. */
  readonly hostname: string;
}

/**
 * Contains the required information for creating a ChromePlatform represented in
 * the database.
 */
export interface CreateChromePlatformRequest {
  /** The chromePlatform to create. */
  readonly chromePlatform:
    | ChromePlatform
    | undefined;
  /**
   * The ID to use for the ChromePlatform, which will become the final component of
   * the ChromePlatform's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly chromePlatformId: string;
}

export interface UpdateChromePlatformRequest {
  /** The chromePlatform to update. */
  readonly chromePlatform:
    | ChromePlatform
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface GetChromePlatformRequest {
  /** The name of the chromePlatform to retrieve. */
  readonly name: string;
}

export interface ListChromePlatformsRequest {
  /**
   * The maximum number of chromePlatforms to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 chromePlatforms will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListChromePlatforms` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListChromePlatforms` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListChromePlatformsResponse {
  /** The chromePlatforms from datastore. */
  readonly chromePlatforms: readonly ChromePlatform[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteChromePlatformRequest {
  /** The name of the ChromePlatform to delete */
  readonly name: string;
}

export interface ChromePlatformResult {
  readonly platform: ChromePlatform | undefined;
  readonly errorMsg: string;
}

export interface ListOSVersionsRequest {
  /**
   * The maximum number of OSVersion to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 OSVersion will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListOSVersion` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListOSVersion` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListOSVersionsResponse {
  /** The os versions for browser lab from datastore. */
  readonly osVersion: readonly OSVersion[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

/**
 * Contains the required information for creating a MachineLSEPrototype represented in
 * the database.
 */
export interface CreateMachineLSEPrototypeRequest {
  /** The MachineLSEPrototype to create. */
  readonly machineLSEPrototype:
    | MachineLSEPrototype
    | undefined;
  /**
   * The ID to use for the MachineLSEPrototype, which will become the final component of
   * the MachineLSEPrototype's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly machineLSEPrototypeId: string;
}

export interface UpdateMachineLSEPrototypeRequest {
  /** The MachineLSEPrototype to update. */
  readonly machineLSEPrototype:
    | MachineLSEPrototype
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface GetMachineLSEPrototypeRequest {
  /** The name of the MachineLSEPrototype to retrieve. */
  readonly name: string;
}

export interface ListMachineLSEPrototypesRequest {
  /**
   * The maximum number of MachineLSEPrototypes to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 MachineLSEPrototypes will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListMachineLSEPrototypes` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListMachineLSEPrototypes` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListMachineLSEPrototypesResponse {
  /** The MachineLSEPrototypes from datastore. */
  readonly machineLSEPrototypes: readonly MachineLSEPrototype[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteMachineLSEPrototypeRequest {
  /** The name of the MachineLSEPrototype to delete */
  readonly name: string;
}

/**
 * Contains the required information for creating a RackLSEPrototype represented in
 * the database.
 */
export interface CreateRackLSEPrototypeRequest {
  /** The RackLSEPrototype to create. */
  readonly rackLSEPrototype:
    | RackLSEPrototype
    | undefined;
  /**
   * The ID to use for the RackLSEPrototype, which will become the final component of
   * the RackLSEPrototype's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly rackLSEPrototypeId: string;
}

export interface UpdateRackLSEPrototypeRequest {
  /** The RackLSEPrototype to update. */
  readonly rackLSEPrototype:
    | RackLSEPrototype
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface GetRackLSEPrototypeRequest {
  /** The name of the RackLSEPrototype to retrieve. */
  readonly name: string;
}

export interface ListRackLSEPrototypesRequest {
  /**
   * The maximum number of RackLSEPrototypes to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 RackLSEPrototypes will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListRackLSEPrototypes` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListRackLSEPrototypes` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListRackLSEPrototypesResponse {
  /** The RackLSEPrototypes from datastore. */
  readonly rackLSEPrototypes: readonly RackLSEPrototype[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteRackLSEPrototypeRequest {
  /** The name of the RackLSEPrototype to delete */
  readonly name: string;
}

/**
 * Contains the required information for creating machine/nics/drac represented in
 * the database.
 */
export interface MachineRegistrationRequest {
  /** The machine to create. */
  readonly machine: Machine | undefined;
}

export interface UpdateMachineRequest {
  /** The machine to update. */
  readonly machine:
    | Machine
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface GetMachineRequest {
  /** The name of the machine to retrieve. */
  readonly name: string;
}

export interface ListMachinesRequest {
  /**
   * The maximum number of machines to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 machines will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListMachines` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListMachines` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
  /**
   * if this is true, machine will contain the full information of Nic/Drac.
   * By setting this to true, the list call will be slower as the server
   * needs to query and populate the Nic/Drac for each machine.
   */
  readonly full: boolean;
}

export interface ListMachinesResponse {
  /** The machines from datastore. */
  readonly machines: readonly Machine[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteMachineRequest {
  /** The name of the Machine to delete */
  readonly name: string;
}

export interface RenameMachineRequest {
  /** The name of the Machine to rename */
  readonly name: string;
  /** The new name of the Machine */
  readonly newName: string;
}

export interface MachineDBSource {
  readonly host: string;
}

export interface ConfigSource {
  /** Empty config_service means it's local file. */
  readonly configServiceName: string;
  readonly fileName: string;
}

/**
 * Contains the required information for creating a Rack represented in
 * the database.
 */
export interface CreateRackRequest {
  /** The rack to create. */
  readonly rack:
    | Rack
    | undefined;
  /**
   * The ID to use for the Rack, which will become the final component of
   * the Rack's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly rackId: string;
}

export interface UpdateRackRequest {
  /** The rack to update. */
  readonly rack:
    | Rack
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface GetRackRequest {
  /** The name of the rack to retrieve. */
  readonly name: string;
}

export interface ListRacksRequest {
  /**
   * The maximum number of racks to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 racks will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListRacks` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListRacks` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
  /**
   * if this is true, rack will contain the full information of KVM/RPM/Switch.
   * By setting this to true, the list call will be slower as the server
   * needs to query and populate the KVM/RPM/Switch for each rack.
   */
  readonly full: boolean;
}

export interface ListRacksResponse {
  /** The racks from datastore. */
  readonly racks: readonly Rack[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteRackRequest {
  /** The name of the Rack to delete */
  readonly name: string;
}

export interface RenameRackRequest {
  /** The name of the Rack to rename */
  readonly name: string;
  /** The new name of the Machine */
  readonly newName: string;
}

/**
 * Contains the required information for creating a MachineLSE represented in
 * the database.
 */
export interface CreateMachineLSERequest {
  /** The machineLSE to create. */
  readonly machineLSE:
    | MachineLSE
    | undefined;
  /**
   * The ID to use for the MachineLSE, which will become the final component of
   * the MachineLSE's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly machineLSEId: string;
  readonly networkOption: NetworkOption | undefined;
}

export interface UpdateMachineLSERequest {
  /** The machineLSE to update. */
  readonly machineLSE:
    | MachineLSE
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask:
    | readonly string[]
    | undefined;
  /** mapping of the name to the corresponding network options. */
  readonly networkOptions: { [key: string]: NetworkOption };
}

export interface UpdateMachineLSERequest_NetworkOptionsEntry {
  readonly key: string;
  readonly value: NetworkOption | undefined;
}

export interface GetMachineLSERequest {
  /** The name of the machineLSE to retrieve. */
  readonly name: string;
}

export interface GetMachineLSEBySerialRequest {
  /** The serial number of the machine underlying the host */
  readonly serial: string;
  /** if this is true, machinelse will contain the full information of VMs. */
  readonly full: boolean;
}

export interface GetHostDataRequest {
  /** The serial number of the machine underlying the host */
  readonly serial: string;
  /** if this is true, machinelse will contain the full information of VMs. */
  readonly full: boolean;
}

export interface GetHostDataResponse {
  /** MachineLSE data corresponding to the serial number */
  readonly host:
    | MachineLSE
    | undefined;
  /** Machine data corresponding to the serial number */
  readonly machine: Machine | undefined;
}

export interface ListMachineLSEsRequest {
  /**
   * The maximum number of machineLSEs to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 machineLSEs will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListMachineLSEs` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListMachineLSEs` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
  /**
   * if this is true, machinelse will contain the full information of VMs.
   * By setting this to true, the list call will be slower as the server
   * needs to query and populate the VM for each machinelse.
   */
  readonly full: boolean;
}

export interface ListMachineLSEsResponse {
  /** The machineLSEs from datastore. */
  readonly machineLSEs: readonly MachineLSE[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteMachineLSERequest {
  /** The name of the MachineLSE to delete */
  readonly name: string;
}

export interface RenameMachineLSERequest {
  /** The name of the MachineLSE to rename */
  readonly name: string;
  /** The new name of the MachineLSE */
  readonly newName: string;
}

/**
 * Contains the required information for creating a RackLSE represented in
 * the database.
 */
export interface CreateRackLSERequest {
  /** The rackLSE to create. */
  readonly rackLSE:
    | RackLSE
    | undefined;
  /**
   * The ID to use for the RackLSE, which will become the final component of
   * the RackLSE's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly rackLSEId: string;
}

export interface UpdateRackLSERequest {
  /** The rackLSE to update. */
  readonly rackLSE:
    | RackLSE
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface GetRackLSERequest {
  /** The name of the rackLSE to retrieve. */
  readonly name: string;
}

export interface ListRackLSEsRequest {
  /**
   * The maximum number of rackLSEs to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 rackLSEs will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListRackLSEs` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListRackLSEs` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListRackLSEsResponse {
  /** The rackLSEs from datastore. */
  readonly rackLSEs: readonly RackLSE[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteRackLSERequest {
  /** The name of the RackLSE to delete */
  readonly name: string;
}

/**
 * Contains the required information for creating a Nic represented in
 * the database.
 */
export interface CreateNicRequest {
  /** The nic to create. */
  readonly nic:
    | Nic
    | undefined;
  /**
   * The ID to use for the Nic, which will become the final component of
   * the Nic's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly nicId: string;
}

export interface UpdateNicRequest {
  /** The nic to update. */
  readonly nic:
    | Nic
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface GetNicRequest {
  /** The name of the nic to retrieve. */
  readonly name: string;
}

export interface ListNicsRequest {
  /**
   * The maximum number of nics to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 nics will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListNics` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListNics` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListNicsResponse {
  /** The nics from datastore. */
  readonly nics: readonly Nic[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteNicRequest {
  /** The name of the Nic to delete */
  readonly name: string;
}

export interface RenameNicRequest {
  /** The name of the Nic to rename */
  readonly name: string;
  /** The new name of the Nic */
  readonly newName: string;
}

export interface RenameSwitchRequest {
  /** The name of the Switch to rename */
  readonly name: string;
  /** The new name of the Switch */
  readonly newName: string;
}

/**
 * Contains the required information for creating a KVM represented in
 * the database.
 */
export interface CreateKVMRequest {
  /** The KVM to create. */
  readonly KVM:
    | KVM
    | undefined;
  /**
   * The ID to use for the KVM, which will become the final component of
   * the KVM's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly KVMId: string;
}

export interface UpdateKVMRequest {
  /** The KVM to update. */
  readonly KVM:
    | KVM
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
  readonly networkOption: NetworkOption | undefined;
}

export interface GetKVMRequest {
  /** The name of the KVM to retrieve. */
  readonly name: string;
}

export interface ListKVMsRequest {
  /**
   * The maximum number of KVMs to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 KVMs will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListKVMs` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListKVMs` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListKVMsResponse {
  /** The KVMs from datastore. */
  readonly KVMs: readonly KVM[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteKVMRequest {
  /** The name of the KVM to delete */
  readonly name: string;
}

/**
 * Contains the required information for creating a RPM represented in
 * the database.
 */
export interface CreateRPMRequest {
  /** The RPM to create. */
  readonly RPM:
    | RPM
    | undefined;
  /**
   * The ID to use for the RPM, which will become the final component of
   * the RPM's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly RPMId: string;
}

export interface UpdateRPMRequest {
  /** The RPM to update. */
  readonly RPM:
    | RPM
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
  readonly networkOption: NetworkOption | undefined;
}

export interface GetRPMRequest {
  /** The name of the RPM to retrieve. */
  readonly name: string;
}

export interface ListRPMsRequest {
  /**
   * The maximum number of RPMs to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 RPMs will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListRPMs` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListRPMs` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListRPMsResponse {
  /** The RPMs from datastore. */
  readonly RPMs: readonly RPM[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteRPMRequest {
  /** The name of the RPM to delete */
  readonly name: string;
}

/**
 * Contains the required information for creating a Drac represented in
 * the database.
 */
export interface CreateDracRequest {
  /** The drac to create. */
  readonly drac:
    | Drac
    | undefined;
  /**
   * The ID to use for the Drac, which will become the final component of
   * the Drac's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly dracId: string;
  readonly networkOption: NetworkOption | undefined;
}

export interface UpdateDracRequest {
  /** The drac to update. */
  readonly drac:
    | Drac
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
  readonly networkOption: NetworkOption | undefined;
}

export interface GetDracRequest {
  /** The name of the drac to retrieve. */
  readonly name: string;
}

export interface ListDracsRequest {
  /**
   * The maximum number of dracs to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 dracs will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListDracs` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListDracs` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListDracsResponse {
  /** The dracs from datastore. */
  readonly dracs: readonly Drac[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteDracRequest {
  /** The name of the Drac to delete */
  readonly name: string;
}

/**
 * Contains the required information for creating a Switch represented in
 * the database.
 */
export interface CreateSwitchRequest {
  /** The switch to create. */
  readonly switch:
    | Switch
    | undefined;
  /**
   * The ID to use for the Switch, which will become the final component of
   * the Switch's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly switchId: string;
}

export interface UpdateSwitchRequest {
  /** The switch to update. */
  readonly switch:
    | Switch
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface GetSwitchRequest {
  /** The name of the switch to retrieve. */
  readonly name: string;
}

export interface ListSwitchesRequest {
  /**
   * The maximum number of switches to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 switches will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListSwitches` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListSwitches` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListSwitchesResponse {
  /** The switches from datastore. */
  readonly switches: readonly Switch[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteSwitchRequest {
  /** The name of the Switch to delete */
  readonly name: string;
}

/**
 * Contains the required information for creating a Vlan represented in
 * the database.
 */
export interface CreateVlanRequest {
  /** The vlan to create. */
  readonly vlan:
    | Vlan
    | undefined;
  /**
   * The ID to use for the Vlan, which will become the final component of
   * the Vlan's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly vlanId: string;
}

export interface UpdateVlanRequest {
  /** The vlan to update. */
  readonly vlan:
    | Vlan
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface GetVlanRequest {
  /** The name of the vlan to retrieve. */
  readonly name: string;
}

export interface ListIPsRequest {
  /**
   * The maximum number of ips to return. The service may return fewer than
   * this value.
   * If unspecified, 100 IPs will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListIPs` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListIPs` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListVlansRequest {
  /**
   * The maximum number of vlans to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 vlans will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListVlans` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListVlans` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListIPsResponse {
  readonly ips: readonly IP[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface ListVlansResponse {
  /** The vlans from datastore. */
  readonly vlans: readonly Vlan[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteVlanRequest {
  /** The name of the Vlan to delete */
  readonly name: string;
}

export interface ImportOSVlansRequest {
  readonly machineDbSource?: MachineDBSource | undefined;
  readonly configSource?: ConfigSource | undefined;
}

export interface GetStateRequest {
  /** The name of the resource to retrieve the state. */
  readonly resourceName: string;
}

export interface GetDutStateRequest {
  /** Machine/Asset id. */
  readonly chromeosDeviceId: string;
  /** Hostname of the DUT/MachineLSE. */
  readonly hostname: string;
}

export interface ListDutStatesRequest {
  /**
   * The maximum number of DutStates to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 DutStates will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListDutStates` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListDutStates` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListDutStatesResponse {
  /** The DutStates from datastore. */
  readonly dutStates: readonly DutState[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface UpdateStateRequest {
  /** The state record to update. */
  readonly state:
    | StateRecord
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface UpdateDutStateRequest {
  /** The state record to update. */
  readonly dutState:
    | DutState
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask:
    | readonly string[]
    | undefined;
  /** Used for updating back essential configs from repair/deploy task. */
  readonly dutMeta: DutMeta | undefined;
  readonly labMeta: LabMeta | undefined;
}

/** Next ID: 4 */
export interface ChromeOsRecoveryData {
  /**
   * The state record to update.
   * DutState.Id is required and its value should be equal to UpdateDeviceRecoveryDataRequest.DeviceId
   * DutState.Hostname is optional. If set, it should be equal to UpdateDeviceRecoveryDataRequest.Hostname
   */
  readonly dutState:
    | DutState
    | undefined;
  /** Used for updating back essential configs from repair/deploy task. */
  readonly dutData:
    | ChromeOsRecoveryData_DutData
    | undefined;
  /** Used for updating back essential configs from repair/deploy task. */
  readonly labData: ChromeOsRecoveryData_LabData | undefined;
}

export interface ChromeOsRecoveryData_DutData {
  readonly serialNumber: string;
  readonly hwID: string;
  readonly deviceSku: string;
  readonly dlmSkuId: string;
}

export interface ChromeOsRecoveryData_WifiRouter {
  readonly hostname: string;
  readonly state: PeripheralState;
  /** The model of the router. */
  readonly model: string;
  /** Supported test router hardware and software features. */
  readonly supportedFeatures: readonly WifiRouterFeature[];
  /** The type of router device this is (e.g. OpenWrt-based, ChromeOS Gale). */
  readonly deviceType: WifiRouterDeviceType;
}

export interface ChromeOsRecoveryData_BluetoothPeer {
  readonly hostname: string;
  readonly state: PeripheralState;
}

export interface ChromeOsRecoveryData_Dolos {
  readonly serialUsb: string;
  readonly fwVersion: string;
}

export interface ChromeOsRecoveryData_ModemInfo {
  /** The device model cellular sub-variant. */
  readonly modelVariant: string;
  /** The modem hardware type. */
  readonly type: ModemType;
  /** Equipment Identifier */
  readonly imei: string;
}

export interface ChromeOsRecoveryData_LabData {
  readonly servoType: string;
  readonly smartUsbhub: boolean;
  readonly servoTopology: ServoTopology | undefined;
  readonly servoComponent: string;
  readonly wifiRouters: readonly ChromeOsRecoveryData_WifiRouter[];
  readonly bluetoothPeers: readonly ChromeOsRecoveryData_BluetoothPeer[];
  /** ServoUsbDrive contains details of the servo's plugged USB drive. */
  readonly servoUsbDrive: UsbDrive | undefined;
  readonly roVpdMap: { [key: string]: string };
  /** CBI contents on the DUT */
  readonly cbi:
    | Cbi
    | undefined;
  /** Audio Box Jack Plugger is a part of chameleon */
  readonly audioboxJackpluggerState: Chameleon_AudioBoxJackPlugger;
  /** WifiRouterFeatures that are supported by all wifi routers in the testbed. */
  readonly wifiRouterFeatures: readonly WifiRouterFeature[];
  /** Info on the available cellular modem */
  readonly modemInfo:
    | ChromeOsRecoveryData_ModemInfo
    | undefined;
  /** Info on the installed SIM cards */
  readonly simInfos: readonly SIMInfo[];
  readonly dolos:
    | ChromeOsRecoveryData_Dolos
    | undefined;
  /** Info on all carrier dependencies that can be satisfied by this DUT. */
  readonly supportedCarriers: readonly string[];
}

export interface ChromeOsRecoveryData_LabData_RoVpdMapEntry {
  readonly key: string;
  readonly value: string;
}

/** Next ID: 10 */
export interface UpdateDeviceRecoveryDataRequest {
  /** The id or asset tag for device. */
  readonly deviceId: string;
  readonly resourceType: UpdateDeviceRecoveryDataRequest_ResourceType;
  /** The hostname of the device. */
  readonly hostname: string;
  /** The DUT state to update. */
  readonly resourceState: State;
  readonly chromeos?: ChromeOsRecoveryData | undefined;
}

/** Type of the device. */
export enum UpdateDeviceRecoveryDataRequest_ResourceType {
  RESOURCE_TYPE_UNSPECIFIED = 0,
  RESOURCE_TYPE_CHROMEOS_DEVICE = 1,
  RESOURCE_TYPE_ATTACHED_DEVICE = 2,
}

export function updateDeviceRecoveryDataRequest_ResourceTypeFromJSON(
  object: any,
): UpdateDeviceRecoveryDataRequest_ResourceType {
  switch (object) {
    case 0:
    case "RESOURCE_TYPE_UNSPECIFIED":
      return UpdateDeviceRecoveryDataRequest_ResourceType.RESOURCE_TYPE_UNSPECIFIED;
    case 1:
    case "RESOURCE_TYPE_CHROMEOS_DEVICE":
      return UpdateDeviceRecoveryDataRequest_ResourceType.RESOURCE_TYPE_CHROMEOS_DEVICE;
    case 2:
    case "RESOURCE_TYPE_ATTACHED_DEVICE":
      return UpdateDeviceRecoveryDataRequest_ResourceType.RESOURCE_TYPE_ATTACHED_DEVICE;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum UpdateDeviceRecoveryDataRequest_ResourceType",
      );
  }
}

export function updateDeviceRecoveryDataRequest_ResourceTypeToJSON(
  object: UpdateDeviceRecoveryDataRequest_ResourceType,
): string {
  switch (object) {
    case UpdateDeviceRecoveryDataRequest_ResourceType.RESOURCE_TYPE_UNSPECIFIED:
      return "RESOURCE_TYPE_UNSPECIFIED";
    case UpdateDeviceRecoveryDataRequest_ResourceType.RESOURCE_TYPE_CHROMEOS_DEVICE:
      return "RESOURCE_TYPE_CHROMEOS_DEVICE";
    case UpdateDeviceRecoveryDataRequest_ResourceType.RESOURCE_TYPE_ATTACHED_DEVICE:
      return "RESOURCE_TYPE_ATTACHED_DEVICE";
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum UpdateDeviceRecoveryDataRequest_ResourceType",
      );
  }
}

/** Reserved for response to UpdateDeviceRecoveryDataRequest */
export interface UpdateDeviceRecoveryDataResponse {
}

export interface UpdateTestDataRequest {
  readonly deviceId: string;
  readonly hostname: string;
  /** The DUT state to update. */
  readonly resourceState: State;
  readonly chromeosData?: UpdateTestDataRequest_ChromeOs | undefined;
  readonly androidData?: UpdateTestDataRequest_Android | undefined;
  readonly updateMask: readonly string[] | undefined;
}

export interface UpdateTestDataRequest_ChromeOs {
  /**
   * The state record to update.
   * DutState.Id is required and its value should be equal to UpdateTestDataRequest.DeviceId
   */
  readonly dutState: DutState | undefined;
}

/** Reserved for Android details of UpdateTestDataRequest */
export interface UpdateTestDataRequest_Android {
}

/** Reserved for response to UpdateTestDataRequest */
export interface UpdateTestDataResponse {
}

export interface RackRegistrationRequest {
  /** The rack to create. */
  readonly rack: Rack | undefined;
}

export interface NetworkOption {
  /** The vlan to setup the network configurations */
  readonly vlan: string;
  /** Specifying nic name for automatically assign IP */
  readonly nic: string;
  /** Delete the existing network configurations if existing */
  readonly delete: boolean;
  /** The user-specified ip, if it's setup, other options will be ignored. */
  readonly ip: string;
}

export interface CreateAssetRequest {
  /** The asset to register */
  readonly asset: asset | undefined;
}

export interface UpdateAssetRequest {
  /** The asset to update. */
  readonly asset:
    | asset
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface GetAssetRequest {
  /** The name of the asset to retrieve. */
  readonly name: string;
}

export interface ListAssetsRequest {
  /**
   * The maximum number of assets to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 assets will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListAssets` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListAssets` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListAssetsResponse {
  /** The assets from datastore. */
  readonly assets: readonly asset[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteAssetRequest {
  /** The name of the asset to delete */
  readonly name: string;
}

export interface RenameAssetRequest {
  /** The name of the Asset to rename */
  readonly name: string;
  /** The new name of the Asset. */
  readonly newName: string;
}

export interface BatchGetKVMsRequest {
  /** The parent resource shared by all kvms being retrieved. */
  readonly parent: string;
  /**
   * The names of the kvms to retrieve.
   * Format: kvms/{kvm}
   */
  readonly names: readonly string[];
}

export interface BatchGetKVMsResponse {
  /** The KVMs from datastore. */
  readonly KVMs: readonly KVM[];
}

export interface BatchGetDHCPConfigsRequest {
  /** The parent resource shared by all dhcp configs being retrieved. */
  readonly parent: string;
  /** The hostnames of the dhcp configs to retrieve. */
  readonly names: readonly string[];
}

export interface BatchGetDHCPConfigsResponse {
  /** The KVMs from datastore. */
  readonly dhcpConfigs: readonly DHCPConfig[];
}

export interface BatchGetMachineLSEsRequest {
  /** The parent resource shared by all machine lses being retrieved. */
  readonly parent: string;
  /**
   * The names of the machine lses to retrieve.
   * Format: machineLSEs/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetMachineLSEsResponse {
  /** The Machine lses from datastore. */
  readonly machineLses: readonly MachineLSE[];
}

export interface BatchGetMachinesRequest {
  /** The parent resource shared by all machines being retrieved. */
  readonly parent: string;
  /**
   * The names of the machines to retrieve.
   * Format: machines/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetMachinesResponse {
  /** The machines from datastore. */
  readonly machines: readonly Machine[];
}

export interface BatchGetSwitchesRequest {
  /** The parent resource shared by all switches being retrieved. */
  readonly parent: string;
  /**
   * The names of the switches to retrieve.
   * Format: switches/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetSwitchesResponse {
  /** The switches from datastore. */
  readonly switches: readonly Switch[];
}

export interface BatchGetRPMsRequest {
  /** The parent resource shared by all rpms being retrieved. */
  readonly parent: string;
  /**
   * The names of the rpms to retrieve.
   * Format: rpms/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetRPMsResponse {
  /** The rpms from datastore. */
  readonly rpms: readonly RPM[];
}

export interface BatchGetDracsRequest {
  /** The parent resource shared by all dracs being retrieved. */
  readonly parent: string;
  /**
   * The names of the dracs to retrieve.
   * Format: dracs/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetDracsResponse {
  /** The dracs from datastore. */
  readonly dracs: readonly Drac[];
}

export interface BatchGetNicsRequest {
  /** The parent resource shared by all nics being retrieved. */
  readonly parent: string;
  /**
   * The names of the nics to retrieve.
   * Format: nics/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetNicsResponse {
  /** The nics from datastore. */
  readonly nics: readonly Nic[];
}

export interface BatchGetVMsRequest {
  /** The parent resource shared by all vms being retrieved. */
  readonly parent: string;
  /**
   * The names of the vms to retrieve.
   * Format: vms/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetVMsResponse {
  /** The vms from datastore. */
  readonly vms: readonly VM[];
}

export interface BatchGetVlansRequest {
  /** The parent resource shared by all vlans being retrieved. */
  readonly parent: string;
  /**
   * The names of the vlans to retrieve.
   * Format: vlans/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetVlansResponse {
  /** The vlans from datastore. */
  readonly vlans: readonly Vlan[];
}

export interface BatchGetRacksRequest {
  /** The parent resource shared by all racks being retrieved. */
  readonly parent: string;
  /**
   * The names of the racks to retrieve.
   * Format: racks/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetRacksResponse {
  /** The racks from datastore. */
  readonly racks: readonly Rack[];
}

export interface BatchGetChromePlatformsRequest {
  /** The parent resource shared by all chrome platforms being retrieved. */
  readonly parent: string;
  /**
   * The names of the chrome platforms to retrieve.
   * Format: chromeplatforms/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetChromePlatformsResponse {
  /** The chrome platforms from datastore. */
  readonly chromePlatforms: readonly ChromePlatform[];
}

export interface BatchGetMachineLSEPrototypesRequest {
  /** The parent resource shared by all machine lse prototypes being retrieved. */
  readonly parent: string;
  /**
   * The names of the machine lse prototypes to retrieve.
   * Format: machineLSEPrototypes/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetMachineLSEPrototypesResponse {
  /** The machine lse prototypes from datastore. */
  readonly machineLsePrototypes: readonly MachineLSEPrototype[];
}

export interface BatchGetRackLSEPrototypesRequest {
  /** The parent resource shared by all rack lse prototypes being retrieved. */
  readonly parent: string;
  /**
   * The names of the rack lse prototypes to retrieve.
   * Format: rackLSEPrototypes/{name}
   */
  readonly names: readonly string[];
}

export interface BatchGetRackLSEPrototypesResponse {
  /** The rack lse prototypes from datastore. */
  readonly rackLsePrototypes: readonly RackLSEPrototype[];
}

export interface GetChromeOSDeviceDataRequest {
  /** Machine/Asset id. */
  readonly chromeosDeviceId: string;
  /** Hostname of the DUT/MachineLSE. */
  readonly hostname: string;
}

/**
 * Contains the required information for creating a CachingService represented in
 * the database.
 */
export interface CreateCachingServiceRequest {
  /** The CachingService to create. */
  readonly cachingService:
    | CachingService
    | undefined;
  /**
   * The ID to use for the CachingService, which will become the final component of
   * the CachingService's resource name.
   *
   * Pattern is {hostname or ipv4}.
   */
  readonly cachingServiceId: string;
}

export interface UpdateCachingServiceRequest {
  /** The CachingService to update. */
  readonly cachingService:
    | CachingService
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface GetCachingServiceRequest {
  /**
   * The name of the CachingService to retrieve.
   * Pattern is 'cachingservices/{hostname or ipv4}'
   */
  readonly name: string;
}

export interface ListCachingServicesRequest {
  /**
   * The maximum number of CachingServices to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 CachingServices will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListCachingServices` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListCachingServices` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition. */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListCachingServicesResponse {
  /** The CachingServices from datastore. */
  readonly cachingServices: readonly CachingService[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteCachingServiceRequest {
  /**
   * The name of the CachingService to delete.
   * Pattern is 'cachingservices/{hostname or ipv4}'
   */
  readonly name: string;
}

/**
 * Contains the required information for creating a SchedulingUnit represented in
 * the database.
 */
export interface CreateSchedulingUnitRequest {
  /** The SchedulingUnit to create. */
  readonly schedulingUnit:
    | SchedulingUnit
    | undefined;
  /**
   * The ID to use for the SchedulingUnit, which will become the final component of
   * the SchedulingUnit's resource name.
   *
   * This value should follow the regex "^[a-zA-Z0-9-)(_:.]{3,63}$" (3-63 characters,
   * contains only ASCII letters, numbers, dash and underscore.
   */
  readonly schedulingUnitId: string;
}

export interface UpdateSchedulingUnitRequest {
  /** The SchedulingUnit to update. */
  readonly schedulingUnit:
    | SchedulingUnit
    | undefined;
  /** The list of fields to be updated. */
  readonly updateMask: readonly string[] | undefined;
}

export interface GetSchedulingUnitRequest {
  /**
   * The name of the SchedulingUnit to retrieve.
   * Pattern is 'schedulingunits/{ipv4}'
   */
  readonly name: string;
}

export interface ListSchedulingUnitsRequest {
  /**
   * The maximum number of SchedulingUnits to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 SchedulingUnits will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListSchedulingUnits` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListSchedulingUnits` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition. */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListSchedulingUnitsResponse {
  /** The SchedulingUnits from datastore. */
  readonly schedulingUnits: readonly SchedulingUnit[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface DeleteSchedulingUnitRequest {
  /**
   * The name of the SchedulingUnit to delete.
   * Pattern is 'schedulingunits/{schedulingunit}'
   */
  readonly name: string;
}

export interface UpdateConfigBundleRequest {
  /**
   * The ConfigBundle to update.
   * The ConfigBundle's ID composed of `${program}-${design}-${designconfig}` is
   * used to identify the ConfigBundle to be updated.
   * Pattern is 'config_bundles/{config_bundle}'
   * Proto path:
   * go.chromium.org/chromiumos/config/proto/chromiumos/config/payload/config_bundle.proto
   */
  readonly configBundle: Uint8Array;
  /**
   * OPTIONAL: The list of fields to be updated. If omitted, the whole
   * config_bundle will be updated.
   */
  readonly updateMask:
    | readonly string[]
    | undefined;
  /**
   * If set to true, and the ConfigBundle is not found, a new ConfigBundle will
   * be created. In this situation, `update_mask` is ignored.
   */
  readonly allowMissing: boolean;
}

export interface UpdateConfigBundleResponse {
  /**
   * Return value of UpdateConfigBundle.
   * NOTE: bytes is returned to avoid import errors for protos in
   * go.chromium.org. This should ideally be the ConfigBundle proto.
   * Proto path:
   * go.chromium.org/chromiumos/config/proto/chromiumos/config/payload/config_bundle.proto
   */
  readonly configBundle: Uint8Array;
}

export interface GetDeviceDataRequest {
  /** Machine/Asset ID. */
  readonly deviceId: string;
  /** Hostname of the DUT/MachineLSE. */
  readonly hostname: string;
}

export interface GetDeviceDataResponse {
  readonly schedulingUnit?: SchedulingUnit | undefined;
  readonly chromeOsDeviceData?: ChromeOSDeviceData | undefined;
  readonly attachedDeviceData?: AttachedDeviceData | undefined;
  readonly browserDeviceData?: BrowserDeviceData | undefined;
  readonly resourceType: GetDeviceDataResponse_ResourceType;
}

export enum GetDeviceDataResponse_ResourceType {
  RESOURCE_TYPE_UNSPECIFIED = 0,
  RESOURCE_TYPE_SCHEDULING_UNIT = 1,
  RESOURCE_TYPE_CHROMEOS_DEVICE = 2,
  RESOURCE_TYPE_ATTACHED_DEVICE = 3,
  RESOURCE_TYPE_BROWSER_DEVICE = 4,
}

export function getDeviceDataResponse_ResourceTypeFromJSON(object: any): GetDeviceDataResponse_ResourceType {
  switch (object) {
    case 0:
    case "RESOURCE_TYPE_UNSPECIFIED":
      return GetDeviceDataResponse_ResourceType.RESOURCE_TYPE_UNSPECIFIED;
    case 1:
    case "RESOURCE_TYPE_SCHEDULING_UNIT":
      return GetDeviceDataResponse_ResourceType.RESOURCE_TYPE_SCHEDULING_UNIT;
    case 2:
    case "RESOURCE_TYPE_CHROMEOS_DEVICE":
      return GetDeviceDataResponse_ResourceType.RESOURCE_TYPE_CHROMEOS_DEVICE;
    case 3:
    case "RESOURCE_TYPE_ATTACHED_DEVICE":
      return GetDeviceDataResponse_ResourceType.RESOURCE_TYPE_ATTACHED_DEVICE;
    case 4:
    case "RESOURCE_TYPE_BROWSER_DEVICE":
      return GetDeviceDataResponse_ResourceType.RESOURCE_TYPE_BROWSER_DEVICE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GetDeviceDataResponse_ResourceType");
  }
}

export function getDeviceDataResponse_ResourceTypeToJSON(object: GetDeviceDataResponse_ResourceType): string {
  switch (object) {
    case GetDeviceDataResponse_ResourceType.RESOURCE_TYPE_UNSPECIFIED:
      return "RESOURCE_TYPE_UNSPECIFIED";
    case GetDeviceDataResponse_ResourceType.RESOURCE_TYPE_SCHEDULING_UNIT:
      return "RESOURCE_TYPE_SCHEDULING_UNIT";
    case GetDeviceDataResponse_ResourceType.RESOURCE_TYPE_CHROMEOS_DEVICE:
      return "RESOURCE_TYPE_CHROMEOS_DEVICE";
    case GetDeviceDataResponse_ResourceType.RESOURCE_TYPE_ATTACHED_DEVICE:
      return "RESOURCE_TYPE_ATTACHED_DEVICE";
    case GetDeviceDataResponse_ResourceType.RESOURCE_TYPE_BROWSER_DEVICE:
      return "RESOURCE_TYPE_BROWSER_DEVICE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum GetDeviceDataResponse_ResourceType");
  }
}

/** Next Tag: 4 */
export interface AttachedDeviceData {
  readonly labConfig: MachineLSE | undefined;
  readonly machine: Machine | undefined;
  readonly dutState: DutState | undefined;
}

/** Next Tag: 2 */
export interface BrowserDeviceData {
  readonly host: MachineLSE | undefined;
  readonly vm: VM | undefined;
}

export interface CheckFleetTestsPolicyRequest {
  /** Test parameters to validate to see if the test is a valid test. */
  readonly testName: string;
  readonly board: string;
  readonly model: string;
  readonly image: string;
  /**
   * Service account which is used to run the test from crosfleet cli. If this field is empty, service account is obtained from the context.
   * Must be a valid LUCI identity. Ex: service:xyz, user:abc@xyz etc.
   */
  readonly testServiceAccount: string;
  /** Quota Scheduler account which will be used to run this test */
  readonly qsAccount: string;
}

export interface TestStatus {
  /** TestStatus Code will provide information about the validity or invalidity of the test */
  readonly code: TestStatus_Code;
  /** message contains a description of the Status */
  readonly message: string;
}

export enum TestStatus_Code {
  UNSPECIFIED = 0,
  OK = 1,
  NOT_A_PUBLIC_BOARD = 2,
  NOT_A_PUBLIC_MODEL = 3,
  NOT_A_PUBLIC_IMAGE = 4,
  NOT_A_PUBLIC_TEST = 5,
  INVALID_QS_ACCOUNT = 6,
}

export function testStatus_CodeFromJSON(object: any): TestStatus_Code {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return TestStatus_Code.UNSPECIFIED;
    case 1:
    case "OK":
      return TestStatus_Code.OK;
    case 2:
    case "NOT_A_PUBLIC_BOARD":
      return TestStatus_Code.NOT_A_PUBLIC_BOARD;
    case 3:
    case "NOT_A_PUBLIC_MODEL":
      return TestStatus_Code.NOT_A_PUBLIC_MODEL;
    case 4:
    case "NOT_A_PUBLIC_IMAGE":
      return TestStatus_Code.NOT_A_PUBLIC_IMAGE;
    case 5:
    case "NOT_A_PUBLIC_TEST":
      return TestStatus_Code.NOT_A_PUBLIC_TEST;
    case 6:
    case "INVALID_QS_ACCOUNT":
      return TestStatus_Code.INVALID_QS_ACCOUNT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TestStatus_Code");
  }
}

export function testStatus_CodeToJSON(object: TestStatus_Code): string {
  switch (object) {
    case TestStatus_Code.UNSPECIFIED:
      return "UNSPECIFIED";
    case TestStatus_Code.OK:
      return "OK";
    case TestStatus_Code.NOT_A_PUBLIC_BOARD:
      return "NOT_A_PUBLIC_BOARD";
    case TestStatus_Code.NOT_A_PUBLIC_MODEL:
      return "NOT_A_PUBLIC_MODEL";
    case TestStatus_Code.NOT_A_PUBLIC_IMAGE:
      return "NOT_A_PUBLIC_IMAGE";
    case TestStatus_Code.NOT_A_PUBLIC_TEST:
      return "NOT_A_PUBLIC_TEST";
    case TestStatus_Code.INVALID_QS_ACCOUNT:
      return "INVALID_QS_ACCOUNT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TestStatus_Code");
  }
}

export interface CheckFleetTestsPolicyResponse {
  /**
   * Indicates whether the test parameters are valid for a test.
   *
   * @deprecated
   */
  readonly isTestValid: boolean;
  /** Status of the test with a code and an optional message */
  readonly testStatus: TestStatus | undefined;
}

/** Request containing hostname for retrieving the ownership data for a Machine, VM etc */
export interface GetOwnershipDataRequest {
  /** Name of the Machine/MachineLSE/VM etc. */
  readonly hostname: string;
}

/** Request for getting all the bot ownership/security configs. */
export interface ListOwnershipDataRequest {
  /**
   * The maximum number of entries to return. The service may return fewer than
   * this value.
   * If unspecified, at most 100 entries will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListOwnershipDataRequest` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListOwnershipDataRequest` must match
   * the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

/** Contains ownership/security configs by host */
export interface OwnershipByHost {
  /** Name of the Machine/MachineLSE/VM etc. */
  readonly hostname: string;
  /** Ownership/security_config for the host */
  readonly ownership: OwnershipData | undefined;
}

/** Response for getting all the bot ownership/security configs. */
export interface ListOwnershipDataResponse {
  /** The Ownerships from datastore. */
  readonly ownershipData: readonly OwnershipByHost[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface GetDefaultWifiRequest {
  /**
   * The name of the default wifi to retrieve.
   * Format: defaultwifis/{defaultwifi}
   */
  readonly name: string;
}

export interface ListDefaultWifisRequest {
  /**
   * The maximum number of DefaultWifis to return. The service may return fewer
   * than this value.
   * If unspecified, at most 100 default wifi will be returned.
   * The maximum value is 1000; values above 1000 will be coerced to 1000.
   */
  readonly pageSize: number;
  /**
   * A page token, received from a previous `ListDefaultWifis` call.
   * Provide this to retrieve the subsequent page.
   *
   * When paginating, all other parameters provided to `ListDefaultWifis` must
   * match the call that provided the page token.
   */
  readonly pageToken: string;
  /** filter takes the filtering condition. */
  readonly filter: string;
  /**
   * if this is true, only keys will be returned else the entire object
   * will be returned. By setting this to true, the list call be will faster.
   */
  readonly keysOnly: boolean;
}

export interface ListDefaultWifisResponse {
  /** The default wifis from datastore. */
  readonly defaultWifis: readonly DefaultWifi[];
  /**
   * A token, which can be sent as `page_token` to retrieve the next page.
   * If this field is omitted, there are no subsequent pages.
   */
  readonly nextPageToken: string;
}

export interface CreateDefaultWifiRequest {
  /**
   * The ID to use for the default wifi, which will become the final component
   * of the default wifi's resource name.
   *
   * This value should be 3-63 characters, and valid characters
   * are /[a-z][0-9]-_.:/.
   */
  readonly defaultWifiId: string;
  /** The default wifi to create. */
  readonly defaultWifi: DefaultWifi | undefined;
}

export interface UpdateDefaultWifiRequest {
  /**
   * The default wifi to update.
   *
   * The default wifi's `name` field is used to identify the resource to update.
   * Format: defaultwifis/{defaultwifi}
   */
  readonly defaultWifi:
    | DefaultWifi
    | undefined;
  /** The list of fields to update. */
  readonly updateMask: readonly string[] | undefined;
}

export interface DeleteDefaultWifiRequest {
  /**
   * The name of the default wifi to delete.
   * Format: defaultwifis/{defaultwifi}
   */
  readonly name: string;
}

function createBaseGetDUTsForLabstationRequest(): GetDUTsForLabstationRequest {
  return { hostname: [] };
}

export const GetDUTsForLabstationRequest: MessageFns<GetDUTsForLabstationRequest> = {
  encode(message: GetDUTsForLabstationRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.hostname) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDUTsForLabstationRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDUTsForLabstationRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDUTsForLabstationRequest {
    return {
      hostname: globalThis.Array.isArray(object?.hostname) ? object.hostname.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetDUTsForLabstationRequest): unknown {
    const obj: any = {};
    if (message.hostname?.length) {
      obj.hostname = message.hostname;
    }
    return obj;
  },

  create(base?: DeepPartial<GetDUTsForLabstationRequest>): GetDUTsForLabstationRequest {
    return GetDUTsForLabstationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetDUTsForLabstationRequest>): GetDUTsForLabstationRequest {
    const message = createBaseGetDUTsForLabstationRequest() as any;
    message.hostname = object.hostname?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetDUTsForLabstationResponse(): GetDUTsForLabstationResponse {
  return { items: [] };
}

export const GetDUTsForLabstationResponse: MessageFns<GetDUTsForLabstationResponse> = {
  encode(message: GetDUTsForLabstationResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.items) {
      GetDUTsForLabstationResponse_LabstationMapping.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDUTsForLabstationResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDUTsForLabstationResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.items.push(GetDUTsForLabstationResponse_LabstationMapping.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDUTsForLabstationResponse {
    return {
      items: globalThis.Array.isArray(object?.items)
        ? object.items.map((e: any) => GetDUTsForLabstationResponse_LabstationMapping.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetDUTsForLabstationResponse): unknown {
    const obj: any = {};
    if (message.items?.length) {
      obj.items = message.items.map((e) => GetDUTsForLabstationResponse_LabstationMapping.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<GetDUTsForLabstationResponse>): GetDUTsForLabstationResponse {
    return GetDUTsForLabstationResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetDUTsForLabstationResponse>): GetDUTsForLabstationResponse {
    const message = createBaseGetDUTsForLabstationResponse() as any;
    message.items = object.items?.map((e) => GetDUTsForLabstationResponse_LabstationMapping.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetDUTsForLabstationResponse_LabstationMapping(): GetDUTsForLabstationResponse_LabstationMapping {
  return { hostname: "", dutName: [] };
}

export const GetDUTsForLabstationResponse_LabstationMapping: MessageFns<
  GetDUTsForLabstationResponse_LabstationMapping
> = {
  encode(
    message: GetDUTsForLabstationResponse_LabstationMapping,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    for (const v of message.dutName) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDUTsForLabstationResponse_LabstationMapping {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDUTsForLabstationResponse_LabstationMapping() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.dutName.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDUTsForLabstationResponse_LabstationMapping {
    return {
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      dutName: globalThis.Array.isArray(object?.dutName) ? object.dutName.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetDUTsForLabstationResponse_LabstationMapping): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.dutName?.length) {
      obj.dutName = message.dutName;
    }
    return obj;
  },

  create(
    base?: DeepPartial<GetDUTsForLabstationResponse_LabstationMapping>,
  ): GetDUTsForLabstationResponse_LabstationMapping {
    return GetDUTsForLabstationResponse_LabstationMapping.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<GetDUTsForLabstationResponse_LabstationMapping>,
  ): GetDUTsForLabstationResponse_LabstationMapping {
    const message = createBaseGetDUTsForLabstationResponse_LabstationMapping() as any;
    message.hostname = object.hostname ?? "";
    message.dutName = object.dutName?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateMachineLSEDeploymentRequest(): UpdateMachineLSEDeploymentRequest {
  return { machineLseDeployment: undefined, updateMask: undefined };
}

export const UpdateMachineLSEDeploymentRequest: MessageFns<UpdateMachineLSEDeploymentRequest> = {
  encode(message: UpdateMachineLSEDeploymentRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.machineLseDeployment !== undefined) {
      MachineLSEDeployment.encode(message.machineLseDeployment, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateMachineLSEDeploymentRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMachineLSEDeploymentRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineLseDeployment = MachineLSEDeployment.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMachineLSEDeploymentRequest {
    return {
      machineLseDeployment: isSet(object.machineLseDeployment)
        ? MachineLSEDeployment.fromJSON(object.machineLseDeployment)
        : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateMachineLSEDeploymentRequest): unknown {
    const obj: any = {};
    if (message.machineLseDeployment !== undefined) {
      obj.machineLseDeployment = MachineLSEDeployment.toJSON(message.machineLseDeployment);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateMachineLSEDeploymentRequest>): UpdateMachineLSEDeploymentRequest {
    return UpdateMachineLSEDeploymentRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateMachineLSEDeploymentRequest>): UpdateMachineLSEDeploymentRequest {
    const message = createBaseUpdateMachineLSEDeploymentRequest() as any;
    message.machineLseDeployment = (object.machineLseDeployment !== undefined && object.machineLseDeployment !== null)
      ? MachineLSEDeployment.fromPartial(object.machineLseDeployment)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseBatchUpdateMachineLSEDeploymentRequest(): BatchUpdateMachineLSEDeploymentRequest {
  return { parent: "", requests: [] };
}

export const BatchUpdateMachineLSEDeploymentRequest: MessageFns<BatchUpdateMachineLSEDeploymentRequest> = {
  encode(message: BatchUpdateMachineLSEDeploymentRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.requests) {
      UpdateMachineLSEDeploymentRequest.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchUpdateMachineLSEDeploymentRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchUpdateMachineLSEDeploymentRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.requests.push(UpdateMachineLSEDeploymentRequest.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchUpdateMachineLSEDeploymentRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      requests: globalThis.Array.isArray(object?.requests)
        ? object.requests.map((e: any) => UpdateMachineLSEDeploymentRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BatchUpdateMachineLSEDeploymentRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.requests?.length) {
      obj.requests = message.requests.map((e) => UpdateMachineLSEDeploymentRequest.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchUpdateMachineLSEDeploymentRequest>): BatchUpdateMachineLSEDeploymentRequest {
    return BatchUpdateMachineLSEDeploymentRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchUpdateMachineLSEDeploymentRequest>): BatchUpdateMachineLSEDeploymentRequest {
    const message = createBaseBatchUpdateMachineLSEDeploymentRequest() as any;
    message.parent = object.parent ?? "";
    message.requests = object.requests?.map((e) => UpdateMachineLSEDeploymentRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchUpdateMachineLSEDeploymentResponse(): BatchUpdateMachineLSEDeploymentResponse {
  return { machineLseDeployments: [] };
}

export const BatchUpdateMachineLSEDeploymentResponse: MessageFns<BatchUpdateMachineLSEDeploymentResponse> = {
  encode(message: BatchUpdateMachineLSEDeploymentResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.machineLseDeployments) {
      MachineLSEDeployment.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchUpdateMachineLSEDeploymentResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchUpdateMachineLSEDeploymentResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineLseDeployments.push(MachineLSEDeployment.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchUpdateMachineLSEDeploymentResponse {
    return {
      machineLseDeployments: globalThis.Array.isArray(object?.machineLseDeployments)
        ? object.machineLseDeployments.map((e: any) => MachineLSEDeployment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BatchUpdateMachineLSEDeploymentResponse): unknown {
    const obj: any = {};
    if (message.machineLseDeployments?.length) {
      obj.machineLseDeployments = message.machineLseDeployments.map((e) => MachineLSEDeployment.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchUpdateMachineLSEDeploymentResponse>): BatchUpdateMachineLSEDeploymentResponse {
    return BatchUpdateMachineLSEDeploymentResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchUpdateMachineLSEDeploymentResponse>): BatchUpdateMachineLSEDeploymentResponse {
    const message = createBaseBatchUpdateMachineLSEDeploymentResponse() as any;
    message.machineLseDeployments = object.machineLseDeployments?.map((e) => MachineLSEDeployment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetMachineLSEDeploymentRequest(): GetMachineLSEDeploymentRequest {
  return { name: "" };
}

export const GetMachineLSEDeploymentRequest: MessageFns<GetMachineLSEDeploymentRequest> = {
  encode(message: GetMachineLSEDeploymentRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetMachineLSEDeploymentRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMachineLSEDeploymentRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMachineLSEDeploymentRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetMachineLSEDeploymentRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetMachineLSEDeploymentRequest>): GetMachineLSEDeploymentRequest {
    return GetMachineLSEDeploymentRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetMachineLSEDeploymentRequest>): GetMachineLSEDeploymentRequest {
    const message = createBaseGetMachineLSEDeploymentRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseBatchGetMachineLSEDeploymentsRequest(): BatchGetMachineLSEDeploymentsRequest {
  return { parent: "", names: [] };
}

export const BatchGetMachineLSEDeploymentsRequest: MessageFns<BatchGetMachineLSEDeploymentsRequest> = {
  encode(message: BatchGetMachineLSEDeploymentsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetMachineLSEDeploymentsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetMachineLSEDeploymentsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetMachineLSEDeploymentsRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetMachineLSEDeploymentsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetMachineLSEDeploymentsRequest>): BatchGetMachineLSEDeploymentsRequest {
    return BatchGetMachineLSEDeploymentsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetMachineLSEDeploymentsRequest>): BatchGetMachineLSEDeploymentsRequest {
    const message = createBaseBatchGetMachineLSEDeploymentsRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetMachineLSEDeploymentsResponse(): BatchGetMachineLSEDeploymentsResponse {
  return { machineLseDeployments: [] };
}

export const BatchGetMachineLSEDeploymentsResponse: MessageFns<BatchGetMachineLSEDeploymentsResponse> = {
  encode(message: BatchGetMachineLSEDeploymentsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.machineLseDeployments) {
      MachineLSEDeployment.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetMachineLSEDeploymentsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetMachineLSEDeploymentsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineLseDeployments.push(MachineLSEDeployment.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetMachineLSEDeploymentsResponse {
    return {
      machineLseDeployments: globalThis.Array.isArray(object?.machineLseDeployments)
        ? object.machineLseDeployments.map((e: any) => MachineLSEDeployment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BatchGetMachineLSEDeploymentsResponse): unknown {
    const obj: any = {};
    if (message.machineLseDeployments?.length) {
      obj.machineLseDeployments = message.machineLseDeployments.map((e) => MachineLSEDeployment.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetMachineLSEDeploymentsResponse>): BatchGetMachineLSEDeploymentsResponse {
    return BatchGetMachineLSEDeploymentsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetMachineLSEDeploymentsResponse>): BatchGetMachineLSEDeploymentsResponse {
    const message = createBaseBatchGetMachineLSEDeploymentsResponse() as any;
    message.machineLseDeployments = object.machineLseDeployments?.map((e) => MachineLSEDeployment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListMachineLSEDeploymentsRequest(): ListMachineLSEDeploymentsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListMachineLSEDeploymentsRequest: MessageFns<ListMachineLSEDeploymentsRequest> = {
  encode(message: ListMachineLSEDeploymentsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListMachineLSEDeploymentsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMachineLSEDeploymentsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMachineLSEDeploymentsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListMachineLSEDeploymentsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListMachineLSEDeploymentsRequest>): ListMachineLSEDeploymentsRequest {
    return ListMachineLSEDeploymentsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListMachineLSEDeploymentsRequest>): ListMachineLSEDeploymentsRequest {
    const message = createBaseListMachineLSEDeploymentsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListMachineLSEDeploymentsResponse(): ListMachineLSEDeploymentsResponse {
  return { machineLseDeployments: [], nextPageToken: "" };
}

export const ListMachineLSEDeploymentsResponse: MessageFns<ListMachineLSEDeploymentsResponse> = {
  encode(message: ListMachineLSEDeploymentsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.machineLseDeployments) {
      MachineLSEDeployment.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListMachineLSEDeploymentsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMachineLSEDeploymentsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineLseDeployments.push(MachineLSEDeployment.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMachineLSEDeploymentsResponse {
    return {
      machineLseDeployments: globalThis.Array.isArray(object?.machineLseDeployments)
        ? object.machineLseDeployments.map((e: any) => MachineLSEDeployment.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListMachineLSEDeploymentsResponse): unknown {
    const obj: any = {};
    if (message.machineLseDeployments?.length) {
      obj.machineLseDeployments = message.machineLseDeployments.map((e) => MachineLSEDeployment.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListMachineLSEDeploymentsResponse>): ListMachineLSEDeploymentsResponse {
    return ListMachineLSEDeploymentsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListMachineLSEDeploymentsResponse>): ListMachineLSEDeploymentsResponse {
    const message = createBaseListMachineLSEDeploymentsResponse() as any;
    message.machineLseDeployments = object.machineLseDeployments?.map((e) => MachineLSEDeployment.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseCreateVMRequest(): CreateVMRequest {
  return { vm: undefined, networkOption: undefined };
}

export const CreateVMRequest: MessageFns<CreateVMRequest> = {
  encode(message: CreateVMRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.vm !== undefined) {
      VM.encode(message.vm, writer.uint32(10).fork()).join();
    }
    if (message.networkOption !== undefined) {
      NetworkOption.encode(message.networkOption, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateVMRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateVMRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.vm = VM.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.networkOption = NetworkOption.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateVMRequest {
    return {
      vm: isSet(object.vm) ? VM.fromJSON(object.vm) : undefined,
      networkOption: isSet(object.networkOption) ? NetworkOption.fromJSON(object.networkOption) : undefined,
    };
  },

  toJSON(message: CreateVMRequest): unknown {
    const obj: any = {};
    if (message.vm !== undefined) {
      obj.vm = VM.toJSON(message.vm);
    }
    if (message.networkOption !== undefined) {
      obj.networkOption = NetworkOption.toJSON(message.networkOption);
    }
    return obj;
  },

  create(base?: DeepPartial<CreateVMRequest>): CreateVMRequest {
    return CreateVMRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateVMRequest>): CreateVMRequest {
    const message = createBaseCreateVMRequest() as any;
    message.vm = (object.vm !== undefined && object.vm !== null) ? VM.fromPartial(object.vm) : undefined;
    message.networkOption = (object.networkOption !== undefined && object.networkOption !== null)
      ? NetworkOption.fromPartial(object.networkOption)
      : undefined;
    return message;
  },
};

function createBaseUpdateVMRequest(): UpdateVMRequest {
  return { vm: undefined, updateMask: undefined, networkOption: undefined };
}

export const UpdateVMRequest: MessageFns<UpdateVMRequest> = {
  encode(message: UpdateVMRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.vm !== undefined) {
      VM.encode(message.vm, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(26).fork()).join();
    }
    if (message.networkOption !== undefined) {
      NetworkOption.encode(message.networkOption, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateVMRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateVMRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.vm = VM.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.networkOption = NetworkOption.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateVMRequest {
    return {
      vm: isSet(object.vm) ? VM.fromJSON(object.vm) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
      networkOption: isSet(object.networkOption) ? NetworkOption.fromJSON(object.networkOption) : undefined,
    };
  },

  toJSON(message: UpdateVMRequest): unknown {
    const obj: any = {};
    if (message.vm !== undefined) {
      obj.vm = VM.toJSON(message.vm);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    if (message.networkOption !== undefined) {
      obj.networkOption = NetworkOption.toJSON(message.networkOption);
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateVMRequest>): UpdateVMRequest {
    return UpdateVMRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateVMRequest>): UpdateVMRequest {
    const message = createBaseUpdateVMRequest() as any;
    message.vm = (object.vm !== undefined && object.vm !== null) ? VM.fromPartial(object.vm) : undefined;
    message.updateMask = object.updateMask ?? undefined;
    message.networkOption = (object.networkOption !== undefined && object.networkOption !== null)
      ? NetworkOption.fromPartial(object.networkOption)
      : undefined;
    return message;
  },
};

function createBaseGetVMRequest(): GetVMRequest {
  return { name: "" };
}

export const GetVMRequest: MessageFns<GetVMRequest> = {
  encode(message: GetVMRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetVMRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVMRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVMRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetVMRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetVMRequest>): GetVMRequest {
    return GetVMRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetVMRequest>): GetVMRequest {
    const message = createBaseGetVMRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseDeleteVMRequest(): DeleteVMRequest {
  return { name: "" };
}

export const DeleteVMRequest: MessageFns<DeleteVMRequest> = {
  encode(message: DeleteVMRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteVMRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteVMRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteVMRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteVMRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteVMRequest>): DeleteVMRequest {
    return DeleteVMRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteVMRequest>): DeleteVMRequest {
    const message = createBaseDeleteVMRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListVMsRequest(): ListVMsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListVMsRequest: MessageFns<ListVMsRequest> = {
  encode(message: ListVMsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListVMsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListVMsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListVMsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListVMsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListVMsRequest>): ListVMsRequest {
    return ListVMsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListVMsRequest>): ListVMsRequest {
    const message = createBaseListVMsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListVMsResponse(): ListVMsResponse {
  return { vms: [], nextPageToken: "" };
}

export const ListVMsResponse: MessageFns<ListVMsResponse> = {
  encode(message: ListVMsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.vms) {
      VM.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListVMsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListVMsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.vms.push(VM.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListVMsResponse {
    return {
      vms: globalThis.Array.isArray(object?.vms) ? object.vms.map((e: any) => VM.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListVMsResponse): unknown {
    const obj: any = {};
    if (message.vms?.length) {
      obj.vms = message.vms.map((e) => VM.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListVMsResponse>): ListVMsResponse {
    return ListVMsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListVMsResponse>): ListVMsResponse {
    const message = createBaseListVMsResponse() as any;
    message.vms = object.vms?.map((e) => VM.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseGetDHCPConfigRequest(): GetDHCPConfigRequest {
  return { hostname: "" };
}

export const GetDHCPConfigRequest: MessageFns<GetDHCPConfigRequest> = {
  encode(message: GetDHCPConfigRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDHCPConfigRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDHCPConfigRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDHCPConfigRequest {
    return { hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "" };
  },

  toJSON(message: GetDHCPConfigRequest): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    return obj;
  },

  create(base?: DeepPartial<GetDHCPConfigRequest>): GetDHCPConfigRequest {
    return GetDHCPConfigRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetDHCPConfigRequest>): GetDHCPConfigRequest {
    const message = createBaseGetDHCPConfigRequest() as any;
    message.hostname = object.hostname ?? "";
    return message;
  },
};

function createBaseCreateChromePlatformRequest(): CreateChromePlatformRequest {
  return { chromePlatform: undefined, chromePlatformId: "" };
}

export const CreateChromePlatformRequest: MessageFns<CreateChromePlatformRequest> = {
  encode(message: CreateChromePlatformRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.chromePlatform !== undefined) {
      ChromePlatform.encode(message.chromePlatform, writer.uint32(10).fork()).join();
    }
    if (message.chromePlatformId !== "") {
      writer.uint32(18).string(message.chromePlatformId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateChromePlatformRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateChromePlatformRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.chromePlatform = ChromePlatform.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.chromePlatformId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateChromePlatformRequest {
    return {
      chromePlatform: isSet(object.chromePlatform) ? ChromePlatform.fromJSON(object.chromePlatform) : undefined,
      chromePlatformId: isSet(object.chromePlatformId) ? globalThis.String(object.chromePlatformId) : "",
    };
  },

  toJSON(message: CreateChromePlatformRequest): unknown {
    const obj: any = {};
    if (message.chromePlatform !== undefined) {
      obj.chromePlatform = ChromePlatform.toJSON(message.chromePlatform);
    }
    if (message.chromePlatformId !== "") {
      obj.chromePlatformId = message.chromePlatformId;
    }
    return obj;
  },

  create(base?: DeepPartial<CreateChromePlatformRequest>): CreateChromePlatformRequest {
    return CreateChromePlatformRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateChromePlatformRequest>): CreateChromePlatformRequest {
    const message = createBaseCreateChromePlatformRequest() as any;
    message.chromePlatform = (object.chromePlatform !== undefined && object.chromePlatform !== null)
      ? ChromePlatform.fromPartial(object.chromePlatform)
      : undefined;
    message.chromePlatformId = object.chromePlatformId ?? "";
    return message;
  },
};

function createBaseUpdateChromePlatformRequest(): UpdateChromePlatformRequest {
  return { chromePlatform: undefined, updateMask: undefined };
}

export const UpdateChromePlatformRequest: MessageFns<UpdateChromePlatformRequest> = {
  encode(message: UpdateChromePlatformRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.chromePlatform !== undefined) {
      ChromePlatform.encode(message.chromePlatform, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateChromePlatformRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateChromePlatformRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.chromePlatform = ChromePlatform.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateChromePlatformRequest {
    return {
      chromePlatform: isSet(object.chromePlatform) ? ChromePlatform.fromJSON(object.chromePlatform) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateChromePlatformRequest): unknown {
    const obj: any = {};
    if (message.chromePlatform !== undefined) {
      obj.chromePlatform = ChromePlatform.toJSON(message.chromePlatform);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateChromePlatformRequest>): UpdateChromePlatformRequest {
    return UpdateChromePlatformRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateChromePlatformRequest>): UpdateChromePlatformRequest {
    const message = createBaseUpdateChromePlatformRequest() as any;
    message.chromePlatform = (object.chromePlatform !== undefined && object.chromePlatform !== null)
      ? ChromePlatform.fromPartial(object.chromePlatform)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseGetChromePlatformRequest(): GetChromePlatformRequest {
  return { name: "" };
}

export const GetChromePlatformRequest: MessageFns<GetChromePlatformRequest> = {
  encode(message: GetChromePlatformRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetChromePlatformRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetChromePlatformRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetChromePlatformRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetChromePlatformRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetChromePlatformRequest>): GetChromePlatformRequest {
    return GetChromePlatformRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetChromePlatformRequest>): GetChromePlatformRequest {
    const message = createBaseGetChromePlatformRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListChromePlatformsRequest(): ListChromePlatformsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListChromePlatformsRequest: MessageFns<ListChromePlatformsRequest> = {
  encode(message: ListChromePlatformsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListChromePlatformsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListChromePlatformsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListChromePlatformsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListChromePlatformsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListChromePlatformsRequest>): ListChromePlatformsRequest {
    return ListChromePlatformsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListChromePlatformsRequest>): ListChromePlatformsRequest {
    const message = createBaseListChromePlatformsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListChromePlatformsResponse(): ListChromePlatformsResponse {
  return { chromePlatforms: [], nextPageToken: "" };
}

export const ListChromePlatformsResponse: MessageFns<ListChromePlatformsResponse> = {
  encode(message: ListChromePlatformsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.chromePlatforms) {
      ChromePlatform.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListChromePlatformsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListChromePlatformsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.chromePlatforms.push(ChromePlatform.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListChromePlatformsResponse {
    return {
      chromePlatforms: globalThis.Array.isArray(object?.chromePlatforms)
        ? object.chromePlatforms.map((e: any) => ChromePlatform.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListChromePlatformsResponse): unknown {
    const obj: any = {};
    if (message.chromePlatforms?.length) {
      obj.chromePlatforms = message.chromePlatforms.map((e) => ChromePlatform.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListChromePlatformsResponse>): ListChromePlatformsResponse {
    return ListChromePlatformsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListChromePlatformsResponse>): ListChromePlatformsResponse {
    const message = createBaseListChromePlatformsResponse() as any;
    message.chromePlatforms = object.chromePlatforms?.map((e) => ChromePlatform.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteChromePlatformRequest(): DeleteChromePlatformRequest {
  return { name: "" };
}

export const DeleteChromePlatformRequest: MessageFns<DeleteChromePlatformRequest> = {
  encode(message: DeleteChromePlatformRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteChromePlatformRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteChromePlatformRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteChromePlatformRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteChromePlatformRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteChromePlatformRequest>): DeleteChromePlatformRequest {
    return DeleteChromePlatformRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteChromePlatformRequest>): DeleteChromePlatformRequest {
    const message = createBaseDeleteChromePlatformRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseChromePlatformResult(): ChromePlatformResult {
  return { platform: undefined, errorMsg: "" };
}

export const ChromePlatformResult: MessageFns<ChromePlatformResult> = {
  encode(message: ChromePlatformResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.platform !== undefined) {
      ChromePlatform.encode(message.platform, writer.uint32(10).fork()).join();
    }
    if (message.errorMsg !== "") {
      writer.uint32(18).string(message.errorMsg);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromePlatformResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromePlatformResult() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.platform = ChromePlatform.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.errorMsg = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromePlatformResult {
    return {
      platform: isSet(object.platform) ? ChromePlatform.fromJSON(object.platform) : undefined,
      errorMsg: isSet(object.errorMsg) ? globalThis.String(object.errorMsg) : "",
    };
  },

  toJSON(message: ChromePlatformResult): unknown {
    const obj: any = {};
    if (message.platform !== undefined) {
      obj.platform = ChromePlatform.toJSON(message.platform);
    }
    if (message.errorMsg !== "") {
      obj.errorMsg = message.errorMsg;
    }
    return obj;
  },

  create(base?: DeepPartial<ChromePlatformResult>): ChromePlatformResult {
    return ChromePlatformResult.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromePlatformResult>): ChromePlatformResult {
    const message = createBaseChromePlatformResult() as any;
    message.platform = (object.platform !== undefined && object.platform !== null)
      ? ChromePlatform.fromPartial(object.platform)
      : undefined;
    message.errorMsg = object.errorMsg ?? "";
    return message;
  },
};

function createBaseListOSVersionsRequest(): ListOSVersionsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListOSVersionsRequest: MessageFns<ListOSVersionsRequest> = {
  encode(message: ListOSVersionsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListOSVersionsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOSVersionsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOSVersionsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListOSVersionsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListOSVersionsRequest>): ListOSVersionsRequest {
    return ListOSVersionsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListOSVersionsRequest>): ListOSVersionsRequest {
    const message = createBaseListOSVersionsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListOSVersionsResponse(): ListOSVersionsResponse {
  return { osVersion: [], nextPageToken: "" };
}

export const ListOSVersionsResponse: MessageFns<ListOSVersionsResponse> = {
  encode(message: ListOSVersionsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.osVersion) {
      OSVersion.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListOSVersionsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOSVersionsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.osVersion.push(OSVersion.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOSVersionsResponse {
    return {
      osVersion: globalThis.Array.isArray(object?.osVersion)
        ? object.osVersion.map((e: any) => OSVersion.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListOSVersionsResponse): unknown {
    const obj: any = {};
    if (message.osVersion?.length) {
      obj.osVersion = message.osVersion.map((e) => OSVersion.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListOSVersionsResponse>): ListOSVersionsResponse {
    return ListOSVersionsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListOSVersionsResponse>): ListOSVersionsResponse {
    const message = createBaseListOSVersionsResponse() as any;
    message.osVersion = object.osVersion?.map((e) => OSVersion.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseCreateMachineLSEPrototypeRequest(): CreateMachineLSEPrototypeRequest {
  return { machineLSEPrototype: undefined, machineLSEPrototypeId: "" };
}

export const CreateMachineLSEPrototypeRequest: MessageFns<CreateMachineLSEPrototypeRequest> = {
  encode(message: CreateMachineLSEPrototypeRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.machineLSEPrototype !== undefined) {
      MachineLSEPrototype.encode(message.machineLSEPrototype, writer.uint32(10).fork()).join();
    }
    if (message.machineLSEPrototypeId !== "") {
      writer.uint32(18).string(message.machineLSEPrototypeId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateMachineLSEPrototypeRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateMachineLSEPrototypeRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineLSEPrototype = MachineLSEPrototype.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.machineLSEPrototypeId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateMachineLSEPrototypeRequest {
    return {
      machineLSEPrototype: isSet(object.machineLSEPrototype)
        ? MachineLSEPrototype.fromJSON(object.machineLSEPrototype)
        : undefined,
      machineLSEPrototypeId: isSet(object.machineLSEPrototypeId) ? globalThis.String(object.machineLSEPrototypeId) : "",
    };
  },

  toJSON(message: CreateMachineLSEPrototypeRequest): unknown {
    const obj: any = {};
    if (message.machineLSEPrototype !== undefined) {
      obj.machineLSEPrototype = MachineLSEPrototype.toJSON(message.machineLSEPrototype);
    }
    if (message.machineLSEPrototypeId !== "") {
      obj.machineLSEPrototypeId = message.machineLSEPrototypeId;
    }
    return obj;
  },

  create(base?: DeepPartial<CreateMachineLSEPrototypeRequest>): CreateMachineLSEPrototypeRequest {
    return CreateMachineLSEPrototypeRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateMachineLSEPrototypeRequest>): CreateMachineLSEPrototypeRequest {
    const message = createBaseCreateMachineLSEPrototypeRequest() as any;
    message.machineLSEPrototype = (object.machineLSEPrototype !== undefined && object.machineLSEPrototype !== null)
      ? MachineLSEPrototype.fromPartial(object.machineLSEPrototype)
      : undefined;
    message.machineLSEPrototypeId = object.machineLSEPrototypeId ?? "";
    return message;
  },
};

function createBaseUpdateMachineLSEPrototypeRequest(): UpdateMachineLSEPrototypeRequest {
  return { machineLSEPrototype: undefined, updateMask: undefined };
}

export const UpdateMachineLSEPrototypeRequest: MessageFns<UpdateMachineLSEPrototypeRequest> = {
  encode(message: UpdateMachineLSEPrototypeRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.machineLSEPrototype !== undefined) {
      MachineLSEPrototype.encode(message.machineLSEPrototype, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateMachineLSEPrototypeRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMachineLSEPrototypeRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineLSEPrototype = MachineLSEPrototype.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMachineLSEPrototypeRequest {
    return {
      machineLSEPrototype: isSet(object.machineLSEPrototype)
        ? MachineLSEPrototype.fromJSON(object.machineLSEPrototype)
        : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateMachineLSEPrototypeRequest): unknown {
    const obj: any = {};
    if (message.machineLSEPrototype !== undefined) {
      obj.machineLSEPrototype = MachineLSEPrototype.toJSON(message.machineLSEPrototype);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateMachineLSEPrototypeRequest>): UpdateMachineLSEPrototypeRequest {
    return UpdateMachineLSEPrototypeRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateMachineLSEPrototypeRequest>): UpdateMachineLSEPrototypeRequest {
    const message = createBaseUpdateMachineLSEPrototypeRequest() as any;
    message.machineLSEPrototype = (object.machineLSEPrototype !== undefined && object.machineLSEPrototype !== null)
      ? MachineLSEPrototype.fromPartial(object.machineLSEPrototype)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseGetMachineLSEPrototypeRequest(): GetMachineLSEPrototypeRequest {
  return { name: "" };
}

export const GetMachineLSEPrototypeRequest: MessageFns<GetMachineLSEPrototypeRequest> = {
  encode(message: GetMachineLSEPrototypeRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetMachineLSEPrototypeRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMachineLSEPrototypeRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMachineLSEPrototypeRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetMachineLSEPrototypeRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetMachineLSEPrototypeRequest>): GetMachineLSEPrototypeRequest {
    return GetMachineLSEPrototypeRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetMachineLSEPrototypeRequest>): GetMachineLSEPrototypeRequest {
    const message = createBaseGetMachineLSEPrototypeRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListMachineLSEPrototypesRequest(): ListMachineLSEPrototypesRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListMachineLSEPrototypesRequest: MessageFns<ListMachineLSEPrototypesRequest> = {
  encode(message: ListMachineLSEPrototypesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListMachineLSEPrototypesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMachineLSEPrototypesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMachineLSEPrototypesRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListMachineLSEPrototypesRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListMachineLSEPrototypesRequest>): ListMachineLSEPrototypesRequest {
    return ListMachineLSEPrototypesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListMachineLSEPrototypesRequest>): ListMachineLSEPrototypesRequest {
    const message = createBaseListMachineLSEPrototypesRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListMachineLSEPrototypesResponse(): ListMachineLSEPrototypesResponse {
  return { machineLSEPrototypes: [], nextPageToken: "" };
}

export const ListMachineLSEPrototypesResponse: MessageFns<ListMachineLSEPrototypesResponse> = {
  encode(message: ListMachineLSEPrototypesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.machineLSEPrototypes) {
      MachineLSEPrototype.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListMachineLSEPrototypesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMachineLSEPrototypesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineLSEPrototypes.push(MachineLSEPrototype.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMachineLSEPrototypesResponse {
    return {
      machineLSEPrototypes: globalThis.Array.isArray(object?.machineLSEPrototypes)
        ? object.machineLSEPrototypes.map((e: any) => MachineLSEPrototype.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListMachineLSEPrototypesResponse): unknown {
    const obj: any = {};
    if (message.machineLSEPrototypes?.length) {
      obj.machineLSEPrototypes = message.machineLSEPrototypes.map((e) => MachineLSEPrototype.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListMachineLSEPrototypesResponse>): ListMachineLSEPrototypesResponse {
    return ListMachineLSEPrototypesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListMachineLSEPrototypesResponse>): ListMachineLSEPrototypesResponse {
    const message = createBaseListMachineLSEPrototypesResponse() as any;
    message.machineLSEPrototypes = object.machineLSEPrototypes?.map((e) => MachineLSEPrototype.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteMachineLSEPrototypeRequest(): DeleteMachineLSEPrototypeRequest {
  return { name: "" };
}

export const DeleteMachineLSEPrototypeRequest: MessageFns<DeleteMachineLSEPrototypeRequest> = {
  encode(message: DeleteMachineLSEPrototypeRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteMachineLSEPrototypeRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteMachineLSEPrototypeRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteMachineLSEPrototypeRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteMachineLSEPrototypeRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteMachineLSEPrototypeRequest>): DeleteMachineLSEPrototypeRequest {
    return DeleteMachineLSEPrototypeRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteMachineLSEPrototypeRequest>): DeleteMachineLSEPrototypeRequest {
    const message = createBaseDeleteMachineLSEPrototypeRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreateRackLSEPrototypeRequest(): CreateRackLSEPrototypeRequest {
  return { rackLSEPrototype: undefined, rackLSEPrototypeId: "" };
}

export const CreateRackLSEPrototypeRequest: MessageFns<CreateRackLSEPrototypeRequest> = {
  encode(message: CreateRackLSEPrototypeRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.rackLSEPrototype !== undefined) {
      RackLSEPrototype.encode(message.rackLSEPrototype, writer.uint32(10).fork()).join();
    }
    if (message.rackLSEPrototypeId !== "") {
      writer.uint32(18).string(message.rackLSEPrototypeId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateRackLSEPrototypeRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRackLSEPrototypeRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rackLSEPrototype = RackLSEPrototype.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.rackLSEPrototypeId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRackLSEPrototypeRequest {
    return {
      rackLSEPrototype: isSet(object.rackLSEPrototype) ? RackLSEPrototype.fromJSON(object.rackLSEPrototype) : undefined,
      rackLSEPrototypeId: isSet(object.rackLSEPrototypeId) ? globalThis.String(object.rackLSEPrototypeId) : "",
    };
  },

  toJSON(message: CreateRackLSEPrototypeRequest): unknown {
    const obj: any = {};
    if (message.rackLSEPrototype !== undefined) {
      obj.rackLSEPrototype = RackLSEPrototype.toJSON(message.rackLSEPrototype);
    }
    if (message.rackLSEPrototypeId !== "") {
      obj.rackLSEPrototypeId = message.rackLSEPrototypeId;
    }
    return obj;
  },

  create(base?: DeepPartial<CreateRackLSEPrototypeRequest>): CreateRackLSEPrototypeRequest {
    return CreateRackLSEPrototypeRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateRackLSEPrototypeRequest>): CreateRackLSEPrototypeRequest {
    const message = createBaseCreateRackLSEPrototypeRequest() as any;
    message.rackLSEPrototype = (object.rackLSEPrototype !== undefined && object.rackLSEPrototype !== null)
      ? RackLSEPrototype.fromPartial(object.rackLSEPrototype)
      : undefined;
    message.rackLSEPrototypeId = object.rackLSEPrototypeId ?? "";
    return message;
  },
};

function createBaseUpdateRackLSEPrototypeRequest(): UpdateRackLSEPrototypeRequest {
  return { rackLSEPrototype: undefined, updateMask: undefined };
}

export const UpdateRackLSEPrototypeRequest: MessageFns<UpdateRackLSEPrototypeRequest> = {
  encode(message: UpdateRackLSEPrototypeRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.rackLSEPrototype !== undefined) {
      RackLSEPrototype.encode(message.rackLSEPrototype, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateRackLSEPrototypeRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRackLSEPrototypeRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rackLSEPrototype = RackLSEPrototype.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateRackLSEPrototypeRequest {
    return {
      rackLSEPrototype: isSet(object.rackLSEPrototype) ? RackLSEPrototype.fromJSON(object.rackLSEPrototype) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateRackLSEPrototypeRequest): unknown {
    const obj: any = {};
    if (message.rackLSEPrototype !== undefined) {
      obj.rackLSEPrototype = RackLSEPrototype.toJSON(message.rackLSEPrototype);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateRackLSEPrototypeRequest>): UpdateRackLSEPrototypeRequest {
    return UpdateRackLSEPrototypeRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateRackLSEPrototypeRequest>): UpdateRackLSEPrototypeRequest {
    const message = createBaseUpdateRackLSEPrototypeRequest() as any;
    message.rackLSEPrototype = (object.rackLSEPrototype !== undefined && object.rackLSEPrototype !== null)
      ? RackLSEPrototype.fromPartial(object.rackLSEPrototype)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseGetRackLSEPrototypeRequest(): GetRackLSEPrototypeRequest {
  return { name: "" };
}

export const GetRackLSEPrototypeRequest: MessageFns<GetRackLSEPrototypeRequest> = {
  encode(message: GetRackLSEPrototypeRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRackLSEPrototypeRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRackLSEPrototypeRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRackLSEPrototypeRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetRackLSEPrototypeRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetRackLSEPrototypeRequest>): GetRackLSEPrototypeRequest {
    return GetRackLSEPrototypeRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetRackLSEPrototypeRequest>): GetRackLSEPrototypeRequest {
    const message = createBaseGetRackLSEPrototypeRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListRackLSEPrototypesRequest(): ListRackLSEPrototypesRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListRackLSEPrototypesRequest: MessageFns<ListRackLSEPrototypesRequest> = {
  encode(message: ListRackLSEPrototypesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListRackLSEPrototypesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRackLSEPrototypesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRackLSEPrototypesRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListRackLSEPrototypesRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListRackLSEPrototypesRequest>): ListRackLSEPrototypesRequest {
    return ListRackLSEPrototypesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListRackLSEPrototypesRequest>): ListRackLSEPrototypesRequest {
    const message = createBaseListRackLSEPrototypesRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListRackLSEPrototypesResponse(): ListRackLSEPrototypesResponse {
  return { rackLSEPrototypes: [], nextPageToken: "" };
}

export const ListRackLSEPrototypesResponse: MessageFns<ListRackLSEPrototypesResponse> = {
  encode(message: ListRackLSEPrototypesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.rackLSEPrototypes) {
      RackLSEPrototype.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListRackLSEPrototypesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRackLSEPrototypesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rackLSEPrototypes.push(RackLSEPrototype.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRackLSEPrototypesResponse {
    return {
      rackLSEPrototypes: globalThis.Array.isArray(object?.rackLSEPrototypes)
        ? object.rackLSEPrototypes.map((e: any) => RackLSEPrototype.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListRackLSEPrototypesResponse): unknown {
    const obj: any = {};
    if (message.rackLSEPrototypes?.length) {
      obj.rackLSEPrototypes = message.rackLSEPrototypes.map((e) => RackLSEPrototype.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListRackLSEPrototypesResponse>): ListRackLSEPrototypesResponse {
    return ListRackLSEPrototypesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListRackLSEPrototypesResponse>): ListRackLSEPrototypesResponse {
    const message = createBaseListRackLSEPrototypesResponse() as any;
    message.rackLSEPrototypes = object.rackLSEPrototypes?.map((e) => RackLSEPrototype.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteRackLSEPrototypeRequest(): DeleteRackLSEPrototypeRequest {
  return { name: "" };
}

export const DeleteRackLSEPrototypeRequest: MessageFns<DeleteRackLSEPrototypeRequest> = {
  encode(message: DeleteRackLSEPrototypeRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteRackLSEPrototypeRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteRackLSEPrototypeRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteRackLSEPrototypeRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteRackLSEPrototypeRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteRackLSEPrototypeRequest>): DeleteRackLSEPrototypeRequest {
    return DeleteRackLSEPrototypeRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteRackLSEPrototypeRequest>): DeleteRackLSEPrototypeRequest {
    const message = createBaseDeleteRackLSEPrototypeRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseMachineRegistrationRequest(): MachineRegistrationRequest {
  return { machine: undefined };
}

export const MachineRegistrationRequest: MessageFns<MachineRegistrationRequest> = {
  encode(message: MachineRegistrationRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.machine !== undefined) {
      Machine.encode(message.machine, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MachineRegistrationRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMachineRegistrationRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machine = Machine.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MachineRegistrationRequest {
    return { machine: isSet(object.machine) ? Machine.fromJSON(object.machine) : undefined };
  },

  toJSON(message: MachineRegistrationRequest): unknown {
    const obj: any = {};
    if (message.machine !== undefined) {
      obj.machine = Machine.toJSON(message.machine);
    }
    return obj;
  },

  create(base?: DeepPartial<MachineRegistrationRequest>): MachineRegistrationRequest {
    return MachineRegistrationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MachineRegistrationRequest>): MachineRegistrationRequest {
    const message = createBaseMachineRegistrationRequest() as any;
    message.machine = (object.machine !== undefined && object.machine !== null)
      ? Machine.fromPartial(object.machine)
      : undefined;
    return message;
  },
};

function createBaseUpdateMachineRequest(): UpdateMachineRequest {
  return { machine: undefined, updateMask: undefined };
}

export const UpdateMachineRequest: MessageFns<UpdateMachineRequest> = {
  encode(message: UpdateMachineRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.machine !== undefined) {
      Machine.encode(message.machine, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateMachineRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMachineRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machine = Machine.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMachineRequest {
    return {
      machine: isSet(object.machine) ? Machine.fromJSON(object.machine) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateMachineRequest): unknown {
    const obj: any = {};
    if (message.machine !== undefined) {
      obj.machine = Machine.toJSON(message.machine);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateMachineRequest>): UpdateMachineRequest {
    return UpdateMachineRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateMachineRequest>): UpdateMachineRequest {
    const message = createBaseUpdateMachineRequest() as any;
    message.machine = (object.machine !== undefined && object.machine !== null)
      ? Machine.fromPartial(object.machine)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseGetMachineRequest(): GetMachineRequest {
  return { name: "" };
}

export const GetMachineRequest: MessageFns<GetMachineRequest> = {
  encode(message: GetMachineRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetMachineRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMachineRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMachineRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetMachineRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetMachineRequest>): GetMachineRequest {
    return GetMachineRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetMachineRequest>): GetMachineRequest {
    const message = createBaseGetMachineRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListMachinesRequest(): ListMachinesRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false, full: false };
}

export const ListMachinesRequest: MessageFns<ListMachinesRequest> = {
  encode(message: ListMachinesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    if (message.full !== false) {
      writer.uint32(40).bool(message.full);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListMachinesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMachinesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.full = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMachinesRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
      full: isSet(object.full) ? globalThis.Boolean(object.full) : false,
    };
  },

  toJSON(message: ListMachinesRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    if (message.full !== false) {
      obj.full = message.full;
    }
    return obj;
  },

  create(base?: DeepPartial<ListMachinesRequest>): ListMachinesRequest {
    return ListMachinesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListMachinesRequest>): ListMachinesRequest {
    const message = createBaseListMachinesRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    message.full = object.full ?? false;
    return message;
  },
};

function createBaseListMachinesResponse(): ListMachinesResponse {
  return { machines: [], nextPageToken: "" };
}

export const ListMachinesResponse: MessageFns<ListMachinesResponse> = {
  encode(message: ListMachinesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.machines) {
      Machine.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListMachinesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMachinesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machines.push(Machine.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMachinesResponse {
    return {
      machines: globalThis.Array.isArray(object?.machines) ? object.machines.map((e: any) => Machine.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListMachinesResponse): unknown {
    const obj: any = {};
    if (message.machines?.length) {
      obj.machines = message.machines.map((e) => Machine.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListMachinesResponse>): ListMachinesResponse {
    return ListMachinesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListMachinesResponse>): ListMachinesResponse {
    const message = createBaseListMachinesResponse() as any;
    message.machines = object.machines?.map((e) => Machine.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteMachineRequest(): DeleteMachineRequest {
  return { name: "" };
}

export const DeleteMachineRequest: MessageFns<DeleteMachineRequest> = {
  encode(message: DeleteMachineRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteMachineRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteMachineRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteMachineRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteMachineRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteMachineRequest>): DeleteMachineRequest {
    return DeleteMachineRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteMachineRequest>): DeleteMachineRequest {
    const message = createBaseDeleteMachineRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseRenameMachineRequest(): RenameMachineRequest {
  return { name: "", newName: "" };
}

export const RenameMachineRequest: MessageFns<RenameMachineRequest> = {
  encode(message: RenameMachineRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.newName !== "") {
      writer.uint32(18).string(message.newName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RenameMachineRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenameMachineRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.newName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RenameMachineRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      newName: isSet(object.newName) ? globalThis.String(object.newName) : "",
    };
  },

  toJSON(message: RenameMachineRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.newName !== "") {
      obj.newName = message.newName;
    }
    return obj;
  },

  create(base?: DeepPartial<RenameMachineRequest>): RenameMachineRequest {
    return RenameMachineRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RenameMachineRequest>): RenameMachineRequest {
    const message = createBaseRenameMachineRequest() as any;
    message.name = object.name ?? "";
    message.newName = object.newName ?? "";
    return message;
  },
};

function createBaseMachineDBSource(): MachineDBSource {
  return { host: "" };
}

export const MachineDBSource: MessageFns<MachineDBSource> = {
  encode(message: MachineDBSource, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.host !== "") {
      writer.uint32(10).string(message.host);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MachineDBSource {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMachineDBSource() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.host = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MachineDBSource {
    return { host: isSet(object.host) ? globalThis.String(object.host) : "" };
  },

  toJSON(message: MachineDBSource): unknown {
    const obj: any = {};
    if (message.host !== "") {
      obj.host = message.host;
    }
    return obj;
  },

  create(base?: DeepPartial<MachineDBSource>): MachineDBSource {
    return MachineDBSource.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MachineDBSource>): MachineDBSource {
    const message = createBaseMachineDBSource() as any;
    message.host = object.host ?? "";
    return message;
  },
};

function createBaseConfigSource(): ConfigSource {
  return { configServiceName: "", fileName: "" };
}

export const ConfigSource: MessageFns<ConfigSource> = {
  encode(message: ConfigSource, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.configServiceName !== "") {
      writer.uint32(10).string(message.configServiceName);
    }
    if (message.fileName !== "") {
      writer.uint32(18).string(message.fileName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ConfigSource {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfigSource() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.configServiceName = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.fileName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConfigSource {
    return {
      configServiceName: isSet(object.configServiceName) ? globalThis.String(object.configServiceName) : "",
      fileName: isSet(object.fileName) ? globalThis.String(object.fileName) : "",
    };
  },

  toJSON(message: ConfigSource): unknown {
    const obj: any = {};
    if (message.configServiceName !== "") {
      obj.configServiceName = message.configServiceName;
    }
    if (message.fileName !== "") {
      obj.fileName = message.fileName;
    }
    return obj;
  },

  create(base?: DeepPartial<ConfigSource>): ConfigSource {
    return ConfigSource.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ConfigSource>): ConfigSource {
    const message = createBaseConfigSource() as any;
    message.configServiceName = object.configServiceName ?? "";
    message.fileName = object.fileName ?? "";
    return message;
  },
};

function createBaseCreateRackRequest(): CreateRackRequest {
  return { rack: undefined, rackId: "" };
}

export const CreateRackRequest: MessageFns<CreateRackRequest> = {
  encode(message: CreateRackRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.rack !== undefined) {
      Rack.encode(message.rack, writer.uint32(10).fork()).join();
    }
    if (message.rackId !== "") {
      writer.uint32(18).string(message.rackId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateRackRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRackRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rack = Rack.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.rackId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRackRequest {
    return {
      rack: isSet(object.rack) ? Rack.fromJSON(object.rack) : undefined,
      rackId: isSet(object.rackId) ? globalThis.String(object.rackId) : "",
    };
  },

  toJSON(message: CreateRackRequest): unknown {
    const obj: any = {};
    if (message.rack !== undefined) {
      obj.rack = Rack.toJSON(message.rack);
    }
    if (message.rackId !== "") {
      obj.rackId = message.rackId;
    }
    return obj;
  },

  create(base?: DeepPartial<CreateRackRequest>): CreateRackRequest {
    return CreateRackRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateRackRequest>): CreateRackRequest {
    const message = createBaseCreateRackRequest() as any;
    message.rack = (object.rack !== undefined && object.rack !== null) ? Rack.fromPartial(object.rack) : undefined;
    message.rackId = object.rackId ?? "";
    return message;
  },
};

function createBaseUpdateRackRequest(): UpdateRackRequest {
  return { rack: undefined, updateMask: undefined };
}

export const UpdateRackRequest: MessageFns<UpdateRackRequest> = {
  encode(message: UpdateRackRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.rack !== undefined) {
      Rack.encode(message.rack, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateRackRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRackRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rack = Rack.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateRackRequest {
    return {
      rack: isSet(object.rack) ? Rack.fromJSON(object.rack) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateRackRequest): unknown {
    const obj: any = {};
    if (message.rack !== undefined) {
      obj.rack = Rack.toJSON(message.rack);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateRackRequest>): UpdateRackRequest {
    return UpdateRackRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateRackRequest>): UpdateRackRequest {
    const message = createBaseUpdateRackRequest() as any;
    message.rack = (object.rack !== undefined && object.rack !== null) ? Rack.fromPartial(object.rack) : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseGetRackRequest(): GetRackRequest {
  return { name: "" };
}

export const GetRackRequest: MessageFns<GetRackRequest> = {
  encode(message: GetRackRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRackRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRackRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRackRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetRackRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetRackRequest>): GetRackRequest {
    return GetRackRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetRackRequest>): GetRackRequest {
    const message = createBaseGetRackRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListRacksRequest(): ListRacksRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false, full: false };
}

export const ListRacksRequest: MessageFns<ListRacksRequest> = {
  encode(message: ListRacksRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    if (message.full !== false) {
      writer.uint32(40).bool(message.full);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListRacksRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRacksRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.full = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRacksRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
      full: isSet(object.full) ? globalThis.Boolean(object.full) : false,
    };
  },

  toJSON(message: ListRacksRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    if (message.full !== false) {
      obj.full = message.full;
    }
    return obj;
  },

  create(base?: DeepPartial<ListRacksRequest>): ListRacksRequest {
    return ListRacksRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListRacksRequest>): ListRacksRequest {
    const message = createBaseListRacksRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    message.full = object.full ?? false;
    return message;
  },
};

function createBaseListRacksResponse(): ListRacksResponse {
  return { racks: [], nextPageToken: "" };
}

export const ListRacksResponse: MessageFns<ListRacksResponse> = {
  encode(message: ListRacksResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.racks) {
      Rack.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListRacksResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRacksResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.racks.push(Rack.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRacksResponse {
    return {
      racks: globalThis.Array.isArray(object?.racks) ? object.racks.map((e: any) => Rack.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListRacksResponse): unknown {
    const obj: any = {};
    if (message.racks?.length) {
      obj.racks = message.racks.map((e) => Rack.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListRacksResponse>): ListRacksResponse {
    return ListRacksResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListRacksResponse>): ListRacksResponse {
    const message = createBaseListRacksResponse() as any;
    message.racks = object.racks?.map((e) => Rack.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteRackRequest(): DeleteRackRequest {
  return { name: "" };
}

export const DeleteRackRequest: MessageFns<DeleteRackRequest> = {
  encode(message: DeleteRackRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteRackRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteRackRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteRackRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteRackRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteRackRequest>): DeleteRackRequest {
    return DeleteRackRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteRackRequest>): DeleteRackRequest {
    const message = createBaseDeleteRackRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseRenameRackRequest(): RenameRackRequest {
  return { name: "", newName: "" };
}

export const RenameRackRequest: MessageFns<RenameRackRequest> = {
  encode(message: RenameRackRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.newName !== "") {
      writer.uint32(18).string(message.newName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RenameRackRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenameRackRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.newName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RenameRackRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      newName: isSet(object.newName) ? globalThis.String(object.newName) : "",
    };
  },

  toJSON(message: RenameRackRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.newName !== "") {
      obj.newName = message.newName;
    }
    return obj;
  },

  create(base?: DeepPartial<RenameRackRequest>): RenameRackRequest {
    return RenameRackRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RenameRackRequest>): RenameRackRequest {
    const message = createBaseRenameRackRequest() as any;
    message.name = object.name ?? "";
    message.newName = object.newName ?? "";
    return message;
  },
};

function createBaseCreateMachineLSERequest(): CreateMachineLSERequest {
  return { machineLSE: undefined, machineLSEId: "", networkOption: undefined };
}

export const CreateMachineLSERequest: MessageFns<CreateMachineLSERequest> = {
  encode(message: CreateMachineLSERequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.machineLSE !== undefined) {
      MachineLSE.encode(message.machineLSE, writer.uint32(10).fork()).join();
    }
    if (message.machineLSEId !== "") {
      writer.uint32(18).string(message.machineLSEId);
    }
    if (message.networkOption !== undefined) {
      NetworkOption.encode(message.networkOption, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateMachineLSERequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateMachineLSERequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineLSE = MachineLSE.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.machineLSEId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.networkOption = NetworkOption.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateMachineLSERequest {
    return {
      machineLSE: isSet(object.machineLSE) ? MachineLSE.fromJSON(object.machineLSE) : undefined,
      machineLSEId: isSet(object.machineLSEId) ? globalThis.String(object.machineLSEId) : "",
      networkOption: isSet(object.networkOption) ? NetworkOption.fromJSON(object.networkOption) : undefined,
    };
  },

  toJSON(message: CreateMachineLSERequest): unknown {
    const obj: any = {};
    if (message.machineLSE !== undefined) {
      obj.machineLSE = MachineLSE.toJSON(message.machineLSE);
    }
    if (message.machineLSEId !== "") {
      obj.machineLSEId = message.machineLSEId;
    }
    if (message.networkOption !== undefined) {
      obj.networkOption = NetworkOption.toJSON(message.networkOption);
    }
    return obj;
  },

  create(base?: DeepPartial<CreateMachineLSERequest>): CreateMachineLSERequest {
    return CreateMachineLSERequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateMachineLSERequest>): CreateMachineLSERequest {
    const message = createBaseCreateMachineLSERequest() as any;
    message.machineLSE = (object.machineLSE !== undefined && object.machineLSE !== null)
      ? MachineLSE.fromPartial(object.machineLSE)
      : undefined;
    message.machineLSEId = object.machineLSEId ?? "";
    message.networkOption = (object.networkOption !== undefined && object.networkOption !== null)
      ? NetworkOption.fromPartial(object.networkOption)
      : undefined;
    return message;
  },
};

function createBaseUpdateMachineLSERequest(): UpdateMachineLSERequest {
  return { machineLSE: undefined, updateMask: undefined, networkOptions: {} };
}

export const UpdateMachineLSERequest: MessageFns<UpdateMachineLSERequest> = {
  encode(message: UpdateMachineLSERequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.machineLSE !== undefined) {
      MachineLSE.encode(message.machineLSE, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    Object.entries(message.networkOptions).forEach(([key, value]) => {
      UpdateMachineLSERequest_NetworkOptionsEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateMachineLSERequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMachineLSERequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineLSE = MachineLSE.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          const entry4 = UpdateMachineLSERequest_NetworkOptionsEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.networkOptions[entry4.key] = entry4.value;
          }
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMachineLSERequest {
    return {
      machineLSE: isSet(object.machineLSE) ? MachineLSE.fromJSON(object.machineLSE) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
      networkOptions: isObject(object.networkOptions)
        ? Object.entries(object.networkOptions).reduce<{ [key: string]: NetworkOption }>((acc, [key, value]) => {
          acc[key] = NetworkOption.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: UpdateMachineLSERequest): unknown {
    const obj: any = {};
    if (message.machineLSE !== undefined) {
      obj.machineLSE = MachineLSE.toJSON(message.machineLSE);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    if (message.networkOptions) {
      const entries = Object.entries(message.networkOptions);
      if (entries.length > 0) {
        obj.networkOptions = {};
        entries.forEach(([k, v]) => {
          obj.networkOptions[k] = NetworkOption.toJSON(v);
        });
      }
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateMachineLSERequest>): UpdateMachineLSERequest {
    return UpdateMachineLSERequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateMachineLSERequest>): UpdateMachineLSERequest {
    const message = createBaseUpdateMachineLSERequest() as any;
    message.machineLSE = (object.machineLSE !== undefined && object.machineLSE !== null)
      ? MachineLSE.fromPartial(object.machineLSE)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    message.networkOptions = Object.entries(object.networkOptions ?? {}).reduce<{ [key: string]: NetworkOption }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = NetworkOption.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseUpdateMachineLSERequest_NetworkOptionsEntry(): UpdateMachineLSERequest_NetworkOptionsEntry {
  return { key: "", value: undefined };
}

export const UpdateMachineLSERequest_NetworkOptionsEntry: MessageFns<UpdateMachineLSERequest_NetworkOptionsEntry> = {
  encode(
    message: UpdateMachineLSERequest_NetworkOptionsEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      NetworkOption.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateMachineLSERequest_NetworkOptionsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMachineLSERequest_NetworkOptionsEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = NetworkOption.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateMachineLSERequest_NetworkOptionsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? NetworkOption.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: UpdateMachineLSERequest_NetworkOptionsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = NetworkOption.toJSON(message.value);
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateMachineLSERequest_NetworkOptionsEntry>): UpdateMachineLSERequest_NetworkOptionsEntry {
    return UpdateMachineLSERequest_NetworkOptionsEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateMachineLSERequest_NetworkOptionsEntry>,
  ): UpdateMachineLSERequest_NetworkOptionsEntry {
    const message = createBaseUpdateMachineLSERequest_NetworkOptionsEntry() as any;
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? NetworkOption.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseGetMachineLSERequest(): GetMachineLSERequest {
  return { name: "" };
}

export const GetMachineLSERequest: MessageFns<GetMachineLSERequest> = {
  encode(message: GetMachineLSERequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetMachineLSERequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMachineLSERequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMachineLSERequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetMachineLSERequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetMachineLSERequest>): GetMachineLSERequest {
    return GetMachineLSERequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetMachineLSERequest>): GetMachineLSERequest {
    const message = createBaseGetMachineLSERequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseGetMachineLSEBySerialRequest(): GetMachineLSEBySerialRequest {
  return { serial: "", full: false };
}

export const GetMachineLSEBySerialRequest: MessageFns<GetMachineLSEBySerialRequest> = {
  encode(message: GetMachineLSEBySerialRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.serial !== "") {
      writer.uint32(10).string(message.serial);
    }
    if (message.full !== false) {
      writer.uint32(16).bool(message.full);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetMachineLSEBySerialRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMachineLSEBySerialRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.serial = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.full = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMachineLSEBySerialRequest {
    return {
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      full: isSet(object.full) ? globalThis.Boolean(object.full) : false,
    };
  },

  toJSON(message: GetMachineLSEBySerialRequest): unknown {
    const obj: any = {};
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.full !== false) {
      obj.full = message.full;
    }
    return obj;
  },

  create(base?: DeepPartial<GetMachineLSEBySerialRequest>): GetMachineLSEBySerialRequest {
    return GetMachineLSEBySerialRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetMachineLSEBySerialRequest>): GetMachineLSEBySerialRequest {
    const message = createBaseGetMachineLSEBySerialRequest() as any;
    message.serial = object.serial ?? "";
    message.full = object.full ?? false;
    return message;
  },
};

function createBaseGetHostDataRequest(): GetHostDataRequest {
  return { serial: "", full: false };
}

export const GetHostDataRequest: MessageFns<GetHostDataRequest> = {
  encode(message: GetHostDataRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.serial !== "") {
      writer.uint32(10).string(message.serial);
    }
    if (message.full !== false) {
      writer.uint32(16).bool(message.full);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetHostDataRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetHostDataRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.serial = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.full = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetHostDataRequest {
    return {
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      full: isSet(object.full) ? globalThis.Boolean(object.full) : false,
    };
  },

  toJSON(message: GetHostDataRequest): unknown {
    const obj: any = {};
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.full !== false) {
      obj.full = message.full;
    }
    return obj;
  },

  create(base?: DeepPartial<GetHostDataRequest>): GetHostDataRequest {
    return GetHostDataRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetHostDataRequest>): GetHostDataRequest {
    const message = createBaseGetHostDataRequest() as any;
    message.serial = object.serial ?? "";
    message.full = object.full ?? false;
    return message;
  },
};

function createBaseGetHostDataResponse(): GetHostDataResponse {
  return { host: undefined, machine: undefined };
}

export const GetHostDataResponse: MessageFns<GetHostDataResponse> = {
  encode(message: GetHostDataResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.host !== undefined) {
      MachineLSE.encode(message.host, writer.uint32(10).fork()).join();
    }
    if (message.machine !== undefined) {
      Machine.encode(message.machine, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetHostDataResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetHostDataResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.host = MachineLSE.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.machine = Machine.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetHostDataResponse {
    return {
      host: isSet(object.host) ? MachineLSE.fromJSON(object.host) : undefined,
      machine: isSet(object.machine) ? Machine.fromJSON(object.machine) : undefined,
    };
  },

  toJSON(message: GetHostDataResponse): unknown {
    const obj: any = {};
    if (message.host !== undefined) {
      obj.host = MachineLSE.toJSON(message.host);
    }
    if (message.machine !== undefined) {
      obj.machine = Machine.toJSON(message.machine);
    }
    return obj;
  },

  create(base?: DeepPartial<GetHostDataResponse>): GetHostDataResponse {
    return GetHostDataResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetHostDataResponse>): GetHostDataResponse {
    const message = createBaseGetHostDataResponse() as any;
    message.host = (object.host !== undefined && object.host !== null)
      ? MachineLSE.fromPartial(object.host)
      : undefined;
    message.machine = (object.machine !== undefined && object.machine !== null)
      ? Machine.fromPartial(object.machine)
      : undefined;
    return message;
  },
};

function createBaseListMachineLSEsRequest(): ListMachineLSEsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false, full: false };
}

export const ListMachineLSEsRequest: MessageFns<ListMachineLSEsRequest> = {
  encode(message: ListMachineLSEsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    if (message.full !== false) {
      writer.uint32(40).bool(message.full);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListMachineLSEsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMachineLSEsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.full = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMachineLSEsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
      full: isSet(object.full) ? globalThis.Boolean(object.full) : false,
    };
  },

  toJSON(message: ListMachineLSEsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    if (message.full !== false) {
      obj.full = message.full;
    }
    return obj;
  },

  create(base?: DeepPartial<ListMachineLSEsRequest>): ListMachineLSEsRequest {
    return ListMachineLSEsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListMachineLSEsRequest>): ListMachineLSEsRequest {
    const message = createBaseListMachineLSEsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    message.full = object.full ?? false;
    return message;
  },
};

function createBaseListMachineLSEsResponse(): ListMachineLSEsResponse {
  return { machineLSEs: [], nextPageToken: "" };
}

export const ListMachineLSEsResponse: MessageFns<ListMachineLSEsResponse> = {
  encode(message: ListMachineLSEsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.machineLSEs) {
      MachineLSE.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListMachineLSEsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListMachineLSEsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineLSEs.push(MachineLSE.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListMachineLSEsResponse {
    return {
      machineLSEs: globalThis.Array.isArray(object?.machineLSEs)
        ? object.machineLSEs.map((e: any) => MachineLSE.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListMachineLSEsResponse): unknown {
    const obj: any = {};
    if (message.machineLSEs?.length) {
      obj.machineLSEs = message.machineLSEs.map((e) => MachineLSE.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListMachineLSEsResponse>): ListMachineLSEsResponse {
    return ListMachineLSEsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListMachineLSEsResponse>): ListMachineLSEsResponse {
    const message = createBaseListMachineLSEsResponse() as any;
    message.machineLSEs = object.machineLSEs?.map((e) => MachineLSE.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteMachineLSERequest(): DeleteMachineLSERequest {
  return { name: "" };
}

export const DeleteMachineLSERequest: MessageFns<DeleteMachineLSERequest> = {
  encode(message: DeleteMachineLSERequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteMachineLSERequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteMachineLSERequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteMachineLSERequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteMachineLSERequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteMachineLSERequest>): DeleteMachineLSERequest {
    return DeleteMachineLSERequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteMachineLSERequest>): DeleteMachineLSERequest {
    const message = createBaseDeleteMachineLSERequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseRenameMachineLSERequest(): RenameMachineLSERequest {
  return { name: "", newName: "" };
}

export const RenameMachineLSERequest: MessageFns<RenameMachineLSERequest> = {
  encode(message: RenameMachineLSERequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.newName !== "") {
      writer.uint32(18).string(message.newName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RenameMachineLSERequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenameMachineLSERequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.newName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RenameMachineLSERequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      newName: isSet(object.newName) ? globalThis.String(object.newName) : "",
    };
  },

  toJSON(message: RenameMachineLSERequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.newName !== "") {
      obj.newName = message.newName;
    }
    return obj;
  },

  create(base?: DeepPartial<RenameMachineLSERequest>): RenameMachineLSERequest {
    return RenameMachineLSERequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RenameMachineLSERequest>): RenameMachineLSERequest {
    const message = createBaseRenameMachineLSERequest() as any;
    message.name = object.name ?? "";
    message.newName = object.newName ?? "";
    return message;
  },
};

function createBaseCreateRackLSERequest(): CreateRackLSERequest {
  return { rackLSE: undefined, rackLSEId: "" };
}

export const CreateRackLSERequest: MessageFns<CreateRackLSERequest> = {
  encode(message: CreateRackLSERequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.rackLSE !== undefined) {
      RackLSE.encode(message.rackLSE, writer.uint32(10).fork()).join();
    }
    if (message.rackLSEId !== "") {
      writer.uint32(18).string(message.rackLSEId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateRackLSERequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRackLSERequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rackLSE = RackLSE.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.rackLSEId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRackLSERequest {
    return {
      rackLSE: isSet(object.rackLSE) ? RackLSE.fromJSON(object.rackLSE) : undefined,
      rackLSEId: isSet(object.rackLSEId) ? globalThis.String(object.rackLSEId) : "",
    };
  },

  toJSON(message: CreateRackLSERequest): unknown {
    const obj: any = {};
    if (message.rackLSE !== undefined) {
      obj.rackLSE = RackLSE.toJSON(message.rackLSE);
    }
    if (message.rackLSEId !== "") {
      obj.rackLSEId = message.rackLSEId;
    }
    return obj;
  },

  create(base?: DeepPartial<CreateRackLSERequest>): CreateRackLSERequest {
    return CreateRackLSERequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateRackLSERequest>): CreateRackLSERequest {
    const message = createBaseCreateRackLSERequest() as any;
    message.rackLSE = (object.rackLSE !== undefined && object.rackLSE !== null)
      ? RackLSE.fromPartial(object.rackLSE)
      : undefined;
    message.rackLSEId = object.rackLSEId ?? "";
    return message;
  },
};

function createBaseUpdateRackLSERequest(): UpdateRackLSERequest {
  return { rackLSE: undefined, updateMask: undefined };
}

export const UpdateRackLSERequest: MessageFns<UpdateRackLSERequest> = {
  encode(message: UpdateRackLSERequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.rackLSE !== undefined) {
      RackLSE.encode(message.rackLSE, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateRackLSERequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRackLSERequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rackLSE = RackLSE.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateRackLSERequest {
    return {
      rackLSE: isSet(object.rackLSE) ? RackLSE.fromJSON(object.rackLSE) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateRackLSERequest): unknown {
    const obj: any = {};
    if (message.rackLSE !== undefined) {
      obj.rackLSE = RackLSE.toJSON(message.rackLSE);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateRackLSERequest>): UpdateRackLSERequest {
    return UpdateRackLSERequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateRackLSERequest>): UpdateRackLSERequest {
    const message = createBaseUpdateRackLSERequest() as any;
    message.rackLSE = (object.rackLSE !== undefined && object.rackLSE !== null)
      ? RackLSE.fromPartial(object.rackLSE)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseGetRackLSERequest(): GetRackLSERequest {
  return { name: "" };
}

export const GetRackLSERequest: MessageFns<GetRackLSERequest> = {
  encode(message: GetRackLSERequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRackLSERequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRackLSERequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRackLSERequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetRackLSERequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetRackLSERequest>): GetRackLSERequest {
    return GetRackLSERequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetRackLSERequest>): GetRackLSERequest {
    const message = createBaseGetRackLSERequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListRackLSEsRequest(): ListRackLSEsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListRackLSEsRequest: MessageFns<ListRackLSEsRequest> = {
  encode(message: ListRackLSEsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListRackLSEsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRackLSEsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRackLSEsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListRackLSEsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListRackLSEsRequest>): ListRackLSEsRequest {
    return ListRackLSEsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListRackLSEsRequest>): ListRackLSEsRequest {
    const message = createBaseListRackLSEsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListRackLSEsResponse(): ListRackLSEsResponse {
  return { rackLSEs: [], nextPageToken: "" };
}

export const ListRackLSEsResponse: MessageFns<ListRackLSEsResponse> = {
  encode(message: ListRackLSEsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.rackLSEs) {
      RackLSE.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListRackLSEsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRackLSEsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rackLSEs.push(RackLSE.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRackLSEsResponse {
    return {
      rackLSEs: globalThis.Array.isArray(object?.rackLSEs) ? object.rackLSEs.map((e: any) => RackLSE.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListRackLSEsResponse): unknown {
    const obj: any = {};
    if (message.rackLSEs?.length) {
      obj.rackLSEs = message.rackLSEs.map((e) => RackLSE.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListRackLSEsResponse>): ListRackLSEsResponse {
    return ListRackLSEsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListRackLSEsResponse>): ListRackLSEsResponse {
    const message = createBaseListRackLSEsResponse() as any;
    message.rackLSEs = object.rackLSEs?.map((e) => RackLSE.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteRackLSERequest(): DeleteRackLSERequest {
  return { name: "" };
}

export const DeleteRackLSERequest: MessageFns<DeleteRackLSERequest> = {
  encode(message: DeleteRackLSERequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteRackLSERequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteRackLSERequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteRackLSERequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteRackLSERequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteRackLSERequest>): DeleteRackLSERequest {
    return DeleteRackLSERequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteRackLSERequest>): DeleteRackLSERequest {
    const message = createBaseDeleteRackLSERequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreateNicRequest(): CreateNicRequest {
  return { nic: undefined, nicId: "" };
}

export const CreateNicRequest: MessageFns<CreateNicRequest> = {
  encode(message: CreateNicRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.nic !== undefined) {
      Nic.encode(message.nic, writer.uint32(10).fork()).join();
    }
    if (message.nicId !== "") {
      writer.uint32(18).string(message.nicId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateNicRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateNicRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.nic = Nic.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nicId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateNicRequest {
    return {
      nic: isSet(object.nic) ? Nic.fromJSON(object.nic) : undefined,
      nicId: isSet(object.nicId) ? globalThis.String(object.nicId) : "",
    };
  },

  toJSON(message: CreateNicRequest): unknown {
    const obj: any = {};
    if (message.nic !== undefined) {
      obj.nic = Nic.toJSON(message.nic);
    }
    if (message.nicId !== "") {
      obj.nicId = message.nicId;
    }
    return obj;
  },

  create(base?: DeepPartial<CreateNicRequest>): CreateNicRequest {
    return CreateNicRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateNicRequest>): CreateNicRequest {
    const message = createBaseCreateNicRequest() as any;
    message.nic = (object.nic !== undefined && object.nic !== null) ? Nic.fromPartial(object.nic) : undefined;
    message.nicId = object.nicId ?? "";
    return message;
  },
};

function createBaseUpdateNicRequest(): UpdateNicRequest {
  return { nic: undefined, updateMask: undefined };
}

export const UpdateNicRequest: MessageFns<UpdateNicRequest> = {
  encode(message: UpdateNicRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.nic !== undefined) {
      Nic.encode(message.nic, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateNicRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateNicRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.nic = Nic.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateNicRequest {
    return {
      nic: isSet(object.nic) ? Nic.fromJSON(object.nic) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateNicRequest): unknown {
    const obj: any = {};
    if (message.nic !== undefined) {
      obj.nic = Nic.toJSON(message.nic);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateNicRequest>): UpdateNicRequest {
    return UpdateNicRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateNicRequest>): UpdateNicRequest {
    const message = createBaseUpdateNicRequest() as any;
    message.nic = (object.nic !== undefined && object.nic !== null) ? Nic.fromPartial(object.nic) : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseGetNicRequest(): GetNicRequest {
  return { name: "" };
}

export const GetNicRequest: MessageFns<GetNicRequest> = {
  encode(message: GetNicRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetNicRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNicRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNicRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetNicRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetNicRequest>): GetNicRequest {
    return GetNicRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetNicRequest>): GetNicRequest {
    const message = createBaseGetNicRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListNicsRequest(): ListNicsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListNicsRequest: MessageFns<ListNicsRequest> = {
  encode(message: ListNicsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListNicsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListNicsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListNicsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListNicsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListNicsRequest>): ListNicsRequest {
    return ListNicsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListNicsRequest>): ListNicsRequest {
    const message = createBaseListNicsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListNicsResponse(): ListNicsResponse {
  return { nics: [], nextPageToken: "" };
}

export const ListNicsResponse: MessageFns<ListNicsResponse> = {
  encode(message: ListNicsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.nics) {
      Nic.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListNicsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListNicsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.nics.push(Nic.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListNicsResponse {
    return {
      nics: globalThis.Array.isArray(object?.nics) ? object.nics.map((e: any) => Nic.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListNicsResponse): unknown {
    const obj: any = {};
    if (message.nics?.length) {
      obj.nics = message.nics.map((e) => Nic.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListNicsResponse>): ListNicsResponse {
    return ListNicsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListNicsResponse>): ListNicsResponse {
    const message = createBaseListNicsResponse() as any;
    message.nics = object.nics?.map((e) => Nic.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteNicRequest(): DeleteNicRequest {
  return { name: "" };
}

export const DeleteNicRequest: MessageFns<DeleteNicRequest> = {
  encode(message: DeleteNicRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteNicRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteNicRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteNicRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteNicRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteNicRequest>): DeleteNicRequest {
    return DeleteNicRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteNicRequest>): DeleteNicRequest {
    const message = createBaseDeleteNicRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseRenameNicRequest(): RenameNicRequest {
  return { name: "", newName: "" };
}

export const RenameNicRequest: MessageFns<RenameNicRequest> = {
  encode(message: RenameNicRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.newName !== "") {
      writer.uint32(18).string(message.newName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RenameNicRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenameNicRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.newName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RenameNicRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      newName: isSet(object.newName) ? globalThis.String(object.newName) : "",
    };
  },

  toJSON(message: RenameNicRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.newName !== "") {
      obj.newName = message.newName;
    }
    return obj;
  },

  create(base?: DeepPartial<RenameNicRequest>): RenameNicRequest {
    return RenameNicRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RenameNicRequest>): RenameNicRequest {
    const message = createBaseRenameNicRequest() as any;
    message.name = object.name ?? "";
    message.newName = object.newName ?? "";
    return message;
  },
};

function createBaseRenameSwitchRequest(): RenameSwitchRequest {
  return { name: "", newName: "" };
}

export const RenameSwitchRequest: MessageFns<RenameSwitchRequest> = {
  encode(message: RenameSwitchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.newName !== "") {
      writer.uint32(18).string(message.newName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RenameSwitchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenameSwitchRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.newName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RenameSwitchRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      newName: isSet(object.newName) ? globalThis.String(object.newName) : "",
    };
  },

  toJSON(message: RenameSwitchRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.newName !== "") {
      obj.newName = message.newName;
    }
    return obj;
  },

  create(base?: DeepPartial<RenameSwitchRequest>): RenameSwitchRequest {
    return RenameSwitchRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RenameSwitchRequest>): RenameSwitchRequest {
    const message = createBaseRenameSwitchRequest() as any;
    message.name = object.name ?? "";
    message.newName = object.newName ?? "";
    return message;
  },
};

function createBaseCreateKVMRequest(): CreateKVMRequest {
  return { KVM: undefined, KVMId: "" };
}

export const CreateKVMRequest: MessageFns<CreateKVMRequest> = {
  encode(message: CreateKVMRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.KVM !== undefined) {
      KVM.encode(message.KVM, writer.uint32(10).fork()).join();
    }
    if (message.KVMId !== "") {
      writer.uint32(18).string(message.KVMId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateKVMRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateKVMRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.KVM = KVM.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.KVMId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateKVMRequest {
    return {
      KVM: isSet(object.KVM) ? KVM.fromJSON(object.KVM) : undefined,
      KVMId: isSet(object.KVMId) ? globalThis.String(object.KVMId) : "",
    };
  },

  toJSON(message: CreateKVMRequest): unknown {
    const obj: any = {};
    if (message.KVM !== undefined) {
      obj.KVM = KVM.toJSON(message.KVM);
    }
    if (message.KVMId !== "") {
      obj.KVMId = message.KVMId;
    }
    return obj;
  },

  create(base?: DeepPartial<CreateKVMRequest>): CreateKVMRequest {
    return CreateKVMRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateKVMRequest>): CreateKVMRequest {
    const message = createBaseCreateKVMRequest() as any;
    message.KVM = (object.KVM !== undefined && object.KVM !== null) ? KVM.fromPartial(object.KVM) : undefined;
    message.KVMId = object.KVMId ?? "";
    return message;
  },
};

function createBaseUpdateKVMRequest(): UpdateKVMRequest {
  return { KVM: undefined, updateMask: undefined, networkOption: undefined };
}

export const UpdateKVMRequest: MessageFns<UpdateKVMRequest> = {
  encode(message: UpdateKVMRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.KVM !== undefined) {
      KVM.encode(message.KVM, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    if (message.networkOption !== undefined) {
      NetworkOption.encode(message.networkOption, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateKVMRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateKVMRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.KVM = KVM.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.networkOption = NetworkOption.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateKVMRequest {
    return {
      KVM: isSet(object.KVM) ? KVM.fromJSON(object.KVM) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
      networkOption: isSet(object.networkOption) ? NetworkOption.fromJSON(object.networkOption) : undefined,
    };
  },

  toJSON(message: UpdateKVMRequest): unknown {
    const obj: any = {};
    if (message.KVM !== undefined) {
      obj.KVM = KVM.toJSON(message.KVM);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    if (message.networkOption !== undefined) {
      obj.networkOption = NetworkOption.toJSON(message.networkOption);
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateKVMRequest>): UpdateKVMRequest {
    return UpdateKVMRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateKVMRequest>): UpdateKVMRequest {
    const message = createBaseUpdateKVMRequest() as any;
    message.KVM = (object.KVM !== undefined && object.KVM !== null) ? KVM.fromPartial(object.KVM) : undefined;
    message.updateMask = object.updateMask ?? undefined;
    message.networkOption = (object.networkOption !== undefined && object.networkOption !== null)
      ? NetworkOption.fromPartial(object.networkOption)
      : undefined;
    return message;
  },
};

function createBaseGetKVMRequest(): GetKVMRequest {
  return { name: "" };
}

export const GetKVMRequest: MessageFns<GetKVMRequest> = {
  encode(message: GetKVMRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetKVMRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetKVMRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetKVMRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetKVMRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetKVMRequest>): GetKVMRequest {
    return GetKVMRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetKVMRequest>): GetKVMRequest {
    const message = createBaseGetKVMRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListKVMsRequest(): ListKVMsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListKVMsRequest: MessageFns<ListKVMsRequest> = {
  encode(message: ListKVMsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListKVMsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListKVMsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListKVMsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListKVMsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListKVMsRequest>): ListKVMsRequest {
    return ListKVMsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListKVMsRequest>): ListKVMsRequest {
    const message = createBaseListKVMsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListKVMsResponse(): ListKVMsResponse {
  return { KVMs: [], nextPageToken: "" };
}

export const ListKVMsResponse: MessageFns<ListKVMsResponse> = {
  encode(message: ListKVMsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.KVMs) {
      KVM.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListKVMsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListKVMsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.KVMs.push(KVM.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListKVMsResponse {
    return {
      KVMs: globalThis.Array.isArray(object?.KVMs) ? object.KVMs.map((e: any) => KVM.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListKVMsResponse): unknown {
    const obj: any = {};
    if (message.KVMs?.length) {
      obj.KVMs = message.KVMs.map((e) => KVM.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListKVMsResponse>): ListKVMsResponse {
    return ListKVMsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListKVMsResponse>): ListKVMsResponse {
    const message = createBaseListKVMsResponse() as any;
    message.KVMs = object.KVMs?.map((e) => KVM.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteKVMRequest(): DeleteKVMRequest {
  return { name: "" };
}

export const DeleteKVMRequest: MessageFns<DeleteKVMRequest> = {
  encode(message: DeleteKVMRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteKVMRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteKVMRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteKVMRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteKVMRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteKVMRequest>): DeleteKVMRequest {
    return DeleteKVMRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteKVMRequest>): DeleteKVMRequest {
    const message = createBaseDeleteKVMRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreateRPMRequest(): CreateRPMRequest {
  return { RPM: undefined, RPMId: "" };
}

export const CreateRPMRequest: MessageFns<CreateRPMRequest> = {
  encode(message: CreateRPMRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.RPM !== undefined) {
      RPM.encode(message.RPM, writer.uint32(10).fork()).join();
    }
    if (message.RPMId !== "") {
      writer.uint32(18).string(message.RPMId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateRPMRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRPMRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.RPM = RPM.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.RPMId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRPMRequest {
    return {
      RPM: isSet(object.RPM) ? RPM.fromJSON(object.RPM) : undefined,
      RPMId: isSet(object.RPMId) ? globalThis.String(object.RPMId) : "",
    };
  },

  toJSON(message: CreateRPMRequest): unknown {
    const obj: any = {};
    if (message.RPM !== undefined) {
      obj.RPM = RPM.toJSON(message.RPM);
    }
    if (message.RPMId !== "") {
      obj.RPMId = message.RPMId;
    }
    return obj;
  },

  create(base?: DeepPartial<CreateRPMRequest>): CreateRPMRequest {
    return CreateRPMRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateRPMRequest>): CreateRPMRequest {
    const message = createBaseCreateRPMRequest() as any;
    message.RPM = (object.RPM !== undefined && object.RPM !== null) ? RPM.fromPartial(object.RPM) : undefined;
    message.RPMId = object.RPMId ?? "";
    return message;
  },
};

function createBaseUpdateRPMRequest(): UpdateRPMRequest {
  return { RPM: undefined, updateMask: undefined, networkOption: undefined };
}

export const UpdateRPMRequest: MessageFns<UpdateRPMRequest> = {
  encode(message: UpdateRPMRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.RPM !== undefined) {
      RPM.encode(message.RPM, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    if (message.networkOption !== undefined) {
      NetworkOption.encode(message.networkOption, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateRPMRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRPMRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.RPM = RPM.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.networkOption = NetworkOption.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateRPMRequest {
    return {
      RPM: isSet(object.RPM) ? RPM.fromJSON(object.RPM) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
      networkOption: isSet(object.networkOption) ? NetworkOption.fromJSON(object.networkOption) : undefined,
    };
  },

  toJSON(message: UpdateRPMRequest): unknown {
    const obj: any = {};
    if (message.RPM !== undefined) {
      obj.RPM = RPM.toJSON(message.RPM);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    if (message.networkOption !== undefined) {
      obj.networkOption = NetworkOption.toJSON(message.networkOption);
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateRPMRequest>): UpdateRPMRequest {
    return UpdateRPMRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateRPMRequest>): UpdateRPMRequest {
    const message = createBaseUpdateRPMRequest() as any;
    message.RPM = (object.RPM !== undefined && object.RPM !== null) ? RPM.fromPartial(object.RPM) : undefined;
    message.updateMask = object.updateMask ?? undefined;
    message.networkOption = (object.networkOption !== undefined && object.networkOption !== null)
      ? NetworkOption.fromPartial(object.networkOption)
      : undefined;
    return message;
  },
};

function createBaseGetRPMRequest(): GetRPMRequest {
  return { name: "" };
}

export const GetRPMRequest: MessageFns<GetRPMRequest> = {
  encode(message: GetRPMRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRPMRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRPMRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRPMRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetRPMRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetRPMRequest>): GetRPMRequest {
    return GetRPMRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetRPMRequest>): GetRPMRequest {
    const message = createBaseGetRPMRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListRPMsRequest(): ListRPMsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListRPMsRequest: MessageFns<ListRPMsRequest> = {
  encode(message: ListRPMsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListRPMsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRPMsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRPMsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListRPMsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListRPMsRequest>): ListRPMsRequest {
    return ListRPMsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListRPMsRequest>): ListRPMsRequest {
    const message = createBaseListRPMsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListRPMsResponse(): ListRPMsResponse {
  return { RPMs: [], nextPageToken: "" };
}

export const ListRPMsResponse: MessageFns<ListRPMsResponse> = {
  encode(message: ListRPMsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.RPMs) {
      RPM.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListRPMsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRPMsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.RPMs.push(RPM.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRPMsResponse {
    return {
      RPMs: globalThis.Array.isArray(object?.RPMs) ? object.RPMs.map((e: any) => RPM.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListRPMsResponse): unknown {
    const obj: any = {};
    if (message.RPMs?.length) {
      obj.RPMs = message.RPMs.map((e) => RPM.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListRPMsResponse>): ListRPMsResponse {
    return ListRPMsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListRPMsResponse>): ListRPMsResponse {
    const message = createBaseListRPMsResponse() as any;
    message.RPMs = object.RPMs?.map((e) => RPM.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteRPMRequest(): DeleteRPMRequest {
  return { name: "" };
}

export const DeleteRPMRequest: MessageFns<DeleteRPMRequest> = {
  encode(message: DeleteRPMRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteRPMRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteRPMRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteRPMRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteRPMRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteRPMRequest>): DeleteRPMRequest {
    return DeleteRPMRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteRPMRequest>): DeleteRPMRequest {
    const message = createBaseDeleteRPMRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreateDracRequest(): CreateDracRequest {
  return { drac: undefined, dracId: "", networkOption: undefined };
}

export const CreateDracRequest: MessageFns<CreateDracRequest> = {
  encode(message: CreateDracRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.drac !== undefined) {
      Drac.encode(message.drac, writer.uint32(10).fork()).join();
    }
    if (message.dracId !== "") {
      writer.uint32(18).string(message.dracId);
    }
    if (message.networkOption !== undefined) {
      NetworkOption.encode(message.networkOption, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateDracRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateDracRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.drac = Drac.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.dracId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.networkOption = NetworkOption.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateDracRequest {
    return {
      drac: isSet(object.drac) ? Drac.fromJSON(object.drac) : undefined,
      dracId: isSet(object.dracId) ? globalThis.String(object.dracId) : "",
      networkOption: isSet(object.networkOption) ? NetworkOption.fromJSON(object.networkOption) : undefined,
    };
  },

  toJSON(message: CreateDracRequest): unknown {
    const obj: any = {};
    if (message.drac !== undefined) {
      obj.drac = Drac.toJSON(message.drac);
    }
    if (message.dracId !== "") {
      obj.dracId = message.dracId;
    }
    if (message.networkOption !== undefined) {
      obj.networkOption = NetworkOption.toJSON(message.networkOption);
    }
    return obj;
  },

  create(base?: DeepPartial<CreateDracRequest>): CreateDracRequest {
    return CreateDracRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateDracRequest>): CreateDracRequest {
    const message = createBaseCreateDracRequest() as any;
    message.drac = (object.drac !== undefined && object.drac !== null) ? Drac.fromPartial(object.drac) : undefined;
    message.dracId = object.dracId ?? "";
    message.networkOption = (object.networkOption !== undefined && object.networkOption !== null)
      ? NetworkOption.fromPartial(object.networkOption)
      : undefined;
    return message;
  },
};

function createBaseUpdateDracRequest(): UpdateDracRequest {
  return { drac: undefined, updateMask: undefined, networkOption: undefined };
}

export const UpdateDracRequest: MessageFns<UpdateDracRequest> = {
  encode(message: UpdateDracRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.drac !== undefined) {
      Drac.encode(message.drac, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    if (message.networkOption !== undefined) {
      NetworkOption.encode(message.networkOption, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateDracRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDracRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.drac = Drac.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.networkOption = NetworkOption.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateDracRequest {
    return {
      drac: isSet(object.drac) ? Drac.fromJSON(object.drac) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
      networkOption: isSet(object.networkOption) ? NetworkOption.fromJSON(object.networkOption) : undefined,
    };
  },

  toJSON(message: UpdateDracRequest): unknown {
    const obj: any = {};
    if (message.drac !== undefined) {
      obj.drac = Drac.toJSON(message.drac);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    if (message.networkOption !== undefined) {
      obj.networkOption = NetworkOption.toJSON(message.networkOption);
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateDracRequest>): UpdateDracRequest {
    return UpdateDracRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateDracRequest>): UpdateDracRequest {
    const message = createBaseUpdateDracRequest() as any;
    message.drac = (object.drac !== undefined && object.drac !== null) ? Drac.fromPartial(object.drac) : undefined;
    message.updateMask = object.updateMask ?? undefined;
    message.networkOption = (object.networkOption !== undefined && object.networkOption !== null)
      ? NetworkOption.fromPartial(object.networkOption)
      : undefined;
    return message;
  },
};

function createBaseGetDracRequest(): GetDracRequest {
  return { name: "" };
}

export const GetDracRequest: MessageFns<GetDracRequest> = {
  encode(message: GetDracRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDracRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDracRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDracRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetDracRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetDracRequest>): GetDracRequest {
    return GetDracRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetDracRequest>): GetDracRequest {
    const message = createBaseGetDracRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListDracsRequest(): ListDracsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListDracsRequest: MessageFns<ListDracsRequest> = {
  encode(message: ListDracsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListDracsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDracsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDracsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListDracsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListDracsRequest>): ListDracsRequest {
    return ListDracsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDracsRequest>): ListDracsRequest {
    const message = createBaseListDracsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListDracsResponse(): ListDracsResponse {
  return { dracs: [], nextPageToken: "" };
}

export const ListDracsResponse: MessageFns<ListDracsResponse> = {
  encode(message: ListDracsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.dracs) {
      Drac.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListDracsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDracsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dracs.push(Drac.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDracsResponse {
    return {
      dracs: globalThis.Array.isArray(object?.dracs) ? object.dracs.map((e: any) => Drac.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListDracsResponse): unknown {
    const obj: any = {};
    if (message.dracs?.length) {
      obj.dracs = message.dracs.map((e) => Drac.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListDracsResponse>): ListDracsResponse {
    return ListDracsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDracsResponse>): ListDracsResponse {
    const message = createBaseListDracsResponse() as any;
    message.dracs = object.dracs?.map((e) => Drac.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteDracRequest(): DeleteDracRequest {
  return { name: "" };
}

export const DeleteDracRequest: MessageFns<DeleteDracRequest> = {
  encode(message: DeleteDracRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteDracRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteDracRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteDracRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteDracRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteDracRequest>): DeleteDracRequest {
    return DeleteDracRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteDracRequest>): DeleteDracRequest {
    const message = createBaseDeleteDracRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreateSwitchRequest(): CreateSwitchRequest {
  return { switch: undefined, switchId: "" };
}

export const CreateSwitchRequest: MessageFns<CreateSwitchRequest> = {
  encode(message: CreateSwitchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.switch !== undefined) {
      Switch.encode(message.switch, writer.uint32(10).fork()).join();
    }
    if (message.switchId !== "") {
      writer.uint32(18).string(message.switchId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateSwitchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSwitchRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.switch = Switch.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.switchId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSwitchRequest {
    return {
      switch: isSet(object.switch) ? Switch.fromJSON(object.switch) : undefined,
      switchId: isSet(object.switchId) ? globalThis.String(object.switchId) : "",
    };
  },

  toJSON(message: CreateSwitchRequest): unknown {
    const obj: any = {};
    if (message.switch !== undefined) {
      obj.switch = Switch.toJSON(message.switch);
    }
    if (message.switchId !== "") {
      obj.switchId = message.switchId;
    }
    return obj;
  },

  create(base?: DeepPartial<CreateSwitchRequest>): CreateSwitchRequest {
    return CreateSwitchRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateSwitchRequest>): CreateSwitchRequest {
    const message = createBaseCreateSwitchRequest() as any;
    message.switch = (object.switch !== undefined && object.switch !== null)
      ? Switch.fromPartial(object.switch)
      : undefined;
    message.switchId = object.switchId ?? "";
    return message;
  },
};

function createBaseUpdateSwitchRequest(): UpdateSwitchRequest {
  return { switch: undefined, updateMask: undefined };
}

export const UpdateSwitchRequest: MessageFns<UpdateSwitchRequest> = {
  encode(message: UpdateSwitchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.switch !== undefined) {
      Switch.encode(message.switch, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateSwitchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSwitchRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.switch = Switch.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSwitchRequest {
    return {
      switch: isSet(object.switch) ? Switch.fromJSON(object.switch) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateSwitchRequest): unknown {
    const obj: any = {};
    if (message.switch !== undefined) {
      obj.switch = Switch.toJSON(message.switch);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateSwitchRequest>): UpdateSwitchRequest {
    return UpdateSwitchRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateSwitchRequest>): UpdateSwitchRequest {
    const message = createBaseUpdateSwitchRequest() as any;
    message.switch = (object.switch !== undefined && object.switch !== null)
      ? Switch.fromPartial(object.switch)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseGetSwitchRequest(): GetSwitchRequest {
  return { name: "" };
}

export const GetSwitchRequest: MessageFns<GetSwitchRequest> = {
  encode(message: GetSwitchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetSwitchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSwitchRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSwitchRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetSwitchRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetSwitchRequest>): GetSwitchRequest {
    return GetSwitchRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetSwitchRequest>): GetSwitchRequest {
    const message = createBaseGetSwitchRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListSwitchesRequest(): ListSwitchesRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListSwitchesRequest: MessageFns<ListSwitchesRequest> = {
  encode(message: ListSwitchesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListSwitchesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSwitchesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListSwitchesRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListSwitchesRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListSwitchesRequest>): ListSwitchesRequest {
    return ListSwitchesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListSwitchesRequest>): ListSwitchesRequest {
    const message = createBaseListSwitchesRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListSwitchesResponse(): ListSwitchesResponse {
  return { switches: [], nextPageToken: "" };
}

export const ListSwitchesResponse: MessageFns<ListSwitchesResponse> = {
  encode(message: ListSwitchesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.switches) {
      Switch.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListSwitchesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSwitchesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.switches.push(Switch.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListSwitchesResponse {
    return {
      switches: globalThis.Array.isArray(object?.switches) ? object.switches.map((e: any) => Switch.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListSwitchesResponse): unknown {
    const obj: any = {};
    if (message.switches?.length) {
      obj.switches = message.switches.map((e) => Switch.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListSwitchesResponse>): ListSwitchesResponse {
    return ListSwitchesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListSwitchesResponse>): ListSwitchesResponse {
    const message = createBaseListSwitchesResponse() as any;
    message.switches = object.switches?.map((e) => Switch.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteSwitchRequest(): DeleteSwitchRequest {
  return { name: "" };
}

export const DeleteSwitchRequest: MessageFns<DeleteSwitchRequest> = {
  encode(message: DeleteSwitchRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteSwitchRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteSwitchRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteSwitchRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteSwitchRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteSwitchRequest>): DeleteSwitchRequest {
    return DeleteSwitchRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteSwitchRequest>): DeleteSwitchRequest {
    const message = createBaseDeleteSwitchRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreateVlanRequest(): CreateVlanRequest {
  return { vlan: undefined, vlanId: "" };
}

export const CreateVlanRequest: MessageFns<CreateVlanRequest> = {
  encode(message: CreateVlanRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.vlan !== undefined) {
      Vlan.encode(message.vlan, writer.uint32(10).fork()).join();
    }
    if (message.vlanId !== "") {
      writer.uint32(18).string(message.vlanId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateVlanRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateVlanRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.vlan = Vlan.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.vlanId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateVlanRequest {
    return {
      vlan: isSet(object.vlan) ? Vlan.fromJSON(object.vlan) : undefined,
      vlanId: isSet(object.vlanId) ? globalThis.String(object.vlanId) : "",
    };
  },

  toJSON(message: CreateVlanRequest): unknown {
    const obj: any = {};
    if (message.vlan !== undefined) {
      obj.vlan = Vlan.toJSON(message.vlan);
    }
    if (message.vlanId !== "") {
      obj.vlanId = message.vlanId;
    }
    return obj;
  },

  create(base?: DeepPartial<CreateVlanRequest>): CreateVlanRequest {
    return CreateVlanRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateVlanRequest>): CreateVlanRequest {
    const message = createBaseCreateVlanRequest() as any;
    message.vlan = (object.vlan !== undefined && object.vlan !== null) ? Vlan.fromPartial(object.vlan) : undefined;
    message.vlanId = object.vlanId ?? "";
    return message;
  },
};

function createBaseUpdateVlanRequest(): UpdateVlanRequest {
  return { vlan: undefined, updateMask: undefined };
}

export const UpdateVlanRequest: MessageFns<UpdateVlanRequest> = {
  encode(message: UpdateVlanRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.vlan !== undefined) {
      Vlan.encode(message.vlan, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateVlanRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateVlanRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.vlan = Vlan.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateVlanRequest {
    return {
      vlan: isSet(object.vlan) ? Vlan.fromJSON(object.vlan) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateVlanRequest): unknown {
    const obj: any = {};
    if (message.vlan !== undefined) {
      obj.vlan = Vlan.toJSON(message.vlan);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateVlanRequest>): UpdateVlanRequest {
    return UpdateVlanRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateVlanRequest>): UpdateVlanRequest {
    const message = createBaseUpdateVlanRequest() as any;
    message.vlan = (object.vlan !== undefined && object.vlan !== null) ? Vlan.fromPartial(object.vlan) : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseGetVlanRequest(): GetVlanRequest {
  return { name: "" };
}

export const GetVlanRequest: MessageFns<GetVlanRequest> = {
  encode(message: GetVlanRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetVlanRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVlanRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVlanRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetVlanRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetVlanRequest>): GetVlanRequest {
    return GetVlanRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetVlanRequest>): GetVlanRequest {
    const message = createBaseGetVlanRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListIPsRequest(): ListIPsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListIPsRequest: MessageFns<ListIPsRequest> = {
  encode(message: ListIPsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListIPsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIPsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIPsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListIPsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListIPsRequest>): ListIPsRequest {
    return ListIPsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListIPsRequest>): ListIPsRequest {
    const message = createBaseListIPsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListVlansRequest(): ListVlansRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListVlansRequest: MessageFns<ListVlansRequest> = {
  encode(message: ListVlansRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListVlansRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListVlansRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListVlansRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListVlansRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListVlansRequest>): ListVlansRequest {
    return ListVlansRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListVlansRequest>): ListVlansRequest {
    const message = createBaseListVlansRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListIPsResponse(): ListIPsResponse {
  return { ips: [], nextPageToken: "" };
}

export const ListIPsResponse: MessageFns<ListIPsResponse> = {
  encode(message: ListIPsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.ips) {
      IP.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListIPsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListIPsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.ips.push(IP.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListIPsResponse {
    return {
      ips: globalThis.Array.isArray(object?.ips) ? object.ips.map((e: any) => IP.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListIPsResponse): unknown {
    const obj: any = {};
    if (message.ips?.length) {
      obj.ips = message.ips.map((e) => IP.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListIPsResponse>): ListIPsResponse {
    return ListIPsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListIPsResponse>): ListIPsResponse {
    const message = createBaseListIPsResponse() as any;
    message.ips = object.ips?.map((e) => IP.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseListVlansResponse(): ListVlansResponse {
  return { vlans: [], nextPageToken: "" };
}

export const ListVlansResponse: MessageFns<ListVlansResponse> = {
  encode(message: ListVlansResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.vlans) {
      Vlan.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListVlansResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListVlansResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.vlans.push(Vlan.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListVlansResponse {
    return {
      vlans: globalThis.Array.isArray(object?.vlans) ? object.vlans.map((e: any) => Vlan.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListVlansResponse): unknown {
    const obj: any = {};
    if (message.vlans?.length) {
      obj.vlans = message.vlans.map((e) => Vlan.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListVlansResponse>): ListVlansResponse {
    return ListVlansResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListVlansResponse>): ListVlansResponse {
    const message = createBaseListVlansResponse() as any;
    message.vlans = object.vlans?.map((e) => Vlan.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteVlanRequest(): DeleteVlanRequest {
  return { name: "" };
}

export const DeleteVlanRequest: MessageFns<DeleteVlanRequest> = {
  encode(message: DeleteVlanRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteVlanRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteVlanRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteVlanRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteVlanRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteVlanRequest>): DeleteVlanRequest {
    return DeleteVlanRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteVlanRequest>): DeleteVlanRequest {
    const message = createBaseDeleteVlanRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseImportOSVlansRequest(): ImportOSVlansRequest {
  return { machineDbSource: undefined, configSource: undefined };
}

export const ImportOSVlansRequest: MessageFns<ImportOSVlansRequest> = {
  encode(message: ImportOSVlansRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.machineDbSource !== undefined) {
      MachineDBSource.encode(message.machineDbSource, writer.uint32(10).fork()).join();
    }
    if (message.configSource !== undefined) {
      ConfigSource.encode(message.configSource, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ImportOSVlansRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImportOSVlansRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineDbSource = MachineDBSource.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.configSource = ConfigSource.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ImportOSVlansRequest {
    return {
      machineDbSource: isSet(object.machineDbSource) ? MachineDBSource.fromJSON(object.machineDbSource) : undefined,
      configSource: isSet(object.configSource) ? ConfigSource.fromJSON(object.configSource) : undefined,
    };
  },

  toJSON(message: ImportOSVlansRequest): unknown {
    const obj: any = {};
    if (message.machineDbSource !== undefined) {
      obj.machineDbSource = MachineDBSource.toJSON(message.machineDbSource);
    }
    if (message.configSource !== undefined) {
      obj.configSource = ConfigSource.toJSON(message.configSource);
    }
    return obj;
  },

  create(base?: DeepPartial<ImportOSVlansRequest>): ImportOSVlansRequest {
    return ImportOSVlansRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ImportOSVlansRequest>): ImportOSVlansRequest {
    const message = createBaseImportOSVlansRequest() as any;
    message.machineDbSource = (object.machineDbSource !== undefined && object.machineDbSource !== null)
      ? MachineDBSource.fromPartial(object.machineDbSource)
      : undefined;
    message.configSource = (object.configSource !== undefined && object.configSource !== null)
      ? ConfigSource.fromPartial(object.configSource)
      : undefined;
    return message;
  },
};

function createBaseGetStateRequest(): GetStateRequest {
  return { resourceName: "" };
}

export const GetStateRequest: MessageFns<GetStateRequest> = {
  encode(message: GetStateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.resourceName !== "") {
      writer.uint32(10).string(message.resourceName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetStateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStateRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.resourceName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStateRequest {
    return { resourceName: isSet(object.resourceName) ? globalThis.String(object.resourceName) : "" };
  },

  toJSON(message: GetStateRequest): unknown {
    const obj: any = {};
    if (message.resourceName !== "") {
      obj.resourceName = message.resourceName;
    }
    return obj;
  },

  create(base?: DeepPartial<GetStateRequest>): GetStateRequest {
    return GetStateRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetStateRequest>): GetStateRequest {
    const message = createBaseGetStateRequest() as any;
    message.resourceName = object.resourceName ?? "";
    return message;
  },
};

function createBaseGetDutStateRequest(): GetDutStateRequest {
  return { chromeosDeviceId: "", hostname: "" };
}

export const GetDutStateRequest: MessageFns<GetDutStateRequest> = {
  encode(message: GetDutStateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.chromeosDeviceId !== "") {
      writer.uint32(10).string(message.chromeosDeviceId);
    }
    if (message.hostname !== "") {
      writer.uint32(18).string(message.hostname);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDutStateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDutStateRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.chromeosDeviceId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDutStateRequest {
    return {
      chromeosDeviceId: isSet(object.chromeosDeviceId) ? globalThis.String(object.chromeosDeviceId) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
    };
  },

  toJSON(message: GetDutStateRequest): unknown {
    const obj: any = {};
    if (message.chromeosDeviceId !== "") {
      obj.chromeosDeviceId = message.chromeosDeviceId;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    return obj;
  },

  create(base?: DeepPartial<GetDutStateRequest>): GetDutStateRequest {
    return GetDutStateRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetDutStateRequest>): GetDutStateRequest {
    const message = createBaseGetDutStateRequest() as any;
    message.chromeosDeviceId = object.chromeosDeviceId ?? "";
    message.hostname = object.hostname ?? "";
    return message;
  },
};

function createBaseListDutStatesRequest(): ListDutStatesRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListDutStatesRequest: MessageFns<ListDutStatesRequest> = {
  encode(message: ListDutStatesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListDutStatesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDutStatesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDutStatesRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListDutStatesRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListDutStatesRequest>): ListDutStatesRequest {
    return ListDutStatesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDutStatesRequest>): ListDutStatesRequest {
    const message = createBaseListDutStatesRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListDutStatesResponse(): ListDutStatesResponse {
  return { dutStates: [], nextPageToken: "" };
}

export const ListDutStatesResponse: MessageFns<ListDutStatesResponse> = {
  encode(message: ListDutStatesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.dutStates) {
      DutState.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListDutStatesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDutStatesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dutStates.push(DutState.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDutStatesResponse {
    return {
      dutStates: globalThis.Array.isArray(object?.dutStates)
        ? object.dutStates.map((e: any) => DutState.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListDutStatesResponse): unknown {
    const obj: any = {};
    if (message.dutStates?.length) {
      obj.dutStates = message.dutStates.map((e) => DutState.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListDutStatesResponse>): ListDutStatesResponse {
    return ListDutStatesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDutStatesResponse>): ListDutStatesResponse {
    const message = createBaseListDutStatesResponse() as any;
    message.dutStates = object.dutStates?.map((e) => DutState.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseUpdateStateRequest(): UpdateStateRequest {
  return { state: undefined, updateMask: undefined };
}

export const UpdateStateRequest: MessageFns<UpdateStateRequest> = {
  encode(message: UpdateStateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.state !== undefined) {
      StateRecord.encode(message.state, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateStateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateStateRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.state = StateRecord.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateStateRequest {
    return {
      state: isSet(object.state) ? StateRecord.fromJSON(object.state) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateStateRequest): unknown {
    const obj: any = {};
    if (message.state !== undefined) {
      obj.state = StateRecord.toJSON(message.state);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateStateRequest>): UpdateStateRequest {
    return UpdateStateRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateStateRequest>): UpdateStateRequest {
    const message = createBaseUpdateStateRequest() as any;
    message.state = (object.state !== undefined && object.state !== null)
      ? StateRecord.fromPartial(object.state)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseUpdateDutStateRequest(): UpdateDutStateRequest {
  return { dutState: undefined, updateMask: undefined, dutMeta: undefined, labMeta: undefined };
}

export const UpdateDutStateRequest: MessageFns<UpdateDutStateRequest> = {
  encode(message: UpdateDutStateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.dutState !== undefined) {
      DutState.encode(message.dutState, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    if (message.dutMeta !== undefined) {
      DutMeta.encode(message.dutMeta, writer.uint32(26).fork()).join();
    }
    if (message.labMeta !== undefined) {
      LabMeta.encode(message.labMeta, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateDutStateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDutStateRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dutState = DutState.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.dutMeta = DutMeta.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.labMeta = LabMeta.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateDutStateRequest {
    return {
      dutState: isSet(object.dutState) ? DutState.fromJSON(object.dutState) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
      dutMeta: isSet(object.dutMeta) ? DutMeta.fromJSON(object.dutMeta) : undefined,
      labMeta: isSet(object.labMeta) ? LabMeta.fromJSON(object.labMeta) : undefined,
    };
  },

  toJSON(message: UpdateDutStateRequest): unknown {
    const obj: any = {};
    if (message.dutState !== undefined) {
      obj.dutState = DutState.toJSON(message.dutState);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    if (message.dutMeta !== undefined) {
      obj.dutMeta = DutMeta.toJSON(message.dutMeta);
    }
    if (message.labMeta !== undefined) {
      obj.labMeta = LabMeta.toJSON(message.labMeta);
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateDutStateRequest>): UpdateDutStateRequest {
    return UpdateDutStateRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateDutStateRequest>): UpdateDutStateRequest {
    const message = createBaseUpdateDutStateRequest() as any;
    message.dutState = (object.dutState !== undefined && object.dutState !== null)
      ? DutState.fromPartial(object.dutState)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    message.dutMeta = (object.dutMeta !== undefined && object.dutMeta !== null)
      ? DutMeta.fromPartial(object.dutMeta)
      : undefined;
    message.labMeta = (object.labMeta !== undefined && object.labMeta !== null)
      ? LabMeta.fromPartial(object.labMeta)
      : undefined;
    return message;
  },
};

function createBaseChromeOsRecoveryData(): ChromeOsRecoveryData {
  return { dutState: undefined, dutData: undefined, labData: undefined };
}

export const ChromeOsRecoveryData: MessageFns<ChromeOsRecoveryData> = {
  encode(message: ChromeOsRecoveryData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.dutState !== undefined) {
      DutState.encode(message.dutState, writer.uint32(10).fork()).join();
    }
    if (message.dutData !== undefined) {
      ChromeOsRecoveryData_DutData.encode(message.dutData, writer.uint32(18).fork()).join();
    }
    if (message.labData !== undefined) {
      ChromeOsRecoveryData_LabData.encode(message.labData, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOsRecoveryData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOsRecoveryData() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dutState = DutState.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.dutData = ChromeOsRecoveryData_DutData.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.labData = ChromeOsRecoveryData_LabData.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOsRecoveryData {
    return {
      dutState: isSet(object.dutState) ? DutState.fromJSON(object.dutState) : undefined,
      dutData: isSet(object.dutData) ? ChromeOsRecoveryData_DutData.fromJSON(object.dutData) : undefined,
      labData: isSet(object.labData) ? ChromeOsRecoveryData_LabData.fromJSON(object.labData) : undefined,
    };
  },

  toJSON(message: ChromeOsRecoveryData): unknown {
    const obj: any = {};
    if (message.dutState !== undefined) {
      obj.dutState = DutState.toJSON(message.dutState);
    }
    if (message.dutData !== undefined) {
      obj.dutData = ChromeOsRecoveryData_DutData.toJSON(message.dutData);
    }
    if (message.labData !== undefined) {
      obj.labData = ChromeOsRecoveryData_LabData.toJSON(message.labData);
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOsRecoveryData>): ChromeOsRecoveryData {
    return ChromeOsRecoveryData.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOsRecoveryData>): ChromeOsRecoveryData {
    const message = createBaseChromeOsRecoveryData() as any;
    message.dutState = (object.dutState !== undefined && object.dutState !== null)
      ? DutState.fromPartial(object.dutState)
      : undefined;
    message.dutData = (object.dutData !== undefined && object.dutData !== null)
      ? ChromeOsRecoveryData_DutData.fromPartial(object.dutData)
      : undefined;
    message.labData = (object.labData !== undefined && object.labData !== null)
      ? ChromeOsRecoveryData_LabData.fromPartial(object.labData)
      : undefined;
    return message;
  },
};

function createBaseChromeOsRecoveryData_DutData(): ChromeOsRecoveryData_DutData {
  return { serialNumber: "", hwID: "", deviceSku: "", dlmSkuId: "" };
}

export const ChromeOsRecoveryData_DutData: MessageFns<ChromeOsRecoveryData_DutData> = {
  encode(message: ChromeOsRecoveryData_DutData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    if (message.hwID !== "") {
      writer.uint32(18).string(message.hwID);
    }
    if (message.deviceSku !== "") {
      writer.uint32(26).string(message.deviceSku);
    }
    if (message.dlmSkuId !== "") {
      writer.uint32(34).string(message.dlmSkuId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOsRecoveryData_DutData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOsRecoveryData_DutData() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hwID = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.deviceSku = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.dlmSkuId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOsRecoveryData_DutData {
    return {
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      hwID: isSet(object.hwID) ? globalThis.String(object.hwID) : "",
      deviceSku: isSet(object.deviceSku) ? globalThis.String(object.deviceSku) : "",
      dlmSkuId: isSet(object.dlmSkuId) ? globalThis.String(object.dlmSkuId) : "",
    };
  },

  toJSON(message: ChromeOsRecoveryData_DutData): unknown {
    const obj: any = {};
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.hwID !== "") {
      obj.hwID = message.hwID;
    }
    if (message.deviceSku !== "") {
      obj.deviceSku = message.deviceSku;
    }
    if (message.dlmSkuId !== "") {
      obj.dlmSkuId = message.dlmSkuId;
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOsRecoveryData_DutData>): ChromeOsRecoveryData_DutData {
    return ChromeOsRecoveryData_DutData.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOsRecoveryData_DutData>): ChromeOsRecoveryData_DutData {
    const message = createBaseChromeOsRecoveryData_DutData() as any;
    message.serialNumber = object.serialNumber ?? "";
    message.hwID = object.hwID ?? "";
    message.deviceSku = object.deviceSku ?? "";
    message.dlmSkuId = object.dlmSkuId ?? "";
    return message;
  },
};

function createBaseChromeOsRecoveryData_WifiRouter(): ChromeOsRecoveryData_WifiRouter {
  return { hostname: "", state: 0, model: "", supportedFeatures: [], deviceType: 0 };
}

export const ChromeOsRecoveryData_WifiRouter: MessageFns<ChromeOsRecoveryData_WifiRouter> = {
  encode(message: ChromeOsRecoveryData_WifiRouter, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    if (message.model !== "") {
      writer.uint32(26).string(message.model);
    }
    writer.uint32(34).fork();
    for (const v of message.supportedFeatures) {
      writer.int32(v);
    }
    writer.join();
    if (message.deviceType !== 0) {
      writer.uint32(40).int32(message.deviceType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOsRecoveryData_WifiRouter {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOsRecoveryData_WifiRouter() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.model = reader.string();
          continue;
        }
        case 4: {
          if (tag === 32) {
            message.supportedFeatures.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.supportedFeatures.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.deviceType = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOsRecoveryData_WifiRouter {
    return {
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      state: isSet(object.state) ? peripheralStateFromJSON(object.state) : 0,
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      supportedFeatures: globalThis.Array.isArray(object?.supportedFeatures)
        ? object.supportedFeatures.map((e: any) => wifiRouterFeatureFromJSON(e))
        : [],
      deviceType: isSet(object.deviceType) ? wifiRouterDeviceTypeFromJSON(object.deviceType) : 0,
    };
  },

  toJSON(message: ChromeOsRecoveryData_WifiRouter): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.state !== 0) {
      obj.state = peripheralStateToJSON(message.state);
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.supportedFeatures?.length) {
      obj.supportedFeatures = message.supportedFeatures.map((e) => wifiRouterFeatureToJSON(e));
    }
    if (message.deviceType !== 0) {
      obj.deviceType = wifiRouterDeviceTypeToJSON(message.deviceType);
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOsRecoveryData_WifiRouter>): ChromeOsRecoveryData_WifiRouter {
    return ChromeOsRecoveryData_WifiRouter.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOsRecoveryData_WifiRouter>): ChromeOsRecoveryData_WifiRouter {
    const message = createBaseChromeOsRecoveryData_WifiRouter() as any;
    message.hostname = object.hostname ?? "";
    message.state = object.state ?? 0;
    message.model = object.model ?? "";
    message.supportedFeatures = object.supportedFeatures?.map((e) => e) || [];
    message.deviceType = object.deviceType ?? 0;
    return message;
  },
};

function createBaseChromeOsRecoveryData_BluetoothPeer(): ChromeOsRecoveryData_BluetoothPeer {
  return { hostname: "", state: 0 };
}

export const ChromeOsRecoveryData_BluetoothPeer: MessageFns<ChromeOsRecoveryData_BluetoothPeer> = {
  encode(message: ChromeOsRecoveryData_BluetoothPeer, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOsRecoveryData_BluetoothPeer {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOsRecoveryData_BluetoothPeer() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOsRecoveryData_BluetoothPeer {
    return {
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      state: isSet(object.state) ? peripheralStateFromJSON(object.state) : 0,
    };
  },

  toJSON(message: ChromeOsRecoveryData_BluetoothPeer): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.state !== 0) {
      obj.state = peripheralStateToJSON(message.state);
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOsRecoveryData_BluetoothPeer>): ChromeOsRecoveryData_BluetoothPeer {
    return ChromeOsRecoveryData_BluetoothPeer.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOsRecoveryData_BluetoothPeer>): ChromeOsRecoveryData_BluetoothPeer {
    const message = createBaseChromeOsRecoveryData_BluetoothPeer() as any;
    message.hostname = object.hostname ?? "";
    message.state = object.state ?? 0;
    return message;
  },
};

function createBaseChromeOsRecoveryData_Dolos(): ChromeOsRecoveryData_Dolos {
  return { serialUsb: "", fwVersion: "" };
}

export const ChromeOsRecoveryData_Dolos: MessageFns<ChromeOsRecoveryData_Dolos> = {
  encode(message: ChromeOsRecoveryData_Dolos, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.serialUsb !== "") {
      writer.uint32(10).string(message.serialUsb);
    }
    if (message.fwVersion !== "") {
      writer.uint32(18).string(message.fwVersion);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOsRecoveryData_Dolos {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOsRecoveryData_Dolos() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.serialUsb = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.fwVersion = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOsRecoveryData_Dolos {
    return {
      serialUsb: isSet(object.serialUsb) ? globalThis.String(object.serialUsb) : "",
      fwVersion: isSet(object.fwVersion) ? globalThis.String(object.fwVersion) : "",
    };
  },

  toJSON(message: ChromeOsRecoveryData_Dolos): unknown {
    const obj: any = {};
    if (message.serialUsb !== "") {
      obj.serialUsb = message.serialUsb;
    }
    if (message.fwVersion !== "") {
      obj.fwVersion = message.fwVersion;
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOsRecoveryData_Dolos>): ChromeOsRecoveryData_Dolos {
    return ChromeOsRecoveryData_Dolos.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOsRecoveryData_Dolos>): ChromeOsRecoveryData_Dolos {
    const message = createBaseChromeOsRecoveryData_Dolos() as any;
    message.serialUsb = object.serialUsb ?? "";
    message.fwVersion = object.fwVersion ?? "";
    return message;
  },
};

function createBaseChromeOsRecoveryData_ModemInfo(): ChromeOsRecoveryData_ModemInfo {
  return { modelVariant: "", type: 0, imei: "" };
}

export const ChromeOsRecoveryData_ModemInfo: MessageFns<ChromeOsRecoveryData_ModemInfo> = {
  encode(message: ChromeOsRecoveryData_ModemInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.modelVariant !== "") {
      writer.uint32(10).string(message.modelVariant);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.imei !== "") {
      writer.uint32(26).string(message.imei);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOsRecoveryData_ModemInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOsRecoveryData_ModemInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.modelVariant = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.imei = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOsRecoveryData_ModemInfo {
    return {
      modelVariant: isSet(object.modelVariant) ? globalThis.String(object.modelVariant) : "",
      type: isSet(object.type) ? modemTypeFromJSON(object.type) : 0,
      imei: isSet(object.imei) ? globalThis.String(object.imei) : "",
    };
  },

  toJSON(message: ChromeOsRecoveryData_ModemInfo): unknown {
    const obj: any = {};
    if (message.modelVariant !== "") {
      obj.modelVariant = message.modelVariant;
    }
    if (message.type !== 0) {
      obj.type = modemTypeToJSON(message.type);
    }
    if (message.imei !== "") {
      obj.imei = message.imei;
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOsRecoveryData_ModemInfo>): ChromeOsRecoveryData_ModemInfo {
    return ChromeOsRecoveryData_ModemInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOsRecoveryData_ModemInfo>): ChromeOsRecoveryData_ModemInfo {
    const message = createBaseChromeOsRecoveryData_ModemInfo() as any;
    message.modelVariant = object.modelVariant ?? "";
    message.type = object.type ?? 0;
    message.imei = object.imei ?? "";
    return message;
  },
};

function createBaseChromeOsRecoveryData_LabData(): ChromeOsRecoveryData_LabData {
  return {
    servoType: "",
    smartUsbhub: false,
    servoTopology: undefined,
    servoComponent: "",
    wifiRouters: [],
    bluetoothPeers: [],
    servoUsbDrive: undefined,
    roVpdMap: {},
    cbi: undefined,
    audioboxJackpluggerState: 0,
    wifiRouterFeatures: [],
    modemInfo: undefined,
    simInfos: [],
    dolos: undefined,
    supportedCarriers: [],
  };
}

export const ChromeOsRecoveryData_LabData: MessageFns<ChromeOsRecoveryData_LabData> = {
  encode(message: ChromeOsRecoveryData_LabData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.servoType !== "") {
      writer.uint32(10).string(message.servoType);
    }
    if (message.smartUsbhub !== false) {
      writer.uint32(16).bool(message.smartUsbhub);
    }
    if (message.servoTopology !== undefined) {
      ServoTopology.encode(message.servoTopology, writer.uint32(26).fork()).join();
    }
    if (message.servoComponent !== "") {
      writer.uint32(34).string(message.servoComponent);
    }
    for (const v of message.wifiRouters) {
      ChromeOsRecoveryData_WifiRouter.encode(v!, writer.uint32(42).fork()).join();
    }
    for (const v of message.bluetoothPeers) {
      ChromeOsRecoveryData_BluetoothPeer.encode(v!, writer.uint32(50).fork()).join();
    }
    if (message.servoUsbDrive !== undefined) {
      UsbDrive.encode(message.servoUsbDrive, writer.uint32(58).fork()).join();
    }
    Object.entries(message.roVpdMap).forEach(([key, value]) => {
      ChromeOsRecoveryData_LabData_RoVpdMapEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).join();
    });
    if (message.cbi !== undefined) {
      Cbi.encode(message.cbi, writer.uint32(74).fork()).join();
    }
    if (message.audioboxJackpluggerState !== 0) {
      writer.uint32(80).int32(message.audioboxJackpluggerState);
    }
    writer.uint32(90).fork();
    for (const v of message.wifiRouterFeatures) {
      writer.int32(v);
    }
    writer.join();
    if (message.modemInfo !== undefined) {
      ChromeOsRecoveryData_ModemInfo.encode(message.modemInfo, writer.uint32(98).fork()).join();
    }
    for (const v of message.simInfos) {
      SIMInfo.encode(v!, writer.uint32(106).fork()).join();
    }
    if (message.dolos !== undefined) {
      ChromeOsRecoveryData_Dolos.encode(message.dolos, writer.uint32(114).fork()).join();
    }
    for (const v of message.supportedCarriers) {
      writer.uint32(122).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOsRecoveryData_LabData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOsRecoveryData_LabData() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.servoType = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.smartUsbhub = reader.bool();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.servoTopology = ServoTopology.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.servoComponent = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.wifiRouters.push(ChromeOsRecoveryData_WifiRouter.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.bluetoothPeers.push(ChromeOsRecoveryData_BluetoothPeer.decode(reader, reader.uint32()));
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.servoUsbDrive = UsbDrive.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          const entry8 = ChromeOsRecoveryData_LabData_RoVpdMapEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.roVpdMap[entry8.key] = entry8.value;
          }
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.cbi = Cbi.decode(reader, reader.uint32());
          continue;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.audioboxJackpluggerState = reader.int32() as any;
          continue;
        }
        case 11: {
          if (tag === 88) {
            message.wifiRouterFeatures.push(reader.int32() as any);

            continue;
          }

          if (tag === 90) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.wifiRouterFeatures.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.modemInfo = ChromeOsRecoveryData_ModemInfo.decode(reader, reader.uint32());
          continue;
        }
        case 13: {
          if (tag !== 106) {
            break;
          }

          message.simInfos.push(SIMInfo.decode(reader, reader.uint32()));
          continue;
        }
        case 14: {
          if (tag !== 114) {
            break;
          }

          message.dolos = ChromeOsRecoveryData_Dolos.decode(reader, reader.uint32());
          continue;
        }
        case 15: {
          if (tag !== 122) {
            break;
          }

          message.supportedCarriers.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOsRecoveryData_LabData {
    return {
      servoType: isSet(object.servoType) ? globalThis.String(object.servoType) : "",
      smartUsbhub: isSet(object.smartUsbhub) ? globalThis.Boolean(object.smartUsbhub) : false,
      servoTopology: isSet(object.servoTopology) ? ServoTopology.fromJSON(object.servoTopology) : undefined,
      servoComponent: isSet(object.servoComponent) ? globalThis.String(object.servoComponent) : "",
      wifiRouters: globalThis.Array.isArray(object?.wifiRouters)
        ? object.wifiRouters.map((e: any) => ChromeOsRecoveryData_WifiRouter.fromJSON(e))
        : [],
      bluetoothPeers: globalThis.Array.isArray(object?.bluetoothPeers)
        ? object.bluetoothPeers.map((e: any) => ChromeOsRecoveryData_BluetoothPeer.fromJSON(e))
        : [],
      servoUsbDrive: isSet(object.servoUsbDrive) ? UsbDrive.fromJSON(object.servoUsbDrive) : undefined,
      roVpdMap: isObject(object.roVpdMap)
        ? Object.entries(object.roVpdMap).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      cbi: isSet(object.cbi) ? Cbi.fromJSON(object.cbi) : undefined,
      audioboxJackpluggerState: isSet(object.audioboxJackpluggerState)
        ? chameleon_AudioBoxJackPluggerFromJSON(object.audioboxJackpluggerState)
        : 0,
      wifiRouterFeatures: globalThis.Array.isArray(object?.wifiRouterFeatures)
        ? object.wifiRouterFeatures.map((e: any) => wifiRouterFeatureFromJSON(e))
        : [],
      modemInfo: isSet(object.modemInfo) ? ChromeOsRecoveryData_ModemInfo.fromJSON(object.modemInfo) : undefined,
      simInfos: globalThis.Array.isArray(object?.simInfos) ? object.simInfos.map((e: any) => SIMInfo.fromJSON(e)) : [],
      dolos: isSet(object.dolos) ? ChromeOsRecoveryData_Dolos.fromJSON(object.dolos) : undefined,
      supportedCarriers: globalThis.Array.isArray(object?.supportedCarriers)
        ? object.supportedCarriers.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ChromeOsRecoveryData_LabData): unknown {
    const obj: any = {};
    if (message.servoType !== "") {
      obj.servoType = message.servoType;
    }
    if (message.smartUsbhub !== false) {
      obj.smartUsbhub = message.smartUsbhub;
    }
    if (message.servoTopology !== undefined) {
      obj.servoTopology = ServoTopology.toJSON(message.servoTopology);
    }
    if (message.servoComponent !== "") {
      obj.servoComponent = message.servoComponent;
    }
    if (message.wifiRouters?.length) {
      obj.wifiRouters = message.wifiRouters.map((e) => ChromeOsRecoveryData_WifiRouter.toJSON(e));
    }
    if (message.bluetoothPeers?.length) {
      obj.bluetoothPeers = message.bluetoothPeers.map((e) => ChromeOsRecoveryData_BluetoothPeer.toJSON(e));
    }
    if (message.servoUsbDrive !== undefined) {
      obj.servoUsbDrive = UsbDrive.toJSON(message.servoUsbDrive);
    }
    if (message.roVpdMap) {
      const entries = Object.entries(message.roVpdMap);
      if (entries.length > 0) {
        obj.roVpdMap = {};
        entries.forEach(([k, v]) => {
          obj.roVpdMap[k] = v;
        });
      }
    }
    if (message.cbi !== undefined) {
      obj.cbi = Cbi.toJSON(message.cbi);
    }
    if (message.audioboxJackpluggerState !== 0) {
      obj.audioboxJackpluggerState = chameleon_AudioBoxJackPluggerToJSON(message.audioboxJackpluggerState);
    }
    if (message.wifiRouterFeatures?.length) {
      obj.wifiRouterFeatures = message.wifiRouterFeatures.map((e) => wifiRouterFeatureToJSON(e));
    }
    if (message.modemInfo !== undefined) {
      obj.modemInfo = ChromeOsRecoveryData_ModemInfo.toJSON(message.modemInfo);
    }
    if (message.simInfos?.length) {
      obj.simInfos = message.simInfos.map((e) => SIMInfo.toJSON(e));
    }
    if (message.dolos !== undefined) {
      obj.dolos = ChromeOsRecoveryData_Dolos.toJSON(message.dolos);
    }
    if (message.supportedCarriers?.length) {
      obj.supportedCarriers = message.supportedCarriers;
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOsRecoveryData_LabData>): ChromeOsRecoveryData_LabData {
    return ChromeOsRecoveryData_LabData.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOsRecoveryData_LabData>): ChromeOsRecoveryData_LabData {
    const message = createBaseChromeOsRecoveryData_LabData() as any;
    message.servoType = object.servoType ?? "";
    message.smartUsbhub = object.smartUsbhub ?? false;
    message.servoTopology = (object.servoTopology !== undefined && object.servoTopology !== null)
      ? ServoTopology.fromPartial(object.servoTopology)
      : undefined;
    message.servoComponent = object.servoComponent ?? "";
    message.wifiRouters = object.wifiRouters?.map((e) => ChromeOsRecoveryData_WifiRouter.fromPartial(e)) || [];
    message.bluetoothPeers = object.bluetoothPeers?.map((e) => ChromeOsRecoveryData_BluetoothPeer.fromPartial(e)) || [];
    message.servoUsbDrive = (object.servoUsbDrive !== undefined && object.servoUsbDrive !== null)
      ? UsbDrive.fromPartial(object.servoUsbDrive)
      : undefined;
    message.roVpdMap = Object.entries(object.roVpdMap ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.cbi = (object.cbi !== undefined && object.cbi !== null) ? Cbi.fromPartial(object.cbi) : undefined;
    message.audioboxJackpluggerState = object.audioboxJackpluggerState ?? 0;
    message.wifiRouterFeatures = object.wifiRouterFeatures?.map((e) => e) || [];
    message.modemInfo = (object.modemInfo !== undefined && object.modemInfo !== null)
      ? ChromeOsRecoveryData_ModemInfo.fromPartial(object.modemInfo)
      : undefined;
    message.simInfos = object.simInfos?.map((e) => SIMInfo.fromPartial(e)) || [];
    message.dolos = (object.dolos !== undefined && object.dolos !== null)
      ? ChromeOsRecoveryData_Dolos.fromPartial(object.dolos)
      : undefined;
    message.supportedCarriers = object.supportedCarriers?.map((e) => e) || [];
    return message;
  },
};

function createBaseChromeOsRecoveryData_LabData_RoVpdMapEntry(): ChromeOsRecoveryData_LabData_RoVpdMapEntry {
  return { key: "", value: "" };
}

export const ChromeOsRecoveryData_LabData_RoVpdMapEntry: MessageFns<ChromeOsRecoveryData_LabData_RoVpdMapEntry> = {
  encode(message: ChromeOsRecoveryData_LabData_RoVpdMapEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOsRecoveryData_LabData_RoVpdMapEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOsRecoveryData_LabData_RoVpdMapEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOsRecoveryData_LabData_RoVpdMapEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ChromeOsRecoveryData_LabData_RoVpdMapEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOsRecoveryData_LabData_RoVpdMapEntry>): ChromeOsRecoveryData_LabData_RoVpdMapEntry {
    return ChromeOsRecoveryData_LabData_RoVpdMapEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ChromeOsRecoveryData_LabData_RoVpdMapEntry>,
  ): ChromeOsRecoveryData_LabData_RoVpdMapEntry {
    const message = createBaseChromeOsRecoveryData_LabData_RoVpdMapEntry() as any;
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseUpdateDeviceRecoveryDataRequest(): UpdateDeviceRecoveryDataRequest {
  return { deviceId: "", resourceType: 0, hostname: "", resourceState: 0, chromeos: undefined };
}

export const UpdateDeviceRecoveryDataRequest: MessageFns<UpdateDeviceRecoveryDataRequest> = {
  encode(message: UpdateDeviceRecoveryDataRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.deviceId !== "") {
      writer.uint32(58).string(message.deviceId);
    }
    if (message.resourceType !== 0) {
      writer.uint32(64).int32(message.resourceType);
    }
    if (message.hostname !== "") {
      writer.uint32(18).string(message.hostname);
    }
    if (message.resourceState !== 0) {
      writer.uint32(24).int32(message.resourceState);
    }
    if (message.chromeos !== undefined) {
      ChromeOsRecoveryData.encode(message.chromeos, writer.uint32(74).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateDeviceRecoveryDataRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDeviceRecoveryDataRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.resourceType = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.resourceState = reader.int32() as any;
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.chromeos = ChromeOsRecoveryData.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateDeviceRecoveryDataRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      resourceType: isSet(object.resourceType)
        ? updateDeviceRecoveryDataRequest_ResourceTypeFromJSON(object.resourceType)
        : 0,
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      resourceState: isSet(object.resourceState) ? stateFromJSON(object.resourceState) : 0,
      chromeos: isSet(object.chromeos) ? ChromeOsRecoveryData.fromJSON(object.chromeos) : undefined,
    };
  },

  toJSON(message: UpdateDeviceRecoveryDataRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.resourceType !== 0) {
      obj.resourceType = updateDeviceRecoveryDataRequest_ResourceTypeToJSON(message.resourceType);
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.resourceState !== 0) {
      obj.resourceState = stateToJSON(message.resourceState);
    }
    if (message.chromeos !== undefined) {
      obj.chromeos = ChromeOsRecoveryData.toJSON(message.chromeos);
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateDeviceRecoveryDataRequest>): UpdateDeviceRecoveryDataRequest {
    return UpdateDeviceRecoveryDataRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateDeviceRecoveryDataRequest>): UpdateDeviceRecoveryDataRequest {
    const message = createBaseUpdateDeviceRecoveryDataRequest() as any;
    message.deviceId = object.deviceId ?? "";
    message.resourceType = object.resourceType ?? 0;
    message.hostname = object.hostname ?? "";
    message.resourceState = object.resourceState ?? 0;
    message.chromeos = (object.chromeos !== undefined && object.chromeos !== null)
      ? ChromeOsRecoveryData.fromPartial(object.chromeos)
      : undefined;
    return message;
  },
};

function createBaseUpdateDeviceRecoveryDataResponse(): UpdateDeviceRecoveryDataResponse {
  return {};
}

export const UpdateDeviceRecoveryDataResponse: MessageFns<UpdateDeviceRecoveryDataResponse> = {
  encode(_: UpdateDeviceRecoveryDataResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateDeviceRecoveryDataResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDeviceRecoveryDataResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateDeviceRecoveryDataResponse {
    return {};
  },

  toJSON(_: UpdateDeviceRecoveryDataResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<UpdateDeviceRecoveryDataResponse>): UpdateDeviceRecoveryDataResponse {
    return UpdateDeviceRecoveryDataResponse.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateDeviceRecoveryDataResponse>): UpdateDeviceRecoveryDataResponse {
    const message = createBaseUpdateDeviceRecoveryDataResponse() as any;
    return message;
  },
};

function createBaseUpdateTestDataRequest(): UpdateTestDataRequest {
  return {
    deviceId: "",
    hostname: "",
    resourceState: 0,
    chromeosData: undefined,
    androidData: undefined,
    updateMask: undefined,
  };
}

export const UpdateTestDataRequest: MessageFns<UpdateTestDataRequest> = {
  encode(message: UpdateTestDataRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.hostname !== "") {
      writer.uint32(18).string(message.hostname);
    }
    if (message.resourceState !== 0) {
      writer.uint32(24).int32(message.resourceState);
    }
    if (message.chromeosData !== undefined) {
      UpdateTestDataRequest_ChromeOs.encode(message.chromeosData, writer.uint32(34).fork()).join();
    }
    if (message.androidData !== undefined) {
      UpdateTestDataRequest_Android.encode(message.androidData, writer.uint32(42).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateTestDataRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTestDataRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.resourceState = reader.int32() as any;
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.chromeosData = UpdateTestDataRequest_ChromeOs.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.androidData = UpdateTestDataRequest_Android.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTestDataRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      resourceState: isSet(object.resourceState) ? stateFromJSON(object.resourceState) : 0,
      chromeosData: isSet(object.chromeosData)
        ? UpdateTestDataRequest_ChromeOs.fromJSON(object.chromeosData)
        : undefined,
      androidData: isSet(object.androidData) ? UpdateTestDataRequest_Android.fromJSON(object.androidData) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateTestDataRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.resourceState !== 0) {
      obj.resourceState = stateToJSON(message.resourceState);
    }
    if (message.chromeosData !== undefined) {
      obj.chromeosData = UpdateTestDataRequest_ChromeOs.toJSON(message.chromeosData);
    }
    if (message.androidData !== undefined) {
      obj.androidData = UpdateTestDataRequest_Android.toJSON(message.androidData);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateTestDataRequest>): UpdateTestDataRequest {
    return UpdateTestDataRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateTestDataRequest>): UpdateTestDataRequest {
    const message = createBaseUpdateTestDataRequest() as any;
    message.deviceId = object.deviceId ?? "";
    message.hostname = object.hostname ?? "";
    message.resourceState = object.resourceState ?? 0;
    message.chromeosData = (object.chromeosData !== undefined && object.chromeosData !== null)
      ? UpdateTestDataRequest_ChromeOs.fromPartial(object.chromeosData)
      : undefined;
    message.androidData = (object.androidData !== undefined && object.androidData !== null)
      ? UpdateTestDataRequest_Android.fromPartial(object.androidData)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseUpdateTestDataRequest_ChromeOs(): UpdateTestDataRequest_ChromeOs {
  return { dutState: undefined };
}

export const UpdateTestDataRequest_ChromeOs: MessageFns<UpdateTestDataRequest_ChromeOs> = {
  encode(message: UpdateTestDataRequest_ChromeOs, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.dutState !== undefined) {
      DutState.encode(message.dutState, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateTestDataRequest_ChromeOs {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTestDataRequest_ChromeOs() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dutState = DutState.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTestDataRequest_ChromeOs {
    return { dutState: isSet(object.dutState) ? DutState.fromJSON(object.dutState) : undefined };
  },

  toJSON(message: UpdateTestDataRequest_ChromeOs): unknown {
    const obj: any = {};
    if (message.dutState !== undefined) {
      obj.dutState = DutState.toJSON(message.dutState);
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateTestDataRequest_ChromeOs>): UpdateTestDataRequest_ChromeOs {
    return UpdateTestDataRequest_ChromeOs.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateTestDataRequest_ChromeOs>): UpdateTestDataRequest_ChromeOs {
    const message = createBaseUpdateTestDataRequest_ChromeOs() as any;
    message.dutState = (object.dutState !== undefined && object.dutState !== null)
      ? DutState.fromPartial(object.dutState)
      : undefined;
    return message;
  },
};

function createBaseUpdateTestDataRequest_Android(): UpdateTestDataRequest_Android {
  return {};
}

export const UpdateTestDataRequest_Android: MessageFns<UpdateTestDataRequest_Android> = {
  encode(_: UpdateTestDataRequest_Android, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateTestDataRequest_Android {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTestDataRequest_Android() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateTestDataRequest_Android {
    return {};
  },

  toJSON(_: UpdateTestDataRequest_Android): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<UpdateTestDataRequest_Android>): UpdateTestDataRequest_Android {
    return UpdateTestDataRequest_Android.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateTestDataRequest_Android>): UpdateTestDataRequest_Android {
    const message = createBaseUpdateTestDataRequest_Android() as any;
    return message;
  },
};

function createBaseUpdateTestDataResponse(): UpdateTestDataResponse {
  return {};
}

export const UpdateTestDataResponse: MessageFns<UpdateTestDataResponse> = {
  encode(_: UpdateTestDataResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateTestDataResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTestDataResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateTestDataResponse {
    return {};
  },

  toJSON(_: UpdateTestDataResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<UpdateTestDataResponse>): UpdateTestDataResponse {
    return UpdateTestDataResponse.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<UpdateTestDataResponse>): UpdateTestDataResponse {
    const message = createBaseUpdateTestDataResponse() as any;
    return message;
  },
};

function createBaseRackRegistrationRequest(): RackRegistrationRequest {
  return { rack: undefined };
}

export const RackRegistrationRequest: MessageFns<RackRegistrationRequest> = {
  encode(message: RackRegistrationRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.rack !== undefined) {
      Rack.encode(message.rack, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RackRegistrationRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRackRegistrationRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rack = Rack.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RackRegistrationRequest {
    return { rack: isSet(object.rack) ? Rack.fromJSON(object.rack) : undefined };
  },

  toJSON(message: RackRegistrationRequest): unknown {
    const obj: any = {};
    if (message.rack !== undefined) {
      obj.rack = Rack.toJSON(message.rack);
    }
    return obj;
  },

  create(base?: DeepPartial<RackRegistrationRequest>): RackRegistrationRequest {
    return RackRegistrationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RackRegistrationRequest>): RackRegistrationRequest {
    const message = createBaseRackRegistrationRequest() as any;
    message.rack = (object.rack !== undefined && object.rack !== null) ? Rack.fromPartial(object.rack) : undefined;
    return message;
  },
};

function createBaseNetworkOption(): NetworkOption {
  return { vlan: "", nic: "", delete: false, ip: "" };
}

export const NetworkOption: MessageFns<NetworkOption> = {
  encode(message: NetworkOption, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.vlan !== "") {
      writer.uint32(10).string(message.vlan);
    }
    if (message.nic !== "") {
      writer.uint32(18).string(message.nic);
    }
    if (message.delete !== false) {
      writer.uint32(24).bool(message.delete);
    }
    if (message.ip !== "") {
      writer.uint32(34).string(message.ip);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NetworkOption {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetworkOption() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.vlan = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nic = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.delete = reader.bool();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.ip = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NetworkOption {
    return {
      vlan: isSet(object.vlan) ? globalThis.String(object.vlan) : "",
      nic: isSet(object.nic) ? globalThis.String(object.nic) : "",
      delete: isSet(object.delete) ? globalThis.Boolean(object.delete) : false,
      ip: isSet(object.ip) ? globalThis.String(object.ip) : "",
    };
  },

  toJSON(message: NetworkOption): unknown {
    const obj: any = {};
    if (message.vlan !== "") {
      obj.vlan = message.vlan;
    }
    if (message.nic !== "") {
      obj.nic = message.nic;
    }
    if (message.delete !== false) {
      obj.delete = message.delete;
    }
    if (message.ip !== "") {
      obj.ip = message.ip;
    }
    return obj;
  },

  create(base?: DeepPartial<NetworkOption>): NetworkOption {
    return NetworkOption.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<NetworkOption>): NetworkOption {
    const message = createBaseNetworkOption() as any;
    message.vlan = object.vlan ?? "";
    message.nic = object.nic ?? "";
    message.delete = object.delete ?? false;
    message.ip = object.ip ?? "";
    return message;
  },
};

function createBaseCreateAssetRequest(): CreateAssetRequest {
  return { asset: undefined };
}

export const CreateAssetRequest: MessageFns<CreateAssetRequest> = {
  encode(message: CreateAssetRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.asset !== undefined) {
      asset.encode(message.asset, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateAssetRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAssetRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.asset = asset.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateAssetRequest {
    return { asset: isSet(object.asset) ? asset.fromJSON(object.asset) : undefined };
  },

  toJSON(message: CreateAssetRequest): unknown {
    const obj: any = {};
    if (message.asset !== undefined) {
      obj.asset = asset.toJSON(message.asset);
    }
    return obj;
  },

  create(base?: DeepPartial<CreateAssetRequest>): CreateAssetRequest {
    return CreateAssetRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateAssetRequest>): CreateAssetRequest {
    const message = createBaseCreateAssetRequest() as any;
    message.asset = (object.asset !== undefined && object.asset !== null) ? asset.fromPartial(object.asset) : undefined;
    return message;
  },
};

function createBaseUpdateAssetRequest(): UpdateAssetRequest {
  return { asset: undefined, updateMask: undefined };
}

export const UpdateAssetRequest: MessageFns<UpdateAssetRequest> = {
  encode(message: UpdateAssetRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.asset !== undefined) {
      asset.encode(message.asset, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateAssetRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateAssetRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.asset = asset.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateAssetRequest {
    return {
      asset: isSet(object.asset) ? asset.fromJSON(object.asset) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateAssetRequest): unknown {
    const obj: any = {};
    if (message.asset !== undefined) {
      obj.asset = asset.toJSON(message.asset);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateAssetRequest>): UpdateAssetRequest {
    return UpdateAssetRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateAssetRequest>): UpdateAssetRequest {
    const message = createBaseUpdateAssetRequest() as any;
    message.asset = (object.asset !== undefined && object.asset !== null) ? asset.fromPartial(object.asset) : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseGetAssetRequest(): GetAssetRequest {
  return { name: "" };
}

export const GetAssetRequest: MessageFns<GetAssetRequest> = {
  encode(message: GetAssetRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetAssetRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAssetRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAssetRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetAssetRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetAssetRequest>): GetAssetRequest {
    return GetAssetRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetAssetRequest>): GetAssetRequest {
    const message = createBaseGetAssetRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListAssetsRequest(): ListAssetsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListAssetsRequest: MessageFns<ListAssetsRequest> = {
  encode(message: ListAssetsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListAssetsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAssetsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAssetsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListAssetsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListAssetsRequest>): ListAssetsRequest {
    return ListAssetsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListAssetsRequest>): ListAssetsRequest {
    const message = createBaseListAssetsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListAssetsResponse(): ListAssetsResponse {
  return { assets: [], nextPageToken: "" };
}

export const ListAssetsResponse: MessageFns<ListAssetsResponse> = {
  encode(message: ListAssetsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.assets) {
      asset.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListAssetsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListAssetsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.assets.push(asset.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListAssetsResponse {
    return {
      assets: globalThis.Array.isArray(object?.assets) ? object.assets.map((e: any) => asset.fromJSON(e)) : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListAssetsResponse): unknown {
    const obj: any = {};
    if (message.assets?.length) {
      obj.assets = message.assets.map((e) => asset.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListAssetsResponse>): ListAssetsResponse {
    return ListAssetsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListAssetsResponse>): ListAssetsResponse {
    const message = createBaseListAssetsResponse() as any;
    message.assets = object.assets?.map((e) => asset.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteAssetRequest(): DeleteAssetRequest {
  return { name: "" };
}

export const DeleteAssetRequest: MessageFns<DeleteAssetRequest> = {
  encode(message: DeleteAssetRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteAssetRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAssetRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteAssetRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteAssetRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteAssetRequest>): DeleteAssetRequest {
    return DeleteAssetRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteAssetRequest>): DeleteAssetRequest {
    const message = createBaseDeleteAssetRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseRenameAssetRequest(): RenameAssetRequest {
  return { name: "", newName: "" };
}

export const RenameAssetRequest: MessageFns<RenameAssetRequest> = {
  encode(message: RenameAssetRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.newName !== "") {
      writer.uint32(18).string(message.newName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RenameAssetRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenameAssetRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.newName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RenameAssetRequest {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      newName: isSet(object.newName) ? globalThis.String(object.newName) : "",
    };
  },

  toJSON(message: RenameAssetRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.newName !== "") {
      obj.newName = message.newName;
    }
    return obj;
  },

  create(base?: DeepPartial<RenameAssetRequest>): RenameAssetRequest {
    return RenameAssetRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RenameAssetRequest>): RenameAssetRequest {
    const message = createBaseRenameAssetRequest() as any;
    message.name = object.name ?? "";
    message.newName = object.newName ?? "";
    return message;
  },
};

function createBaseBatchGetKVMsRequest(): BatchGetKVMsRequest {
  return { parent: "", names: [] };
}

export const BatchGetKVMsRequest: MessageFns<BatchGetKVMsRequest> = {
  encode(message: BatchGetKVMsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetKVMsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetKVMsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetKVMsRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetKVMsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetKVMsRequest>): BatchGetKVMsRequest {
    return BatchGetKVMsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetKVMsRequest>): BatchGetKVMsRequest {
    const message = createBaseBatchGetKVMsRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetKVMsResponse(): BatchGetKVMsResponse {
  return { KVMs: [] };
}

export const BatchGetKVMsResponse: MessageFns<BatchGetKVMsResponse> = {
  encode(message: BatchGetKVMsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.KVMs) {
      KVM.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetKVMsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetKVMsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.KVMs.push(KVM.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetKVMsResponse {
    return { KVMs: globalThis.Array.isArray(object?.KVMs) ? object.KVMs.map((e: any) => KVM.fromJSON(e)) : [] };
  },

  toJSON(message: BatchGetKVMsResponse): unknown {
    const obj: any = {};
    if (message.KVMs?.length) {
      obj.KVMs = message.KVMs.map((e) => KVM.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetKVMsResponse>): BatchGetKVMsResponse {
    return BatchGetKVMsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetKVMsResponse>): BatchGetKVMsResponse {
    const message = createBaseBatchGetKVMsResponse() as any;
    message.KVMs = object.KVMs?.map((e) => KVM.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetDHCPConfigsRequest(): BatchGetDHCPConfigsRequest {
  return { parent: "", names: [] };
}

export const BatchGetDHCPConfigsRequest: MessageFns<BatchGetDHCPConfigsRequest> = {
  encode(message: BatchGetDHCPConfigsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetDHCPConfigsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetDHCPConfigsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetDHCPConfigsRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetDHCPConfigsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetDHCPConfigsRequest>): BatchGetDHCPConfigsRequest {
    return BatchGetDHCPConfigsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetDHCPConfigsRequest>): BatchGetDHCPConfigsRequest {
    const message = createBaseBatchGetDHCPConfigsRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetDHCPConfigsResponse(): BatchGetDHCPConfigsResponse {
  return { dhcpConfigs: [] };
}

export const BatchGetDHCPConfigsResponse: MessageFns<BatchGetDHCPConfigsResponse> = {
  encode(message: BatchGetDHCPConfigsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.dhcpConfigs) {
      DHCPConfig.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetDHCPConfigsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetDHCPConfigsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dhcpConfigs.push(DHCPConfig.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetDHCPConfigsResponse {
    return {
      dhcpConfigs: globalThis.Array.isArray(object?.dhcpConfigs)
        ? object.dhcpConfigs.map((e: any) => DHCPConfig.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BatchGetDHCPConfigsResponse): unknown {
    const obj: any = {};
    if (message.dhcpConfigs?.length) {
      obj.dhcpConfigs = message.dhcpConfigs.map((e) => DHCPConfig.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetDHCPConfigsResponse>): BatchGetDHCPConfigsResponse {
    return BatchGetDHCPConfigsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetDHCPConfigsResponse>): BatchGetDHCPConfigsResponse {
    const message = createBaseBatchGetDHCPConfigsResponse() as any;
    message.dhcpConfigs = object.dhcpConfigs?.map((e) => DHCPConfig.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetMachineLSEsRequest(): BatchGetMachineLSEsRequest {
  return { parent: "", names: [] };
}

export const BatchGetMachineLSEsRequest: MessageFns<BatchGetMachineLSEsRequest> = {
  encode(message: BatchGetMachineLSEsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetMachineLSEsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetMachineLSEsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetMachineLSEsRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetMachineLSEsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetMachineLSEsRequest>): BatchGetMachineLSEsRequest {
    return BatchGetMachineLSEsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetMachineLSEsRequest>): BatchGetMachineLSEsRequest {
    const message = createBaseBatchGetMachineLSEsRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetMachineLSEsResponse(): BatchGetMachineLSEsResponse {
  return { machineLses: [] };
}

export const BatchGetMachineLSEsResponse: MessageFns<BatchGetMachineLSEsResponse> = {
  encode(message: BatchGetMachineLSEsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.machineLses) {
      MachineLSE.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetMachineLSEsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetMachineLSEsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineLses.push(MachineLSE.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetMachineLSEsResponse {
    return {
      machineLses: globalThis.Array.isArray(object?.machineLses)
        ? object.machineLses.map((e: any) => MachineLSE.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BatchGetMachineLSEsResponse): unknown {
    const obj: any = {};
    if (message.machineLses?.length) {
      obj.machineLses = message.machineLses.map((e) => MachineLSE.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetMachineLSEsResponse>): BatchGetMachineLSEsResponse {
    return BatchGetMachineLSEsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetMachineLSEsResponse>): BatchGetMachineLSEsResponse {
    const message = createBaseBatchGetMachineLSEsResponse() as any;
    message.machineLses = object.machineLses?.map((e) => MachineLSE.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetMachinesRequest(): BatchGetMachinesRequest {
  return { parent: "", names: [] };
}

export const BatchGetMachinesRequest: MessageFns<BatchGetMachinesRequest> = {
  encode(message: BatchGetMachinesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetMachinesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetMachinesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetMachinesRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetMachinesRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetMachinesRequest>): BatchGetMachinesRequest {
    return BatchGetMachinesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetMachinesRequest>): BatchGetMachinesRequest {
    const message = createBaseBatchGetMachinesRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetMachinesResponse(): BatchGetMachinesResponse {
  return { machines: [] };
}

export const BatchGetMachinesResponse: MessageFns<BatchGetMachinesResponse> = {
  encode(message: BatchGetMachinesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.machines) {
      Machine.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetMachinesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetMachinesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machines.push(Machine.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetMachinesResponse {
    return {
      machines: globalThis.Array.isArray(object?.machines) ? object.machines.map((e: any) => Machine.fromJSON(e)) : [],
    };
  },

  toJSON(message: BatchGetMachinesResponse): unknown {
    const obj: any = {};
    if (message.machines?.length) {
      obj.machines = message.machines.map((e) => Machine.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetMachinesResponse>): BatchGetMachinesResponse {
    return BatchGetMachinesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetMachinesResponse>): BatchGetMachinesResponse {
    const message = createBaseBatchGetMachinesResponse() as any;
    message.machines = object.machines?.map((e) => Machine.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetSwitchesRequest(): BatchGetSwitchesRequest {
  return { parent: "", names: [] };
}

export const BatchGetSwitchesRequest: MessageFns<BatchGetSwitchesRequest> = {
  encode(message: BatchGetSwitchesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetSwitchesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetSwitchesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetSwitchesRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetSwitchesRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetSwitchesRequest>): BatchGetSwitchesRequest {
    return BatchGetSwitchesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetSwitchesRequest>): BatchGetSwitchesRequest {
    const message = createBaseBatchGetSwitchesRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetSwitchesResponse(): BatchGetSwitchesResponse {
  return { switches: [] };
}

export const BatchGetSwitchesResponse: MessageFns<BatchGetSwitchesResponse> = {
  encode(message: BatchGetSwitchesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.switches) {
      Switch.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetSwitchesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetSwitchesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.switches.push(Switch.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetSwitchesResponse {
    return {
      switches: globalThis.Array.isArray(object?.switches) ? object.switches.map((e: any) => Switch.fromJSON(e)) : [],
    };
  },

  toJSON(message: BatchGetSwitchesResponse): unknown {
    const obj: any = {};
    if (message.switches?.length) {
      obj.switches = message.switches.map((e) => Switch.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetSwitchesResponse>): BatchGetSwitchesResponse {
    return BatchGetSwitchesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetSwitchesResponse>): BatchGetSwitchesResponse {
    const message = createBaseBatchGetSwitchesResponse() as any;
    message.switches = object.switches?.map((e) => Switch.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetRPMsRequest(): BatchGetRPMsRequest {
  return { parent: "", names: [] };
}

export const BatchGetRPMsRequest: MessageFns<BatchGetRPMsRequest> = {
  encode(message: BatchGetRPMsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetRPMsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetRPMsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetRPMsRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetRPMsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetRPMsRequest>): BatchGetRPMsRequest {
    return BatchGetRPMsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetRPMsRequest>): BatchGetRPMsRequest {
    const message = createBaseBatchGetRPMsRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetRPMsResponse(): BatchGetRPMsResponse {
  return { rpms: [] };
}

export const BatchGetRPMsResponse: MessageFns<BatchGetRPMsResponse> = {
  encode(message: BatchGetRPMsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.rpms) {
      RPM.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetRPMsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetRPMsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rpms.push(RPM.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetRPMsResponse {
    return { rpms: globalThis.Array.isArray(object?.rpms) ? object.rpms.map((e: any) => RPM.fromJSON(e)) : [] };
  },

  toJSON(message: BatchGetRPMsResponse): unknown {
    const obj: any = {};
    if (message.rpms?.length) {
      obj.rpms = message.rpms.map((e) => RPM.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetRPMsResponse>): BatchGetRPMsResponse {
    return BatchGetRPMsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetRPMsResponse>): BatchGetRPMsResponse {
    const message = createBaseBatchGetRPMsResponse() as any;
    message.rpms = object.rpms?.map((e) => RPM.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetDracsRequest(): BatchGetDracsRequest {
  return { parent: "", names: [] };
}

export const BatchGetDracsRequest: MessageFns<BatchGetDracsRequest> = {
  encode(message: BatchGetDracsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetDracsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetDracsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetDracsRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetDracsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetDracsRequest>): BatchGetDracsRequest {
    return BatchGetDracsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetDracsRequest>): BatchGetDracsRequest {
    const message = createBaseBatchGetDracsRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetDracsResponse(): BatchGetDracsResponse {
  return { dracs: [] };
}

export const BatchGetDracsResponse: MessageFns<BatchGetDracsResponse> = {
  encode(message: BatchGetDracsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.dracs) {
      Drac.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetDracsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetDracsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dracs.push(Drac.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetDracsResponse {
    return { dracs: globalThis.Array.isArray(object?.dracs) ? object.dracs.map((e: any) => Drac.fromJSON(e)) : [] };
  },

  toJSON(message: BatchGetDracsResponse): unknown {
    const obj: any = {};
    if (message.dracs?.length) {
      obj.dracs = message.dracs.map((e) => Drac.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetDracsResponse>): BatchGetDracsResponse {
    return BatchGetDracsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetDracsResponse>): BatchGetDracsResponse {
    const message = createBaseBatchGetDracsResponse() as any;
    message.dracs = object.dracs?.map((e) => Drac.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetNicsRequest(): BatchGetNicsRequest {
  return { parent: "", names: [] };
}

export const BatchGetNicsRequest: MessageFns<BatchGetNicsRequest> = {
  encode(message: BatchGetNicsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetNicsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetNicsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetNicsRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetNicsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetNicsRequest>): BatchGetNicsRequest {
    return BatchGetNicsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetNicsRequest>): BatchGetNicsRequest {
    const message = createBaseBatchGetNicsRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetNicsResponse(): BatchGetNicsResponse {
  return { nics: [] };
}

export const BatchGetNicsResponse: MessageFns<BatchGetNicsResponse> = {
  encode(message: BatchGetNicsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.nics) {
      Nic.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetNicsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetNicsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.nics.push(Nic.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetNicsResponse {
    return { nics: globalThis.Array.isArray(object?.nics) ? object.nics.map((e: any) => Nic.fromJSON(e)) : [] };
  },

  toJSON(message: BatchGetNicsResponse): unknown {
    const obj: any = {};
    if (message.nics?.length) {
      obj.nics = message.nics.map((e) => Nic.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetNicsResponse>): BatchGetNicsResponse {
    return BatchGetNicsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetNicsResponse>): BatchGetNicsResponse {
    const message = createBaseBatchGetNicsResponse() as any;
    message.nics = object.nics?.map((e) => Nic.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetVMsRequest(): BatchGetVMsRequest {
  return { parent: "", names: [] };
}

export const BatchGetVMsRequest: MessageFns<BatchGetVMsRequest> = {
  encode(message: BatchGetVMsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetVMsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetVMsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetVMsRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetVMsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetVMsRequest>): BatchGetVMsRequest {
    return BatchGetVMsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetVMsRequest>): BatchGetVMsRequest {
    const message = createBaseBatchGetVMsRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetVMsResponse(): BatchGetVMsResponse {
  return { vms: [] };
}

export const BatchGetVMsResponse: MessageFns<BatchGetVMsResponse> = {
  encode(message: BatchGetVMsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.vms) {
      VM.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetVMsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetVMsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.vms.push(VM.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetVMsResponse {
    return { vms: globalThis.Array.isArray(object?.vms) ? object.vms.map((e: any) => VM.fromJSON(e)) : [] };
  },

  toJSON(message: BatchGetVMsResponse): unknown {
    const obj: any = {};
    if (message.vms?.length) {
      obj.vms = message.vms.map((e) => VM.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetVMsResponse>): BatchGetVMsResponse {
    return BatchGetVMsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetVMsResponse>): BatchGetVMsResponse {
    const message = createBaseBatchGetVMsResponse() as any;
    message.vms = object.vms?.map((e) => VM.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetVlansRequest(): BatchGetVlansRequest {
  return { parent: "", names: [] };
}

export const BatchGetVlansRequest: MessageFns<BatchGetVlansRequest> = {
  encode(message: BatchGetVlansRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetVlansRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetVlansRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetVlansRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetVlansRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetVlansRequest>): BatchGetVlansRequest {
    return BatchGetVlansRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetVlansRequest>): BatchGetVlansRequest {
    const message = createBaseBatchGetVlansRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetVlansResponse(): BatchGetVlansResponse {
  return { vlans: [] };
}

export const BatchGetVlansResponse: MessageFns<BatchGetVlansResponse> = {
  encode(message: BatchGetVlansResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.vlans) {
      Vlan.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetVlansResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetVlansResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.vlans.push(Vlan.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetVlansResponse {
    return { vlans: globalThis.Array.isArray(object?.vlans) ? object.vlans.map((e: any) => Vlan.fromJSON(e)) : [] };
  },

  toJSON(message: BatchGetVlansResponse): unknown {
    const obj: any = {};
    if (message.vlans?.length) {
      obj.vlans = message.vlans.map((e) => Vlan.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetVlansResponse>): BatchGetVlansResponse {
    return BatchGetVlansResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetVlansResponse>): BatchGetVlansResponse {
    const message = createBaseBatchGetVlansResponse() as any;
    message.vlans = object.vlans?.map((e) => Vlan.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetRacksRequest(): BatchGetRacksRequest {
  return { parent: "", names: [] };
}

export const BatchGetRacksRequest: MessageFns<BatchGetRacksRequest> = {
  encode(message: BatchGetRacksRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetRacksRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetRacksRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetRacksRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetRacksRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetRacksRequest>): BatchGetRacksRequest {
    return BatchGetRacksRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetRacksRequest>): BatchGetRacksRequest {
    const message = createBaseBatchGetRacksRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetRacksResponse(): BatchGetRacksResponse {
  return { racks: [] };
}

export const BatchGetRacksResponse: MessageFns<BatchGetRacksResponse> = {
  encode(message: BatchGetRacksResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.racks) {
      Rack.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetRacksResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetRacksResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.racks.push(Rack.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetRacksResponse {
    return { racks: globalThis.Array.isArray(object?.racks) ? object.racks.map((e: any) => Rack.fromJSON(e)) : [] };
  },

  toJSON(message: BatchGetRacksResponse): unknown {
    const obj: any = {};
    if (message.racks?.length) {
      obj.racks = message.racks.map((e) => Rack.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetRacksResponse>): BatchGetRacksResponse {
    return BatchGetRacksResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetRacksResponse>): BatchGetRacksResponse {
    const message = createBaseBatchGetRacksResponse() as any;
    message.racks = object.racks?.map((e) => Rack.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetChromePlatformsRequest(): BatchGetChromePlatformsRequest {
  return { parent: "", names: [] };
}

export const BatchGetChromePlatformsRequest: MessageFns<BatchGetChromePlatformsRequest> = {
  encode(message: BatchGetChromePlatformsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetChromePlatformsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetChromePlatformsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetChromePlatformsRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetChromePlatformsRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetChromePlatformsRequest>): BatchGetChromePlatformsRequest {
    return BatchGetChromePlatformsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetChromePlatformsRequest>): BatchGetChromePlatformsRequest {
    const message = createBaseBatchGetChromePlatformsRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetChromePlatformsResponse(): BatchGetChromePlatformsResponse {
  return { chromePlatforms: [] };
}

export const BatchGetChromePlatformsResponse: MessageFns<BatchGetChromePlatformsResponse> = {
  encode(message: BatchGetChromePlatformsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.chromePlatforms) {
      ChromePlatform.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetChromePlatformsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetChromePlatformsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.chromePlatforms.push(ChromePlatform.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetChromePlatformsResponse {
    return {
      chromePlatforms: globalThis.Array.isArray(object?.chromePlatforms)
        ? object.chromePlatforms.map((e: any) => ChromePlatform.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BatchGetChromePlatformsResponse): unknown {
    const obj: any = {};
    if (message.chromePlatforms?.length) {
      obj.chromePlatforms = message.chromePlatforms.map((e) => ChromePlatform.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetChromePlatformsResponse>): BatchGetChromePlatformsResponse {
    return BatchGetChromePlatformsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetChromePlatformsResponse>): BatchGetChromePlatformsResponse {
    const message = createBaseBatchGetChromePlatformsResponse() as any;
    message.chromePlatforms = object.chromePlatforms?.map((e) => ChromePlatform.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetMachineLSEPrototypesRequest(): BatchGetMachineLSEPrototypesRequest {
  return { parent: "", names: [] };
}

export const BatchGetMachineLSEPrototypesRequest: MessageFns<BatchGetMachineLSEPrototypesRequest> = {
  encode(message: BatchGetMachineLSEPrototypesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetMachineLSEPrototypesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetMachineLSEPrototypesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetMachineLSEPrototypesRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetMachineLSEPrototypesRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetMachineLSEPrototypesRequest>): BatchGetMachineLSEPrototypesRequest {
    return BatchGetMachineLSEPrototypesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetMachineLSEPrototypesRequest>): BatchGetMachineLSEPrototypesRequest {
    const message = createBaseBatchGetMachineLSEPrototypesRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetMachineLSEPrototypesResponse(): BatchGetMachineLSEPrototypesResponse {
  return { machineLsePrototypes: [] };
}

export const BatchGetMachineLSEPrototypesResponse: MessageFns<BatchGetMachineLSEPrototypesResponse> = {
  encode(message: BatchGetMachineLSEPrototypesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.machineLsePrototypes) {
      MachineLSEPrototype.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetMachineLSEPrototypesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetMachineLSEPrototypesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.machineLsePrototypes.push(MachineLSEPrototype.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetMachineLSEPrototypesResponse {
    return {
      machineLsePrototypes: globalThis.Array.isArray(object?.machineLsePrototypes)
        ? object.machineLsePrototypes.map((e: any) => MachineLSEPrototype.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BatchGetMachineLSEPrototypesResponse): unknown {
    const obj: any = {};
    if (message.machineLsePrototypes?.length) {
      obj.machineLsePrototypes = message.machineLsePrototypes.map((e) => MachineLSEPrototype.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetMachineLSEPrototypesResponse>): BatchGetMachineLSEPrototypesResponse {
    return BatchGetMachineLSEPrototypesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetMachineLSEPrototypesResponse>): BatchGetMachineLSEPrototypesResponse {
    const message = createBaseBatchGetMachineLSEPrototypesResponse() as any;
    message.machineLsePrototypes = object.machineLsePrototypes?.map((e) => MachineLSEPrototype.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBatchGetRackLSEPrototypesRequest(): BatchGetRackLSEPrototypesRequest {
  return { parent: "", names: [] };
}

export const BatchGetRackLSEPrototypesRequest: MessageFns<BatchGetRackLSEPrototypesRequest> = {
  encode(message: BatchGetRackLSEPrototypesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parent !== "") {
      writer.uint32(10).string(message.parent);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetRackLSEPrototypesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetRackLSEPrototypesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parent = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetRackLSEPrototypesRequest {
    return {
      parent: isSet(object.parent) ? globalThis.String(object.parent) : "",
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: BatchGetRackLSEPrototypesRequest): unknown {
    const obj: any = {};
    if (message.parent !== "") {
      obj.parent = message.parent;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetRackLSEPrototypesRequest>): BatchGetRackLSEPrototypesRequest {
    return BatchGetRackLSEPrototypesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetRackLSEPrototypesRequest>): BatchGetRackLSEPrototypesRequest {
    const message = createBaseBatchGetRackLSEPrototypesRequest() as any;
    message.parent = object.parent ?? "";
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseBatchGetRackLSEPrototypesResponse(): BatchGetRackLSEPrototypesResponse {
  return { rackLsePrototypes: [] };
}

export const BatchGetRackLSEPrototypesResponse: MessageFns<BatchGetRackLSEPrototypesResponse> = {
  encode(message: BatchGetRackLSEPrototypesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.rackLsePrototypes) {
      RackLSEPrototype.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BatchGetRackLSEPrototypesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBatchGetRackLSEPrototypesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.rackLsePrototypes.push(RackLSEPrototype.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BatchGetRackLSEPrototypesResponse {
    return {
      rackLsePrototypes: globalThis.Array.isArray(object?.rackLsePrototypes)
        ? object.rackLsePrototypes.map((e: any) => RackLSEPrototype.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BatchGetRackLSEPrototypesResponse): unknown {
    const obj: any = {};
    if (message.rackLsePrototypes?.length) {
      obj.rackLsePrototypes = message.rackLsePrototypes.map((e) => RackLSEPrototype.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<BatchGetRackLSEPrototypesResponse>): BatchGetRackLSEPrototypesResponse {
    return BatchGetRackLSEPrototypesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BatchGetRackLSEPrototypesResponse>): BatchGetRackLSEPrototypesResponse {
    const message = createBaseBatchGetRackLSEPrototypesResponse() as any;
    message.rackLsePrototypes = object.rackLsePrototypes?.map((e) => RackLSEPrototype.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetChromeOSDeviceDataRequest(): GetChromeOSDeviceDataRequest {
  return { chromeosDeviceId: "", hostname: "" };
}

export const GetChromeOSDeviceDataRequest: MessageFns<GetChromeOSDeviceDataRequest> = {
  encode(message: GetChromeOSDeviceDataRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.chromeosDeviceId !== "") {
      writer.uint32(10).string(message.chromeosDeviceId);
    }
    if (message.hostname !== "") {
      writer.uint32(18).string(message.hostname);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetChromeOSDeviceDataRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetChromeOSDeviceDataRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.chromeosDeviceId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetChromeOSDeviceDataRequest {
    return {
      chromeosDeviceId: isSet(object.chromeosDeviceId) ? globalThis.String(object.chromeosDeviceId) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
    };
  },

  toJSON(message: GetChromeOSDeviceDataRequest): unknown {
    const obj: any = {};
    if (message.chromeosDeviceId !== "") {
      obj.chromeosDeviceId = message.chromeosDeviceId;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    return obj;
  },

  create(base?: DeepPartial<GetChromeOSDeviceDataRequest>): GetChromeOSDeviceDataRequest {
    return GetChromeOSDeviceDataRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetChromeOSDeviceDataRequest>): GetChromeOSDeviceDataRequest {
    const message = createBaseGetChromeOSDeviceDataRequest() as any;
    message.chromeosDeviceId = object.chromeosDeviceId ?? "";
    message.hostname = object.hostname ?? "";
    return message;
  },
};

function createBaseCreateCachingServiceRequest(): CreateCachingServiceRequest {
  return { cachingService: undefined, cachingServiceId: "" };
}

export const CreateCachingServiceRequest: MessageFns<CreateCachingServiceRequest> = {
  encode(message: CreateCachingServiceRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.cachingService !== undefined) {
      CachingService.encode(message.cachingService, writer.uint32(10).fork()).join();
    }
    if (message.cachingServiceId !== "") {
      writer.uint32(18).string(message.cachingServiceId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateCachingServiceRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCachingServiceRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.cachingService = CachingService.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.cachingServiceId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateCachingServiceRequest {
    return {
      cachingService: isSet(object.cachingService) ? CachingService.fromJSON(object.cachingService) : undefined,
      cachingServiceId: isSet(object.cachingServiceId) ? globalThis.String(object.cachingServiceId) : "",
    };
  },

  toJSON(message: CreateCachingServiceRequest): unknown {
    const obj: any = {};
    if (message.cachingService !== undefined) {
      obj.cachingService = CachingService.toJSON(message.cachingService);
    }
    if (message.cachingServiceId !== "") {
      obj.cachingServiceId = message.cachingServiceId;
    }
    return obj;
  },

  create(base?: DeepPartial<CreateCachingServiceRequest>): CreateCachingServiceRequest {
    return CreateCachingServiceRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateCachingServiceRequest>): CreateCachingServiceRequest {
    const message = createBaseCreateCachingServiceRequest() as any;
    message.cachingService = (object.cachingService !== undefined && object.cachingService !== null)
      ? CachingService.fromPartial(object.cachingService)
      : undefined;
    message.cachingServiceId = object.cachingServiceId ?? "";
    return message;
  },
};

function createBaseUpdateCachingServiceRequest(): UpdateCachingServiceRequest {
  return { cachingService: undefined, updateMask: undefined };
}

export const UpdateCachingServiceRequest: MessageFns<UpdateCachingServiceRequest> = {
  encode(message: UpdateCachingServiceRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.cachingService !== undefined) {
      CachingService.encode(message.cachingService, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateCachingServiceRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCachingServiceRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.cachingService = CachingService.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCachingServiceRequest {
    return {
      cachingService: isSet(object.cachingService) ? CachingService.fromJSON(object.cachingService) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateCachingServiceRequest): unknown {
    const obj: any = {};
    if (message.cachingService !== undefined) {
      obj.cachingService = CachingService.toJSON(message.cachingService);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateCachingServiceRequest>): UpdateCachingServiceRequest {
    return UpdateCachingServiceRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateCachingServiceRequest>): UpdateCachingServiceRequest {
    const message = createBaseUpdateCachingServiceRequest() as any;
    message.cachingService = (object.cachingService !== undefined && object.cachingService !== null)
      ? CachingService.fromPartial(object.cachingService)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseGetCachingServiceRequest(): GetCachingServiceRequest {
  return { name: "" };
}

export const GetCachingServiceRequest: MessageFns<GetCachingServiceRequest> = {
  encode(message: GetCachingServiceRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetCachingServiceRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCachingServiceRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCachingServiceRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetCachingServiceRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetCachingServiceRequest>): GetCachingServiceRequest {
    return GetCachingServiceRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetCachingServiceRequest>): GetCachingServiceRequest {
    const message = createBaseGetCachingServiceRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListCachingServicesRequest(): ListCachingServicesRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListCachingServicesRequest: MessageFns<ListCachingServicesRequest> = {
  encode(message: ListCachingServicesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListCachingServicesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCachingServicesRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCachingServicesRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListCachingServicesRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListCachingServicesRequest>): ListCachingServicesRequest {
    return ListCachingServicesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListCachingServicesRequest>): ListCachingServicesRequest {
    const message = createBaseListCachingServicesRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListCachingServicesResponse(): ListCachingServicesResponse {
  return { cachingServices: [], nextPageToken: "" };
}

export const ListCachingServicesResponse: MessageFns<ListCachingServicesResponse> = {
  encode(message: ListCachingServicesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.cachingServices) {
      CachingService.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListCachingServicesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCachingServicesResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.cachingServices.push(CachingService.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCachingServicesResponse {
    return {
      cachingServices: globalThis.Array.isArray(object?.cachingServices)
        ? object.cachingServices.map((e: any) => CachingService.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListCachingServicesResponse): unknown {
    const obj: any = {};
    if (message.cachingServices?.length) {
      obj.cachingServices = message.cachingServices.map((e) => CachingService.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListCachingServicesResponse>): ListCachingServicesResponse {
    return ListCachingServicesResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListCachingServicesResponse>): ListCachingServicesResponse {
    const message = createBaseListCachingServicesResponse() as any;
    message.cachingServices = object.cachingServices?.map((e) => CachingService.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteCachingServiceRequest(): DeleteCachingServiceRequest {
  return { name: "" };
}

export const DeleteCachingServiceRequest: MessageFns<DeleteCachingServiceRequest> = {
  encode(message: DeleteCachingServiceRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteCachingServiceRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteCachingServiceRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteCachingServiceRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteCachingServiceRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteCachingServiceRequest>): DeleteCachingServiceRequest {
    return DeleteCachingServiceRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteCachingServiceRequest>): DeleteCachingServiceRequest {
    const message = createBaseDeleteCachingServiceRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreateSchedulingUnitRequest(): CreateSchedulingUnitRequest {
  return { schedulingUnit: undefined, schedulingUnitId: "" };
}

export const CreateSchedulingUnitRequest: MessageFns<CreateSchedulingUnitRequest> = {
  encode(message: CreateSchedulingUnitRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.schedulingUnit !== undefined) {
      SchedulingUnit.encode(message.schedulingUnit, writer.uint32(10).fork()).join();
    }
    if (message.schedulingUnitId !== "") {
      writer.uint32(18).string(message.schedulingUnitId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateSchedulingUnitRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSchedulingUnitRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.schedulingUnit = SchedulingUnit.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.schedulingUnitId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSchedulingUnitRequest {
    return {
      schedulingUnit: isSet(object.schedulingUnit) ? SchedulingUnit.fromJSON(object.schedulingUnit) : undefined,
      schedulingUnitId: isSet(object.schedulingUnitId) ? globalThis.String(object.schedulingUnitId) : "",
    };
  },

  toJSON(message: CreateSchedulingUnitRequest): unknown {
    const obj: any = {};
    if (message.schedulingUnit !== undefined) {
      obj.schedulingUnit = SchedulingUnit.toJSON(message.schedulingUnit);
    }
    if (message.schedulingUnitId !== "") {
      obj.schedulingUnitId = message.schedulingUnitId;
    }
    return obj;
  },

  create(base?: DeepPartial<CreateSchedulingUnitRequest>): CreateSchedulingUnitRequest {
    return CreateSchedulingUnitRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateSchedulingUnitRequest>): CreateSchedulingUnitRequest {
    const message = createBaseCreateSchedulingUnitRequest() as any;
    message.schedulingUnit = (object.schedulingUnit !== undefined && object.schedulingUnit !== null)
      ? SchedulingUnit.fromPartial(object.schedulingUnit)
      : undefined;
    message.schedulingUnitId = object.schedulingUnitId ?? "";
    return message;
  },
};

function createBaseUpdateSchedulingUnitRequest(): UpdateSchedulingUnitRequest {
  return { schedulingUnit: undefined, updateMask: undefined };
}

export const UpdateSchedulingUnitRequest: MessageFns<UpdateSchedulingUnitRequest> = {
  encode(message: UpdateSchedulingUnitRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.schedulingUnit !== undefined) {
      SchedulingUnit.encode(message.schedulingUnit, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateSchedulingUnitRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSchedulingUnitRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.schedulingUnit = SchedulingUnit.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateSchedulingUnitRequest {
    return {
      schedulingUnit: isSet(object.schedulingUnit) ? SchedulingUnit.fromJSON(object.schedulingUnit) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateSchedulingUnitRequest): unknown {
    const obj: any = {};
    if (message.schedulingUnit !== undefined) {
      obj.schedulingUnit = SchedulingUnit.toJSON(message.schedulingUnit);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateSchedulingUnitRequest>): UpdateSchedulingUnitRequest {
    return UpdateSchedulingUnitRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateSchedulingUnitRequest>): UpdateSchedulingUnitRequest {
    const message = createBaseUpdateSchedulingUnitRequest() as any;
    message.schedulingUnit = (object.schedulingUnit !== undefined && object.schedulingUnit !== null)
      ? SchedulingUnit.fromPartial(object.schedulingUnit)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseGetSchedulingUnitRequest(): GetSchedulingUnitRequest {
  return { name: "" };
}

export const GetSchedulingUnitRequest: MessageFns<GetSchedulingUnitRequest> = {
  encode(message: GetSchedulingUnitRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetSchedulingUnitRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSchedulingUnitRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSchedulingUnitRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetSchedulingUnitRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetSchedulingUnitRequest>): GetSchedulingUnitRequest {
    return GetSchedulingUnitRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetSchedulingUnitRequest>): GetSchedulingUnitRequest {
    const message = createBaseGetSchedulingUnitRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListSchedulingUnitsRequest(): ListSchedulingUnitsRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListSchedulingUnitsRequest: MessageFns<ListSchedulingUnitsRequest> = {
  encode(message: ListSchedulingUnitsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListSchedulingUnitsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSchedulingUnitsRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListSchedulingUnitsRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListSchedulingUnitsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListSchedulingUnitsRequest>): ListSchedulingUnitsRequest {
    return ListSchedulingUnitsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListSchedulingUnitsRequest>): ListSchedulingUnitsRequest {
    const message = createBaseListSchedulingUnitsRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListSchedulingUnitsResponse(): ListSchedulingUnitsResponse {
  return { schedulingUnits: [], nextPageToken: "" };
}

export const ListSchedulingUnitsResponse: MessageFns<ListSchedulingUnitsResponse> = {
  encode(message: ListSchedulingUnitsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.schedulingUnits) {
      SchedulingUnit.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListSchedulingUnitsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListSchedulingUnitsResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.schedulingUnits.push(SchedulingUnit.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListSchedulingUnitsResponse {
    return {
      schedulingUnits: globalThis.Array.isArray(object?.schedulingUnits)
        ? object.schedulingUnits.map((e: any) => SchedulingUnit.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListSchedulingUnitsResponse): unknown {
    const obj: any = {};
    if (message.schedulingUnits?.length) {
      obj.schedulingUnits = message.schedulingUnits.map((e) => SchedulingUnit.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListSchedulingUnitsResponse>): ListSchedulingUnitsResponse {
    return ListSchedulingUnitsResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListSchedulingUnitsResponse>): ListSchedulingUnitsResponse {
    const message = createBaseListSchedulingUnitsResponse() as any;
    message.schedulingUnits = object.schedulingUnits?.map((e) => SchedulingUnit.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseDeleteSchedulingUnitRequest(): DeleteSchedulingUnitRequest {
  return { name: "" };
}

export const DeleteSchedulingUnitRequest: MessageFns<DeleteSchedulingUnitRequest> = {
  encode(message: DeleteSchedulingUnitRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteSchedulingUnitRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteSchedulingUnitRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteSchedulingUnitRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteSchedulingUnitRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteSchedulingUnitRequest>): DeleteSchedulingUnitRequest {
    return DeleteSchedulingUnitRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteSchedulingUnitRequest>): DeleteSchedulingUnitRequest {
    const message = createBaseDeleteSchedulingUnitRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseUpdateConfigBundleRequest(): UpdateConfigBundleRequest {
  return { configBundle: new Uint8Array(0), updateMask: undefined, allowMissing: false };
}

export const UpdateConfigBundleRequest: MessageFns<UpdateConfigBundleRequest> = {
  encode(message: UpdateConfigBundleRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.configBundle.length !== 0) {
      writer.uint32(10).bytes(message.configBundle);
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    if (message.allowMissing !== false) {
      writer.uint32(24).bool(message.allowMissing);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateConfigBundleRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateConfigBundleRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.configBundle = reader.bytes();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.allowMissing = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateConfigBundleRequest {
    return {
      configBundle: isSet(object.configBundle) ? bytesFromBase64(object.configBundle) : new Uint8Array(0),
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
      allowMissing: isSet(object.allowMissing) ? globalThis.Boolean(object.allowMissing) : false,
    };
  },

  toJSON(message: UpdateConfigBundleRequest): unknown {
    const obj: any = {};
    if (message.configBundle.length !== 0) {
      obj.configBundle = base64FromBytes(message.configBundle);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    if (message.allowMissing !== false) {
      obj.allowMissing = message.allowMissing;
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateConfigBundleRequest>): UpdateConfigBundleRequest {
    return UpdateConfigBundleRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateConfigBundleRequest>): UpdateConfigBundleRequest {
    const message = createBaseUpdateConfigBundleRequest() as any;
    message.configBundle = object.configBundle ?? new Uint8Array(0);
    message.updateMask = object.updateMask ?? undefined;
    message.allowMissing = object.allowMissing ?? false;
    return message;
  },
};

function createBaseUpdateConfigBundleResponse(): UpdateConfigBundleResponse {
  return { configBundle: new Uint8Array(0) };
}

export const UpdateConfigBundleResponse: MessageFns<UpdateConfigBundleResponse> = {
  encode(message: UpdateConfigBundleResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.configBundle.length !== 0) {
      writer.uint32(10).bytes(message.configBundle);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateConfigBundleResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateConfigBundleResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.configBundle = reader.bytes();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateConfigBundleResponse {
    return { configBundle: isSet(object.configBundle) ? bytesFromBase64(object.configBundle) : new Uint8Array(0) };
  },

  toJSON(message: UpdateConfigBundleResponse): unknown {
    const obj: any = {};
    if (message.configBundle.length !== 0) {
      obj.configBundle = base64FromBytes(message.configBundle);
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateConfigBundleResponse>): UpdateConfigBundleResponse {
    return UpdateConfigBundleResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateConfigBundleResponse>): UpdateConfigBundleResponse {
    const message = createBaseUpdateConfigBundleResponse() as any;
    message.configBundle = object.configBundle ?? new Uint8Array(0);
    return message;
  },
};

function createBaseGetDeviceDataRequest(): GetDeviceDataRequest {
  return { deviceId: "", hostname: "" };
}

export const GetDeviceDataRequest: MessageFns<GetDeviceDataRequest> = {
  encode(message: GetDeviceDataRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.deviceId !== "") {
      writer.uint32(10).string(message.deviceId);
    }
    if (message.hostname !== "") {
      writer.uint32(18).string(message.hostname);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeviceDataRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceDataRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceDataRequest {
    return {
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
    };
  },

  toJSON(message: GetDeviceDataRequest): unknown {
    const obj: any = {};
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    return obj;
  },

  create(base?: DeepPartial<GetDeviceDataRequest>): GetDeviceDataRequest {
    return GetDeviceDataRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetDeviceDataRequest>): GetDeviceDataRequest {
    const message = createBaseGetDeviceDataRequest() as any;
    message.deviceId = object.deviceId ?? "";
    message.hostname = object.hostname ?? "";
    return message;
  },
};

function createBaseGetDeviceDataResponse(): GetDeviceDataResponse {
  return {
    schedulingUnit: undefined,
    chromeOsDeviceData: undefined,
    attachedDeviceData: undefined,
    browserDeviceData: undefined,
    resourceType: 0,
  };
}

export const GetDeviceDataResponse: MessageFns<GetDeviceDataResponse> = {
  encode(message: GetDeviceDataResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.schedulingUnit !== undefined) {
      SchedulingUnit.encode(message.schedulingUnit, writer.uint32(10).fork()).join();
    }
    if (message.chromeOsDeviceData !== undefined) {
      ChromeOSDeviceData.encode(message.chromeOsDeviceData, writer.uint32(18).fork()).join();
    }
    if (message.attachedDeviceData !== undefined) {
      AttachedDeviceData.encode(message.attachedDeviceData, writer.uint32(26).fork()).join();
    }
    if (message.browserDeviceData !== undefined) {
      BrowserDeviceData.encode(message.browserDeviceData, writer.uint32(42).fork()).join();
    }
    if (message.resourceType !== 0) {
      writer.uint32(32).int32(message.resourceType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDeviceDataResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceDataResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.schedulingUnit = SchedulingUnit.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.chromeOsDeviceData = ChromeOSDeviceData.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.attachedDeviceData = AttachedDeviceData.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.browserDeviceData = BrowserDeviceData.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.resourceType = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceDataResponse {
    return {
      schedulingUnit: isSet(object.schedulingUnit) ? SchedulingUnit.fromJSON(object.schedulingUnit) : undefined,
      chromeOsDeviceData: isSet(object.chromeOsDeviceData)
        ? ChromeOSDeviceData.fromJSON(object.chromeOsDeviceData)
        : undefined,
      attachedDeviceData: isSet(object.attachedDeviceData)
        ? AttachedDeviceData.fromJSON(object.attachedDeviceData)
        : undefined,
      browserDeviceData: isSet(object.browserDeviceData)
        ? BrowserDeviceData.fromJSON(object.browserDeviceData)
        : undefined,
      resourceType: isSet(object.resourceType) ? getDeviceDataResponse_ResourceTypeFromJSON(object.resourceType) : 0,
    };
  },

  toJSON(message: GetDeviceDataResponse): unknown {
    const obj: any = {};
    if (message.schedulingUnit !== undefined) {
      obj.schedulingUnit = SchedulingUnit.toJSON(message.schedulingUnit);
    }
    if (message.chromeOsDeviceData !== undefined) {
      obj.chromeOsDeviceData = ChromeOSDeviceData.toJSON(message.chromeOsDeviceData);
    }
    if (message.attachedDeviceData !== undefined) {
      obj.attachedDeviceData = AttachedDeviceData.toJSON(message.attachedDeviceData);
    }
    if (message.browserDeviceData !== undefined) {
      obj.browserDeviceData = BrowserDeviceData.toJSON(message.browserDeviceData);
    }
    if (message.resourceType !== 0) {
      obj.resourceType = getDeviceDataResponse_ResourceTypeToJSON(message.resourceType);
    }
    return obj;
  },

  create(base?: DeepPartial<GetDeviceDataResponse>): GetDeviceDataResponse {
    return GetDeviceDataResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetDeviceDataResponse>): GetDeviceDataResponse {
    const message = createBaseGetDeviceDataResponse() as any;
    message.schedulingUnit = (object.schedulingUnit !== undefined && object.schedulingUnit !== null)
      ? SchedulingUnit.fromPartial(object.schedulingUnit)
      : undefined;
    message.chromeOsDeviceData = (object.chromeOsDeviceData !== undefined && object.chromeOsDeviceData !== null)
      ? ChromeOSDeviceData.fromPartial(object.chromeOsDeviceData)
      : undefined;
    message.attachedDeviceData = (object.attachedDeviceData !== undefined && object.attachedDeviceData !== null)
      ? AttachedDeviceData.fromPartial(object.attachedDeviceData)
      : undefined;
    message.browserDeviceData = (object.browserDeviceData !== undefined && object.browserDeviceData !== null)
      ? BrowserDeviceData.fromPartial(object.browserDeviceData)
      : undefined;
    message.resourceType = object.resourceType ?? 0;
    return message;
  },
};

function createBaseAttachedDeviceData(): AttachedDeviceData {
  return { labConfig: undefined, machine: undefined, dutState: undefined };
}

export const AttachedDeviceData: MessageFns<AttachedDeviceData> = {
  encode(message: AttachedDeviceData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.labConfig !== undefined) {
      MachineLSE.encode(message.labConfig, writer.uint32(10).fork()).join();
    }
    if (message.machine !== undefined) {
      Machine.encode(message.machine, writer.uint32(18).fork()).join();
    }
    if (message.dutState !== undefined) {
      DutState.encode(message.dutState, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AttachedDeviceData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttachedDeviceData() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.labConfig = MachineLSE.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.machine = Machine.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.dutState = DutState.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttachedDeviceData {
    return {
      labConfig: isSet(object.labConfig) ? MachineLSE.fromJSON(object.labConfig) : undefined,
      machine: isSet(object.machine) ? Machine.fromJSON(object.machine) : undefined,
      dutState: isSet(object.dutState) ? DutState.fromJSON(object.dutState) : undefined,
    };
  },

  toJSON(message: AttachedDeviceData): unknown {
    const obj: any = {};
    if (message.labConfig !== undefined) {
      obj.labConfig = MachineLSE.toJSON(message.labConfig);
    }
    if (message.machine !== undefined) {
      obj.machine = Machine.toJSON(message.machine);
    }
    if (message.dutState !== undefined) {
      obj.dutState = DutState.toJSON(message.dutState);
    }
    return obj;
  },

  create(base?: DeepPartial<AttachedDeviceData>): AttachedDeviceData {
    return AttachedDeviceData.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AttachedDeviceData>): AttachedDeviceData {
    const message = createBaseAttachedDeviceData() as any;
    message.labConfig = (object.labConfig !== undefined && object.labConfig !== null)
      ? MachineLSE.fromPartial(object.labConfig)
      : undefined;
    message.machine = (object.machine !== undefined && object.machine !== null)
      ? Machine.fromPartial(object.machine)
      : undefined;
    message.dutState = (object.dutState !== undefined && object.dutState !== null)
      ? DutState.fromPartial(object.dutState)
      : undefined;
    return message;
  },
};

function createBaseBrowserDeviceData(): BrowserDeviceData {
  return { host: undefined, vm: undefined };
}

export const BrowserDeviceData: MessageFns<BrowserDeviceData> = {
  encode(message: BrowserDeviceData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.host !== undefined) {
      MachineLSE.encode(message.host, writer.uint32(10).fork()).join();
    }
    if (message.vm !== undefined) {
      VM.encode(message.vm, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BrowserDeviceData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBrowserDeviceData() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.host = MachineLSE.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.vm = VM.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BrowserDeviceData {
    return {
      host: isSet(object.host) ? MachineLSE.fromJSON(object.host) : undefined,
      vm: isSet(object.vm) ? VM.fromJSON(object.vm) : undefined,
    };
  },

  toJSON(message: BrowserDeviceData): unknown {
    const obj: any = {};
    if (message.host !== undefined) {
      obj.host = MachineLSE.toJSON(message.host);
    }
    if (message.vm !== undefined) {
      obj.vm = VM.toJSON(message.vm);
    }
    return obj;
  },

  create(base?: DeepPartial<BrowserDeviceData>): BrowserDeviceData {
    return BrowserDeviceData.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BrowserDeviceData>): BrowserDeviceData {
    const message = createBaseBrowserDeviceData() as any;
    message.host = (object.host !== undefined && object.host !== null)
      ? MachineLSE.fromPartial(object.host)
      : undefined;
    message.vm = (object.vm !== undefined && object.vm !== null) ? VM.fromPartial(object.vm) : undefined;
    return message;
  },
};

function createBaseCheckFleetTestsPolicyRequest(): CheckFleetTestsPolicyRequest {
  return { testName: "", board: "", model: "", image: "", testServiceAccount: "", qsAccount: "" };
}

export const CheckFleetTestsPolicyRequest: MessageFns<CheckFleetTestsPolicyRequest> = {
  encode(message: CheckFleetTestsPolicyRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.testName !== "") {
      writer.uint32(10).string(message.testName);
    }
    if (message.board !== "") {
      writer.uint32(18).string(message.board);
    }
    if (message.model !== "") {
      writer.uint32(26).string(message.model);
    }
    if (message.image !== "") {
      writer.uint32(34).string(message.image);
    }
    if (message.testServiceAccount !== "") {
      writer.uint32(42).string(message.testServiceAccount);
    }
    if (message.qsAccount !== "") {
      writer.uint32(50).string(message.qsAccount);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CheckFleetTestsPolicyRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckFleetTestsPolicyRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.testName = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.board = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.model = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.image = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.testServiceAccount = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.qsAccount = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckFleetTestsPolicyRequest {
    return {
      testName: isSet(object.testName) ? globalThis.String(object.testName) : "",
      board: isSet(object.board) ? globalThis.String(object.board) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
      testServiceAccount: isSet(object.testServiceAccount) ? globalThis.String(object.testServiceAccount) : "",
      qsAccount: isSet(object.qsAccount) ? globalThis.String(object.qsAccount) : "",
    };
  },

  toJSON(message: CheckFleetTestsPolicyRequest): unknown {
    const obj: any = {};
    if (message.testName !== "") {
      obj.testName = message.testName;
    }
    if (message.board !== "") {
      obj.board = message.board;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.testServiceAccount !== "") {
      obj.testServiceAccount = message.testServiceAccount;
    }
    if (message.qsAccount !== "") {
      obj.qsAccount = message.qsAccount;
    }
    return obj;
  },

  create(base?: DeepPartial<CheckFleetTestsPolicyRequest>): CheckFleetTestsPolicyRequest {
    return CheckFleetTestsPolicyRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CheckFleetTestsPolicyRequest>): CheckFleetTestsPolicyRequest {
    const message = createBaseCheckFleetTestsPolicyRequest() as any;
    message.testName = object.testName ?? "";
    message.board = object.board ?? "";
    message.model = object.model ?? "";
    message.image = object.image ?? "";
    message.testServiceAccount = object.testServiceAccount ?? "";
    message.qsAccount = object.qsAccount ?? "";
    return message;
  },
};

function createBaseTestStatus(): TestStatus {
  return { code: 0, message: "" };
}

export const TestStatus: MessageFns<TestStatus> = {
  encode(message: TestStatus, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TestStatus {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestStatus() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestStatus {
    return {
      code: isSet(object.code) ? testStatus_CodeFromJSON(object.code) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: TestStatus): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = testStatus_CodeToJSON(message.code);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create(base?: DeepPartial<TestStatus>): TestStatus {
    return TestStatus.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TestStatus>): TestStatus {
    const message = createBaseTestStatus() as any;
    message.code = object.code ?? 0;
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseCheckFleetTestsPolicyResponse(): CheckFleetTestsPolicyResponse {
  return { isTestValid: false, testStatus: undefined };
}

export const CheckFleetTestsPolicyResponse: MessageFns<CheckFleetTestsPolicyResponse> = {
  encode(message: CheckFleetTestsPolicyResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.isTestValid !== false) {
      writer.uint32(8).bool(message.isTestValid);
    }
    if (message.testStatus !== undefined) {
      TestStatus.encode(message.testStatus, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CheckFleetTestsPolicyResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckFleetTestsPolicyResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.isTestValid = reader.bool();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.testStatus = TestStatus.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CheckFleetTestsPolicyResponse {
    return {
      isTestValid: isSet(object.isTestValid) ? globalThis.Boolean(object.isTestValid) : false,
      testStatus: isSet(object.testStatus) ? TestStatus.fromJSON(object.testStatus) : undefined,
    };
  },

  toJSON(message: CheckFleetTestsPolicyResponse): unknown {
    const obj: any = {};
    if (message.isTestValid !== false) {
      obj.isTestValid = message.isTestValid;
    }
    if (message.testStatus !== undefined) {
      obj.testStatus = TestStatus.toJSON(message.testStatus);
    }
    return obj;
  },

  create(base?: DeepPartial<CheckFleetTestsPolicyResponse>): CheckFleetTestsPolicyResponse {
    return CheckFleetTestsPolicyResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CheckFleetTestsPolicyResponse>): CheckFleetTestsPolicyResponse {
    const message = createBaseCheckFleetTestsPolicyResponse() as any;
    message.isTestValid = object.isTestValid ?? false;
    message.testStatus = (object.testStatus !== undefined && object.testStatus !== null)
      ? TestStatus.fromPartial(object.testStatus)
      : undefined;
    return message;
  },
};

function createBaseGetOwnershipDataRequest(): GetOwnershipDataRequest {
  return { hostname: "" };
}

export const GetOwnershipDataRequest: MessageFns<GetOwnershipDataRequest> = {
  encode(message: GetOwnershipDataRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetOwnershipDataRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOwnershipDataRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOwnershipDataRequest {
    return { hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "" };
  },

  toJSON(message: GetOwnershipDataRequest): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    return obj;
  },

  create(base?: DeepPartial<GetOwnershipDataRequest>): GetOwnershipDataRequest {
    return GetOwnershipDataRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetOwnershipDataRequest>): GetOwnershipDataRequest {
    const message = createBaseGetOwnershipDataRequest() as any;
    message.hostname = object.hostname ?? "";
    return message;
  },
};

function createBaseListOwnershipDataRequest(): ListOwnershipDataRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListOwnershipDataRequest: MessageFns<ListOwnershipDataRequest> = {
  encode(message: ListOwnershipDataRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListOwnershipDataRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOwnershipDataRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOwnershipDataRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListOwnershipDataRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListOwnershipDataRequest>): ListOwnershipDataRequest {
    return ListOwnershipDataRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListOwnershipDataRequest>): ListOwnershipDataRequest {
    const message = createBaseListOwnershipDataRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseOwnershipByHost(): OwnershipByHost {
  return { hostname: "", ownership: undefined };
}

export const OwnershipByHost: MessageFns<OwnershipByHost> = {
  encode(message: OwnershipByHost, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    if (message.ownership !== undefined) {
      OwnershipData.encode(message.ownership, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): OwnershipByHost {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOwnershipByHost() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.ownership = OwnershipData.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OwnershipByHost {
    return {
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      ownership: isSet(object.ownership) ? OwnershipData.fromJSON(object.ownership) : undefined,
    };
  },

  toJSON(message: OwnershipByHost): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.ownership !== undefined) {
      obj.ownership = OwnershipData.toJSON(message.ownership);
    }
    return obj;
  },

  create(base?: DeepPartial<OwnershipByHost>): OwnershipByHost {
    return OwnershipByHost.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<OwnershipByHost>): OwnershipByHost {
    const message = createBaseOwnershipByHost() as any;
    message.hostname = object.hostname ?? "";
    message.ownership = (object.ownership !== undefined && object.ownership !== null)
      ? OwnershipData.fromPartial(object.ownership)
      : undefined;
    return message;
  },
};

function createBaseListOwnershipDataResponse(): ListOwnershipDataResponse {
  return { ownershipData: [], nextPageToken: "" };
}

export const ListOwnershipDataResponse: MessageFns<ListOwnershipDataResponse> = {
  encode(message: ListOwnershipDataResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.ownershipData) {
      OwnershipByHost.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListOwnershipDataResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOwnershipDataResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.ownershipData.push(OwnershipByHost.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOwnershipDataResponse {
    return {
      ownershipData: globalThis.Array.isArray(object?.ownershipData)
        ? object.ownershipData.map((e: any) => OwnershipByHost.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListOwnershipDataResponse): unknown {
    const obj: any = {};
    if (message.ownershipData?.length) {
      obj.ownershipData = message.ownershipData.map((e) => OwnershipByHost.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListOwnershipDataResponse>): ListOwnershipDataResponse {
    return ListOwnershipDataResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListOwnershipDataResponse>): ListOwnershipDataResponse {
    const message = createBaseListOwnershipDataResponse() as any;
    message.ownershipData = object.ownershipData?.map((e) => OwnershipByHost.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseGetDefaultWifiRequest(): GetDefaultWifiRequest {
  return { name: "" };
}

export const GetDefaultWifiRequest: MessageFns<GetDefaultWifiRequest> = {
  encode(message: GetDefaultWifiRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetDefaultWifiRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDefaultWifiRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDefaultWifiRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: GetDefaultWifiRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<GetDefaultWifiRequest>): GetDefaultWifiRequest {
    return GetDefaultWifiRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<GetDefaultWifiRequest>): GetDefaultWifiRequest {
    const message = createBaseGetDefaultWifiRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListDefaultWifisRequest(): ListDefaultWifisRequest {
  return { pageSize: 0, pageToken: "", filter: "", keysOnly: false };
}

export const ListDefaultWifisRequest: MessageFns<ListDefaultWifisRequest> = {
  encode(message: ListDefaultWifisRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.filter !== "") {
      writer.uint32(26).string(message.filter);
    }
    if (message.keysOnly !== false) {
      writer.uint32(32).bool(message.keysOnly);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListDefaultWifisRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDefaultWifisRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.filter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.keysOnly = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDefaultWifisRequest {
    return {
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? globalThis.String(object.pageToken) : "",
      filter: isSet(object.filter) ? globalThis.String(object.filter) : "",
      keysOnly: isSet(object.keysOnly) ? globalThis.Boolean(object.keysOnly) : false,
    };
  },

  toJSON(message: ListDefaultWifisRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.filter !== "") {
      obj.filter = message.filter;
    }
    if (message.keysOnly !== false) {
      obj.keysOnly = message.keysOnly;
    }
    return obj;
  },

  create(base?: DeepPartial<ListDefaultWifisRequest>): ListDefaultWifisRequest {
    return ListDefaultWifisRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDefaultWifisRequest>): ListDefaultWifisRequest {
    const message = createBaseListDefaultWifisRequest() as any;
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.filter = object.filter ?? "";
    message.keysOnly = object.keysOnly ?? false;
    return message;
  },
};

function createBaseListDefaultWifisResponse(): ListDefaultWifisResponse {
  return { defaultWifis: [], nextPageToken: "" };
}

export const ListDefaultWifisResponse: MessageFns<ListDefaultWifisResponse> = {
  encode(message: ListDefaultWifisResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.defaultWifis) {
      DefaultWifi.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListDefaultWifisResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDefaultWifisResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.defaultWifis.push(DefaultWifi.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDefaultWifisResponse {
    return {
      defaultWifis: globalThis.Array.isArray(object?.defaultWifis)
        ? object.defaultWifis.map((e: any) => DefaultWifi.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? globalThis.String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListDefaultWifisResponse): unknown {
    const obj: any = {};
    if (message.defaultWifis?.length) {
      obj.defaultWifis = message.defaultWifis.map((e) => DefaultWifi.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create(base?: DeepPartial<ListDefaultWifisResponse>): ListDefaultWifisResponse {
    return ListDefaultWifisResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListDefaultWifisResponse>): ListDefaultWifisResponse {
    const message = createBaseListDefaultWifisResponse() as any;
    message.defaultWifis = object.defaultWifis?.map((e) => DefaultWifi.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseCreateDefaultWifiRequest(): CreateDefaultWifiRequest {
  return { defaultWifiId: "", defaultWifi: undefined };
}

export const CreateDefaultWifiRequest: MessageFns<CreateDefaultWifiRequest> = {
  encode(message: CreateDefaultWifiRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.defaultWifiId !== "") {
      writer.uint32(10).string(message.defaultWifiId);
    }
    if (message.defaultWifi !== undefined) {
      DefaultWifi.encode(message.defaultWifi, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateDefaultWifiRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateDefaultWifiRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.defaultWifiId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.defaultWifi = DefaultWifi.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateDefaultWifiRequest {
    return {
      defaultWifiId: isSet(object.defaultWifiId) ? globalThis.String(object.defaultWifiId) : "",
      defaultWifi: isSet(object.defaultWifi) ? DefaultWifi.fromJSON(object.defaultWifi) : undefined,
    };
  },

  toJSON(message: CreateDefaultWifiRequest): unknown {
    const obj: any = {};
    if (message.defaultWifiId !== "") {
      obj.defaultWifiId = message.defaultWifiId;
    }
    if (message.defaultWifi !== undefined) {
      obj.defaultWifi = DefaultWifi.toJSON(message.defaultWifi);
    }
    return obj;
  },

  create(base?: DeepPartial<CreateDefaultWifiRequest>): CreateDefaultWifiRequest {
    return CreateDefaultWifiRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CreateDefaultWifiRequest>): CreateDefaultWifiRequest {
    const message = createBaseCreateDefaultWifiRequest() as any;
    message.defaultWifiId = object.defaultWifiId ?? "";
    message.defaultWifi = (object.defaultWifi !== undefined && object.defaultWifi !== null)
      ? DefaultWifi.fromPartial(object.defaultWifi)
      : undefined;
    return message;
  },
};

function createBaseUpdateDefaultWifiRequest(): UpdateDefaultWifiRequest {
  return { defaultWifi: undefined, updateMask: undefined };
}

export const UpdateDefaultWifiRequest: MessageFns<UpdateDefaultWifiRequest> = {
  encode(message: UpdateDefaultWifiRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.defaultWifi !== undefined) {
      DefaultWifi.encode(message.defaultWifi, writer.uint32(10).fork()).join();
    }
    if (message.updateMask !== undefined) {
      FieldMask.encode(FieldMask.wrap(message.updateMask), writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateDefaultWifiRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDefaultWifiRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.defaultWifi = DefaultWifi.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.updateMask = FieldMask.unwrap(FieldMask.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateDefaultWifiRequest {
    return {
      defaultWifi: isSet(object.defaultWifi) ? DefaultWifi.fromJSON(object.defaultWifi) : undefined,
      updateMask: isSet(object.updateMask) ? FieldMask.unwrap(FieldMask.fromJSON(object.updateMask)) : undefined,
    };
  },

  toJSON(message: UpdateDefaultWifiRequest): unknown {
    const obj: any = {};
    if (message.defaultWifi !== undefined) {
      obj.defaultWifi = DefaultWifi.toJSON(message.defaultWifi);
    }
    if (message.updateMask !== undefined) {
      obj.updateMask = FieldMask.toJSON(FieldMask.wrap(message.updateMask));
    }
    return obj;
  },

  create(base?: DeepPartial<UpdateDefaultWifiRequest>): UpdateDefaultWifiRequest {
    return UpdateDefaultWifiRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateDefaultWifiRequest>): UpdateDefaultWifiRequest {
    const message = createBaseUpdateDefaultWifiRequest() as any;
    message.defaultWifi = (object.defaultWifi !== undefined && object.defaultWifi !== null)
      ? DefaultWifi.fromPartial(object.defaultWifi)
      : undefined;
    message.updateMask = object.updateMask ?? undefined;
    return message;
  },
};

function createBaseDeleteDefaultWifiRequest(): DeleteDefaultWifiRequest {
  return { name: "" };
}

export const DeleteDefaultWifiRequest: MessageFns<DeleteDefaultWifiRequest> = {
  encode(message: DeleteDefaultWifiRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteDefaultWifiRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteDefaultWifiRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteDefaultWifiRequest {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: DeleteDefaultWifiRequest): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<DeleteDefaultWifiRequest>): DeleteDefaultWifiRequest {
    return DeleteDefaultWifiRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteDefaultWifiRequest>): DeleteDefaultWifiRequest {
    const message = createBaseDeleteDefaultWifiRequest() as any;
    message.name = object.name ?? "";
    return message;
  },
};

export interface Fleet {
  /** CreateChromePlatform creates a new chromePlatform. */
  CreateChromePlatform(request: CreateChromePlatformRequest): Promise<ChromePlatform>;
  /** Update updates the chromePlatform */
  UpdateChromePlatform(request: UpdateChromePlatformRequest): Promise<ChromePlatform>;
  /** Get retrieves the details of the chromePlatform */
  GetChromePlatform(request: GetChromePlatformRequest): Promise<ChromePlatform>;
  /** List gets all the chromePlatforms */
  ListChromePlatforms(request: ListChromePlatformsRequest): Promise<ListChromePlatformsResponse>;
  /** Delete delete the chromePlatform */
  DeleteChromePlatform(request: DeleteChromePlatformRequest): Promise<Empty>;
  /** List all the chrome osversions. */
  ListOSVersions(request: ListOSVersionsRequest): Promise<ListOSVersionsResponse>;
  /** CreateMachineLSEPrototype creates a new MachineLSEPrototype. */
  CreateMachineLSEPrototype(request: CreateMachineLSEPrototypeRequest): Promise<MachineLSEPrototype>;
  /** Update updates the MachineLSEPrototype */
  UpdateMachineLSEPrototype(request: UpdateMachineLSEPrototypeRequest): Promise<MachineLSEPrototype>;
  /** Get retrieves the details of the MachineLSEPrototype */
  GetMachineLSEPrototype(request: GetMachineLSEPrototypeRequest): Promise<MachineLSEPrototype>;
  /** List gets all the MachineLSEPrototypes */
  ListMachineLSEPrototypes(request: ListMachineLSEPrototypesRequest): Promise<ListMachineLSEPrototypesResponse>;
  /** Delete delete the MachineLSEPrototype */
  DeleteMachineLSEPrototype(request: DeleteMachineLSEPrototypeRequest): Promise<Empty>;
  /** CreateRackLSEPrototype creates a new RackLSEPrototype. */
  CreateRackLSEPrototype(request: CreateRackLSEPrototypeRequest): Promise<RackLSEPrototype>;
  /** Update updates the RackLSEPrototype */
  UpdateRackLSEPrototype(request: UpdateRackLSEPrototypeRequest): Promise<RackLSEPrototype>;
  /** Get retrieves the details of the RackLSEPrototype */
  GetRackLSEPrototype(request: GetRackLSEPrototypeRequest): Promise<RackLSEPrototype>;
  /** List gets all the RackLSEPrototypes */
  ListRackLSEPrototypes(request: ListRackLSEPrototypesRequest): Promise<ListRackLSEPrototypesResponse>;
  /** Delete delete the RackLSEPrototype */
  DeleteRackLSEPrototype(request: DeleteRackLSEPrototypeRequest): Promise<Empty>;
  /** MachineRegistration creates a new machine/nics/drac. */
  MachineRegistration(request: MachineRegistrationRequest): Promise<Machine>;
  /** Update updates the machine */
  UpdateMachine(request: UpdateMachineRequest): Promise<Machine>;
  /** Get retrieves the details of the machine */
  GetMachine(request: GetMachineRequest): Promise<Machine>;
  /** List gets all the machines */
  ListMachines(request: ListMachinesRequest): Promise<ListMachinesResponse>;
  /** Delete delete the machine */
  DeleteMachine(request: DeleteMachineRequest): Promise<Empty>;
  /** Rename rename the machine */
  RenameMachine(request: RenameMachineRequest): Promise<Machine>;
  /** RackRegistration creates a new rack/kvms/rpms/switches */
  RackRegistration(request: RackRegistrationRequest): Promise<Rack>;
  /** Update updates the rack */
  UpdateRack(request: UpdateRackRequest): Promise<Rack>;
  /** Get retrieves the details of the rack */
  GetRack(request: GetRackRequest): Promise<Rack>;
  /** List gets all the racks */
  ListRacks(request: ListRacksRequest): Promise<ListRacksResponse>;
  /** Delete delete the rack */
  DeleteRack(request: DeleteRackRequest): Promise<Empty>;
  /** Rename rename the rack */
  RenameRack(request: RenameRackRequest): Promise<Rack>;
  /** CreateMachineLSE creates a new machineLSE */
  CreateMachineLSE(request: CreateMachineLSERequest): Promise<MachineLSE>;
  /** Update updates the machineLSE */
  UpdateMachineLSE(request: UpdateMachineLSERequest): Promise<MachineLSE>;
  /** Get retrieves the details of the machineLSE */
  GetMachineLSE(request: GetMachineLSERequest): Promise<MachineLSE>;
  /** Get retrieves the details of the machineLSE by serial number */
  GetMachineLSEBySerial(request: GetMachineLSEBySerialRequest): Promise<MachineLSE>;
  /** Get retrieves the details of the host by serial number */
  GetHostData(request: GetHostDataRequest): Promise<GetHostDataResponse>;
  /** List gets all the machineLSEs */
  ListMachineLSEs(request: ListMachineLSEsRequest): Promise<ListMachineLSEsResponse>;
  /** Delete delete the machineLSE */
  DeleteMachineLSE(request: DeleteMachineLSERequest): Promise<Empty>;
  /** Rename the machine lse */
  RenameMachineLSE(request: RenameMachineLSERequest): Promise<MachineLSE>;
  /** CreateRackLSE creates a new rackLSE */
  CreateRackLSE(request: CreateRackLSERequest): Promise<RackLSE>;
  /** Update updates the rackLSE */
  UpdateRackLSE(request: UpdateRackLSERequest): Promise<RackLSE>;
  /** Get retrieves the details of the rackLSE */
  GetRackLSE(request: GetRackLSERequest): Promise<RackLSE>;
  /** List gets all the rackLSEs */
  ListRackLSEs(request: ListRackLSEsRequest): Promise<ListRackLSEsResponse>;
  /** Delete delete the rackLSE */
  DeleteRackLSE(request: DeleteRackLSERequest): Promise<Empty>;
  /** CreateNic creates a new nic */
  CreateNic(request: CreateNicRequest): Promise<Nic>;
  /** Update updates the nic */
  UpdateNic(request: UpdateNicRequest): Promise<Nic>;
  /** Get retrieves the details of the nic */
  GetNic(request: GetNicRequest): Promise<Nic>;
  /** List gets all the nics */
  ListNics(request: ListNicsRequest): Promise<ListNicsResponse>;
  /** Delete delete the nic */
  DeleteNic(request: DeleteNicRequest): Promise<Empty>;
  /** Rename rename the nic */
  RenameNic(request: RenameNicRequest): Promise<Nic>;
  /** CreateKVM creates a new KVM */
  CreateKVM(request: CreateKVMRequest): Promise<KVM>;
  /** Update updates the KVM */
  UpdateKVM(request: UpdateKVMRequest): Promise<KVM>;
  /** Get retrieves the details of the KVM */
  GetKVM(request: GetKVMRequest): Promise<KVM>;
  /** List gets all the KVMs */
  ListKVMs(request: ListKVMsRequest): Promise<ListKVMsResponse>;
  /** Delete delete the KVM */
  DeleteKVM(request: DeleteKVMRequest): Promise<Empty>;
  /** CreateRPM creates a new RPM */
  CreateRPM(request: CreateRPMRequest): Promise<RPM>;
  /** Update updates the RPM */
  UpdateRPM(request: UpdateRPMRequest): Promise<RPM>;
  /** Get retrieves the details of the RPM */
  GetRPM(request: GetRPMRequest): Promise<RPM>;
  /** List gets all the RPMs */
  ListRPMs(request: ListRPMsRequest): Promise<ListRPMsResponse>;
  /** Delete delete the RPM */
  DeleteRPM(request: DeleteRPMRequest): Promise<Empty>;
  /** CreateDrac creates a new drac */
  CreateDrac(request: CreateDracRequest): Promise<Drac>;
  /** Update updates the drac */
  UpdateDrac(request: UpdateDracRequest): Promise<Drac>;
  /** Get retrieves the details of the drac */
  GetDrac(request: GetDracRequest): Promise<Drac>;
  /** List gets all the dracs */
  ListDracs(request: ListDracsRequest): Promise<ListDracsResponse>;
  /** Delete delete the drac */
  DeleteDrac(request: DeleteDracRequest): Promise<Empty>;
  /** CreateSwitch creates a new switch */
  CreateSwitch(request: CreateSwitchRequest): Promise<Switch>;
  /** Update updates the switch */
  UpdateSwitch(request: UpdateSwitchRequest): Promise<Switch>;
  /** Get retrieves the details of the switch */
  GetSwitch(request: GetSwitchRequest): Promise<Switch>;
  /** List gets all the switches */
  ListSwitches(request: ListSwitchesRequest): Promise<ListSwitchesResponse>;
  /** Delete delete the switch */
  DeleteSwitch(request: DeleteSwitchRequest): Promise<Empty>;
  /** Rename rename the switch */
  RenameSwitch(request: RenameSwitchRequest): Promise<Switch>;
  /** CreateVlan creates a new vlan */
  CreateVlan(request: CreateVlanRequest): Promise<Vlan>;
  /** Update updates the vlan */
  UpdateVlan(request: UpdateVlanRequest): Promise<Vlan>;
  /** Get retrieves the details of the vlan */
  GetVlan(request: GetVlanRequest): Promise<Vlan>;
  /** ListIPs retrieves the IPs */
  ListIPs(request: ListIPsRequest): Promise<ListIPsResponse>;
  /** List gets all the vlans */
  ListVlans(request: ListVlansRequest): Promise<ListVlansResponse>;
  /** Delete delete the vlan */
  DeleteVlan(request: DeleteVlanRequest): Promise<Empty>;
  /** ImportOSVlans imports the ChromeOS vlans, ips, and dhcp configs. */
  ImportOSVlans(request: ImportOSVlansRequest): Promise<Status>;
  /**
   * UpdateState updates the state for a resource.
   * If the state doesn't exist before, it will create the state record for the resource.
   */
  UpdateState(request: UpdateStateRequest): Promise<StateRecord>;
  /** GetState retrieves the state of a resource. */
  GetState(request: GetStateRequest): Promise<StateRecord>;
  /** GetDutState retrieves requested Chrome OS device DutState from UFS. */
  GetDutState(request: GetDutStateRequest): Promise<DutState>;
  /** ListDutStates gets all the DutStates */
  ListDutStates(request: ListDutStatesRequest): Promise<ListDutStatesResponse>;
  /**
   * UpdateDutState updates the state config for a DUT
   * If the dut state doesn't exist before, it will create the dut state record.
   */
  UpdateDutState(request: UpdateDutStateRequest): Promise<DutState>;
  /** UpdateDeviceRecoveryData updates the device configs for a DUT */
  UpdateDeviceRecoveryData(request: UpdateDeviceRecoveryDataRequest): Promise<UpdateDeviceRecoveryDataResponse>;
  /** UpdateTestData updates the device date provide by Test runner. */
  UpdateTestData(request: UpdateTestDataRequest): Promise<UpdateTestDataResponse>;
  /** GetDHCPConfig retrieves a dhcp record. */
  GetDHCPConfig(request: GetDHCPConfigRequest): Promise<DHCPConfig>;
  /** CreateVM creates a new VM */
  CreateVM(request: CreateVMRequest): Promise<VM>;
  /** UpdateVM updates a VM */
  UpdateVM(request: UpdateVMRequest): Promise<VM>;
  /** DeleteVM delete a VM */
  DeleteVM(request: DeleteVMRequest): Promise<Empty>;
  /** GetVM retrieves the details of the VM */
  GetVM(request: GetVMRequest): Promise<VM>;
  /** ListVMs gets all the Vms */
  ListVMs(request: ListVMsRequest): Promise<ListVMsResponse>;
  /** CreateAsset creates a new asset */
  CreateAsset(request: CreateAssetRequest): Promise<asset>;
  /** Update updates the asset */
  UpdateAsset(request: UpdateAssetRequest): Promise<asset>;
  /** Get retrieves the details of the asset */
  GetAsset(request: GetAssetRequest): Promise<asset>;
  /** List gets all the assets */
  ListAssets(request: ListAssetsRequest): Promise<ListAssetsResponse>;
  /** Delete delete the asset */
  DeleteAsset(request: DeleteAssetRequest): Promise<Empty>;
  /** Rename the asset */
  RenameAsset(request: RenameAssetRequest): Promise<asset>;
  /** BatchGetKVMs retrieves a batch of kvms */
  BatchGetKVMs(request: BatchGetKVMsRequest): Promise<BatchGetKVMsResponse>;
  /** BatchGetDHCPConfigs retrieves a batch of dhcp records. */
  BatchGetDHCPConfigs(request: BatchGetDHCPConfigsRequest): Promise<BatchGetDHCPConfigsResponse>;
  /** BatchGetMachineLSEs retrieves a batch of machineLSEs */
  BatchGetMachineLSEs(request: BatchGetMachineLSEsRequest): Promise<BatchGetMachineLSEsResponse>;
  /** BatchGetMachines retrieves a batch of machines */
  BatchGetMachines(request: BatchGetMachinesRequest): Promise<BatchGetMachinesResponse>;
  /** BatchGetSwitches retrieves a batch of switches */
  BatchGetSwitches(request: BatchGetSwitchesRequest): Promise<BatchGetSwitchesResponse>;
  /** BatchGetRPMs retrieves a batch of rpms */
  BatchGetRPMs(request: BatchGetRPMsRequest): Promise<BatchGetRPMsResponse>;
  /** BatchGetDracs retrieves a batch of dracs */
  BatchGetDracs(request: BatchGetDracsRequest): Promise<BatchGetDracsResponse>;
  /** BatchGetNics retrieves a batch of nics */
  BatchGetNics(request: BatchGetNicsRequest): Promise<BatchGetNicsResponse>;
  /** BatchGetVMs retrieves a batch of vms */
  BatchGetVMs(request: BatchGetVMsRequest): Promise<BatchGetVMsResponse>;
  /** BatchGetVlans retrieves a batch of vlans */
  BatchGetVlans(request: BatchGetVlansRequest): Promise<BatchGetVlansResponse>;
  /** BatchGetRacks retrieves a batch of racks */
  BatchGetRacks(request: BatchGetRacksRequest): Promise<BatchGetRacksResponse>;
  /** BatchGetChromePlatforms retrieves a batch of chrome platforms */
  BatchGetChromePlatforms(request: BatchGetChromePlatformsRequest): Promise<BatchGetChromePlatformsResponse>;
  /** BatchGetMachineLSEPrototypes retrieves a batch of machine lse prototypes */
  BatchGetMachineLSEPrototypes(
    request: BatchGetMachineLSEPrototypesRequest,
  ): Promise<BatchGetMachineLSEPrototypesResponse>;
  /** BatchGetRackLSEPrototypes retrieves a batch of rack lse prototypes */
  BatchGetRackLSEPrototypes(request: BatchGetRackLSEPrototypesRequest): Promise<BatchGetRackLSEPrototypesResponse>;
  /** GetChromeOSDeviceData retrieves requested Chrome OS device data from the UFS and inventoryV2. */
  GetChromeOSDeviceData(request: GetChromeOSDeviceDataRequest): Promise<ChromeOSDeviceData>;
  /** CreateCachingService creates a new CachingService. */
  CreateCachingService(request: CreateCachingServiceRequest): Promise<CachingService>;
  /** Update updates the CachingService. */
  UpdateCachingService(request: UpdateCachingServiceRequest): Promise<CachingService>;
  /** Get retrieves the details of the CachingService. */
  GetCachingService(request: GetCachingServiceRequest): Promise<CachingService>;
  /** List gets all the CachingServices. */
  ListCachingServices(request: ListCachingServicesRequest): Promise<ListCachingServicesResponse>;
  /** Delete delete the CachingService. */
  DeleteCachingService(request: DeleteCachingServiceRequest): Promise<Empty>;
  /** UpdateMachineLSEDeployment updates a deployment record for a host. */
  UpdateMachineLSEDeployment(request: UpdateMachineLSEDeploymentRequest): Promise<MachineLSEDeployment>;
  /** BatchUpdateMachineLSEDeployment updates the deployment records for a batch of hosts. */
  BatchUpdateMachineLSEDeployment(
    request: BatchUpdateMachineLSEDeploymentRequest,
  ): Promise<BatchUpdateMachineLSEDeploymentResponse>;
  /** GetMachineLSEDeployment retrieves a deployment record for a given host identifier, e.g. serial number. */
  GetMachineLSEDeployment(request: GetMachineLSEDeploymentRequest): Promise<MachineLSEDeployment>;
  /** BatchGetMachineLSEDeployments retrieves a batch of deployment records. */
  BatchGetMachineLSEDeployments(
    request: BatchGetMachineLSEDeploymentsRequest,
  ): Promise<BatchGetMachineLSEDeploymentsResponse>;
  /** ListMachineLSEDeployments lists all deployment records which fulfill the requirements */
  ListMachineLSEDeployments(request: ListMachineLSEDeploymentsRequest): Promise<ListMachineLSEDeploymentsResponse>;
  /** CreateSchedulingUnit creates a new SchedulingUnit. */
  CreateSchedulingUnit(request: CreateSchedulingUnitRequest): Promise<SchedulingUnit>;
  /** Update updates the SchedulingUnit. */
  UpdateSchedulingUnit(request: UpdateSchedulingUnitRequest): Promise<SchedulingUnit>;
  /** Get retrieves the details of the SchedulingUnit. */
  GetSchedulingUnit(request: GetSchedulingUnitRequest): Promise<SchedulingUnit>;
  /** List gets all the SchedulingUnits. */
  ListSchedulingUnits(request: ListSchedulingUnitsRequest): Promise<ListSchedulingUnitsResponse>;
  /** Delete delete the SchedulingUnit. */
  DeleteSchedulingUnit(request: DeleteSchedulingUnitRequest): Promise<Empty>;
  /** UpdateConfigBundle updates the ConfigBundle */
  UpdateConfigBundle(request: UpdateConfigBundleRequest): Promise<UpdateConfigBundleResponse>;
  /** GetDeviceData retrieves requested device data from UFS. */
  GetDeviceData(request: GetDeviceDataRequest): Promise<GetDeviceDataResponse>;
  /** CheckFleetPolicyForTest checks whether a given test parameters indicate a valid test */
  CheckFleetTestsPolicy(request: CheckFleetTestsPolicyRequest): Promise<CheckFleetTestsPolicyResponse>;
  /** GetOwnershipData retrieves the ownership data for a given machine/vm/machineLse */
  GetOwnershipData(request: GetOwnershipDataRequest): Promise<OwnershipData>;
  /** ListOwnershipData lists all the ownership/security_info data */
  ListOwnershipData(request: ListOwnershipDataRequest): Promise<ListOwnershipDataResponse>;
  /** GetDUTsForLabstation gets the DUTs associated with a labstation. */
  GetDUTsForLabstation(request: GetDUTsForLabstationRequest): Promise<GetDUTsForLabstationResponse>;
  /** GetDefaultWifi gets the specified default wifi. */
  GetDefaultWifi(request: GetDefaultWifiRequest): Promise<DefaultWifi>;
  /** ListDefaultWifi lists all the default wifis. */
  ListDefaultWifis(request: ListDefaultWifisRequest): Promise<ListDefaultWifisResponse>;
  /** CreateDefaultWifi creates a new default wifi. */
  CreateDefaultWifi(request: CreateDefaultWifiRequest): Promise<DefaultWifi>;
  /** UpdateDefaultWifi updates the specified default wifi. */
  UpdateDefaultWifi(request: UpdateDefaultWifiRequest): Promise<DefaultWifi>;
  /** DeleteDefaultWifi deletes the specified default wifi. */
  DeleteDefaultWifi(request: DeleteDefaultWifiRequest): Promise<Empty>;
}

export const FleetServiceName = "unifiedfleet.api.v1.rpc.Fleet";
export class FleetClientImpl implements Fleet {
  static readonly DEFAULT_SERVICE = FleetServiceName;
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || FleetServiceName;
    this.rpc = rpc;
    this.CreateChromePlatform = this.CreateChromePlatform.bind(this);
    this.UpdateChromePlatform = this.UpdateChromePlatform.bind(this);
    this.GetChromePlatform = this.GetChromePlatform.bind(this);
    this.ListChromePlatforms = this.ListChromePlatforms.bind(this);
    this.DeleteChromePlatform = this.DeleteChromePlatform.bind(this);
    this.ListOSVersions = this.ListOSVersions.bind(this);
    this.CreateMachineLSEPrototype = this.CreateMachineLSEPrototype.bind(this);
    this.UpdateMachineLSEPrototype = this.UpdateMachineLSEPrototype.bind(this);
    this.GetMachineLSEPrototype = this.GetMachineLSEPrototype.bind(this);
    this.ListMachineLSEPrototypes = this.ListMachineLSEPrototypes.bind(this);
    this.DeleteMachineLSEPrototype = this.DeleteMachineLSEPrototype.bind(this);
    this.CreateRackLSEPrototype = this.CreateRackLSEPrototype.bind(this);
    this.UpdateRackLSEPrototype = this.UpdateRackLSEPrototype.bind(this);
    this.GetRackLSEPrototype = this.GetRackLSEPrototype.bind(this);
    this.ListRackLSEPrototypes = this.ListRackLSEPrototypes.bind(this);
    this.DeleteRackLSEPrototype = this.DeleteRackLSEPrototype.bind(this);
    this.MachineRegistration = this.MachineRegistration.bind(this);
    this.UpdateMachine = this.UpdateMachine.bind(this);
    this.GetMachine = this.GetMachine.bind(this);
    this.ListMachines = this.ListMachines.bind(this);
    this.DeleteMachine = this.DeleteMachine.bind(this);
    this.RenameMachine = this.RenameMachine.bind(this);
    this.RackRegistration = this.RackRegistration.bind(this);
    this.UpdateRack = this.UpdateRack.bind(this);
    this.GetRack = this.GetRack.bind(this);
    this.ListRacks = this.ListRacks.bind(this);
    this.DeleteRack = this.DeleteRack.bind(this);
    this.RenameRack = this.RenameRack.bind(this);
    this.CreateMachineLSE = this.CreateMachineLSE.bind(this);
    this.UpdateMachineLSE = this.UpdateMachineLSE.bind(this);
    this.GetMachineLSE = this.GetMachineLSE.bind(this);
    this.GetMachineLSEBySerial = this.GetMachineLSEBySerial.bind(this);
    this.GetHostData = this.GetHostData.bind(this);
    this.ListMachineLSEs = this.ListMachineLSEs.bind(this);
    this.DeleteMachineLSE = this.DeleteMachineLSE.bind(this);
    this.RenameMachineLSE = this.RenameMachineLSE.bind(this);
    this.CreateRackLSE = this.CreateRackLSE.bind(this);
    this.UpdateRackLSE = this.UpdateRackLSE.bind(this);
    this.GetRackLSE = this.GetRackLSE.bind(this);
    this.ListRackLSEs = this.ListRackLSEs.bind(this);
    this.DeleteRackLSE = this.DeleteRackLSE.bind(this);
    this.CreateNic = this.CreateNic.bind(this);
    this.UpdateNic = this.UpdateNic.bind(this);
    this.GetNic = this.GetNic.bind(this);
    this.ListNics = this.ListNics.bind(this);
    this.DeleteNic = this.DeleteNic.bind(this);
    this.RenameNic = this.RenameNic.bind(this);
    this.CreateKVM = this.CreateKVM.bind(this);
    this.UpdateKVM = this.UpdateKVM.bind(this);
    this.GetKVM = this.GetKVM.bind(this);
    this.ListKVMs = this.ListKVMs.bind(this);
    this.DeleteKVM = this.DeleteKVM.bind(this);
    this.CreateRPM = this.CreateRPM.bind(this);
    this.UpdateRPM = this.UpdateRPM.bind(this);
    this.GetRPM = this.GetRPM.bind(this);
    this.ListRPMs = this.ListRPMs.bind(this);
    this.DeleteRPM = this.DeleteRPM.bind(this);
    this.CreateDrac = this.CreateDrac.bind(this);
    this.UpdateDrac = this.UpdateDrac.bind(this);
    this.GetDrac = this.GetDrac.bind(this);
    this.ListDracs = this.ListDracs.bind(this);
    this.DeleteDrac = this.DeleteDrac.bind(this);
    this.CreateSwitch = this.CreateSwitch.bind(this);
    this.UpdateSwitch = this.UpdateSwitch.bind(this);
    this.GetSwitch = this.GetSwitch.bind(this);
    this.ListSwitches = this.ListSwitches.bind(this);
    this.DeleteSwitch = this.DeleteSwitch.bind(this);
    this.RenameSwitch = this.RenameSwitch.bind(this);
    this.CreateVlan = this.CreateVlan.bind(this);
    this.UpdateVlan = this.UpdateVlan.bind(this);
    this.GetVlan = this.GetVlan.bind(this);
    this.ListIPs = this.ListIPs.bind(this);
    this.ListVlans = this.ListVlans.bind(this);
    this.DeleteVlan = this.DeleteVlan.bind(this);
    this.ImportOSVlans = this.ImportOSVlans.bind(this);
    this.UpdateState = this.UpdateState.bind(this);
    this.GetState = this.GetState.bind(this);
    this.GetDutState = this.GetDutState.bind(this);
    this.ListDutStates = this.ListDutStates.bind(this);
    this.UpdateDutState = this.UpdateDutState.bind(this);
    this.UpdateDeviceRecoveryData = this.UpdateDeviceRecoveryData.bind(this);
    this.UpdateTestData = this.UpdateTestData.bind(this);
    this.GetDHCPConfig = this.GetDHCPConfig.bind(this);
    this.CreateVM = this.CreateVM.bind(this);
    this.UpdateVM = this.UpdateVM.bind(this);
    this.DeleteVM = this.DeleteVM.bind(this);
    this.GetVM = this.GetVM.bind(this);
    this.ListVMs = this.ListVMs.bind(this);
    this.CreateAsset = this.CreateAsset.bind(this);
    this.UpdateAsset = this.UpdateAsset.bind(this);
    this.GetAsset = this.GetAsset.bind(this);
    this.ListAssets = this.ListAssets.bind(this);
    this.DeleteAsset = this.DeleteAsset.bind(this);
    this.RenameAsset = this.RenameAsset.bind(this);
    this.BatchGetKVMs = this.BatchGetKVMs.bind(this);
    this.BatchGetDHCPConfigs = this.BatchGetDHCPConfigs.bind(this);
    this.BatchGetMachineLSEs = this.BatchGetMachineLSEs.bind(this);
    this.BatchGetMachines = this.BatchGetMachines.bind(this);
    this.BatchGetSwitches = this.BatchGetSwitches.bind(this);
    this.BatchGetRPMs = this.BatchGetRPMs.bind(this);
    this.BatchGetDracs = this.BatchGetDracs.bind(this);
    this.BatchGetNics = this.BatchGetNics.bind(this);
    this.BatchGetVMs = this.BatchGetVMs.bind(this);
    this.BatchGetVlans = this.BatchGetVlans.bind(this);
    this.BatchGetRacks = this.BatchGetRacks.bind(this);
    this.BatchGetChromePlatforms = this.BatchGetChromePlatforms.bind(this);
    this.BatchGetMachineLSEPrototypes = this.BatchGetMachineLSEPrototypes.bind(this);
    this.BatchGetRackLSEPrototypes = this.BatchGetRackLSEPrototypes.bind(this);
    this.GetChromeOSDeviceData = this.GetChromeOSDeviceData.bind(this);
    this.CreateCachingService = this.CreateCachingService.bind(this);
    this.UpdateCachingService = this.UpdateCachingService.bind(this);
    this.GetCachingService = this.GetCachingService.bind(this);
    this.ListCachingServices = this.ListCachingServices.bind(this);
    this.DeleteCachingService = this.DeleteCachingService.bind(this);
    this.UpdateMachineLSEDeployment = this.UpdateMachineLSEDeployment.bind(this);
    this.BatchUpdateMachineLSEDeployment = this.BatchUpdateMachineLSEDeployment.bind(this);
    this.GetMachineLSEDeployment = this.GetMachineLSEDeployment.bind(this);
    this.BatchGetMachineLSEDeployments = this.BatchGetMachineLSEDeployments.bind(this);
    this.ListMachineLSEDeployments = this.ListMachineLSEDeployments.bind(this);
    this.CreateSchedulingUnit = this.CreateSchedulingUnit.bind(this);
    this.UpdateSchedulingUnit = this.UpdateSchedulingUnit.bind(this);
    this.GetSchedulingUnit = this.GetSchedulingUnit.bind(this);
    this.ListSchedulingUnits = this.ListSchedulingUnits.bind(this);
    this.DeleteSchedulingUnit = this.DeleteSchedulingUnit.bind(this);
    this.UpdateConfigBundle = this.UpdateConfigBundle.bind(this);
    this.GetDeviceData = this.GetDeviceData.bind(this);
    this.CheckFleetTestsPolicy = this.CheckFleetTestsPolicy.bind(this);
    this.GetOwnershipData = this.GetOwnershipData.bind(this);
    this.ListOwnershipData = this.ListOwnershipData.bind(this);
    this.GetDUTsForLabstation = this.GetDUTsForLabstation.bind(this);
    this.GetDefaultWifi = this.GetDefaultWifi.bind(this);
    this.ListDefaultWifis = this.ListDefaultWifis.bind(this);
    this.CreateDefaultWifi = this.CreateDefaultWifi.bind(this);
    this.UpdateDefaultWifi = this.UpdateDefaultWifi.bind(this);
    this.DeleteDefaultWifi = this.DeleteDefaultWifi.bind(this);
  }
  CreateChromePlatform(request: CreateChromePlatformRequest): Promise<ChromePlatform> {
    const data = CreateChromePlatformRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateChromePlatform", data);
    return promise.then((data) => ChromePlatform.fromJSON(data));
  }

  UpdateChromePlatform(request: UpdateChromePlatformRequest): Promise<ChromePlatform> {
    const data = UpdateChromePlatformRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateChromePlatform", data);
    return promise.then((data) => ChromePlatform.fromJSON(data));
  }

  GetChromePlatform(request: GetChromePlatformRequest): Promise<ChromePlatform> {
    const data = GetChromePlatformRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetChromePlatform", data);
    return promise.then((data) => ChromePlatform.fromJSON(data));
  }

  ListChromePlatforms(request: ListChromePlatformsRequest): Promise<ListChromePlatformsResponse> {
    const data = ListChromePlatformsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListChromePlatforms", data);
    return promise.then((data) => ListChromePlatformsResponse.fromJSON(data));
  }

  DeleteChromePlatform(request: DeleteChromePlatformRequest): Promise<Empty> {
    const data = DeleteChromePlatformRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteChromePlatform", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  ListOSVersions(request: ListOSVersionsRequest): Promise<ListOSVersionsResponse> {
    const data = ListOSVersionsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListOSVersions", data);
    return promise.then((data) => ListOSVersionsResponse.fromJSON(data));
  }

  CreateMachineLSEPrototype(request: CreateMachineLSEPrototypeRequest): Promise<MachineLSEPrototype> {
    const data = CreateMachineLSEPrototypeRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateMachineLSEPrototype", data);
    return promise.then((data) => MachineLSEPrototype.fromJSON(data));
  }

  UpdateMachineLSEPrototype(request: UpdateMachineLSEPrototypeRequest): Promise<MachineLSEPrototype> {
    const data = UpdateMachineLSEPrototypeRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateMachineLSEPrototype", data);
    return promise.then((data) => MachineLSEPrototype.fromJSON(data));
  }

  GetMachineLSEPrototype(request: GetMachineLSEPrototypeRequest): Promise<MachineLSEPrototype> {
    const data = GetMachineLSEPrototypeRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetMachineLSEPrototype", data);
    return promise.then((data) => MachineLSEPrototype.fromJSON(data));
  }

  ListMachineLSEPrototypes(request: ListMachineLSEPrototypesRequest): Promise<ListMachineLSEPrototypesResponse> {
    const data = ListMachineLSEPrototypesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListMachineLSEPrototypes", data);
    return promise.then((data) => ListMachineLSEPrototypesResponse.fromJSON(data));
  }

  DeleteMachineLSEPrototype(request: DeleteMachineLSEPrototypeRequest): Promise<Empty> {
    const data = DeleteMachineLSEPrototypeRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteMachineLSEPrototype", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  CreateRackLSEPrototype(request: CreateRackLSEPrototypeRequest): Promise<RackLSEPrototype> {
    const data = CreateRackLSEPrototypeRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateRackLSEPrototype", data);
    return promise.then((data) => RackLSEPrototype.fromJSON(data));
  }

  UpdateRackLSEPrototype(request: UpdateRackLSEPrototypeRequest): Promise<RackLSEPrototype> {
    const data = UpdateRackLSEPrototypeRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateRackLSEPrototype", data);
    return promise.then((data) => RackLSEPrototype.fromJSON(data));
  }

  GetRackLSEPrototype(request: GetRackLSEPrototypeRequest): Promise<RackLSEPrototype> {
    const data = GetRackLSEPrototypeRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetRackLSEPrototype", data);
    return promise.then((data) => RackLSEPrototype.fromJSON(data));
  }

  ListRackLSEPrototypes(request: ListRackLSEPrototypesRequest): Promise<ListRackLSEPrototypesResponse> {
    const data = ListRackLSEPrototypesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListRackLSEPrototypes", data);
    return promise.then((data) => ListRackLSEPrototypesResponse.fromJSON(data));
  }

  DeleteRackLSEPrototype(request: DeleteRackLSEPrototypeRequest): Promise<Empty> {
    const data = DeleteRackLSEPrototypeRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteRackLSEPrototype", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  MachineRegistration(request: MachineRegistrationRequest): Promise<Machine> {
    const data = MachineRegistrationRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "MachineRegistration", data);
    return promise.then((data) => Machine.fromJSON(data));
  }

  UpdateMachine(request: UpdateMachineRequest): Promise<Machine> {
    const data = UpdateMachineRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateMachine", data);
    return promise.then((data) => Machine.fromJSON(data));
  }

  GetMachine(request: GetMachineRequest): Promise<Machine> {
    const data = GetMachineRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetMachine", data);
    return promise.then((data) => Machine.fromJSON(data));
  }

  ListMachines(request: ListMachinesRequest): Promise<ListMachinesResponse> {
    const data = ListMachinesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListMachines", data);
    return promise.then((data) => ListMachinesResponse.fromJSON(data));
  }

  DeleteMachine(request: DeleteMachineRequest): Promise<Empty> {
    const data = DeleteMachineRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteMachine", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  RenameMachine(request: RenameMachineRequest): Promise<Machine> {
    const data = RenameMachineRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "RenameMachine", data);
    return promise.then((data) => Machine.fromJSON(data));
  }

  RackRegistration(request: RackRegistrationRequest): Promise<Rack> {
    const data = RackRegistrationRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "RackRegistration", data);
    return promise.then((data) => Rack.fromJSON(data));
  }

  UpdateRack(request: UpdateRackRequest): Promise<Rack> {
    const data = UpdateRackRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateRack", data);
    return promise.then((data) => Rack.fromJSON(data));
  }

  GetRack(request: GetRackRequest): Promise<Rack> {
    const data = GetRackRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetRack", data);
    return promise.then((data) => Rack.fromJSON(data));
  }

  ListRacks(request: ListRacksRequest): Promise<ListRacksResponse> {
    const data = ListRacksRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListRacks", data);
    return promise.then((data) => ListRacksResponse.fromJSON(data));
  }

  DeleteRack(request: DeleteRackRequest): Promise<Empty> {
    const data = DeleteRackRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteRack", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  RenameRack(request: RenameRackRequest): Promise<Rack> {
    const data = RenameRackRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "RenameRack", data);
    return promise.then((data) => Rack.fromJSON(data));
  }

  CreateMachineLSE(request: CreateMachineLSERequest): Promise<MachineLSE> {
    const data = CreateMachineLSERequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateMachineLSE", data);
    return promise.then((data) => MachineLSE.fromJSON(data));
  }

  UpdateMachineLSE(request: UpdateMachineLSERequest): Promise<MachineLSE> {
    const data = UpdateMachineLSERequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateMachineLSE", data);
    return promise.then((data) => MachineLSE.fromJSON(data));
  }

  GetMachineLSE(request: GetMachineLSERequest): Promise<MachineLSE> {
    const data = GetMachineLSERequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetMachineLSE", data);
    return promise.then((data) => MachineLSE.fromJSON(data));
  }

  GetMachineLSEBySerial(request: GetMachineLSEBySerialRequest): Promise<MachineLSE> {
    const data = GetMachineLSEBySerialRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetMachineLSEBySerial", data);
    return promise.then((data) => MachineLSE.fromJSON(data));
  }

  GetHostData(request: GetHostDataRequest): Promise<GetHostDataResponse> {
    const data = GetHostDataRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetHostData", data);
    return promise.then((data) => GetHostDataResponse.fromJSON(data));
  }

  ListMachineLSEs(request: ListMachineLSEsRequest): Promise<ListMachineLSEsResponse> {
    const data = ListMachineLSEsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListMachineLSEs", data);
    return promise.then((data) => ListMachineLSEsResponse.fromJSON(data));
  }

  DeleteMachineLSE(request: DeleteMachineLSERequest): Promise<Empty> {
    const data = DeleteMachineLSERequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteMachineLSE", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  RenameMachineLSE(request: RenameMachineLSERequest): Promise<MachineLSE> {
    const data = RenameMachineLSERequest.toJSON(request);
    const promise = this.rpc.request(this.service, "RenameMachineLSE", data);
    return promise.then((data) => MachineLSE.fromJSON(data));
  }

  CreateRackLSE(request: CreateRackLSERequest): Promise<RackLSE> {
    const data = CreateRackLSERequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateRackLSE", data);
    return promise.then((data) => RackLSE.fromJSON(data));
  }

  UpdateRackLSE(request: UpdateRackLSERequest): Promise<RackLSE> {
    const data = UpdateRackLSERequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateRackLSE", data);
    return promise.then((data) => RackLSE.fromJSON(data));
  }

  GetRackLSE(request: GetRackLSERequest): Promise<RackLSE> {
    const data = GetRackLSERequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetRackLSE", data);
    return promise.then((data) => RackLSE.fromJSON(data));
  }

  ListRackLSEs(request: ListRackLSEsRequest): Promise<ListRackLSEsResponse> {
    const data = ListRackLSEsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListRackLSEs", data);
    return promise.then((data) => ListRackLSEsResponse.fromJSON(data));
  }

  DeleteRackLSE(request: DeleteRackLSERequest): Promise<Empty> {
    const data = DeleteRackLSERequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteRackLSE", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  CreateNic(request: CreateNicRequest): Promise<Nic> {
    const data = CreateNicRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateNic", data);
    return promise.then((data) => Nic.fromJSON(data));
  }

  UpdateNic(request: UpdateNicRequest): Promise<Nic> {
    const data = UpdateNicRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateNic", data);
    return promise.then((data) => Nic.fromJSON(data));
  }

  GetNic(request: GetNicRequest): Promise<Nic> {
    const data = GetNicRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetNic", data);
    return promise.then((data) => Nic.fromJSON(data));
  }

  ListNics(request: ListNicsRequest): Promise<ListNicsResponse> {
    const data = ListNicsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListNics", data);
    return promise.then((data) => ListNicsResponse.fromJSON(data));
  }

  DeleteNic(request: DeleteNicRequest): Promise<Empty> {
    const data = DeleteNicRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteNic", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  RenameNic(request: RenameNicRequest): Promise<Nic> {
    const data = RenameNicRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "RenameNic", data);
    return promise.then((data) => Nic.fromJSON(data));
  }

  CreateKVM(request: CreateKVMRequest): Promise<KVM> {
    const data = CreateKVMRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateKVM", data);
    return promise.then((data) => KVM.fromJSON(data));
  }

  UpdateKVM(request: UpdateKVMRequest): Promise<KVM> {
    const data = UpdateKVMRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateKVM", data);
    return promise.then((data) => KVM.fromJSON(data));
  }

  GetKVM(request: GetKVMRequest): Promise<KVM> {
    const data = GetKVMRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetKVM", data);
    return promise.then((data) => KVM.fromJSON(data));
  }

  ListKVMs(request: ListKVMsRequest): Promise<ListKVMsResponse> {
    const data = ListKVMsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListKVMs", data);
    return promise.then((data) => ListKVMsResponse.fromJSON(data));
  }

  DeleteKVM(request: DeleteKVMRequest): Promise<Empty> {
    const data = DeleteKVMRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteKVM", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  CreateRPM(request: CreateRPMRequest): Promise<RPM> {
    const data = CreateRPMRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateRPM", data);
    return promise.then((data) => RPM.fromJSON(data));
  }

  UpdateRPM(request: UpdateRPMRequest): Promise<RPM> {
    const data = UpdateRPMRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateRPM", data);
    return promise.then((data) => RPM.fromJSON(data));
  }

  GetRPM(request: GetRPMRequest): Promise<RPM> {
    const data = GetRPMRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetRPM", data);
    return promise.then((data) => RPM.fromJSON(data));
  }

  ListRPMs(request: ListRPMsRequest): Promise<ListRPMsResponse> {
    const data = ListRPMsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListRPMs", data);
    return promise.then((data) => ListRPMsResponse.fromJSON(data));
  }

  DeleteRPM(request: DeleteRPMRequest): Promise<Empty> {
    const data = DeleteRPMRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteRPM", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  CreateDrac(request: CreateDracRequest): Promise<Drac> {
    const data = CreateDracRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateDrac", data);
    return promise.then((data) => Drac.fromJSON(data));
  }

  UpdateDrac(request: UpdateDracRequest): Promise<Drac> {
    const data = UpdateDracRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateDrac", data);
    return promise.then((data) => Drac.fromJSON(data));
  }

  GetDrac(request: GetDracRequest): Promise<Drac> {
    const data = GetDracRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetDrac", data);
    return promise.then((data) => Drac.fromJSON(data));
  }

  ListDracs(request: ListDracsRequest): Promise<ListDracsResponse> {
    const data = ListDracsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListDracs", data);
    return promise.then((data) => ListDracsResponse.fromJSON(data));
  }

  DeleteDrac(request: DeleteDracRequest): Promise<Empty> {
    const data = DeleteDracRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteDrac", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  CreateSwitch(request: CreateSwitchRequest): Promise<Switch> {
    const data = CreateSwitchRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateSwitch", data);
    return promise.then((data) => Switch.fromJSON(data));
  }

  UpdateSwitch(request: UpdateSwitchRequest): Promise<Switch> {
    const data = UpdateSwitchRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateSwitch", data);
    return promise.then((data) => Switch.fromJSON(data));
  }

  GetSwitch(request: GetSwitchRequest): Promise<Switch> {
    const data = GetSwitchRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetSwitch", data);
    return promise.then((data) => Switch.fromJSON(data));
  }

  ListSwitches(request: ListSwitchesRequest): Promise<ListSwitchesResponse> {
    const data = ListSwitchesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListSwitches", data);
    return promise.then((data) => ListSwitchesResponse.fromJSON(data));
  }

  DeleteSwitch(request: DeleteSwitchRequest): Promise<Empty> {
    const data = DeleteSwitchRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteSwitch", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  RenameSwitch(request: RenameSwitchRequest): Promise<Switch> {
    const data = RenameSwitchRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "RenameSwitch", data);
    return promise.then((data) => Switch.fromJSON(data));
  }

  CreateVlan(request: CreateVlanRequest): Promise<Vlan> {
    const data = CreateVlanRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateVlan", data);
    return promise.then((data) => Vlan.fromJSON(data));
  }

  UpdateVlan(request: UpdateVlanRequest): Promise<Vlan> {
    const data = UpdateVlanRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateVlan", data);
    return promise.then((data) => Vlan.fromJSON(data));
  }

  GetVlan(request: GetVlanRequest): Promise<Vlan> {
    const data = GetVlanRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetVlan", data);
    return promise.then((data) => Vlan.fromJSON(data));
  }

  ListIPs(request: ListIPsRequest): Promise<ListIPsResponse> {
    const data = ListIPsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListIPs", data);
    return promise.then((data) => ListIPsResponse.fromJSON(data));
  }

  ListVlans(request: ListVlansRequest): Promise<ListVlansResponse> {
    const data = ListVlansRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListVlans", data);
    return promise.then((data) => ListVlansResponse.fromJSON(data));
  }

  DeleteVlan(request: DeleteVlanRequest): Promise<Empty> {
    const data = DeleteVlanRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteVlan", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  ImportOSVlans(request: ImportOSVlansRequest): Promise<Status> {
    const data = ImportOSVlansRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ImportOSVlans", data);
    return promise.then((data) => Status.fromJSON(data));
  }

  UpdateState(request: UpdateStateRequest): Promise<StateRecord> {
    const data = UpdateStateRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateState", data);
    return promise.then((data) => StateRecord.fromJSON(data));
  }

  GetState(request: GetStateRequest): Promise<StateRecord> {
    const data = GetStateRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetState", data);
    return promise.then((data) => StateRecord.fromJSON(data));
  }

  GetDutState(request: GetDutStateRequest): Promise<DutState> {
    const data = GetDutStateRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetDutState", data);
    return promise.then((data) => DutState.fromJSON(data));
  }

  ListDutStates(request: ListDutStatesRequest): Promise<ListDutStatesResponse> {
    const data = ListDutStatesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListDutStates", data);
    return promise.then((data) => ListDutStatesResponse.fromJSON(data));
  }

  UpdateDutState(request: UpdateDutStateRequest): Promise<DutState> {
    const data = UpdateDutStateRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateDutState", data);
    return promise.then((data) => DutState.fromJSON(data));
  }

  UpdateDeviceRecoveryData(request: UpdateDeviceRecoveryDataRequest): Promise<UpdateDeviceRecoveryDataResponse> {
    const data = UpdateDeviceRecoveryDataRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateDeviceRecoveryData", data);
    return promise.then((data) => UpdateDeviceRecoveryDataResponse.fromJSON(data));
  }

  UpdateTestData(request: UpdateTestDataRequest): Promise<UpdateTestDataResponse> {
    const data = UpdateTestDataRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateTestData", data);
    return promise.then((data) => UpdateTestDataResponse.fromJSON(data));
  }

  GetDHCPConfig(request: GetDHCPConfigRequest): Promise<DHCPConfig> {
    const data = GetDHCPConfigRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetDHCPConfig", data);
    return promise.then((data) => DHCPConfig.fromJSON(data));
  }

  CreateVM(request: CreateVMRequest): Promise<VM> {
    const data = CreateVMRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateVM", data);
    return promise.then((data) => VM.fromJSON(data));
  }

  UpdateVM(request: UpdateVMRequest): Promise<VM> {
    const data = UpdateVMRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateVM", data);
    return promise.then((data) => VM.fromJSON(data));
  }

  DeleteVM(request: DeleteVMRequest): Promise<Empty> {
    const data = DeleteVMRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteVM", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  GetVM(request: GetVMRequest): Promise<VM> {
    const data = GetVMRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetVM", data);
    return promise.then((data) => VM.fromJSON(data));
  }

  ListVMs(request: ListVMsRequest): Promise<ListVMsResponse> {
    const data = ListVMsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListVMs", data);
    return promise.then((data) => ListVMsResponse.fromJSON(data));
  }

  CreateAsset(request: CreateAssetRequest): Promise<asset> {
    const data = CreateAssetRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateAsset", data);
    return promise.then((data) => asset.fromJSON(data));
  }

  UpdateAsset(request: UpdateAssetRequest): Promise<asset> {
    const data = UpdateAssetRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateAsset", data);
    return promise.then((data) => asset.fromJSON(data));
  }

  GetAsset(request: GetAssetRequest): Promise<asset> {
    const data = GetAssetRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetAsset", data);
    return promise.then((data) => asset.fromJSON(data));
  }

  ListAssets(request: ListAssetsRequest): Promise<ListAssetsResponse> {
    const data = ListAssetsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListAssets", data);
    return promise.then((data) => ListAssetsResponse.fromJSON(data));
  }

  DeleteAsset(request: DeleteAssetRequest): Promise<Empty> {
    const data = DeleteAssetRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteAsset", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  RenameAsset(request: RenameAssetRequest): Promise<asset> {
    const data = RenameAssetRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "RenameAsset", data);
    return promise.then((data) => asset.fromJSON(data));
  }

  BatchGetKVMs(request: BatchGetKVMsRequest): Promise<BatchGetKVMsResponse> {
    const data = BatchGetKVMsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetKVMs", data);
    return promise.then((data) => BatchGetKVMsResponse.fromJSON(data));
  }

  BatchGetDHCPConfigs(request: BatchGetDHCPConfigsRequest): Promise<BatchGetDHCPConfigsResponse> {
    const data = BatchGetDHCPConfigsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetDHCPConfigs", data);
    return promise.then((data) => BatchGetDHCPConfigsResponse.fromJSON(data));
  }

  BatchGetMachineLSEs(request: BatchGetMachineLSEsRequest): Promise<BatchGetMachineLSEsResponse> {
    const data = BatchGetMachineLSEsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetMachineLSEs", data);
    return promise.then((data) => BatchGetMachineLSEsResponse.fromJSON(data));
  }

  BatchGetMachines(request: BatchGetMachinesRequest): Promise<BatchGetMachinesResponse> {
    const data = BatchGetMachinesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetMachines", data);
    return promise.then((data) => BatchGetMachinesResponse.fromJSON(data));
  }

  BatchGetSwitches(request: BatchGetSwitchesRequest): Promise<BatchGetSwitchesResponse> {
    const data = BatchGetSwitchesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetSwitches", data);
    return promise.then((data) => BatchGetSwitchesResponse.fromJSON(data));
  }

  BatchGetRPMs(request: BatchGetRPMsRequest): Promise<BatchGetRPMsResponse> {
    const data = BatchGetRPMsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetRPMs", data);
    return promise.then((data) => BatchGetRPMsResponse.fromJSON(data));
  }

  BatchGetDracs(request: BatchGetDracsRequest): Promise<BatchGetDracsResponse> {
    const data = BatchGetDracsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetDracs", data);
    return promise.then((data) => BatchGetDracsResponse.fromJSON(data));
  }

  BatchGetNics(request: BatchGetNicsRequest): Promise<BatchGetNicsResponse> {
    const data = BatchGetNicsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetNics", data);
    return promise.then((data) => BatchGetNicsResponse.fromJSON(data));
  }

  BatchGetVMs(request: BatchGetVMsRequest): Promise<BatchGetVMsResponse> {
    const data = BatchGetVMsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetVMs", data);
    return promise.then((data) => BatchGetVMsResponse.fromJSON(data));
  }

  BatchGetVlans(request: BatchGetVlansRequest): Promise<BatchGetVlansResponse> {
    const data = BatchGetVlansRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetVlans", data);
    return promise.then((data) => BatchGetVlansResponse.fromJSON(data));
  }

  BatchGetRacks(request: BatchGetRacksRequest): Promise<BatchGetRacksResponse> {
    const data = BatchGetRacksRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetRacks", data);
    return promise.then((data) => BatchGetRacksResponse.fromJSON(data));
  }

  BatchGetChromePlatforms(request: BatchGetChromePlatformsRequest): Promise<BatchGetChromePlatformsResponse> {
    const data = BatchGetChromePlatformsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetChromePlatforms", data);
    return promise.then((data) => BatchGetChromePlatformsResponse.fromJSON(data));
  }

  BatchGetMachineLSEPrototypes(
    request: BatchGetMachineLSEPrototypesRequest,
  ): Promise<BatchGetMachineLSEPrototypesResponse> {
    const data = BatchGetMachineLSEPrototypesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetMachineLSEPrototypes", data);
    return promise.then((data) => BatchGetMachineLSEPrototypesResponse.fromJSON(data));
  }

  BatchGetRackLSEPrototypes(request: BatchGetRackLSEPrototypesRequest): Promise<BatchGetRackLSEPrototypesResponse> {
    const data = BatchGetRackLSEPrototypesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetRackLSEPrototypes", data);
    return promise.then((data) => BatchGetRackLSEPrototypesResponse.fromJSON(data));
  }

  GetChromeOSDeviceData(request: GetChromeOSDeviceDataRequest): Promise<ChromeOSDeviceData> {
    const data = GetChromeOSDeviceDataRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetChromeOSDeviceData", data);
    return promise.then((data) => ChromeOSDeviceData.fromJSON(data));
  }

  CreateCachingService(request: CreateCachingServiceRequest): Promise<CachingService> {
    const data = CreateCachingServiceRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateCachingService", data);
    return promise.then((data) => CachingService.fromJSON(data));
  }

  UpdateCachingService(request: UpdateCachingServiceRequest): Promise<CachingService> {
    const data = UpdateCachingServiceRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateCachingService", data);
    return promise.then((data) => CachingService.fromJSON(data));
  }

  GetCachingService(request: GetCachingServiceRequest): Promise<CachingService> {
    const data = GetCachingServiceRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetCachingService", data);
    return promise.then((data) => CachingService.fromJSON(data));
  }

  ListCachingServices(request: ListCachingServicesRequest): Promise<ListCachingServicesResponse> {
    const data = ListCachingServicesRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListCachingServices", data);
    return promise.then((data) => ListCachingServicesResponse.fromJSON(data));
  }

  DeleteCachingService(request: DeleteCachingServiceRequest): Promise<Empty> {
    const data = DeleteCachingServiceRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteCachingService", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  UpdateMachineLSEDeployment(request: UpdateMachineLSEDeploymentRequest): Promise<MachineLSEDeployment> {
    const data = UpdateMachineLSEDeploymentRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateMachineLSEDeployment", data);
    return promise.then((data) => MachineLSEDeployment.fromJSON(data));
  }

  BatchUpdateMachineLSEDeployment(
    request: BatchUpdateMachineLSEDeploymentRequest,
  ): Promise<BatchUpdateMachineLSEDeploymentResponse> {
    const data = BatchUpdateMachineLSEDeploymentRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchUpdateMachineLSEDeployment", data);
    return promise.then((data) => BatchUpdateMachineLSEDeploymentResponse.fromJSON(data));
  }

  GetMachineLSEDeployment(request: GetMachineLSEDeploymentRequest): Promise<MachineLSEDeployment> {
    const data = GetMachineLSEDeploymentRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetMachineLSEDeployment", data);
    return promise.then((data) => MachineLSEDeployment.fromJSON(data));
  }

  BatchGetMachineLSEDeployments(
    request: BatchGetMachineLSEDeploymentsRequest,
  ): Promise<BatchGetMachineLSEDeploymentsResponse> {
    const data = BatchGetMachineLSEDeploymentsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "BatchGetMachineLSEDeployments", data);
    return promise.then((data) => BatchGetMachineLSEDeploymentsResponse.fromJSON(data));
  }

  ListMachineLSEDeployments(request: ListMachineLSEDeploymentsRequest): Promise<ListMachineLSEDeploymentsResponse> {
    const data = ListMachineLSEDeploymentsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListMachineLSEDeployments", data);
    return promise.then((data) => ListMachineLSEDeploymentsResponse.fromJSON(data));
  }

  CreateSchedulingUnit(request: CreateSchedulingUnitRequest): Promise<SchedulingUnit> {
    const data = CreateSchedulingUnitRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateSchedulingUnit", data);
    return promise.then((data) => SchedulingUnit.fromJSON(data));
  }

  UpdateSchedulingUnit(request: UpdateSchedulingUnitRequest): Promise<SchedulingUnit> {
    const data = UpdateSchedulingUnitRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateSchedulingUnit", data);
    return promise.then((data) => SchedulingUnit.fromJSON(data));
  }

  GetSchedulingUnit(request: GetSchedulingUnitRequest): Promise<SchedulingUnit> {
    const data = GetSchedulingUnitRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetSchedulingUnit", data);
    return promise.then((data) => SchedulingUnit.fromJSON(data));
  }

  ListSchedulingUnits(request: ListSchedulingUnitsRequest): Promise<ListSchedulingUnitsResponse> {
    const data = ListSchedulingUnitsRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListSchedulingUnits", data);
    return promise.then((data) => ListSchedulingUnitsResponse.fromJSON(data));
  }

  DeleteSchedulingUnit(request: DeleteSchedulingUnitRequest): Promise<Empty> {
    const data = DeleteSchedulingUnitRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteSchedulingUnit", data);
    return promise.then((data) => Empty.fromJSON(data));
  }

  UpdateConfigBundle(request: UpdateConfigBundleRequest): Promise<UpdateConfigBundleResponse> {
    const data = UpdateConfigBundleRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateConfigBundle", data);
    return promise.then((data) => UpdateConfigBundleResponse.fromJSON(data));
  }

  GetDeviceData(request: GetDeviceDataRequest): Promise<GetDeviceDataResponse> {
    const data = GetDeviceDataRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetDeviceData", data);
    return promise.then((data) => GetDeviceDataResponse.fromJSON(data));
  }

  CheckFleetTestsPolicy(request: CheckFleetTestsPolicyRequest): Promise<CheckFleetTestsPolicyResponse> {
    const data = CheckFleetTestsPolicyRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CheckFleetTestsPolicy", data);
    return promise.then((data) => CheckFleetTestsPolicyResponse.fromJSON(data));
  }

  GetOwnershipData(request: GetOwnershipDataRequest): Promise<OwnershipData> {
    const data = GetOwnershipDataRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetOwnershipData", data);
    return promise.then((data) => OwnershipData.fromJSON(data));
  }

  ListOwnershipData(request: ListOwnershipDataRequest): Promise<ListOwnershipDataResponse> {
    const data = ListOwnershipDataRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListOwnershipData", data);
    return promise.then((data) => ListOwnershipDataResponse.fromJSON(data));
  }

  GetDUTsForLabstation(request: GetDUTsForLabstationRequest): Promise<GetDUTsForLabstationResponse> {
    const data = GetDUTsForLabstationRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetDUTsForLabstation", data);
    return promise.then((data) => GetDUTsForLabstationResponse.fromJSON(data));
  }

  GetDefaultWifi(request: GetDefaultWifiRequest): Promise<DefaultWifi> {
    const data = GetDefaultWifiRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "GetDefaultWifi", data);
    return promise.then((data) => DefaultWifi.fromJSON(data));
  }

  ListDefaultWifis(request: ListDefaultWifisRequest): Promise<ListDefaultWifisResponse> {
    const data = ListDefaultWifisRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "ListDefaultWifis", data);
    return promise.then((data) => ListDefaultWifisResponse.fromJSON(data));
  }

  CreateDefaultWifi(request: CreateDefaultWifiRequest): Promise<DefaultWifi> {
    const data = CreateDefaultWifiRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "CreateDefaultWifi", data);
    return promise.then((data) => DefaultWifi.fromJSON(data));
  }

  UpdateDefaultWifi(request: UpdateDefaultWifiRequest): Promise<DefaultWifi> {
    const data = UpdateDefaultWifiRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "UpdateDefaultWifi", data);
    return promise.then((data) => DefaultWifi.fromJSON(data));
  }

  DeleteDefaultWifi(request: DeleteDefaultWifiRequest): Promise<Empty> {
    const data = DeleteDefaultWifiRequest.toJSON(request);
    const promise = this.rpc.request(this.service, "DeleteDefaultWifi", data);
    return promise.then((data) => Empty.fromJSON(data));
  }
}

interface Rpc {
  request(service: string, method: string, data: unknown): Promise<unknown>;
}

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
